import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import DepartmentsApi from '../../../../api/department'
import ColorPicker from '../../../../components/Inputs/ColorPicker'
import EditorToolbar, {
  formats,
  modules
} from '../../../../components/Inputs/EditorToolbar'

import LoadingFullpage from 'components/Progress/LoadingFullpage'
import { Field, Form, Formik } from 'formik'
import { BiSave } from 'react-icons/bi'
import { MdOutlineCancel } from 'react-icons/md'
import TypesApi from '../../../../api/types'

function TypesForm(props) {
  const { typeId, setForm, loadData } = props
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [departments, setDepartments] = useState([{}])
  const toast = useToast()
  const [content, setContent] = useState('')
  const [initialData, setInitialData] = useState({
    name: '',
    color: '',
    comments: '',
    status: '1',
    id_departments: []
  })
  useEffect(() => {
    loadDepartments()
  }, [])
  useEffect(() => {
    if (typeId !== false && !loading) {
      loadDataItem()
    }
  }, [typeId])

  const loadDataItem = async () => {
    setLoading(true)
    try {
      let response = await TypesApi.Show(typeId)
      setLoading(false)
      if (response.data.error === true) {
        toast({
          title: 'Erro',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        return setForm(false)
      }
      setContent(response.data.result.comments)
      return setInitialData(response.data.result)
    } catch (err) {
      setLoading(false)
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      return setForm(false)
    }
  }

  const handleSubmit = async values => {
    setLoading(true)
    try {
      let response = {}
      values.comments = content
      if (typeId) {
        response = await TypesApi.Update(values, typeId)
      } else {
        response = await TypesApi.Save(values)
      }

      setLoading(false)
      toast({
        title: 'Sucesso',
        description: response.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      loadData()
      return setForm(false)
    } catch (err) {
      setLoading(false)
      let erro = ''
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      return toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }
  const loadDepartments = async () => {
    try {
      let perms = await DepartmentsApi.All()
      return setDepartments(perms.data)
    } catch (err) {
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  const onChangeContents = (contents) => {
    let _contents = null;
    if (contents.indexOf("<p><br></p>") > -1) {
      _contents = contents.replace(/<p><br><\/p>/gi, "<p>&nbsp;</p>");
    }
    setContent(_contents || contents);
  }

  return (
    <>
    {loading && <LoadingFullpage />}
    <Formik
      initialValues={initialData}
      enableReinitialize

      onSubmit={async values => {
        handleSubmit(values)
      }}
    >
      {props => (
        <Form>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            <Field name='name'>
              {({ field, form }) => (
                <FormControl isInvalid={errors.name}>
                  <FormLabel
                     fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='name'
                  >
                    Nome
                  </FormLabel>
                  <Input size='sm' {...field} id='name' placeholder='Nome' />

                  {errors.name && (
                    <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>

            <Field name='status'>
              {({ field, form }) => (
                <FormControl isInvalid={errors.status}>
                  <FormLabel
                     fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='status'
                  >
                    Status
                  </FormLabel>
                  <Select size='sm' {...field} id='status'>
                    <option value='1' key={1}>
                      Ativo
                    </option>
                    <option value='0' key={0}>
                      Inativo
                    </option>
                  </Select>

                  {errors.status && (
                    <FormErrorMessage>{errors.status[0]}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex my='20px'>
            <FormControl>
              <FormLabel
                fontSize='sm'
                fontWeight='bold'
                colorScheme='gray'
                htmlFor='comments'
              >
                Observações
              </FormLabel>
              <Box bg="white" color='black'>
                <EditorToolbar />
                <ReactQuill
                  id='snow'
                  theme='snow'
                  value={content}
                  onChange={onChangeContents}
                  placeholder={'Insira um conteúdo para mostrar como observações deste tipo...'}
                  modules={modules}
                  formats={formats}
                />
              </Box>
            </FormControl>
          </Flex>
          <Flex flexDirection={{ sm: 'column', lg: 'row' }} w='100%'>
            <ColorPicker
              label=''
              onChange={color => {
                props.setFieldValue('color', color)
              }}
            />
          </Flex>
          <Flex flexDirection={{ sm: 'column', lg: 'row' }} mt='10px'>
            <Badge
              backgroundColor={props.values.color}
              color={props.values.color != '' ? '#ffffff' : '#333333'}
              fontSize='md'
              fontWeight='extrabold'
              borderRadius={6}
              py={1}
              px={4}
            >
              {props.values.name}
            </Badge>
          </Flex>
          <Flex flexDirection={{ sm: 'column' }} mt='10px'>
            <Box fontSize='sm' fontWeight='bold' colorScheme='gray'>
              Departamentos
            </Box>
            <SimpleGrid columns={4} spacing={10}>
              {departments.map((val, i) => {
                return (
                  <Box p='2'>
                    <Field
                      name='id_departments'
                      type='checkbox'
                      value={`${val.id}`}
                      key={val.id}
                    >
                      {({ field, form }) => (

                        <Checkbox
                          {...field}
                          colorScheme='gray'
                          isChecked={field.checked}
                        >
                          {val.name}
                        </Checkbox>
                      )}
                    </Field>
                  </Box>
                )
              })}
            </SimpleGrid>
          </Flex>
          <Flex
            align='end'
            justify='end'
            gap={2}
            mt='10px'
            flexDirection={{ sm: 'column', lg: 'row' }}
          >
            <Button
              leftIcon={<MdOutlineCancel />}
              size='sm'
              color='white'
              colorScheme='gray'
              cursor='pointer'
              type='button'
              
              w={{ sm: '100%', lg: 'auto' }}
              onClick={() => {
                setForm(false)
              }}
            >
              Cancelar
            </Button>
            <Button
              leftIcon={<BiSave />}
              size='sm'
              color='white'
              bg='primary'
              variant='no-hover'
              cursor='pointer'
              type='submit'
              
              w={{ sm: '100%', lg: 'auto' }}
            >
              Salvar
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
    </>
  )
}

export default TypesForm
