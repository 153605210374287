import React, { useState, useEffect } from 'react'
import {
  Button,
  useDisclosure,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
  Flex,
  Select
} from '@chakra-ui/react'
import {FiArchive} from 'react-icons/fi'
import TicketsApi from '../../../../api/ticket'
import DepartmentsApi from '../../../../api/department'

function ChangeDepartment (props) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    idTicket,
    setSaveData,
    setTicket,
    setAttachments,
    idDepartment
  } = props
  const [departments, setDepartments] = useState([{}])
  const [department, setDepartment] = useState(idDepartment)

  useEffect(() => {
    loadDepartments()
  }, [])

  const loadDepartments = async () => {
    try {
      let result = await DepartmentsApi.All()
      return setDepartments(result.data)
    } catch (err) {
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  const saveChange = async () => {
    setSaveData(true)
    try {
      let response = {}
      response = await TicketsApi.ChangeDepartment(idTicket, department)
      
      setTicket(response.data.ticket)
      setAttachments([])
      setSaveData(false)
      toast({
        title: (response.data.error)?'Erro':'Sucesso',
        description: response.data.message,
        status: (response.data.error)?'error':'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      onClose()
      
    } catch (err) {
      ////console.log(err)
      setSaveData(false)
      let erro = ''
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      return toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  return (
    <Box>
      <Button onClick={onOpen} colorScheme='orange' size='sm'>
        <FiArchive/> Alterar departamento
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alterar departamento</ModalHeader>
          <ModalBody pb={6}>
            <Select placeholder='Selecione o departamento' onChange={(e)=>{
              setDepartment(e.target.value)
            }}>
              {departments &&
                departments.map((val, i) => {
                  if (idDepartment != val.id && val.status) {
                    return (
                      <option key={`dpto${val.id}`} value={val.id}>
                        {val.name}
                      </option>
                    )
                  }
                })}
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button size='sm' colorScheme='green' mr={3} onClick={saveChange}>
              Salvar
            </Button>
            <Button size='sm' colorScheme='gray' onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default ChangeDepartment
