import axios from './index'

class ContentsApi {
  static List = data => {
    let search = new URLSearchParams(data);
    return axios.get(`${base}?${search}`)
  }

  static Show = id => {
    return axios.get(`${base}/${id}`, )
  }

  static Save = (data) => {
    return axios.post(`${base}`, data)
  }

  static Update = (data, id) => {
    return axios.post(`${base}/${id}`, data)
  }

  static Delete = id => {
    return axios.delete(`${base}/${id}`)
  }

  static News = (qt) => {
    return axios.get(`admin/list-contents/${qt}`)
  }
}


let base = 'admin/contents'

export default ContentsApi
