import axios from "./index";

class AllApi {
  static allOptions = () => {
    return axios.get("admin/list-all");
  };

  static cep = (cep) => {
    return axios.get(`cep/${cep}`);
  };

  static banks = () => {
    return axios.get("admin/list-banks");
  };

  static companyBanks = (company) => {
    return axios.get(`admin/company/list-banks/${company}`);
  };

  static paymentsForm = () => {
    return axios.get("admin/list-payments-forms");
  };

  static services = (idCompany) => {
    return axios.get(`admin/list-services/${idCompany}`);
  };

  static whatsapp = () => {
    return axios.get(`whatsapp`);
  };
}

export default AllApi;
