import React, { useState, useEffect } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast
} from '@chakra-ui/react'

import { Formik, Field, Form } from 'formik'
import DateRangePicker from '../../../../components/Inputs/DateRangePicker'
import AllApi from '../../../../api/all'
import { AiOutlineSearch } from 'react-icons/ai'
import { BiEraser } from 'react-icons/bi'
import { CgMoreR } from 'react-icons/cg'
import moment from 'moment';

function TicketsSearch(props) {
  const { user, loadData, searchParams, loading } = props
  const [options, setOptions] = useState({})
  const [moreFilters, setMoreFilters] = useState(false)
  const toast = useToast()
  const [initialValue, setInitialValue] = useState({
    cod_ticket: searchParams['filter[cod_ticket]']
      ? searchParams['filter[cod_ticket]']
      : '',
    cnpj: searchParams['filter[cnpj]'] ? searchParams['filter[cnpj]'] : '',
    company: searchParams['filter[company]']
      ? searchParams['filter[company]']
      : '',
    external_ticket: searchParams['filter[external_ticket]']
      ? searchParams['filter[external_ticket]']
      : '',
    user_create: searchParams['filter[user_create]']
      ? searchParams['filter[user_create]']
      : '',
    department: searchParams['filter[department]']
      ? searchParams['filter[department]']
      : '',
    subject: searchParams['filter[subject]']
      ? searchParams['filter[subject]']
      : '',
    type: searchParams['filter[type]'] ? searchParams['filter[type]'] : '',
    topic: searchParams['filter[topic]'] ? searchParams['filter[topic]'] : '',
    priority: searchParams['filter[priority]']
      ? searchParams['filter[priority]']
      : '',
    sla: searchParams['filter[sla]'] ? searchParams['filter[sla]'] : '',
    subtopic: searchParams['filter[subtopic]']
      ? searchParams['filter[subtopic]']
      : '',
    created_date: searchParams['filter[created_date]']
      ? searchParams['filter[created_date]']
      : '',
    updated_date: searchParams['filter[updated_date]']
      ? searchParams['filter[updated_date]']
      : '',
    status: searchParams['filter[status]']
      ? searchParams['filter[status]']
      : '',
    solver: searchParams['filter[solver]'] ? searchParams['filter[solver]'] : '',
    created_between: searchParams['filter[created_between]'] ? searchParams['filter[created_between]'] : ''
  })

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    loadOptions()
  }, [])

  const search = params => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      params[`filter[created_between]`] = moment(dateRange[0]).format('YYYY-MM-DD') + '.' + moment(dateRange[1]).format('YYYY-MM-DD')
    }
    params.page = 1
    loadData(params)
  }
  const clear = () => {
    loadData({page: 1})
  }

  const loadOptions = async () => {
    try {
      let response = await AllApi.allOptions()
      if (response.data.error === true) {
        toast({
          title: 'Erro',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })

        return setOptions(false)
      }

      return setOptions(response.data)
    } catch (err) {
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      return setOptions({})
    }
  }

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        let params = {}
        for (var key in values) {
          if (values[key] != '') {
            switch (key) {
              case 'company':
                params[`filter[company.empr_nome]`] = values[key]
                break
              case 'user_create':
                params[`filter[userCreate.name]`] = values[key]
                break
              case 'solver':
                params[`filter[userSolver.name]`] = values[key]
                break
              case 'cnpj':
                params[`filter[company.empr_cnpj]`] = values[key]
                break
              case 'type':
                params[`filter[id_type]`] = values[key]
                break
              case 'topic':
                params[`filter[id_topic]`] = values[key]
                break
              case 'priority':
                params[`filter[id_priority]`] = values[key]
                break
              case 'sla':
                params[`filter[id_sla]`] = values[key]
                break
              case 'status':
                params[`filter[id_status]`] = values[key]
                break
              case 'department':
                params[`filter[id_department]`] = values[key]
                break
              default:
                params[`filter[${key}]`] = values[key]
                break
            }
          }
        }
        search(params)
      }}
    >
      {props => (
        <Form>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            <Field name='created_between'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='created_between'
                  >
                    Data abertura
                  </FormLabel>
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setDateRange={(update) => {
                      setDateRange(update);
                    }}
                  />
                </FormControl>
              )}
            </Field>
            <Field name='cod_ticket'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='cod_ticket'
                  >
                    Protocolo
                  </FormLabel>
                  <Input
                    size='sm'
                    {...field}
                    id='cod_ticket'
                    placeholder='Nº do protocolo'
                  />
                </FormControl>
              )}
            </Field>
            {user.type === 'A' && (
              <>
                <Field name='external_ticket'>
                  {({ field, form }) => (
                    <FormControl>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='external_ticket'
                      >
                        Protocolo externo
                      </FormLabel>
                      <Input
                        size='sm'
                        {...field}
                        id='external_ticket'
                        placeholder='Protocolo externo'
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name='cnpj'>
                  {({ field, form }) => (
                    <FormControl>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='cnpj'
                      >
                        CNPJ
                      </FormLabel>
                      <Input
                        size='sm'
                        {...field}
                        id='cnpj'
                        placeholder='CNPJ da empresa'
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name='company'>
                  {({ field, form }) => (
                    <FormControl>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='company'
                      >
                        Empresa
                      </FormLabel>
                      <Input
                        size='sm'
                        {...field}
                        id='company'
                        placeholder='Nome da empresa'
                      />
                    </FormControl>
                  )}
                </Field>
              </>
            )}
          </Flex>
          {moreFilters && (
            <>
              <Flex
                gap={2}
                mb={8}
                flexDirection={{ sm: 'column', lg: 'row' }}
                w='100%'
              >
                <Field name='subject'>
                  {({ field, form }) => (
                    <FormControl>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='subject'
                      >
                        Assunto
                      </FormLabel>
                      <Input
                        size='sm'
                        {...field}
                        id='subject'
                        placeholder='Assunto do protocolo'
                      />
                    </FormControl>
                  )}
                </Field>
                {user.type === 'A' && (
                  <>
                    <Field name='user_create'>
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel
                            fontSize='sm'
                            fontWeight='bold'
                            colorScheme='gray'
                            htmlFor='user_create'
                          >
                            Criador
                          </FormLabel>
                          <Input
                            size='sm'
                            {...field}
                            id='user_create'
                            placeholder='Nome criador'
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field name='solver'>
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel
                            fontSize='sm'
                            fontWeight='bold'
                            colorScheme='gray'
                            htmlFor='solver'
                          >
                            Resolvedor
                          </FormLabel>
                          <Input
                            size='sm'
                            {...field}
                            id='solver'
                            placeholder='Nome resolvedor'
                          />
                        </FormControl>
                      )}
                    </Field>
                  </>
                )}
              </Flex>
              {user.type === 'A' && (
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: 'column', lg: 'row' }}
                  w='100%'
                >
                  <Field name='department'>
                    {({ field, form }) => (
                      <FormControl>
                        <FormLabel
                          fontSize='sm'
                          fontWeight='bold'
                          colorScheme='gray'
                          htmlFor='department'
                        >
                          Departamento
                        </FormLabel>
                        <Select
                     
                          size='sm'
                          {...field}
                          id='department'
                          placeholder='Selecione o departamento'
                        >
                          {options.departments &&
                            options.departments.map((val, i) => {
                              if (val.status) {
                                return (
                                  <option key={`dpto${val.id}`} value={val.id}>
                                    {val.name}
                                  </option>
                                )
                              }
                            })}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='sla'>
                    {({ field, form }) => (
                      <FormControl>
                        <FormLabel
                          fontSize='sm'
                          fontWeight='bold'
                          colorScheme='gray'
                          htmlFor='sla'
                        >
                          Sla
                        </FormLabel>
                        <Select
                     
                          size='sm'
                          {...field}
                          id='sla'
                          placeholder='Selecione o SLA'
                        >
                          {options.slas &&
                            options.slas.map((val, i) => {
                              if (val.status) {
                                return (
                                  <option key={`sla${val.id}`} value={val.id}>
                                    {val.name}
                                  </option>
                                )
                              }
                            })}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='priority'>
                    {({ field, form }) => (
                      <FormControl>
                        <FormLabel
                          fontSize='sm'
                          fontWeight='bold'
                          colorScheme='gray'
                          htmlFor='priority'
                        >
                          Prioridade
                        </FormLabel>
                        <Select
                     
                          size='sm'
                          {...field}
                          id='priority'
                          placeholder='Selecione a prioridade'
                        >
                          {options.priorities &&
                            options.priorities.map((val, i) => {
                              if (val.status) {
                                return (
                                  <option
                                    key={`priority${val.id}`}
                                    value={val.id}
                                  >
                                    {val.name}
                                  </option>
                                )
                              }
                            })}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='status'>
                    {({ field, form }) => (
                      <FormControl>
                        <FormLabel
                          fontSize='sm'
                          fontWeight='bold'
                          colorScheme='gray'
                          htmlFor='status'
                        >
                          Status
                        </FormLabel>
                        <Select
                     
                          size='sm'
                          {...field}
                          id='status'
                          placeholder='Selecione o status'
                        >
                          {options.status &&
                            options.status.map((val, i) => {
                              if (val.status) {
                                return (
                                  <option
                                    key={`status${val.id}`}
                                    value={val.id}
                                  >
                                    {val.name}
                                  </option>
                                )
                              }
                            })}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
              )}
              <Flex
                gap={2}
                mb={8}
                flexDirection={{ sm: 'column', lg: 'row' }}
                w='100%'
              >
                <Field name='topic'>
                  {({ field, form }) => (
                    <FormControl>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='topic'
                      >
                        Tópico
                      </FormLabel>
                      <Select
                     
                        size='sm'
                        {...field}
                        id='topic'
                        placeholder='Selecione o tópico'
                      >
                        {options.topics &&
                          options.topics.map((val, i) => {
                            if (val.status) {
                              return (
                                <option key={`topic${val.id}`} value={val.id}>
                                  {val.name}
                                </option>
                              )
                            }
                          })}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <Field name='subtopic'>
                  {({ field, form }) => (
                    <FormControl>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='subtopic'
                      >
                        Subtópico
                      </FormLabel>
                      <Select
                     
                        size='sm'
                        {...field}
                        id='subtopic'
                        placeholder='Selecione o subtópico'
                      >
                        {options.subtopics &&
                          options.subtopics.map((val, i) => {
                            if (
                              val.topics[props.values.id_topic] !== undefined &&
                              val.status
                            ) {
                              return (
                                <option
                                  key={`subtopic${val.id}`}
                                  value={val.id}
                                >
                                  {val.name}
                                </option>
                              )
                            }
                          })}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                {user.type === 'A' && (
                  <Field name='type'>
                    {({ field, form }) => (
                      <FormControl>
                        <FormLabel
                          fontSize='sm'
                          fontWeight='bold'
                          colorScheme='gray'
                          htmlFor='type'
                        >
                          Tipo
                        </FormLabel>
                        <Select
                     
                          size='sm'
                          {...field}
                          id='type'
                          placeholder='Selecione o tipo'
                        >
                          {options.types &&
                            options.types.map((val, i) => (
                              <option key={`dpto${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                )}
              </Flex>
            </>
          )}
          <Flex align='end' justify='end'>
            <Button
              w={{ sm: '50%', lg: 'auto' }}
              size='sm'
              p='0px'
              me='4px'
              colorScheme='orange'
              color='white'
              cursor='pointer'
              
              type='button'
              onClick={() => {
                setMoreFilters(!moreFilters)
              }}
            >
              <CgMoreR />
            </Button>
            <Button
              w={{ sm: '50%', lg: 'auto' }}
              size='sm'
              p='0px'
              me='4px'
              bg='primary'
              color='white'
              cursor='pointer'
              
              type='submit'
            >
              <AiOutlineSearch />
            </Button>
            <Button
              w={{ sm: '50%', lg: 'auto' }}
              size='sm'
              p='0px'
              me='4px'
              color='gray'
              cursor='pointer'
              type='button'
              
              onClick={() => {
                props.setFieldValue('name', '')
                clear()
              }}
            >
              <BiEraser />
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default TicketsSearch
