import { Box, useStyleConfig, Heading, Text, Flex, Spacer } from "@chakra-ui/react";
function CardHeader(props) {
  const { title, description, variant, children, buttons, ...rest } = props;
  const styles = useStyleConfig("CardHeader", { variant });
  // Pass the computed styles into the `__css` prop
  return (
    // 4132503700
    <Box __css={styles} {...rest} width="100%">
      
      <Flex>
        <Box>
          <Heading fontSize="xl" colorScheme="blue">
            {title}
          </Heading>
          <Text fontSize="sm" color="gray.600">
            {description}
          </Text>
        </Box>
      </Flex>
      {children}
      {/* <Flex
        gap={2}
        mb={8}
        flexDirection={{ sm: "column", lg: "row" }}
      >
        <Flex>
          <Heading fontSize="xl" colorScheme="blue">
            {title}
          </Heading>
          <Text fontSize="sm" color="gray.600">
            {description}
          </Text>
        </Flex>
        <Flex>{children}</Flex>
      </Flex> */}
    </Box>
  );
}

export default CardHeader;
