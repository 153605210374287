import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { useState } from "react";

import { Field, Form, Formik } from "formik";

import { AiOutlineSearch } from "react-icons/ai";
import { BiEraser } from "react-icons/bi";

function CitiesSearch(props) {
  const { loadData, searchParams, loading } = props;
  const [initialValue, setInitialValue] = useState({
    name: searchParams["filter[name]"] ? searchParams["filter[name]"] : "",
    status_plugnotas: searchParams["filter[status_plugnotas]"]
      ? searchParams["filter[status_plugnotas]"]
      : "",
    envia_codigo_tributacao: searchParams["filter[envia_codigo_tributacao]"]
      ? searchParams["filter[envia_codigo_tributacao]"]
      : "",
  });

  const search = (params) => {
    params.page = 1;
    loadData(params);
  };
  const clear = () => {
    loadData({ page: 1 });
  };

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        let params = {};
        for (var key in values) {
          params[`filter[${key}]`] = values[key];
        }
        search(params);
      }}
    >
      {(props) => (
        <Form>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: "column", lg: "row" }}
            w="100%"
          >
            <Field name="name">
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="name"
                  >
                    Nome
                  </FormLabel>
                  <Input size="sm" {...field} id="name" placeholder="Nome" />
                </FormControl>
              )}
            </Field>
            <Field name="status_plugnotas">
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="status_plugnotas"
                  >
                    Status plugnotas
                  </FormLabel>
                  <Select size="sm" {...field} id="status_plugnotas">
                    <option value="" key={3}></option>
                    <option value="1" key={1}>
                      Homologado
                    </option>
                    <option value="0" key={0}>
                      Não homologado
                    </option>
                  </Select>
                </FormControl>
              )}
            </Field>
            <Field name="envia_codigo_tributacao">
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="envia_codigo_tributacao"
                  >
                    Envia código?
                  </FormLabel>
                  <Select size="sm" {...field} id="envia_codigo_tributacao">
                    <option value="" key={3}></option>
                    <option value="1" key={1}>
                      Sim
                    </option>
                    <option value="0" key={0}>
                      Não
                    </option>
                  </Select>
                </FormControl>
              )}
            </Field>
            <Flex align="end" justify="center">
              <Button
                w={{ sm: "50%", lg: "auto" }}
                size="sm"
                p="0px"
                me="4px"
                colorScheme="green"
                cursor="pointer"
                type="submit"
              >
                <AiOutlineSearch />
              </Button>
              <Button
                w={{ sm: "50%", lg: "auto" }}
                size="sm"
                p="0px"
                me="4px"
                colorScheme="blue"
                cursor="pointer"
                type="button"
                onClick={() => {
                  props.setFieldValue("name", "");
                  clear();
                }}
              >
                <BiEraser />
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default CitiesSearch;
