import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
  Textarea,
  useToast,
  Text,
} from "@chakra-ui/react";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import AllApi from "../../../api/all";
import CompaniesApi from "../../../api/company";
import paymentLinkApi from "../../../api/paymentLink";
import CompaniesClientsApi from "../../../api/companyClients";
import DateRangePicker from "../../../components/Inputs/DateRangePicker";
import AutocompleteInput from "../../../components/Inputs/AutocompleteInput";
import InputMask from "react-input-mask";
import PopupFormClient from "../Clients/PopupFormClient";
import CustomInputMask from "../../../components/Inputs/CustomInputMask";

function PaymentLinkForm(props) {
  const { user, PaymentLinkId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [accountsPlans, setAccountsPlans] = useState([]);
  const [PaymentLink, setPaymentLink] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companySelected, setCompanySelected] = useState("");
  const [paymentsForm, setPaymentsForm] = useState([]);
  const [banks, setBanks] = useState([]);
  const [mask, setMask] = useState("(99) 99999-9999");
  const [clients, setClients] = useState(false);
  const toast = useToast();

  const [initialData, setInitialData] = useState({
    id: "",
    id_company: "",
    id_client: "",
    amount: "",
    emailNotification: "",
    phoneNotification: "",
    description: "",
    expiration: "",
  });

  useEffect(() => {
    if (PaymentLinkId !== false && !loading) {
      loadDataItem();
    }
  }, [PaymentLinkId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await paymentLinkApi.Show(PaymentLinkId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let formData = new FormData();
    for (let value in values) {
      if (values[value] instanceof Date) {
        formData.append(value, values[value].toISOString());
      } else {
        formData.append(value, values[value]);
      }
    }

    try {
      let response = {};
      if (PaymentLinkId) {
        formData.append("_method", "PUT");
        response = await paymentLinkApi.Update(formData, PaymentLinkId);
      } else {
        response = await paymentLinkApi.Save(formData);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";

      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        console.log(errors);
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        return {
          id: value.id,
          name: value.empr_nome,
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  const getClients = async (word) => {
    if (word && word.length > 3) {
      setLoadingClients(true);
      let response = await CompaniesClientsApi.search(word, companySelected);

      setClients({ data: response.data });
      setLoadingClients(false);
    } else {
      setClients([]);
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            {user.type === "A" && (
              <Flex
                h="full"
                gap={2}
                mb={8}
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <FormControl isInvalid={errors.id_company}>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="id_company"
                  >
                    Empresa
                  </FormLabel>
                  {PaymentLinkId && props.values.company ? (
                    <Input
                      size="sm"
                      readOnly
                      value={props.values.company.empr_nome}
                    />
                  ) : (
                    <AutocompleteInput
                      loading={loadingCompanies}
                      options={companies}
                      setOptions={setCompanies}
                      requests={getCompanies}
                      placeholder="Pesquisar empresa"
                      onClickFunction={(e) => {
                        props.setFieldValue("id_company", e.id);
                        setCompanySelected(e.id);
                      }}
                    />
                  )}
                  {errors.id_company && (
                    <FormErrorMessage>{errors.id_company[0]}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            )}
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "column" }}
              w="100%"
            >
              <FormControl isInvalid={errors.id_company}>
                <FormLabel
                  fontSize="sm"
                  fontWeight="bold"
                  colorScheme="gray"
                  htmlFor="id_client"
                >
                  <Flex
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                    align="center"
                    justify="space-between"
                  >
                    <Text>Cliente</Text>
                    <PopupFormClient idCompany={companySelected} />
                  </Flex>
                </FormLabel>
                {PaymentLinkId && props.values.client ? (
                  <Input size="sm" readOnly value={props.values.client.name} />
                ) : (
                  <AutocompleteInput
                    loading={loadingClients}
                    options={clients}
                    setOptions={setClients}
                    requests={getClients}
                    placeholder="Pesquisar cliente"
                    onClickFunction={async (e) => {
                      props.setFieldValue("id_client", e.id);
                      props.setFieldValue("phoneNotification", e.phone);
                      props.setFieldValue("emailNotification", e.email);
                    }}
                  />
                )}
                {errors.id_client && (
                  <FormErrorMessage>{errors.id_client[0]}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>

            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="expiration">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.expiration}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="expiration"
                    >
                      Data expiração
                    </FormLabel>
                    <Flex height={8}>
                      <DateRangePicker
                        startDate={
                          PaymentLinkId
                            ? new Date(props.values.expiration)
                            : props.values.expiration
                        }
                        range={false}
                        setDateRange={(update) => {
                          props.setFieldValue("expiration", update);
                        }}
                      />
                    </Flex>
                    {errors.expiration && (
                      <FormErrorMessage>
                        {errors.expiration[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="amount">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.amount}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="amount"
                    >
                      Valor
                    </FormLabel>
                    <Input
                      as={NumericFormat}
                      size="sm"
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale
                      allowLeadingZeros
                      thousandSeparator="."
                      decimalSeparator=","
                      id="amount"
                      {...field}
                    />
                    {errors.amount && (
                      <FormErrorMessage>{errors.amount[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>

            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="description">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.description}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="description"
                    >
                      Descrição
                    </FormLabel>
                    <Textarea size="sm" {...field} id="description" />
                    {errors.description && (
                      <FormErrorMessage>
                        {errors.description[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="emailNotification">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.emailNotification}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="emailNotification"
                    >
                      Email notificação
                    </FormLabel>
                    <Input size="sm" {...field} id="emailNotification" />

                    {errors.emailNotification && (
                      <FormErrorMessage>
                        {errors.emailNotification[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="phoneNotification">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.phoneNotification}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="phoneNotification"
                    >
                      Celular notificação
                    </FormLabel>
                    <CustomInputMask
                      {...field}
                      mask={"(99) 99999-9999"}
                      name="phoneNotification"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        props.setFieldValue("phoneNotification", value);
                      }}
                    />
                    {errors.phoneNotification && (
                      <FormErrorMessage>
                        {errors.phoneNotification[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"
                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"
                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default PaymentLinkForm;
