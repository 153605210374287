import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import ApiConfigApi from "../../../../api/apiConfig";

function ApiConfigForm(props) {
  const { apiConfigId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [initialData, setInitialData] = useState({
    name: "",
    url: "",
    token: "",
  });

  useEffect(() => {
    if (apiConfigId !== false && !loading) {
      loadApiConfig();
    }
  }, [apiConfigId]);

  const loadApiConfig = async () => {
    setLoading(true);
    try {
      if (apiConfigId) {
      }
      let response = await ApiConfigApi.Show(apiConfigId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }

      return setInitialData({
        ...response.data.result,
        password_confirmation: "",
        password: "",
      });
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = {};
      if (apiConfigId) {
        response = await ApiConfigApi.Update(values, apiConfigId);
      } else {
        response = await ApiConfigApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "column" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input
                      readOnly
                      size="sm"
                      {...field}
                      id="name"
                      placeholder="Nome"
                    />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="url">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.url}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="url"
                    >
                      URL
                    </FormLabel>
                    <Input size="sm" {...field} id="url" />

                    {errors.url && (
                      <FormErrorMessage>{errors.url[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="token">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.token}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="token"
                    >
                      Token
                    </FormLabel>
                    <Input size="sm" {...field} id="token" />

                    {errors.token && (
                      <FormErrorMessage>{errors.token[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              {apiConfigId == 3 && (
                <>
                  <Field name="id_safra_pay">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.id_safra_pay}>
                        <FormLabel
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="id_safra_pay"
                        >
                          ID Safra Pay
                        </FormLabel>
                        <Input size="sm" {...field} id="id_safra_pay" />

                        {errors.id_safra_pay && (
                          <FormErrorMessage>
                            {errors.id_safra_pay[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="link_portal_safra">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.link_portal_safra}>
                        <FormLabel
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="link_portal_safra"
                        >
                          Link portal
                        </FormLabel>
                        <Input size="sm" {...field} id="link_portal_safra" />

                        {errors.link_portal_safra && (
                          <FormErrorMessage>
                            {errors.link_portal_safra[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="link_portal_api">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.link_portal_api}>
                        <FormLabel
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="link_portal_api"
                        >
                          Link API portal
                        </FormLabel>
                        <Input size="sm" {...field} id="link_portal_api" />

                        {errors.link_portal_api && (
                          <FormErrorMessage>
                            {errors.link_portal_api[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </>
              )}
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"
                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"
                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ApiConfigForm;
