import React, { useEffect, useState } from 'react';
import { Input, List, ListItem, Box, Flex, InputGroup, Spinner, InputRightElement, Badge } from '@chakra-ui/react';
import Indeterminate from 'components/Progress/Indeterminate';


const AutocompleteInput = ({
  loading,
  options,
  setOptions,
  requests,
  placeholder,
  onClickFunction,
  multiple = false,
  value = "",
  empty = false
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState(placeholder)


  useEffect(() => {
    if (value != "") {
      setSelectedValue(value)
    }
  }, [value])

  const handleInputChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.length > 2) {
      requests(term)
    } else {
      setOptions([])
    }
  };

  return (
    <Box>

      <InputGroup>

        <Input
        size="sm"
          placeholder={(multiple || empty) ? placeholder : selectedValue}
          value={searchTerm}
          onChange={handleInputChange}
          _placeholder={(multiple || empty) ? { opacity: 0.4, color: 'gray.200' } : { _dark: {color:'white'}, color: 'gray.800' }}
        />

        {loading &&
          <InputRightElement>
            <Spinner />
          </InputRightElement>
        }
      </InputGroup>
      <List
        boxShadow='6px 5px 8px rgba(0,50,30,0.1)'
        mt={2}
        position='absolute'
        backgroundColor='gray.50'
        _dark={{ backgroundColor: 'gray.800', borderColor: 'gray.500' }}
        zIndex='999'
        width='100%'>
        {options?.data?.length > 0 && !loading && options?.data?.map((result) => (
          <ListItem
            colorScheme='gray'
            key={result.id}
            _hover={{ bg: 'gray.100' }}
            _dark={{ _hover: { bg: 'gray.500' } }}
            my={1}
            p={2}
            cursor='pointer'
            onClick={() => {
              onClickFunction(result)
              if (!multiple) {
                setSearchTerm('')
                setSelectedValue(result.name)
                setOptions([])
                //console.log(options)
              }

            }}
          >
            <Flex align='center'>
              {result.name}
              {result.badge &&
                <Badge mx={4} colorScheme={result.badge.colorScheme}>{result.badge.text}</Badge>
              }
            </Flex>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AutocompleteInput;
