import React, { useState } from "react";
import {
  Heading,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Tooltip,
  Center,
  useColorModeValue,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  InputGroup,
  Stack,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import { API_SERVER } from "../../../config/constant";
import {
  AiFillFilePdf,
  AiOutlineSearch,
  AiOutlineMail,
  AiFillMail,
  AiFillCopy,
} from "react-icons/ai";
import {
  BsFillTrashFill,
  BsFiletypeXml,
  BsReply,
  BsSend,
} from "react-icons/bs";
import { TailSpin } from "react-loading-icons";
import { BiKey } from "react-icons/bi";

function NfTablesTableRow(props) {
  const [openEmail, setOpenEmail] = useState(false);
  const [openContabia, setOpenContabia] = useState(false);
  const [openPlugnotas, setOpenPlugnotas] = useState(false);
  const [email, setEmail] = React.useState("");
  const [emailMsg, setEmailMsg] = React.useState({
    show: false,
    type: "info",
    msg: "",
  });
  const {
    id,
    description,
    created_at,
    updated_at,
    status,
    result_plugnotas,
    id_contabia,
    status_contabia,
    message_contabia,
    id_client,
    id_company,
    info,
    plugnotas_id,
    plugnotas_id_integracao,
    plugnotas_prestador,
    plugnotas_status,
    total,
    client,
    company,
    json_plugnotas,
    status_comunicacao_plugnotas,
    plugnotas_json_send,
    cancelNf,
    msg_plugnotas,
    contabia_json_send,
    rps_number,
    nf_number,
    user,
    sendEmailNf,
    refreshNf,
    selectRow
  } = props;

  const textColor = useColorModeValue("gray.700", "white");

  const sendNf = async () => {
    setEmailMsg({
      show: false,
      type: "info",
      msg: "",
    });
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      setEmailMsg({
        show: false,
        type: "warning",
        msg: "Enviando email aguarde...",
      });

      await sendEmailNf(plugnotas_id, email);
      setEmailMsg({
        show: false,
        type: "info",
        msg: "",
      });
    } else {
      setEmailMsg({
        show: true,
        type: "error",
        msg: "E-mail inválido",
      });
    }
  };

  return (
    <>
      <Tr key={id} verticalAlign="top">
        <Td px={2} px={2}>
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="md" color={textColor} minWidth="100%">
              {rps_number}
            </Text>
          </Flex>
        </Td>
        <Td px={2} px={2}>
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="md" color={textColor} minWidth="100%">
              {nf_number}
            </Text>
          </Flex>
        </Td>
        <Td px={2} px={2}>
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="md" color={textColor} minWidth="100%">
              {created_at}
            </Text>
          </Flex>
          {user.type === "A" && plugnotas_id !== null && (
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Text fontSize="xs" color={textColor} minWidth="100%">
                ID PLUGNOTAS: <b>{plugnotas_id}</b>
              </Text>
            </Flex>
          )}
        </Td>
        {user.type === "A" && (
          <Td px={2} px={2}>
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Text
                fontSize="md"
                color={textColor}
                minWidth="100%"
                fontSize="xs"
              >
                {company.empr_nome}
              </Text>
            </Flex>
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Text fontSize="md" color={textColor} minWidth="100%">
                {company.empr_cnpj}
              </Text>
            </Flex>
          </Td>
        )}
        <Td px={2} px={2}>
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="md" color={textColor} minWidth="100%" fontSize="xs">
              {client.name}
            </Text>
          </Flex>
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="md" color={textColor} minWidth="100%">
              {client.cpf_cnpj}
            </Text>
          </Flex>
        </Td>
        <Td px={2} px={2}>
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="md" color={textColor} minWidth="100%">
              R$ {total}
            </Text>
          </Flex>
        </Td>
        {user.type === "A" ? (
          <Td px={2} px={2}>
            <Flex align="left" direction="column">
              <Heading w="100%" as="h6" size="xs">
                PLUGNOTAS
              </Heading>
              <Text>
                <Badge
                  colorScheme={
                    status_comunicacao_plugnotas === "CONCLUIDO"
                      ? "green"
                      : "red"
                  }
                >
                  {status_comunicacao_plugnotas}
                </Badge>
                {status_comunicacao_plugnotas === "ERRO NA COMUNICAÇÃO" && (
                  <>
                    <Button
                      colorScheme="red"
                      onClick={() => setOpenPlugnotas(true)}
                      size="xs"
                      mx={2}
                    >
                      <AiOutlineSearch />
                    </Button>
                    <Modal
                      size="xl"
                      isCentered
                      scrollBehavior="inside"
                      isOpen={openPlugnotas}
                      onClose={() => setOpenPlugnotas(false)}
                    >
                      <ModalOverlay>
                        <ModalContent>
                          <ModalHeader fontSize="lg" fontWeight="bold">
                            Retorno API Plugnotas
                          </ModalHeader>

                          <ModalBody>
                            <Text size="xs">{result_plugnotas}</Text>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              onClick={() => setOpenPlugnotas(false)}
                              ml={3}
                            >
                              Fechar
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </ModalOverlay>
                    </Modal>
                  </>
                )}
                {status_comunicacao_plugnotas === "REJEITADO" && (
                  <>
                    <Button
                      colorScheme="red"
                      onClick={() => setOpenPlugnotas(true)}
                      size="xs"
                      mx={2}
                    >
                      <AiOutlineSearch />
                    </Button>
                    <Modal
                      size="xl"
                      isCentered
                      scrollBehavior="inside"
                      isOpen={openPlugnotas}
                      onClose={() => setOpenPlugnotas(false)}
                    >
                      <ModalOverlay>
                        <ModalContent>
                          <ModalHeader fontSize="lg" fontWeight="bold">
                            JSON enviado para API Plugnotas
                          </ModalHeader>

                          <ModalBody>
                            <Alert status="error">
                              <AlertIcon />
                              {msg_plugnotas}
                            </Alert>
                            <Alert status="warning">
                              <BiKey />
                              ID PLUGNOTAS: {plugnotas_id}
                            </Alert>
                            <Text size="xs" my={2}>
                              {plugnotas_json_send}
                            </Text>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              onClick={() => setOpenPlugnotas(false)}
                              ml={3}
                            >
                              Fechar
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </ModalOverlay>
                    </Modal>
                  </>
                )}
              </Text>
            </Flex>
            <Flex align="left" direction="column" my={4}>
              {(status_comunicacao_plugnotas === "CONCLUIDO" ||
                status_comunicacao_plugnotas === "CANCELADO") && (
                <>
                  <Heading w="100%" as="h6" size="xs">
                    CONTABIA
                  </Heading>

                  <Text>
                    {status_contabia === "1" ? (
                      <Badge colorScheme="green">COMUNICADO</Badge>
                    ) : (
                      <>
                        <Badge colorScheme="red">ERRO NA COMUNICAÇÃO</Badge>
                        {message_contabia !== "" && (
                          <>
                            <Button
                              colorScheme="red"
                              onClick={() => setOpenContabia(true)}
                              size="xs"
                              mx={2}
                            >
                              <AiOutlineSearch />
                            </Button>
                            <Modal
                              isOpen={openContabia}
                              onClose={() => setOpenContabia(false)}
                            >
                              <ModalOverlay>
                                <ModalContent>
                                  <ModalHeader fontSize="lg" fontWeight="bold">
                                    Retorno API Contabia
                                  </ModalHeader>

                                  <ModalBody>
                                    <Alert status="error">
                                      <AlertIcon />
                                      {message_contabia}
                                    </Alert>
                                    <Text>{contabia_json_send}</Text>
                                  </ModalBody>

                                  <ModalFooter>
                                    <Button
                                      colorScheme="red"
                                      onClick={() => setOpenContabia(false)}
                                      ml={3}
                                    >
                                      Fechar
                                    </Button>
                                  </ModalFooter>
                                </ModalContent>
                              </ModalOverlay>
                            </Modal>
                          </>
                        )}
                      </>
                    )}
                  </Text>
                </>
              )}
            </Flex>
          </Td>
        ) : (
          <Td px={2} px={2}>
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Text>
                <Badge
                  colorScheme={
                    status_comunicacao_plugnotas === "CONCLUIDO"
                      ? "green"
                      : "red"
                  }
                >
                  {status_comunicacao_plugnotas}
                </Badge>
              </Text>
            </Flex>
          </Td>
        )}
        <Td px={2}>
          <Center w="100%">
            {(status_comunicacao_plugnotas === "CONCLUIDO" ||
              status_comunicacao_plugnotas === "CANCELADO") && (
              <>
                <Tooltip label="Enviar email">
                  <Button
                    onClick={() => {
                      setEmailMsg({
                        show: false,
                        type: "info",
                        msg: "",
                      });
                      setOpenEmail(true);
                    }}
                    size="sm"
                    p="8px"
                    me="4px"
                    bg="orange.400"
                    color="white"
                    cursor="pointer"
                    rounded="full"
                    _hover={{ bg: "orange.800" }}
                  >
                    <AiOutlineMail />
                  </Button>
                </Tooltip>
                <Modal isOpen={openEmail}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Enviar nota por email</ModalHeader>
                    <ModalBody>
                      <Stack spacing={4}>
                        {emailMsg.show && (
                          <Alert status={emailMsg.type}>
                            {emailMsg.type == "error" && <AlertIcon />}
                            {emailMsg.type == "success" && <AlertIcon />}
                            {emailMsg.msg}
                          </Alert>
                        )}
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<AiOutlineMail color="gray.300" />}
                          />
                          <Input
                            type="email"
                            placeholder="E-mail"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </Stack>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="gray"
                        cursor="pointer"
                        rounded="md"
                        onClick={() => setOpenEmail(false)}
                        disabled={emailMsg.type == "warning"}
                      >
                        Fechar
                      </Button>
                      <Button
                        colorScheme="green"
                        cursor="pointer"
                        rounded="md"
                        _hover={{ bg: "green.800" }}
                        onClick={() => sendNf()}
                        disabled={emailMsg.type == "warning"}
                      >
                        {emailMsg.type == "warning" && (
                          <TailSpin
                            style={{ width: "16px", margin: "0 5px" }}
                            stroke="#000000"
                          />
                        )}{" "}
                        Enviar
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Tooltip label="PDF da nota">
                  <Link
                    href={`${API_SERVER}company/nf/view/${plugnotas_id}/pdf`}
                    isExternal
                    size="sm"
                    p="8px"
                    me="4px"
                    bg="blue.400"
                    color="white"
                    cursor="pointer"
                    rounded="full"
                    _hover={{ bg: "blue.800" }}
                  >
                    <AiFillFilePdf />
                  </Link>
                </Tooltip>
                <Tooltip label="XML da nota">
                  <Link
                    href={`${API_SERVER}company/nf/view/${plugnotas_id}/xml`}
                    isExternal
                    size="sm"
                    p="8px"
                    me="4px"
                    bg="green.400"
                    color="white"
                    cursor="pointer"
                    rounded="full"
                    _hover={{ bg: "green.800" }}
                  >
                    <BsFiletypeXml />
                  </Link>
                </Tooltip>
              </>
            )}
            <Tooltip label="Duplicar nota">
              <Button
                size="sm"
                p="0px"
                me="4px"
                colorScheme="purple"
                cursor="pointer"
                onClick={() => selectRow(id)}
              >
                <AiFillCopy />
              </Button>
            </Tooltip>
            {status_comunicacao_plugnotas === "CONCLUIDO" && (
              <Tooltip label="Cancelar nota">
                <Button
                  size="sm"
                  p="0px"
                  me="4px"
                  bg="red.400"
                  color="white"
                  cursor="pointer"
                  onClick={() => cancelNf(id)}
                  _hover={{ bg: "red.800" }}
                >
                  <BsFillTrashFill />
                </Button>
              </Tooltip>
            )}

            {status_comunicacao_plugnotas === "NÃO COMUNICADO" && (
              <Tooltip label="Reenviar nota">
                <Button
                  size="sm"
                  p="0px"
                  me="4px"
                  bg="purple.400"
                  color="white"
                  cursor="pointer"
                  onClick={() => refreshNf(id)}
                  _hover={{ bg: "purple.800" }}
                >
                  <BsSend />
                </Button>
              </Tooltip>
            )}

            
          </Center>
        </Td>
      </Tr>
    </>
  );
}

export default NfTablesTableRow;
