import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave, BiTrash } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import CompaniesApi from "../../../api/company";
import RecurrentProfileApi from "../../../api/recurrentProfile";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import AutocompleteInput from "../../../components/Inputs/AutocompleteInput";

function CompaniesForm(props) {
  const { companyId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [removeCert, setRemoveCert] = useState(false);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [options, setOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const toast = useToast();
  const [accountsPlans, setAccountsPlans] = useState([]);
  const [initialData, setInitialData] = useState({
    empr_nome: "",
    certificate_pass: "",
    nf_user: "",
    nf_pass: "",
    iss: "",
    cod_serv_mun: "",
    cod_lei_comp: "",
    natureza_tributacao: "",
    natureza_tributacao: "",
    simples_nacional: "",
    regime_tributario_especial: "",
    recurrent_profiles: [],
    contabia_id_service: "",
    contabia_name_service: "",
    nf_cnae: "",
    id_account_plan_client: "",
    id_account_plan_supplier: "",
  });

  useEffect(() => {
    if (companyId !== false && !loading) {
      loadDataItem();
      loadAccountsPlans(companyId);
    }
  }, [companyId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await CompaniesApi.Show(companyId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      setItems(response.data.result.items);
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const loadAccountsPlans = async (idCompany) => {
    try {
      let accounts = await CompaniesApi.AccountPlans(idCompany);

      return setAccountsPlans(accounts.data);
    } catch (err) {
      return setAccountsPlans([]);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let formData = new FormData();
    for (let value in values) {
      if (value != "certificate" && value != "logo") {
        formData.append(value, values[value]);
      }
    }
    for (let item in items) {
      formData.append("items[]", items[item].id);
      formData.append("items_status[]", items[item].status);
    }
    if (values.certificate) {
      formData.append("certificate", values.certificate);
    }
    if (values.logo) {
      formData.append("logo", values.logo);
    }
    if (removeLogo) {
      formData.append("removeLogo", removeLogo);
    }
    if (removeCert) {
      formData.append("removeCert", removeCert);
    }
    try {
      let response = {};
      if (companyId) {
        formData.append("_method", "PUT");
        response = await CompaniesApi.Update(formData, companyId);
      } else {
        response = await CompaniesApi.Save(formData);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      ////console.log(err.response.data.errors)
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getRecurrents = async (word) => {
    if (word && word.length > 3) {
      setLoadingItems(true);
      let response = await RecurrentProfileApi.List();

      setOptions(response.data);
      setLoadingItems(false);
    } else {
      setOptions([]);
    }
  };

  const selectRecurrent = async (item) => {
    let newI = items.filter((o, i) => o.id === item.id);
    if (newI.length === 0) {
      setItems((oldArray) => [...oldArray, { ...item, status: false }]);
    }
  };

  const removeRecurrent = async (index) => {
    setItems(items.filter((o, i) => index !== i));
  };

  const statusRecurrent = async (index, status) => {
    let newI = items.map((o, i) => {
      if (index === i) {
        o.status = status;
      }
      return o;
    });
    setItems(newI);
  };

  const updateCompany = async () => {
    setLoading(true);
    try {
      let response = await CompaniesApi.Save(companyId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      setItems(response.data.result.items);
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Card my={4}>
              <CardHeader
                title={props.values.empr_nome}
                description="Alterar dados da empresa"
              ></CardHeader>
              <CardBody>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "column" }}
                  w="100%"
                >
                  <Flex
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                  >
                    {/* <Tooltip label='Atualizar dados'>
                    <Button
                      size='sm'
                      px='12px'
                      bg='primary'
                      color='white'
                      cursor='pointer'
                      onClick={updateCompany()}
                    >
                      <BiRefresh color='white' /> Atualizar dados
                    </Button>
                  </Tooltip> */}
                  </Flex>
                  <Flex
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                  >
                    <Text>
                      <b>ID:</b> {initialData.empr_empr}
                    </Text>
                    <Text>
                      <b>Nome:</b> {initialData.empr_nome}
                    </Text>
                    <Text>
                      <b>Fantasia:</b> {initialData.empr_fant}
                    </Text>
                  </Flex>
                  <Flex
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                  >
                    <Text>
                      <b>CNPJ:</b> {initialData.empr_cnpj}
                    </Text>
                    <Text>
                      <b>Insc. Estadual:</b> {initialData.empr_insc_esta}
                    </Text>
                    <Text>
                      <b>Insc. Municipal:</b> {initialData.empr_insc_muni}
                    </Text>
                    <Text>
                      <b>CPF:</b> {initialData.empr_cpf}
                    </Text>

                    <Text>
                      <b>CEI:</b> {initialData.empr_cei}
                    </Text>
                  </Flex>
                  <Flex
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                  >
                    <Text>
                      <b>CEP:</b> {initialData.empr_cep}
                    </Text>
                    <Text>
                      <b>Endereço:</b> {initialData.empr_ende}
                    </Text>
                    <Text>
                      <b>Número:</b> {initialData.empr_nume}
                    </Text>
                    <Text>
                      <b>Complemento:</b> {initialData.empr_comp}
                    </Text>
                    <Text>
                      <b>Bairro:</b> {initialData.empr_bair}
                    </Text>
                    <Text>
                      <b>Cod. IBGE:</b> {initialData.empr_ibge}
                    </Text>
                    <Text>
                      <b>Cidade:</b> {initialData.empr_cida}
                    </Text>
                    <Text>
                      <b>UF:</b> {initialData.empr_esta}
                    </Text>
                  </Flex>
                  <Flex
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                  >
                    <Text>
                      <b>Telefone:</b> {initialData.empr_fone_empr}
                    </Text>
                    <Text>
                      <b>E-mail:</b> {initialData.empr_emai_empr}
                    </Text>
                  </Flex>
                  <Flex
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                  >
                    <Text>
                      <b>Observações:</b> {initialData.empr_obse}
                    </Text>
                  </Flex>
                  <Flex
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                  >
                    <Text>
                      <b>Regime tributário:</b> {initialData.empr_regi_trib}
                    </Text>
                    <Text>
                      <b>Tipo:</b> {initialData.empr_tipo_empr}
                    </Text>
                    <Text>
                      <b>CNAE:</b> {initialData.empr_cnae}
                    </Text>
                    <Text>
                      <b>Bloqueio API:</b> {initialData.empr_api_bloq}
                    </Text>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
            <Card my={4}>
              <CardHeader title="Logo" description=""></CardHeader>
              <CardBody>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                >
                  <Field name="logo">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.logo}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="logo"
                        >
                          Imagem (Logo em JPG com tamanho máximo 200kb)
                        </FormLabel>
                        {props.values.logo_url !== "" && (
                          <Box my={4}>
                            <Avatar
                              name={props.values.empr_nome}
                              src={props.values.logo_url}
                              size="2xl"
                              borderRadius="12px"
                              me="18px"
                            />
                            <Checkbox
                              checked={removeLogo}
                              onChange={() => setRemoveLogo(!removeLogo)}
                            >
                              Remover
                            </Checkbox>
                          </Box>
                        )}
                        <Button as="label" htmlFor="logo" mt={2}>
                          Selecionar arquivo
                        </Button>
                        <Text mt={2}>
                          {props.values.logo && (
                            <>
                              {props.values.logo.name}
                              <Button
                                variant="link"
                                color="red.500"
                                onClick={() =>
                                  props.setFieldValue("logo", null)
                                }
                              >
                                <BiTrash />
                              </Button>
                            </>
                          )}
                        </Text>
                        <Input
                          id="logo"
                          name="logo"
                          type="file"
                          display="none"
                          onChange={(event) => {
                            //console.log(event.target.files[0]);
                            props.setFieldValue(
                              "logo",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        {/* <Input
                        id="logo"
                        name="logo"
                        type="file"
                        onChange={(event) => {
                          props.setFieldValue(
                            "logo",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="form-control"
                      /> */}

                        {errors.logo && (
                          <FormErrorMessage>{errors.logo[0]}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
              </CardBody>
            </Card>
            <Card my={4}>
              <CardHeader title="Certificado" description=""></CardHeader>
              {props.values.certificate_url === "" &&
                props.values.certificate_required === "1" && (
                  <Box bg="red" w="100%" p={4} color="white">
                    Certificado é obrigatório para este munícipio
                  </Box>
                )}
              <CardBody flexDirection={{ sm: "column", lg: "column" }} w="100%">
                {props.values.certificate_url !== "" && (
                  <Flex
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                    my={4}
                  >
                    <Link
                      mx={2}
                      size="sm"
                      cursor="pointer"
                      type="button"
                      target="_blank"
                      href={props.values.certificate_url}
                      isExternal
                    >
                      <Flex
                        flexDirection={{ sm: "column", lg: "row" }}
                        w="100%"
                      >
                        <FaFileDownload size={50} />
                      </Flex>
                    </Link>
                    <Checkbox
                      checked={removeCert}
                      onChange={() => setRemoveCert(!removeCert)}
                    >
                      Remover
                    </Checkbox>
                  </Flex>
                )}
                <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%">
                  <Field name="certificate">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={errors.certificate || errors.certificado}
                      >
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="certificate"
                        >
                          Arquivo
                        </FormLabel>
                        <Button as="label" htmlFor="certificate" mt={2}>
                          Selecionar arquivo
                        </Button>
                        <Text mt={2}>
                          {props.values.certificate && (
                            <>
                              {props.values.certificate.name}
                              <Button
                                variant="link"
                                color="red.500"
                                onClick={() =>
                                  props.setFieldValue("certificate", null)
                                }
                              >
                                <BiTrash />
                              </Button>
                            </>
                          )}
                        </Text>
                        <Input
                          id="certificate"
                          name="certificate"
                          type="file"
                          display="none"
                          onChange={(event) => {
                            //console.log(event.target.files[0]);
                            props.setFieldValue(
                              "certificate",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        {/* <Input
                        id="certificate"
                        name="certificate"
                        type="file"
                        onChange={(event) => {
                          props.setFieldValue(
                            "certificate",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="form-control"
                      /> */}

                        {errors.certificado && (
                          <FormErrorMessage>
                            {errors.certificado}
                          </FormErrorMessage>
                        )}
                        {errors.certificate && (
                          <FormErrorMessage>
                            {errors.certificate[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="certificate_pass">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.certificate_pass}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="certificate_pass"
                        >
                          Senha
                        </FormLabel>
                        <Input
                          size="sm"
                          {...field}
                          id="certificate_pass"
                          placeholder="Senha"
                        />

                        {errors.certificate_pass && (
                          <FormErrorMessage>
                            {errors.certificate_pass[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
              </CardBody>
            </Card>
            {props.values.nf_user_required == "1" && (
              <Card my={4}>
                <CardHeader
                  title="Dados da prefeitura"
                  description=""
                ></CardHeader>
                <CardBody>
                  <Flex
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "column" }}
                    w="100%"
                  >
                    {errors["nfse.config.prefeitura"] && (
                      <Flex
                        gap={2}
                        mb={8}
                        flexDirection={{ sm: "column", lg: "row" }}
                        w="100%"
                      >
                        <Alert status="error">
                          <AlertIcon />
                          {errors["nfse.config.prefeitura"]}
                        </Alert>
                      </Flex>
                    )}
                    <Flex
                      gap={2}
                      mb={8}
                      flexDirection={{ sm: "column", lg: "row" }}
                      w="100%"
                    >
                      <Field name="nf_user">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.nf_user}>
                            <FormLabel
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="nf_user"
                            >
                              Usuário emissão NF
                            </FormLabel>
                            <Input
                              size="sm"
                              {...field}
                              id="nf_user"
                              placeholder="Usuário"
                            />

                            {errors.nf_user && (
                              <FormErrorMessage>
                                {errors.nf_user[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                      <Field name="nf_pass">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.nf_pass}>
                            <FormLabel
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="nf_pass"
                            >
                              Senha emissão NF
                            </FormLabel>
                            <Input
                              size="sm"
                              {...field}
                              id="nf_pass"
                              placeholder="Senha"
                            />

                            {errors.nf_pass && (
                              <FormErrorMessage>
                                {errors.nf_pass[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            )}
            <Card my={4}>
              <CardHeader
                title="Planos de conta padrão"
                description=""
              ></CardHeader>
              <CardBody>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                >
                  <Field name="id_account_plan_client">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.id_account_plan_client}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="id_account_plan_client"
                        >
                          Cliente
                        </FormLabel>
                        <Select
                          size="sm"
                          {...field}
                          id="id_account_plan_client"
                        >
                          <option value="" disabled>
                            Selecione
                          </option>
                          {accountsPlans.map((val, i) => (
                            <option
                              key={`accountsPlans${val.id}`}
                              value={val.id}
                            >
                              {val.plan_nome}
                            </option>
                          ))}
                        </Select>

                        {errors.id_account_plan_client && (
                          <FormErrorMessage>
                            {errors.id_account_plan_client[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="id_account_plan_supplier">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.id_account_plan_supplier}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="id_account_plan_supplier"
                        >
                          Fornecedor
                        </FormLabel>
                        <Select
                          size="sm"
                          {...field}
                          id="id_account_plan_supplier"
                        >
                          <option value="" disabled>
                            Selecione
                          </option>
                          {accountsPlans.map((val, i) => (
                            <option
                              key={`accountsPlans${val.id}`}
                              value={val.id}
                            >
                              {val.plan_nome}
                            </option>
                          ))}
                        </Select>

                        {errors.id_account_plan_supplier && (
                          <FormErrorMessage>
                            {errors.id_account_plan_supplier[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
              </CardBody>
            </Card>
            <Card my={4}>
              <CardHeader title="Dados emissão NF" description=""></CardHeader>
              <CardBody flexDirection="column">
                {/* <Flex
                gap={2}
                mb={8}
                flexDirection={{ sm: 'column', lg: 'column' }}
                w='100%'
              >
                <Field name='contabia_id_service'>
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.contabia_id_service}>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='contabia_id_service'
                      >
                        ID serviço contabia
                      </FormLabel>
                      <Input
                        size='sm'
                        {...field}
                        id='contabia_id_service'
                        placeholder='ID serviço contabia'
                      />

                      {errors.contabia_id_service && (
                        <FormErrorMessage>
                          {errors.contabia_id_service[0]}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name='contabia_name_service'>
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.contabia_name_service}>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='contabia_name_service'
                      >
                        Descrição do serviço
                      </FormLabel>
                      <Textarea
                        size='sm'
                        {...field}
                        id='contabia_name_service'
                        placeholder='Descrição do serviço'
                      />

                      {errors.contabia_name_service && (
                        <FormErrorMessage>
                          {errors.contabia_name_service[0]}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Flex> */}
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                >
                  {/* <Field name='nf_cnae'>
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.nf_cnae}>
                      <FormLabel
                        fontSize='sm'
                        fontWeight='bold'
                        colorScheme='gray'
                        htmlFor='nf_cnae'
                      >
                        CNAE
                      </FormLabel>
                      <Input
                        size='sm'
                        {...field}
                        id='nf_cnae'
                        placeholder='CNAE'
                      />

                      {errors.nf_cnae && (
                        <FormErrorMessage>{errors.nf_cnae[0]}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field> */}
                  <Field name="nf_number">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.nf_number}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="nf_number"
                        >
                          Último rps emitido
                        </FormLabel>
                        <Input
                          size="sm"
                          {...field}
                          id="nf_number"
                          placeholder="Último rps emitido"
                        />

                        {errors.nf_number && (
                          <FormErrorMessage>
                            {errors.nf_number[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="nf_serie">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.nf_serie}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="nf_serie"
                        >
                          NF Série
                        </FormLabel>
                        <Input
                          size="sm"
                          {...field}
                          id="nf_serie"
                          placeholder="NF Série"
                        />

                        {errors.nf_serie && (
                          <FormErrorMessage>
                            {errors.nf_serie[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="iss">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.iss}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="iss"
                        >
                          ISS
                        </FormLabel>
                        <Input
                          size="sm"
                          {...field}
                          id="iss"
                          placeholder="Valor ISS"
                        />

                        {errors.iss && (
                          <FormErrorMessage>{errors.iss[0]}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="cod_serv_mun">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.cod_serv_mun}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="cod_serv_mun"
                        >
                          Cód. Serv. Municipal
                        </FormLabel>
                        <Input
                          size="sm"
                          {...field}
                          id="cod_serv_mun"
                          placeholder="Código Serviço Municipal"
                        />

                        {errors.cod_serv_mun && (
                          <FormErrorMessage>
                            {errors.cod_serv_mun[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="cod_lei_comp">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.cod_lei_comp}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="cod_lei_comp"
                        >
                          Cód. Lei Comp.
                        </FormLabel>
                        <Input
                          size="sm"
                          {...field}
                          id="cod_lei_comp"
                          value={props.values.cod_lei_comp}
                          placeholder="Código da Lei Complementar"
                        />

                        {errors.cod_lei_comp && (
                          <FormErrorMessage>
                            {errors.cod_lei_comp[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="natureza_tributacao">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.natureza_tributacao}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="natureza_tributacao"
                        >
                          Natureza da Trib.
                        </FormLabel>
                        <Select size="sm" {...field} id="natureza_tributacao">
                          <option value="" disabled>
                            Selecione
                          </option>
                          <option value="1">Simples Nacional</option>
                          <option value="2">Fixo</option>
                          <option value="3">Depósito em Juízo</option>
                          <option value="4">
                            Exigibilidade suspensa por decisão judicial
                          </option>
                          <option value="5">
                            Exigibilidade suspensa por procedimento
                            administrativo
                          </option>
                          <option value="6">Isenção parcial</option>
                        </Select>

                        {errors.natureza_tributacao && (
                          <FormErrorMessage>
                            {errors.natureza_tributacao[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                >
                  <Field name="simples_nacional">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          errors.simples_nacional || errors.simplesNacional
                        }
                      >
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="simples_nacional"
                        >
                          Simples nacional
                        </FormLabel>
                        <Select size="sm" {...field} id="simples_nacional">
                          <option value="" disabled>
                            Selecione
                          </option>
                          <option value="1">Sim</option>
                          <option value="0">Não</option>
                        </Select>
                        {errors.simplesNacional && (
                          <FormErrorMessage>
                            {errors.simplesNacional}
                          </FormErrorMessage>
                        )}
                        {errors.simples_nacional && (
                          <FormErrorMessage>
                            {errors.simples_nacional[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="regime_tributario">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          errors.regime_tributario || errors.regimeTributario
                        }
                      >
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="regime_tributario"
                        >
                          Regime Tributario
                        </FormLabel>
                        <Select size="sm" {...field} id="regime_tributario">
                          <option value="" disabled>
                            Selecione
                          </option>
                          <option value="0">Nenhum</option>
                          <option value="1">Simples Nacional</option>
                          <option value="2">Simples Nacional - Excesso</option>
                          <option value="3">
                            Regime Normal - Lucro Presumido
                          </option>
                          <option value="4">Normal - Lucro Real</option>
                        </Select>

                        {errors.regimeTributario && (
                          <FormErrorMessage>
                            {errors.regimeTributario}
                          </FormErrorMessage>
                        )}

                        {errors.regime_tributario && (
                          <FormErrorMessage>
                            {errors.regime_tributario[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="regime_tributario_especial">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          errors.regime_tributario_especial ||
                          errors.regimeTributarioEspecial
                        }
                      >
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="regime_tributario_especial"
                        >
                          Regime tributario especial
                        </FormLabel>
                        <Select
                          size="sm"
                          {...field}
                          id="regime_tributario_especial"
                        >
                          <option value="" disabled>
                            Selecione
                          </option>
                          <option value="0">
                            Sem Regime Tributário Especial
                          </option>
                          <option value="1">Micro Empresa Municipal</option>
                          <option value="2">Estimativa</option>
                          <option value="3">Sociedade de Profissionais</option>
                          <option value="4">Cooperativa</option>
                          <option value="5">
                            Microempresário Individual - MEI
                          </option>
                          <option value="6">
                            Microempresa ou Pequeno Porte - ME EPP
                          </option>
                          <option value="7">Lucro Real</option>
                          <option value="8">Lucro Presumido</option>
                          <option value="9">Tributação Normal</option>
                          <option value="10">
                            Simples nacional com excesso do sublimite
                          </option>
                          <option value="11">
                            Empresa de Responsabilidade Limitada
                          </option>
                        </Select>

                        {errors.regimeTributarioEspecial && (
                          <FormErrorMessage>
                            {errors.regimeTributarioEspecial}
                          </FormErrorMessage>
                        )}

                        {errors.regime_tributario_especial && (
                          <FormErrorMessage>
                            {errors.regime_tributario_especial[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
              </CardBody>
            </Card>
            <Card my={4}>
              <CardHeader
                title="Configuração de obrigações"
                description=""
              ></CardHeader>
              <CardBody>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "column" }}
                  w="100%"
                >
                  <Flex
                    h="full"
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                  >
                    <FormControl isInvalid={errors.status}>
                      <FormLabel
                        _dark={{
                          color: "gray.500",
                        }}
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="status"
                      >
                        Perfis de obrigações
                      </FormLabel>
                      <AutocompleteInput
                        loading={loadingItems}
                        options={options}
                        setOptions={setOptions}
                        requests={getRecurrents}
                        placeholder="Pesquisar obrigações"
                        onClickFunction={selectRecurrent}
                        empty={true}
                      />
                    </FormControl>
                  </Flex>
                  <Flex
                    h="full"
                    gap={2}
                    mb={8}
                    flexDirection={{ sm: "column", lg: "row" }}
                  >
                    <TableContainer size="sm" w="full" fontSize="sm">
                      <Table variant="striped" colorScheme="gray">
                        <Thead>
                          <Tr>
                            <Th px={0}>Perfil</Th>
                            <Th px={0} w="60px"></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {items.map((row, i) => {
                            return (
                              <Tr key={i}>
                                <Td px={2}>{row.name}</Td>
                                <Td px={2}>
                                  <Switch
                                    size="lg"
                                    id="isChecked"
                                    onChange={(event) =>
                                      statusRecurrent(i, event.target.checked)
                                    }
                                    isChecked={row.status}
                                  />
                                  <Button
                                    mx={2}
                                    size="sm"
                                    colorScheme="red"
                                    cursor="pointer"
                                    type="button"
                                    onClick={() => removeRecurrent(i)}
                                  >
                                    <MdOutlineCancel />
                                  </Button>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                        <Tfoot>
                          <Tr>
                            <Th px={0}>Perfil</Th>
                            <Th px={0}></Th>
                          </Tr>
                        </Tfoot>
                      </Table>
                    </TableContainer>
                  </Flex>
                  <Flex
                    align="end"
                    justify="end"
                    gap={2}
                    mt="10px"
                    flexDirection={{ sm: "column", lg: "row" }}
                  >
                    <Button
                      leftIcon={<MdOutlineCancel />}
                      size="sm"
                      colorScheme="gray"
                      cursor="pointer"
                      type="button"
                      
                      w={{ sm: "100%", lg: "auto" }}
                      onClick={() => {
                        setForm(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      leftIcon={<BiSave />}
                      size="sm"
                      colorScheme="blue"
                      cursor="pointer"
                      type="submit"
                      
                      w={{ sm: "100%", lg: "auto" }}
                    >
                      Salvar
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CompaniesForm;
