import React, { useState } from 'react'
import { Flex, Box, Heading, Text, Button } from '@chakra-ui/react'
import { NavLink, useLocation } from 'react-router-dom'

export default function Page404 () {
  return (
    <Flex flexDirection='column' pt={{ base: '120px', md: '75px' }}>
      <Box textAlign='center' py={10} px={6}>
        <Heading display='inline-block' as='h2' size='2xl' color='primary'>
          404
        </Heading>
        <Text fontSize='18px' mt={3} mb={2}>
          Página não existe
        </Text>
        <Text color={'gray.500'} mb={6}>
          Você está tentando acessar uma página que não existe ou que você não tenha acesso
        </Text>
        <NavLink to={'admin/dashboard'}>
          <Button colorScheme='gray' bg='primary' color='white' variant='solid'>
            Ir para a Home
          </Button>
        </NavLink>
      </Box>
    </Flex>
  )
}
