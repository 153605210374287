import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Img,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave, BiTrash } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import AllApi from "../../../../api/all";
import CompaniesApi from "../../../../api/company";
import FormsApi from "../../../../api/forms";
import AutocompleteInput from "../../../../components/Inputs/AutocompleteInput";

function CategoriesOptionsForm(props) {
  const { formId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [companies, setCompanies] = useState([{}]);
  const [companiesTable, setCompaniesTable] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [removeIcon, setRemoveIcon] = useState(false);
  const toast = useToast();
  const [initialData, setInitialData] = useState({
    name: "",
    title: "",
    id_sla: "",
    id_form_category: "",
    id_priority: "",
    id_type: "",
    id_subtopic: "",
    id_status: "",
    id_topic: "",
    id_department: "",
    subject: "",
    description: "",
    id_companies: [],
    icon_url: ""
  });

  useEffect(() => {
    if (formId !== false && !loading) {
      loadCategory();
    }
  }, [formId]);
  useEffect(() => {
    loadOptions();
  }, []);
  const loadCategory = async () => {
    setLoading(true);
    try {
      if (formId) {
      }
      let response = await FormsApi.Show(formId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      setCompaniesTable(response.data.result.companies);
      return setInitialData({
        ...response.data.result,
        password_confirmation: "",
        password: "",
      });
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let formData = new FormData();
    for (let value in values) {
      if (value != "icon") {
        formData.append(value, values[value]);
      }
    }
    values.id_companies = [];
    if (companiesTable.length > 0) {
      for (var i = 0; i < companiesTable.length; i++) {
        values.id_companies.push(companiesTable[i].id);
      }
    }
    if (values.icon) {
      formData.append("icon", values.icon);
    }
    if (removeIcon) {
      formData.append("removeIcon", removeIcon);
    }

    try {
      let response = {};
      if (formId) {
        formData.append("_method", "PUT");
        response = await FormsApi.Update(formData, formId);
      } else {
        response = await FormsApi.Save(formData);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadOptions = async () => {
    try {
      let response = await AllApi.allOptions();
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setOptions(false);
      }

      return setOptions(response.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setOptions({});
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        return {
          id: value.id,
          name: value.empr_nome,
          empr_cnpj: value.empr_cnpj,
          empr_nome: value.empr_nome,
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  const removeCompany = async (index) => {
    setCompaniesTable(companiesTable.filter((o, i) => index !== i));
  };

  const selectCompany = async (item) => {
    ////console.log(item);
    let newI = companiesTable.filter((o, i) => o.id === item.id);
    if (newI.length === 0) {
      setCompaniesTable((oldArray) => [
        ...oldArray,
        { ...item, status: false },
      ]);
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "column" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="title">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.title}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="title"
                    >
                      Título
                    </FormLabel>
                    <Input
                      size="sm"
                      {...field}
                      id="title"
                      placeholder="Título"
                    />

                    {errors.title && (
                      <FormErrorMessage>{errors.title[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="subject">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.subject}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="subject"
                    >
                      Assunto
                    </FormLabel>
                    <Input type="text" size="sm" {...field} id="subject" />

                    {errors.subject && (
                      <FormErrorMessage>{errors.subject[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_department">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_department}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_department"
                    >
                      Departamento
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_department"
                      placeholder="Selecione"
                    >
                      {options.departments &&
                        options.departments.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_department && (
                      <FormErrorMessage>
                        {errors.id_department[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_topic">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_topic}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_topic"
                    >
                      Tópico
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_topic"
                      placeholder="Selecione"
                      onChange={(e) => {
                        let topic = options.topics.filter(
                          (o, i) => o.id == e.target.value
                        );
                        props.setFieldValue("id_topic", topic[0].id);
                        props.setFieldValue("id_sla", topic[0].id_sla);
                        props.setFieldValue(
                          "id_priority",
                          topic[0].id_priority
                        );
                      }}
                    >
                      {options.topics &&
                        options.topics.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_topic && (
                      <FormErrorMessage>{errors.id_topic[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_sla">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_sla}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_sla"
                    >
                      Sla
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_sla"
                      placeholder="Selecione"
                    >
                      {options.slas &&
                        options.slas.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_sla && (
                      <FormErrorMessage>{errors.id_sla[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_priority">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_priority}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_priority"
                    >
                      Prioridade
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_priority"
                      placeholder="Selecione"
                    >
                      {options.priorities &&
                        options.priorities.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_priority && (
                      <FormErrorMessage>
                        {errors.id_priority[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_type">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_type}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_type"
                    >
                      Tipo
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_type"
                      placeholder="Selecione"
                    >
                      {options.types &&
                        options.types.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_type && (
                      <FormErrorMessage>{errors.id_type[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>

              <Field name="id_subtopic">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_subtopic}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_subtopic"
                    >
                      Subtópico
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_subtopic"
                      placeholder="Selecione"
                    >
                      {options.subtopics &&
                        options.subtopics.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_subtopic && (
                      <FormErrorMessage>
                        {errors.id_subtopic[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_status">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_status}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_status"
                    >
                      Status
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_status"
                      placeholder="Selecione"
                    >
                      {options.status &&
                        options.status.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_status && (
                      <FormErrorMessage>{errors.id_status[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_form_category">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_form_category}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_form_category"
                    >
                      Categoria
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_form_category"
                      placeholder="Selecione"
                    >
                      {options.categoriesForm &&
                        options.categoriesForm.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_form_category && (
                      <FormErrorMessage>
                        {errors.id_form_category[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.description}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="description"
                    >
                      Descrição
                    </FormLabel>
                    <Textarea
                      type="description"
                      size="sm"
                      {...field}
                      id="description"
                    />

                    {errors.description && (
                      <FormErrorMessage>
                        {errors.description[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Card my={4}>
              <CardHeader title="Icone" description="Utilize arquivos no formato svg"></CardHeader>
              <CardBody flexDirection={{ sm: "column", lg: "column" }} w="100%">
                {props.values.icon_url !== "" && (
                  <Flex
                    flexDirection={{ sm: "column", lg: "row" }}
                    w="100%"
                    my={4}
                  >
                    <Flex
                        flexDirection={{ sm: "column", lg: "row" }}
                        w="100%"
                      >
                        <Img src={props.values.icon_url} width={50} _dark={{filter:'invert(1)'}} />
                      </Flex>
                    <Checkbox
                      checked={removeIcon}
                      onChange={() => setRemoveIcon(!removeIcon)}
                    >
                      Remover
                    </Checkbox>
                  </Flex>
                )}
                <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%">
                  <Field name="icon">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={errors.icon || errors.certificado}
                      >
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="icon"
                        >
                          Arquivo
                        </FormLabel>
                        <Button as="label" htmlFor="icon" mt={2}>
                          Selecionar arquivo
                        </Button>
                        <Text mt={2}>
                          {props.values.icon && (
                            <>
                              {props.values.icon.name}
                              <Button
                                variant="link"
                                color="red.500"
                                onClick={() =>
                                  props.setFieldValue("icon", null)
                                }
                              >
                                <BiTrash />
                              </Button>
                            </>
                          )}
                        </Text>
                        <Input
                          id="icon"
                          name="icon"
                          type="file"
                          display="none"
                          onChange={(event) => {
                            //console.log(event.target.files[0]);
                            props.setFieldValue(
                              "icon",
                              event.currentTarget.files[0]
                            );
                          }}
                        />

                        {errors.certificado && (
                          <FormErrorMessage>
                            {errors.certificado}
                          </FormErrorMessage>
                        )}
                        {errors.icon && (
                          <FormErrorMessage>
                            {errors.icon[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
              </CardBody>
            </Card>

            <Flex h="full" gap={2} mb={8} flexDirection="column">
              <Box
                borderWidth="1px"
                borderRadius="md"
                px={4}
                py={6}
                my={2}
                _dark={{
                  bg: "gray.800",
                  borderColor: "gray.700",
                }}
              >
                <Flex
                  h="full"
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                >
                  <FormControl isInvalid={errors.status}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="status"
                    >
                      Empresas
                    </FormLabel>
                    <AutocompleteInput
                      loading={loadingCompanies}
                      options={companies}
                      setOptions={setCompanies}
                      requests={getCompanies}
                      placeholder="Pesquisar empresas"
                      onClickFunction={selectCompany}
                      empty={true}
                    />
                  </FormControl>
                </Flex>
                <TableContainer size="sm" w="full" fontSize="sm">
                  <Table variant="striped" colorScheme="gray">
                    <Thead>
                      <Tr>
                        <Th px={0}>Nome</Th>
                        <Th px={0}>CNPJ</Th>
                        <Th px={0} w="60px"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {companiesTable.map((row, i) => {
                        return (
                          <Tr key={i}>
                            <Td px={2}>{row.empr_nome}</Td>
                            <Td px={2}>{row.empr_cnpj}</Td>
                            <Td px={2}>
                              <Button
                                mx={2}
                                size="sm"
                                colorScheme="red"
                                cursor="pointer"
                                type="button"
                                onClick={() => removeCompany(i)}
                              >
                                <MdOutlineCancel />
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th px={0}>Nome</Th>
                        <Th px={0}>CNPJ</Th>
                        <Th px={0}></Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              </Box>
            </Flex>
            <Flex
              align="end"
              justify="end"
              gap={2}
              mt="10px"
              flexDirection={{ sm: "column", lg: "row" }}
            >
              <Button
                leftIcon={<MdOutlineCancel />}
                size="sm"
                colorScheme="gray"
                cursor="pointer"
                type="button"
                
                w={{ sm: "100%", lg: "auto" }}
                onClick={() => {
                  setForm(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                leftIcon={<BiSave />}
                size="sm"
                colorScheme="blue"
                
                cursor="pointer"
                type="submit"
                
                w={{ sm: "100%", lg: "auto" }}
              >
                Salvar
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CategoriesOptionsForm;
