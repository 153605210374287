import {
  Button,
  Center,
  Td,
  Text,
  Tooltip,
  Tr
} from '@chakra-ui/react'

import { AiFillDelete, AiFillEdit } from 'react-icons/ai'

function RecurrentTablesTableRow (props) {
  const { id, name, type, day, selectRow, deleteRow } = props

  return (
    <Tr key={id}>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {name}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {type == 'week'
            ? 'Semanal'
            : type == 'month'
            ? 'Mensal'
            : 'Anual'}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {day}
        </Text>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
                cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
          <Tooltip label='Apagar'>
            <Button
              size='sm'
              p='0px'
              colorScheme='red'
                cursor='pointer'
              onClick={() => deleteRow(id)}
            >
              <AiFillDelete />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  )
}

export default RecurrentTablesTableRow
