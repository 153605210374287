import {
  Button,
  Flex,
  FormControl, FormErrorMessage, FormLabel, Input, Select, Textarea, useToast
} from "@chakra-ui/react";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import AllApi from "../../../api/all";
import CompaniesApi from "../../../api/company";
import CompaniesBankApi from "../../../api/companyBanks";
import AutocompleteInput from "../../../components/Inputs/AutocompleteInput";

function BanksForm(props) {
  const { user, bankId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [accountsPlans, setAccountsPlans] = useState([]);
  const [banks, setBanks] = useState([]);
  const toast = useToast();

  const [initialData, setInitialData] = useState({
    id: "",
    id_company: "",
    banc_codi: "",
    banc_nome: "",
    banc_situ: "",
    banc_comp: "",
    banc_agen: "",
    banc_digi_agen: "",
    banc_cont: "",
    banc_digi_cont: "",
    banc_tipo: "",
    banc_cont_cont: "",
    banc_cont_nume: "",
    banc_obse: "",
    sync_contabia: "",
  });

  useEffect(() => {
    if (bankId !== false && !loading) {
      loadDataItem();
    }
    if(user.type !== 'A'){
      loadBanks();
      loadAccountsPlans(user.id_company);
    }
  }, [bankId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await CompaniesBankApi.Show(bankId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      // await loadBanks(response.data.result.id_company);
      await loadAccountsPlans(response.data.result.id_company);
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      //console.log(err)
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = {};
      if (bankId) {
        response = await CompaniesBankApi.Update(values, bankId);
      } else {
        response = await CompaniesBankApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";

      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        return {
          id: value.id,
          name: value.empr_nome,
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  const loadBanks = async () => {
    try {
      let banks = await AllApi.banks();

      return setBanks(banks.data);
    } catch (err) {
      return setBanks([]);
    }
  };

  const loadAccountsPlans = async (idCompany) => {
    try {
      let accounts = await CompaniesApi.AccountPlans(idCompany);

      return setAccountsPlans(accounts.data);
    } catch (err) {
      return setAccountsPlans([]);
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            {user.type === "A" && (
              <Flex
                h="full"
                gap={2}
                mb={8}
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <FormControl isInvalid={errors.id_company}>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="id_company"
                  >
                    Empresa
                  </FormLabel>
                  {bankId && props.values.company ? (
                    <Input
                      size="sm"
                      readOnly
                      value={props.values.company.empr_nome}
                    />
                  ) : (
                    <AutocompleteInput
                      loading={loadingCompanies}
                      options={companies}
                      setOptions={setCompanies}
                      requests={getCompanies}
                      placeholder="Pesquisar empresa"
                      onClickFunction={(e) => {
                        props.setFieldValue("id_company", e.id);
                        loadAccountsPlans(e.id);
                      }}
                    />
                  )}
                  {errors.id_company && (
                    <FormErrorMessage>{errors.id_company[0]}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            )}
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="id_bank">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_bank}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_bank"
                    >
                      Banco
                    </FormLabel>
                    <Select size="sm" {...field} id="id_bank">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {banks.map((val, i) => (
                        <option key={`banks${val.id}`} value={val.id}>
                          {val.cod} - {val.name}
                        </option>
                      ))}
                    </Select>

                    {errors.id_bank && (
                      <FormErrorMessage>{errors.id_bank[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="banc_tipo">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.banc_tipo}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="banc_tipo"
                    >
                      Tipo da Conta
                    </FormLabel>
                    <Select size="sm" {...field} id="banc_tipo">
                      <option value="" disabled>
                        Selecione
                      </option>
                      <option value="0">Conta corrente</option>
                      <option value="1">Poupança</option>
                    </Select>

                    {errors.banc_tipo && (
                      <FormErrorMessage>{errors.banc_tipo[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="banc_situ">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.banc_situ}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="banc_situ"
                    >
                      Situação
                    </FormLabel>
                    <Select size="sm" {...field} id="banc_situ">
                      <option value="" disabled>
                        Selecione
                      </option>
                      <option value="1">Ativo</option>
                      <option value="0">Inativo</option>
                    </Select>

                    {errors.banc_situ && (
                      <FormErrorMessage>{errors.banc_situ[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="banc_agen">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.banc_agen}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="banc_agen"
                    >
                      Agência
                    </FormLabel>
                    <Input size="sm" {...field} id="banc_agen" />

                    {errors.banc_agen && (
                      <FormErrorMessage>{errors.banc_agen[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="banc_digi_agen">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.banc_digi_agen}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="banc_digi_agen"
                    >
                      Digito agência
                    </FormLabel>
                    <Input
                      maxLength={2}
                      size="sm"
                      {...field}
                      id="banc_digi_agen"
                    />

                    {errors.banc_digi_agen && (
                      <FormErrorMessage>
                        {errors.banc_digi_agen[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="banc_cont">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.banc_cont}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="banc_cont"
                    >
                      Número da Conta
                    </FormLabel>
                    <Input size="sm" {...field} id="banc_cont" />

                    {errors.banc_cont && (
                      <FormErrorMessage>{errors.banc_cont[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="banc_digi_cont">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.banc_digi_cont}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="banc_digi_cont"
                    >
                      Digito da conta
                    </FormLabel>
                    <Input
                      maxLength={2}
                      size="sm"
                      {...field}
                      id="banc_digi_cont"
                    />

                    {errors.banc_digi_cont && (
                      <FormErrorMessage>
                        {errors.banc_digi_cont[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="id_account_plan">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_account_plan}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_account_plan"
                    >
                      Plano de contas
                    </FormLabel>
                    <Select size="sm" {...field} id="id_account_plan">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {accountsPlans.map((val, i) => (
                        <option key={`accountsPlans${val.id}`} value={val.id}>
                          {val.plan_nome}
                        </option>
                      ))}
                    </Select>

                    {errors.id_account_plan && (
                      <FormErrorMessage>
                        {errors.id_account_plan[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="banc_cont_nume">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.banc_cont_nume}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="banc_cont_nume"
                    >
                      Numerários
                    </FormLabel>
                    <Input size="sm" {...field} id="banc_cont_nume" />

                    {errors.banc_cont_nume && (
                      <FormErrorMessage>
                        {errors.banc_cont_nume[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="banc_obse">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.banc_obse}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="banc_obse"
                    >
                      Observações
                    </FormLabel>
                    <Textarea size="sm" {...field} id="banc_obse" />
                    {errors.banc_obse && (
                      <FormErrorMessage>{errors.banc_obse[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"
                  
                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"
                  
                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default BanksForm;
