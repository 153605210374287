import { Flex, Spinner, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { WalletIcon } from "components/Icons/Icons";
import Card from "./Card";

function CardSimple(props) {
  const {
    textColor,
    icon,
    iconColor,
    title,
    value,
    loading,
    descritption,
  } = props;

  return (
    <Card minH="125px">
      <Flex direction="column">
        <Flex
          flexDirection="row"
          align="center"
          justify="center"
          w="100%"
          mb="25px"
        >
          <Stat me="auto">
            <StatLabel
              fontSize="xs"
              color="gray.400"
              fontWeight="bold"
              textTransform="uppercase"

            >
              {title}
            </StatLabel>
            <Flex>
              {loading ? (
                <Flex
                  w="100%"
                  h="100%"
                  align="center"
                  justifyContent="left"
                  fontWeight="light"
                  fontSize="sm"
                  gap={2}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.50"
                    color="gray.300"
                    size="md"
                  />{" "}
                  Carregando
                </Flex>
              ) : (
                <StatNumber fontSize="lg" color={textColor} _dark={{color: "gray.50"}} fontWeight="bold">
                  {value}
                </StatNumber>
              )}
            </Flex>
          </Stat>
          <IconBox
            borderRadius="50%"
            h={"45px"}
            w={"45px"}
            bg={iconColor}
          >
            {icon}
          </IconBox>
        </Flex>
        <Text color="gray.400"  fontSize="sm">
          {descritption}
        </Text>
      </Flex>
    </Card>
  );
}
export default CardSimple;
