import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Td,
  Thead,
  Tr,
  useColorModeValue,
  Container,
  Button,
  Spacer,
  useToast,
  Box,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import TablesTableRow from "./AccountsPayableTablesTableRow";
import TableSearch from "./AccountsPayableSearch";
import CompaniesAccountsPayableApi from "../../../api/companyAccountsPayable";
import Indeterminate from "../../../components/Progress/Indeterminate";
import NotFound from "../../../components/Progress/NotFound";
import AccountsPayableForm from "./AccountsPayableForm";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import queryString from "query-string";
import { useHistory } from "react-router";
import TablePagination from "../../../components/Tables/TablePagination";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { useAuth } from "auth-context/auth.context";

function AccountsPayableList(props) {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search);
  const textColor = useColorModeValue("gray.700", "white");
  const [companies, setCompanies] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState({});
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [accountsPayableId, setBankId] = useState(false);
  const [busca, setBusca] = useState({});
  const [sort, setSort] = useState("-created_at");
  const toast = useToast();

  useEffect(() => {
    loadData(queryParams);
  }, []);

  useEffect(() => {
    let p = queryParams.page ? queryParams.sort : 1;
    if (queryParams.sort !== sort || page !== p) {
      loadData(queryParams);
    }
  }, [sort, page]);

  const loadData = async (searchParams) => {
    setLoading(true);
    try {
      let response = await CompaniesAccountsPayableApi.List({
        sort: sort,
        page: page,
        ...searchParams,
      });
      setLoading(false);
      if (response.data && response.data.success === false) {
        return setError(response.data.message);
      }
      setPages(response.data.meta);
      let search = new URLSearchParams({ ...searchParams, sort: sort });
      history.push(`/admin/accounts/payable?${search}`);
      return setCompanies(response.data.data);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const selectRow = (id) => {
    setBankId(id);
    setForm(true);
  };

  const listSort = (column) => {
    if (sort.includes(column)) {
      if (sort.includes("-")) {
        setSort(column);
      } else {
        setSort("-" + column);
      }
    } else {
      setSort(column);
    }
  };

  const deleteRow = async (id) => {
    try {
      let response = await CompaniesAccountsPayableApi.Delete(id);

      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return loadData();
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Flex direction="column" pt={{ sm: "40px", base: "120px", md: "75px" }}>
      {loading && <LoadingFullpage />}
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        _dark={{ bg: "gray.900" }}
      >
        <CardHeader p="6px 0px 22px 0px">
          <Flex w="100%" align="center" justifyContent="space-between">
            <Box>
              {!form && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Contas à pagar
                </Text>
              )}
              {form && accountsPayableId && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Editar conta à pagar
                </Text>
              )}
              {form && !accountsPayableId && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Nova conta à pagar
                </Text>
              )}
            </Box>
            <Spacer />
            <Box>
              {!form && (
                <Button
                  leftIcon={<AiOutlinePlusCircle />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="button"
                  onClick={() => {
                    setForm(true);
                    setBankId(false);
                  }}
                >
                  Nova conta à pagar
                </Button>
              )}
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          {form ? (
            <Container maxW={"12xl"} p="0">
              <AccountsPayableForm
                user={user}
                accountsPayableId={accountsPayableId}
                setForm={() => setForm()}
                loadData={() => loadData()}
              />
            </Container>
          ) : (
            <Container maxW={"12xl"} p="0">
              <TableSearch
                loadData={loadData}
                searchParams={queryParams}
                loading={loading}
                user={user}
              />
              <Box overflowX="auto">
                <Table variant="striped" colorScheme="gray" color={textColor}>
                  <Thead>
                    <Tr my=".8rem" pl="0px" colorScheme="gray">
                      {user.type === "A" && (
                        <Th
                          px={0}
                          pl="0px"
                          colorScheme="gray"
                          cursor="pointer"
                          onClick={() => listSort("company.empr_nome")}
                        >
                          <Flex align="center" justify="start" gap={1}>
                            {sort == "-company.empr_nome" && <RiSortDesc />}
                            {sort == "company.empr_nome" && <RiSortAsc />}
                            <Text>Empresa</Text>
                          </Flex>
                        </Th>
                      )}
                      <Th
                        px={0}
                        pl="0px"
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("titu_titu")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-titu_titu" && <RiSortDesc />}
                          {sort == "titu_titu" && <RiSortAsc />}
                          <Text>Dados</Text>
                        </Flex>
                      </Th>

                      <Th
                        px={0}
                        pl="0px"
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("titu_valo")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-titu_valo" && <RiSortDesc />}
                          {sort == "titu_valo" && <RiSortAsc />}
                          <Text>Valor</Text>
                        </Flex>
                      </Th>
                      {user.type === "A" && (
                        <Th
                          px={0}
                          pl="0px"
                          colorScheme="gray"
                          cursor="pointer"
                          onClick={() => listSort("titu_valo")}
                        >
                          <Flex align="center" justify="start" gap={1}>
                            {sort == "-titu_valo" && <RiSortDesc />}
                            {sort == "titu_valo" && <RiSortAsc />}
                            <Text>Status comunicação</Text>
                          </Flex>
                        </Th>
                      )}
                      <Th
                        px={0}
                        colorScheme="gray"
                        w="140px"
                        textAlign="center"
                      >
                        Ações
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {companies.length == 0 && (
                      <Tr>
                        <Td px={2} colSpan={7}>
                          <NotFound />
                        </Td>
                      </Tr>
                    )}
                    {companies &&
                      companies.map((row) => {
                        return (
                          <TablesTableRow
                            key={row.id}
                            user={user}
                            {...row}
                            selectRow={(id) => selectRow(id)}
                            deleteRow={(id) => deleteRow(id)}
                          />
                        );
                      })}
                  </Tbody>
                </Table>
              </Box>
              <TablePagination pages={pages} changePage={(e) => setPage(e)} />
            </Container>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default AccountsPayableList;
