
import {
  Avatar,
  Badge,
  Button,
  Center,
  Flex,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';

import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

function ContentsTablesTableRow(props) {
  const {
    id,
    status,
    name,
    title,
    category,
    short_content,
    content,
    type,
    image_url,
    selectRow,
    deleteRow
  } = props
  const textColor = useColorModeValue('gray.700', 'white')
  const toast = useToast()


  return (
    <Tr key={id}>
      <Td px={2} minWidth={{ sm: '250px' }} >
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Avatar
            name={name}
            src={image_url}
            w='50px'
            borderRadius='12px'
            me='18px'
          />
          <Flex direction='column'>
            <Text
              fontSize='md'
              color={textColor}
              minWidth='100%'
            >
              {name}
            </Text>
            <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
              {category}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {title}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {type == 'E'&& 'EMPRESA' }
          {type == 'F'&& 'FUNCIONÁRIO' }
          {type == 'A'&& 'TODOS' }
        </Text>
      </Td>
      <Td px={2}>
        <Badge
          bg={status ? 'green.400' : 'red.400'}
          color='white'
          fontSize='11px'
          p='3px 10px'
          borderRadius='8px'
        >
          {status && 'ATIVO'}
          {!status && 'INATIVO'}
        </Badge>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
              cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
          <Tooltip label='Apagar'>
            <Button
              size='sm'
              p='0px'
              colorScheme='red'
              cursor='pointer'
              onClick={() => deleteRow(id)}
            >
              <AiFillDelete />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  )
}

export default ContentsTablesTableRow
