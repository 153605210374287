import {
  Avatar,
  Box,
  Button,
  Center,
  Heading,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import AuthApi from 'api/auth';
import { useAuth } from "auth-context/auth.context";
import { useHistory } from "react-router-dom";

export default function UserCard() {
  const { user, setUser } = useAuth();
  const history = useHistory();

  const logout = async () => {
    try {
      await AuthApi.Logout(user);
    } catch (error) {
      console.log(error.response)
    }
    localStorage.clear();
    window.location.href = "/";
  };


  return (
    <Center
      maxW={'320px'}
      w={'100%'}>
      <Box
        maxW={'320px'}
        w={'100%'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}>
        <Avatar
          size={'xl'}
          name={user.name}
          alt={user.name}
          src={user.avatar_url}
          mb={4}
          pos={'relative'}
        />
        <Heading fontSize={'2xl'} fontFamily={'body'}>
          {user.name}
        </Heading>
        <Text fontWeight={600} color={'gray.500'} mb={4}>
          {user.type !== "A" &&
            user.company !== null &&
            user.company.empr_nome}
          {user.type == "A" && "Administrador"}
        </Text>


        <Stack mt={8} direction={'row'} spacing={4}>
          <Button
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            colorScheme={'gray'}
            onClick={() => history.push('/admin/profile')}>
            Perfil
          </Button>
          <Button
            onClick={logout}
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            colorScheme={'red'}>
            Sair
          </Button>
        </Stack>
      </Box>
    </Center>
  );
}