import React, {useState } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'

import { Formik, Field, Form } from 'formik'

import { AiOutlineSearch } from 'react-icons/ai'
import { BiEraser } from 'react-icons/bi'

function SubTopicSearch (props) {
  const { loadData, searchParams, loading } = props
  const [initialValue, setInitialValue] = useState({
    name: (searchParams["filter[name]"])?searchParams["filter[name]"]:''
  })

  const search = params => {
    params.page = 1
    loadData(params)
  }
  const clear = () => {
    loadData({page: 1})
  }

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        let params = {}
        for (var key in values) {
          params[`filter[${key}]`] = values[key]
        }
        search(params)
      }}
    >
      {props => (
        <Form>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            <Field name='name'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='name'
                  >
                    Nome
                  </FormLabel>
                  <Input size='sm' {...field} id='name' placeholder='Nome' />
                </FormControl>
              )}
            </Field>
            
            <Flex align='end' justify='center'>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='green'
                cursor='pointer'
                
                type='submit'
              >
                <AiOutlineSearch />
              </Button>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='blue'
                cursor='pointer'
                type='button'
                
                onClick={() => {
                  props.setFieldValue('name','')
                  clear()
                }}
              >
                <BiEraser />
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default SubTopicSearch
