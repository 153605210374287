import {
  Button,
  Center,
  Flex,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'

import { AiFillDelete, AiFillEdit } from 'react-icons/ai'

function ClientsTablesTableRow(props) {
  const { id, company, 
    serv_codi,
    serv_nome,
    serv_obse,
    serv_nbs,
    serv_cnae,
    serv_valo,
    serv_valo_praz,
    serv_desc_serv,
    serv_perc_prof,
    sync_contabia,
    selectRow, deleteRow, user } = props

  const textColor = useColorModeValue('gray.700', 'white')

  return (
    <Tr key={id}>
      {user.type === 'A' && (
        <Td px={2} minWidth={{ sm: '250px' }} >
          <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
            <Text fontSize='md' color={textColor} minWidth='100%'>
              {company.empr_nome}
            </Text>
          </Flex>
        </Td>
      )}
      <Td px={2} minWidth={{ sm: '250px' }} >
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Text fontSize='md' color={textColor} minWidth='100%'>
            {serv_codi}
          </Text>
        </Flex>
      </Td>
      <Td px={2} minWidth={{ sm: '250px' }} >
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Text fontSize='md' color={textColor} minWidth='100%'>
            {serv_nome}
          </Text>
        </Flex>
      </Td>
      <Td px={2} minWidth={{ sm: '250px' }} >
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Text fontSize='md' color={textColor} minWidth='100%'>
            {serv_cnae}
          </Text>
        </Flex>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
              cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
          <Tooltip label='Apagar'>
            <Button
              size='sm'
              p='0px'
              colorScheme='red'
              cursor='pointer'
              onClick={() => deleteRow(id)}
            >
              <AiFillDelete />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  )
}

export default ClientsTablesTableRow
