import React, { useState } from 'react'
import {
  Flex,
  Container,
  Heading,
  Stack,
  Button,
  Icon,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  Grid,
  GridItem,
  Img,
  SimpleGrid,
  Box
} from '@chakra-ui/react'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import CardHeader from '../../components/Card/CardHeader'
import TicketsFormHome from '../Dashboard/Tickets/Tickets/TicketsFormHome'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { BsCardImage, BsCurrencyDollar } from 'react-icons/bs'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import { breakpoints } from 'theme/foundations/breakpoints'
import { useAuth } from "auth-context/auth.context";

export default function Dashboard() {
  const { user, setUser } = useAuth();
  const history = useHistory()
  const [showForm, setShowForm] = useState(false)
  const [idSla, setIdSla] = useState(false)
  const [idPriority, setIdPriority] = useState(false)
  const [idType, setIdType] = useState(false)
  const [idSubtopic, setIdSubTopic] = useState(false)
  const [idStatus, setIdStatus] = useState(false)
  const [idTopic, setIdTopic] = useState(false)
  const [idDepartment, setIdDepartment] = useState(false)
  const [subject, setSubject] = useState(false)

  const setForm = (
    sla,
    priority,
    type,
    subtopic,
    status,
    topic,
    department,
    sub
  ) => {
    setIdSla(sla)
    setIdPriority(priority)
    setIdType(type)
    setIdSubTopic(subtopic)
    setIdStatus(status)
    setIdTopic(topic)
    setIdDepartment(department)
    setSubject(sub)
    setShowForm(true)
  }

  const resetForm = () => {
    setIdSla(false)
    setIdPriority(false)
    setIdType(false)
    setIdSubTopic(false)
    setIdStatus(false)
    setIdTopic(false)
    setIdDepartment(false)
    setSubject(false)
    setShowForm(false)
  }

  return (
    <>
      <Modal isOpen={showForm} onClose={() => setShowForm(false)} size='4xl'>
        <ModalOverlay />
        <ModalContent m={10}>
          <ModalHeader>Abrir atendimento</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <TicketsFormHome
              idSla={idSla}
              idPriority={idPriority}
              idType={idType}
              idSubtopic={idSubtopic}
              idStatus={idStatus}
              idTopic={idTopic}
              idDepartment={idDepartment}
              subject={subject}
              resetForm={resetForm}
            />
          </ModalBody>
        </ModalContent>
      </Modal>


      {(user !== null && user.type && user.type == 'A') ? (
        <Container maxW={'5xl'}>
          <Stack
            textAlign={'center'}
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 28 }}
          >
            <Heading
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
              lineHeight={'110%'}
            >
              Painel administrativo{' '}
              <Text as={'span'} color={'primary'}>
                Contabia
              </Text>
            </Heading>
            <Text color={'gray.500'} maxW={'3xl'}>
              Este painel administrativo tem o intuido de administrar seus
              protocolos.
            </Text>
            <Stack spacing={6} direction={'row'}>
              <Button
                rounded={'full'}
                px={6}
                colorScheme={'orange'}
                bg={'primary'}
                _hover={{ bg: 'orange.500' }}
                onClick={() => history.push('/admin/tickets')}
              >
                Visualizar protocolos
              </Button>
              {/* <Button rounded={'full'} px={6} onClick={() => history.push('/admin/tickets')}>
              Abrir protocolo
            </Button> */}
            </Stack>
            <Flex w={'full'}>
              {/* <Illustration
                height={{ sm: '24rem', lg: '28rem' }}
                mt={{ base: 12, sm: 16 }}
              /> */}
            </Flex>
          </Stack>
        </Container>
      ) : (
        <Flex py={{ base: 20, md: 28 }}>
          <Flex
            width='100%'
            flexDirection='column'
            justifyContent='start'
            alignItems='start'
            alignContent='start'
          >
            {(user !== null && user.forms) &&
              user.forms.map(categories => (
                <Card p='1rem' my={2} >
                  <CardHeader p={0} my={0}>
                    <Text p={0} my={0} fontSize='3xl' color='primary' _dark={{ color: 'gray.300' }}>
                      {categories.category.name}
                    </Text>
                    <Text py={0}>{categories.category.description}</Text>
                  </CardHeader>
                  <CardBody p={0} my={0}>
                    <SimpleGrid key={categories.category.id}
                      width='100%'
                      columns={{
                        base: 1,
                        lg: 5,
                      }}
                      gap={6}
                    >
                      {categories.forms &&
                        categories.forms.map(form => (

                          <Box
                            mx='auto'
                            w='100%'
                            cursor='pointer'
                            px={8}
                            py={12}
                            my={2}
                            bg='gray.100'
                            borderRadius={10}
                            _dark={{ bg: 'gray.800', _hover: { bg: 'primary', color: 'white' } }}
                            _hover={{ bg: 'primary', color: 'white' }}
                            sx={{ _hover: { bg: 'primary', color: 'white', '.chakra-image': { filter: 'invert(1)' } } }}
                            key={form.id}
                            onClick={() => {
                              setForm(
                                form.id_sla,
                                form.id_priority,
                                form.id_type,
                                form.id_subtopic,
                                form.id_status,
                                form.id_topic,
                                form.id_department,
                                form.subject
                              )
                            }}
                          >
                            <Flex
                              w='100%'
                              flexDirection='column'
                              justifyContent='center'
                              alignItems='center'
                              alignContent='center'>
                              {form.icon_url ?
                                <Img src={form.icon_url} width='5rem' _dark={{ filter: 'invert(1)' }} />
                                :
                                <BsCardImage size='5rem' color='#e0e0e0' />
                              }

                              <Text fontSize='xl' fontWeight='bold'>{form.name}</Text>
                              <Text fontSize='xs' textAlign='center'>{form.description}</Text>
                            </Flex>
                          </Box>
                        ))}
                    </SimpleGrid>
                  </CardBody>
                </Card>
              ))}
          </Flex>
        </Flex>
      )}
    </>
  )
}
