import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast
} from "@chakra-ui/react";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import AllApi from "../../../api/all";
import CitiesApi from "../../../api/cities";
import CompaniesApi from "../../../api/company";
import CompaniesSupplierApi from "../../../api/companySuppliers";
import CustomInputMask from "../../../components/Inputs/CustomInputMask";
import AutocompleteInput from "../../../components/Inputs/AutocompleteInput";

function SuppliersForm(props) {
  const { user, supplierId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  // const [accountsPlans, setAccountsPlans] = useState([]);
  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);
  const [citySelected, setCitySelected] = useState("");
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [mask, setMask] = useState("(99) 99999-9999");
  const toast = useToast();
  const typeAddress = [
    "Alameda",
    "Avenida",
    "Chácara",
    "Colônia",
    "Condomínio",
    "Estância",
    "Estrada",
    "Fazenda",
    "Praça",
    "Prolongamento",
    "Rodovia",
    "Rua",
    "Sítio",
    "Travessa",
    "Vicinal",
    "Eqnp",
  ];

  const [initialData, setInitialData] = useState({
    id: "",
    type: "J",
    cpf_cnpj: "",
    name: "",
    phone: "",
    email: "",
    cep: "",
    address: "",
    number: "",
    id_city: "",
    city: "",
    district: "",
    complement: "",
    state: "",
    id_company: "",
    insc_mun: "",
    type_address: "",
    id_account_plan: "",
  });

  useEffect(() => {
    if (supplierId !== false && !loading) {
      loadDataItem();
    } else {
      if (user.type != "A") {
        loadAccountsPlans(user.id_company);
      }
    }
  }, [supplierId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await CompaniesSupplierApi.Show(supplierId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      if (response.data.result.uf !== "") {
        // loadCities(response.data.result.state_id);
        setCitySelected(response.data.result.city.name);
      }
      // loadAccountsPlans(response.data.id_company);
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = {};
      if (supplierId) {
        response = await CompaniesSupplierApi.Update(values, supplierId);
      } else {
        response = await CompaniesSupplierApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";

      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadAccountsPlans = async (idCompany) => {
    try {
      let accounts = await CompaniesApi.AccountPlans(idCompany);

      return setAccountsPlans(accounts.data);
    } catch (err) {
      return setAccountsPlans([]);
    }
  };

  const loadCities = async (uf) => {
    try {
      let cities = await CitiesApi.All(uf);
      setCities(cities.data);
      if (initialData.city) {
        if (parseInt(uf) !== initialData.city.state_id) {
          setCitySelected("");
        } else {
          setCitySelected(initialData.city.name);
        }
      } else {
        setCitySelected("");
      }

      return true;
    } catch (err) {
      setCities([]);
      setCitySelected("");
      return false;
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        return {
          id: value.id,
          name: value.empr_nome,
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  const getCep = async (cep) => {
    try {
      let search = cep.replace(/[^0-9]/g, "");

      if (search.length == 8) {
        let result = await AllApi.cep(search);
        if (result.data.error) {
          toast({
            title: "Erro",
            description: result.data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          return false;
        }
        return result.data.cep;
      }
    } catch (err) {
      toast({
        title: "Erro",
        description: err.response.data.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return false;
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            {user.type === "A" && (
              <Flex
                h="full"
                gap={2}
                mb={8}
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <FormControl isInvalid={errors.id_company}>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="id_company"
                  >
                    Empresa
                  </FormLabel>
                  {supplierId && props.values.company ? (
                    <Input
                      size="sm"
                      readOnly
                      value={props.values.company.empr_nome}
                    />
                  ) : (
                    
                    <AutocompleteInput
                      loading={loadingCompanies}
                      options={companies}
                      setOptions={setCompanies}
                      requests={getCompanies}
                      placeholder="Pesquisar empresa"
                      onClickFunction={(e) => {
                        props.setFieldValue("id_company", e.id);
                        // loadAccountsPlans(e.id);
                      }}
                    />
                  )}
                  {errors.id_company && (
                    <FormErrorMessage>{errors.id_company[0]}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            )}
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="type">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.type}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="type"
                    >
                      Tipo
                    </FormLabel>
                    <Select size="sm" {...field} id="type">
                      <option value="" disabled>
                        Selecione
                      </option>
                      <option value="F" key={1}>
                        PF
                      </option>
                      <option value="J" key={0}>
                        PJ
                      </option>
                    </Select>

                    {errors.type && (
                      <FormErrorMessage>{errors.type[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="cpf_cnpj">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.cpf_cnpj}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="cpf_cnpj"
                    >
                      CPF/CNPJ
                    </FormLabel>
                    {/* <Input size='sm' {...field} id='cpf_cnpj' /> */}
                    <CustomInputMask
                      mask={
                        props.values.type == "F"
                          ? "999.999.999-99"
                          : "99.999.999/9999-99"
                      }
                      value={props.values.cpf_cnpj}
                      name="cpf_cnpj"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        props.setFieldValue("cpf_cnpj", value);
                      }}
                    />
                    {errors.cpf_cnpj && (
                      <FormErrorMessage>{errors.cpf_cnpj[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="phone">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.phone}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="phone"
                    >
                      Telefone
                    </FormLabel>
                    {/* <Input size='sm' {...field} id='phone' /> */}
                    <CustomInputMask
                      mask={mask}
                      name="phone"
                      value={props.values.phone}
                      onBlur={(e) => {
                        if (e.target.value.replace("_", "").length === 14) {
                          setMask("(99) 9999-9999");
                        }
                      }}
                      onFocus={(e) => {
                        if (e.target.value.replace("_", "").length === 14) {
                          setMask("(99) 99999-9999");
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        props.setFieldValue("phone", value);
                      }}
                    />
                    {errors.phone && (
                      <FormErrorMessage>{errors.phone[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.email}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="email"
                    >
                      E-mail
                    </FormLabel>
                    <Input size="sm" {...field} id="email" />

                    {errors.email && (
                      <FormErrorMessage>{errors.email[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="cep">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.cep}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="cep"
                    >
                      CEP
                    </FormLabel>
                    {/* <Input size='sm' {...field} id='cep' /> */}
                    <CustomInputMask
                      mask="99999-999"
                      name="cep"
                      onChange={async (e) => {
                        let value = e.target.value || "";
                        props.setFieldValue("cep", value);
                        let cep = await getCep(value);
                        if (cep) {
                          props.setFieldValue("type_address", cep.address_type);
                          props.setFieldValue("address", cep.logradouro);
                          props.setFieldValue("complement", cep.complemento);
                          props.setFieldValue("district", cep.bairro);
                          props.setFieldValue("city", cep.municipio);
                          setCitySelected(cep.municipio);
                          props.setFieldValue("id_city", cep.ibge);
                          props.setFieldValue("state", cep.uf);
                        }
                      }}
                    />
                    {errors.cep && (
                      <FormErrorMessage>{errors.cep[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="type_address">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.type_address}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="type_address"
                    >
                      Tipo de endereço
                    </FormLabel>
                    <Select size="sm" {...field} id="type_address">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {typeAddress.map((val, i) => (
                        <option key={`typeAddress${val}`} value={val}>
                          {val}
                        </option>
                      ))}
                    </Select>

                    {errors.type_address && (
                      <FormErrorMessage>
                        {errors.type_address[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="address">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.address}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="address"
                    >
                      Endereço
                    </FormLabel>
                    <Input size="sm" {...field} id="address" />

                    {errors.address && (
                      <FormErrorMessage>{errors.address[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="complement">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.complement}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="complement"
                    >
                      Complemento
                    </FormLabel>
                    <Input size="sm" {...field} id="complement" />

                    {errors.complement && (
                      <FormErrorMessage>
                        {errors.complement[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="number">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.number}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="number"
                    >
                      Número
                    </FormLabel>
                    <Input size="sm" {...field} id="number" />

                    {errors.number && (
                      <FormErrorMessage>{errors.number[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="district">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.district}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="district"
                    >
                      Bairro
                    </FormLabel>
                    <Input size="sm" {...field} id="district" />

                    {errors.district && (
                      <FormErrorMessage>{errors.district[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="city">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.city}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="city"
                      value={citySelected}
                    >
                      Cidade
                    </FormLabel>
                    <Input size="sm" {...field} id="city" readOnly />

                    {errors.city && (
                      <FormErrorMessage>{errors.city[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="state">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.state_id}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="state_id"
                    >
                      UF
                    </FormLabel>
                    <Input size="sm" {...field} id="state" readOnly />

                    {errors.state_id && (
                      <FormErrorMessage>{errors.state_id[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            {/* <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: "column", lg: "row" }}
            w="100%"
          >
            <Field name="id_account_plan">
              {({ field, form }) => (
                <FormControl isInvalid={errors.id_account_plan}>
                  <FormLabel
                    _dark={{
                      color: "gray.500",
                    }}
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="id_account_plan"
                  >
                    Plano de contas
                  </FormLabel>
                  <Select size="sm" {...field} id="id_account_plan">
                    <option value="" disabled>
                      Selecione
                    </option>
                    {accountsPlans.map((val, i) => (
                      <option key={`accountsPlans${val.id}`} value={val.id}>
                        {val.plan_nome}
                      </option>
                    ))}
                  </Select>

                  {errors.id_account_plan && (
                    <FormErrorMessage>
                      {errors.id_account_plan[0]}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>
          </Flex> */}
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"
                  
                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"
                  
                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SuppliersForm;
