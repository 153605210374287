import { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Container,
  Flex,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import queryString from "query-string";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import { useHistory } from "react-router";
import CompaniesSuppliersApi from "../../../api/companySuppliers";
import NotFound from "../../../components/Progress/NotFound";
import TablePagination from "../../../components/Tables/TablePagination";
import SuppliersForm from "./SuppliersForm";
import TableSearch from "./SuppliersSearch";
import TablesTableRow from "./SuppliersTablesTableRow";
import { useAuth } from "auth-context/auth.context";

function CompaniesSuppliersList(props) {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search);
  const textColor = useColorModeValue("gray.700", "white");
  const [companies, setCompanies] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState({});
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [supplierId, setSupplierId] = useState(false);
  const [busca, setBusca] = useState({});
  const [sort, setSort] = useState("name");
  const toast = useToast();

  useEffect(() => {
    loadData(queryParams);
  }, []);

  useEffect(() => {
    let p = queryParams.page ? queryParams.sort : 1;
    if (queryParams.sort !== sort || page !== p) {
      loadData(queryParams);
    }
  }, [sort, page]);

  const loadData = async (searchParams) => {
    setLoading(true);
    try {
      let response = await CompaniesSuppliersApi.List({
        sort: sort,
        page: page,
        ...searchParams,
      });
      setLoading(false);
      if (response.data && response.data.success === false) {
        return setError(response.data.message);
      }
      setPages(response.data.meta);
      let search = new URLSearchParams({ ...searchParams, sort: sort });
      history.push(`/admin/accounts/suppliers?${search}`);
      return setCompanies(response.data.data);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const selectRow = (id) => {
    setSupplierId(id);
    setForm(true);
  };

  const listSort = (column) => {
    if (sort.includes(column)) {
      if (sort.includes("-")) {
        setSort(column);
      } else {
        setSort("-" + column);
      }
    } else {
      setSort(column);
    }
  };

  const deleteRow = async (id) => {
    try {
      let response = await CompaniesSuppliersApi.Delete(id);

      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return loadData();
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Flex direction="column" pt={{ sm: "40px", base: "120px", md: "75px" }}>
      {loading && <LoadingFullpage />}
      <Card _dark={{ bg: "gray.900" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex w="100%" align="center" justifyContent="space-between">
            <Box>
              {!form && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Fornecedores
                </Text>
              )}
              {form && supplierId && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Editar fornecedor
                </Text>
              )}
              {form && !supplierId && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Novo fornecedor
                </Text>
              )}
            </Box>
            <Spacer />
            <Box>
              {!form && (
                <Button
                  leftIcon={<AiOutlinePlusCircle />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="button"
                  onClick={() => {
                    setForm(true);
                    setSupplierId(false);
                  }}
                >
                  Novo fornecedor
                </Button>
              )}
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          {form ? (
            <Container maxW={"12xl"} p="0">
              <SuppliersForm
                user={user}
                supplierId={supplierId}
                setForm={() => setForm()}
                loadData={() => loadData()}
              />
            </Container>
          ) : (
            <Container maxW={"12xl"} p="0">
              <TableSearch
                loadData={loadData}
                searchParams={queryParams}
                loading={loading}
                user={user}
              />
              <Box overflowX="auto">
                <Table variant="striped" colorScheme="gray" color={textColor}>
                  <Thead>
                    <Tr colorScheme="gray">
                      {user.type === "A" && (
                        <Th
                          px={0}
                          colorScheme="gray"
                          cursor="pointer"
                          onClick={() => listSort("company.empr_nome")}
                        >
                          <Flex align="center" justify="start" gap={1}>
                            {sort == "-company.empr_nome" && <RiSortDesc />}
                            {sort == "company.empr_nome" && <RiSortAsc />}
                            <Text>Empresa</Text>
                          </Flex>
                        </Th>
                      )}
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("name")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-name" && <RiSortDesc />}
                          {sort == "name" && <RiSortAsc />}
                          <Text>Fornecedor</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("cpf_cnpj")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-cpf_cnpj" && <RiSortDesc />}
                          {sort == "cpf_cnpj" && <RiSortAsc />}
                          <Text>CPF/CNPJ</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        w="140px"
                        textAlign="center"
                      >
                        Ações
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {companies.length == 0 && (
                      <Tr>
                        <Td px={2} colSpan={4}>
                          <NotFound />
                        </Td>
                      </Tr>
                    )}
                    {companies &&
                      companies.map((row) => {
                        return (
                          <TablesTableRow
                            key={row.id}
                            user={user}
                            {...row}
                            selectRow={(id) => selectRow(id)}
                            deleteRow={(id) => deleteRow(id)}
                          />
                        );
                      })}
                  </Tbody>
                </Table>
              </Box>
              <TablePagination pages={pages} changePage={(e) => setPage(e)} />
            </Container>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default CompaniesSuppliersList;
