import axios from './index'

class CompaniesApi {
  static List = data => {
    let search = new URLSearchParams(data);
    return axios.get(`${base}?${search}`)
  }

  static Show = id => {
    return axios.get(`${base}/${id}`, )
  }

  static Save = (data) => {
    return axios.post(`${base}`, data)
  }

  static Update = (data, id) => {
    return axios.post(`${base}/${id}`, data)
  }

  static Delete = id => {
    return axios.delete(`${base}/${id}`)
  }

  static All = () => {
    return axios.get(`admin/list-companies`)
  }

  static Search = (search) => {
    return axios.get(`admin/list-companies?search=${search}`)
  }

  static AddManual = (data) => {
    return axios.post(`admin/company/manual-create`, data)
  }

  static AccountPlans = (id) => {
    return axios.get(`admin/company/list-account-plans/${id}`)
  }

  static Banks = (id) => {
    return axios.get(`admin/company/list-banks/${id}`)
  }

  static SyncServices = (id) => {
    return axios.post(`admin/company/sync/services/${id}`)
  }

  static SyncAccountPlans = (id) => {
    return axios.post(`admin/company/sync/account_plans/${id}`)
  }

  static SyncBanks = (id) => {
    return axios.post(`admin/company/sync/banks/${id}`)
  }

  static counters = (id) => {
    return axios.get(`admin/my-counters`)
  }

}

let base = 'admin/companies'

export default CompaniesApi
