import axios from './index'

class TicketsApi {
  static List = data => {
    let search = new URLSearchParams(data);
    return axios.get(`${base}?${search}`)
  }

  static Show = id => {
    return axios.get(`${base}/${id}`, )
  }

  static Save = (data) => {
    return axios.post(`${base}`, data)
  }

  static Update = (data, id) => {
    return axios.post(`${base}/${id}`, data)
  }

  static Delete = id => {
    return axios.delete(`${base}/${id}`)
  }

  static msgSave = (data) => {
    return axios.post(`${base}/message`, data)
  }

  static msgUpdate = (data, id) => {
    return axios.post(`${base}/message/${id}`, data)
  }

  static msgDelete = id => {
    return axios.delete(`${base}/message/${id}`)
  }

  static Take = id => {
    return axios.post(`${base}/take/${id}`, )
  }
  static ReOpen = id => {
    return axios.post(`${base}/reopen/${id}`, )
  }
  static ChangeDepartment = (id, department) => {
    return axios.post(`${base}/department/${id}/${department}`, )
  }
  static ChangeStatus = (id, status) => {
    return axios.post(`${base}/status/${id}/${status}`, )
  }
  static ChangeSolver = (id, user) => {
    return axios.post(`${base}/solver/${id}/${user}`, )
  }
  static Hist = ( id, page ) => {
    return axios.get(`${base}/hist/${id}?page=${page}`, )
  }
  static Views = ( id, page ) => {
    return axios.get(`${base}/views/${id}?page=${page}`, )
  }

}

let base = 'admin/tickets'

export default TicketsApi
