import React from "react";
// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Flex,
  Button,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { MdTimer, MdVideoLibrary } from "react-icons/md";
import ReactHtmlParser from 'react-html-parser'

function CardNews1(props) {
  const {
    title,
    description,
    date
  } = props
  let boxBg = useColorModeValue("white !important", "gray.800 !important");
  let secondaryBg = useColorModeValue("gray.50", "whiteAlpha.100");
  let mainText = useColorModeValue("gray.800", "white");
  return (
    <Flex
      width="100%"
      bg={boxBg}
      direction='column'>
      <Box p='20px'>
        <Box>
          <Text fontWeight='600' color={mainText} w='100%' fontSize='2xl'>
           {title}
          </Text>
        </Box>
      </Box>
      <Flex
        bg={secondaryBg}
        w='100%'
        p='20px'
        borderBottomLeftRadius='inherit'
        borderBottomRightRadius='inherit'
        height='100%'
        direction='column'>
        <Text
          fontSize='sm'
          color='gray.500'
          lineHeight='24px'
          pe='40px'
          fontWeight='500'
          mb='auto'>
          {ReactHtmlParser(description)}
        </Text>
        <Flex>
          <Flex me='25px'>
            <Icon as={MdTimer} w='20px' h='20px' me='6px' color='green.400' />
            <Text color={mainText} fontSize='sm' my='auto' fontWeight='500'>
              {date}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CardNews1;