import {
  Button,
  Center,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import { AiFillEdit } from "react-icons/ai";

function WhatsappTablesTableRow(props) {
  const { id, name, phone, selectRow, deleteRow } = props;

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Tr key={id}>
      <Td px={2}>
        <Text fontSize="sm" colorScheme="gray" fontWeight="normal">
          {name}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize="sm" colorScheme="gray" fontWeight="normal">
          {phone}
        </Text>
      </Td>
      <Td px={2}>
        <Center w="100%">
          <Tooltip label="Editar">
            <Button
              size="sm"
              p="0px"
              me="4px"
              colorScheme="blue"
              cursor="pointer"
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  );
}

export default WhatsappTablesTableRow;
