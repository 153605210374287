import React, { useState } from 'react'
import {
  Alert,
  AlertIcon,
  Badge,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import { AiFillDelete, AiFillEdit, AiOutlineSearch } from "react-icons/ai";

function AccountsReceivablesTablesTableRow(props) {
  const [openContabia, setOpenContabia] = useState(false);
  const [openContabiaP, setOpenContabiaP] = useState(false);
  const {
    id,
    id_company,
    id_contabia,
    titu_fili,
    titu_forn,
    titu_titu,
    titu_seri,
    titu_parc,
    titu_emis,
    titu_venc,
    titu_valo,
    titu_cont,
    titu_obse,
    titu_form_rece,
    created_at,
    updated_at,
    deleted_at,
    id_client,
    id_account_plan,
    return_contabia,
    company,
    selectRow,
    deleteRow,
    user,
    client,
    account_plan,
    payment,
    payment_data
  } = props;

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Tr key={id}>
      {user.type === "A" && (
        <Td px={2} minWidth={{ sm: "250px" }} >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="md" color={textColor} minWidth="100%">
              {company.empr_nome}
            </Text>
          </Flex>
        </Td>
      )}
      <Td px={2} minWidth={{ sm: "250px" }} >
        <Flex align="center" py=".8rem" minWidth="100%" direction='column'>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Data emissão:</b> {titu_emis}
          </Text>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Data vencimento:</b> {titu_venc}
          </Text>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Filial:</b> {titu_fili} </Text>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Número do Título:</b> {titu_titu} </Text>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Série:</b> {titu_seri} </Text>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Parcela:</b> {titu_parc} </Text>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Forma de Recebimento:</b> {titu_form_rece} </Text>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Cliente:</b> {client.cpf_cnpj} {client.name} </Text>
          <Text fontSize="md" color={textColor} minWidth="100%">
            <b>Conta contábil:</b> {account_plan.plan_redu} - {account_plan.plan_nome}</Text>
        </Flex>
      </Td>
      <Td px={2} minWidth={{ sm: "250px" }} >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={textColor} minWidth="100%">
            R$ {titu_valo}
          </Text>
        </Flex>
      </Td>
      {user.type === "A" && (
        <Td px={2} minWidth={{ sm: "250px" }} >
          <Flex align="left" direction="column" my={4}>

            {id_contabia !== null ? (
              <Text>
                <Badge colorScheme="green">TÍTULO COMUNICADO</Badge>
              </Text>
            ) : (
              <Text>

                <Badge colorScheme="red">ERRO NA COMUNICAÇÃO DO TÍTULO</Badge>
                {return_contabia !== null && (
                  <>
                    <Button
                      colorScheme="red"
                      onClick={() => setOpenContabia(true)}
                      size="xs"
                      mx={2}
                    >
                      <AiOutlineSearch />
                    </Button>
                    <Modal
                      isOpen={openContabia}
                      onClose={() => setOpenContabia(false)}
                    >
                      <ModalOverlay>
                        <ModalContent>
                          <ModalHeader fontSize="lg" fontWeight="bold">
                            Retorno API Contabia
                          </ModalHeader>

                          <ModalBody>
                            <Text>{return_contabia}</Text>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              onClick={() => setOpenContabia(false)}
                              ml={3}
                            >
                              Fechar
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </ModalOverlay>
                    </Modal>
                  </>
                )}
              </Text>
            )}
            {(payment && payment_data.id_contabia !== null) && (
              <Text>
                <Badge colorScheme="green">PAGAMENTO COMUNICADO</Badge>
              </Text>
            )}
            {(payment && payment_data.id_contabia === null) && (

              <Text>

                <Badge colorScheme="red">ERRO NA COMUNICAÇÃO DO PAGAMENTO</Badge>
                {payment_data.return_contabia !== null && (
                  <>
                    <Button
                      colorScheme="red"
                      onClick={() => setOpenContabiaP(true)}
                      size="xs"
                      mx={2}
                    >
                      <AiOutlineSearch />
                    </Button>
                    <Modal
                      isOpen={openContabiaP}
                      onClose={() => setOpenContabiaP(false)}
                    >
                      <ModalOverlay>
                        <ModalContent>
                          <ModalHeader fontSize="lg" fontWeight="bold">
                            Retorno API Contabia
                          </ModalHeader>

                          <ModalBody>
                            <Text>{payment_data.return_contabia}</Text>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              onClick={() => setOpenContabiaP(false)}
                              ml={3}
                            >
                              Fechar
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </ModalOverlay>
                    </Modal>
                  </>
                )}
              </Text>
            )}
          </Flex>
        </Td>
      )}
      <Td px={2}>
        <Center w="100%">
          <Tooltip label="Editar">
            <Button
              size="sm"
              p="0px"
              me="4px"
              colorScheme="blue"
              cursor="pointer"
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Apagar">
            <Button
              size="sm"
              p="0px"
              colorScheme="red"
              cursor="pointer"
              onClick={() => deleteRow(id)}
            >
              <AiFillDelete />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  );
}

export default AccountsReceivablesTablesTableRow;
