import React, { useState } from 'react'
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
// Assets
import signInImage from 'assets/img/signInImage.png'
import Logo from 'assets/img/logo.png'
import { useAuth } from '../../auth-context/auth.context'
import AuthApi from '../../api/auth'

import { useHistory } from 'react-router-dom'

function Register () {
  const history = useHistory()
  // Chakra color mode
  const titleColor = useColorModeValue('primary', 'secondary')
  const textColor = useColorModeValue('gray.400', 'white')

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [cpfCnpj, setCpfCnpj] = useState('')
  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(undefined)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [buttonText, setButtonText] = useState('Solicitar cadastro')

  const login = async event => {
    if (event) {
      event.preventDefault()
    }
    if (cpfCnpj === '') {
      return setError('Você deve inserir o CPF ou CNPJ.')
    }
    if (phone === '') {
      return setError('Você deve inserir seu celular.')
    }
    if (name === '') {
      return setError('Você deve inserir seu nome.')
    }
    if (email === '') {
      return setError('Você deve inserir seu e-mail.')
    }
    setButtonText('Solicitando cadastro')
    try {
      let response = await AuthApi.Register({
        name,
        phone,
        cpfCnpj,
        email
      })
      if (response.data && response.data.error === true) {
        setSuccess(undefined)
        setModalSuccess(false)
        return setError(response.data.msg)
      }
      setButtonText('Solicitar cadastro')
      setModalSuccess(true)
      setError(undefined)
      return setSuccess(response.data.msg)
    } catch (err) {
      setModalSuccess(false)
      setSuccess(undefined)
      setButtonText('Solicitar cadastro')
      if (err.response) {
        return setError(err.response.data.msg)
      }
      return setError('Ocorreu um erro ao solicitar o cadastro.')
    }
  }

  return (
    <Flex position='relative' mb='40px'>
      <AlertDialog isOpen={modalSuccess}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Solicitação enviada
            </AlertDialogHeader>

            <AlertDialogBody>{success}</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                cfontSize='12px'
                type='submit'
                bg='primary'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='20px'
                _hover={{
                  bg: 'secondary'
                }}
                _active={{
                  bg: 'primary'
                }}
                onClick={() => {
                  history.push('/auth/signin')
                }}
              >
                Voltar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: '100px', md: '0px' }}
      >
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: '150px', lg: '80px' }}
          >
            <img src={Logo} width={140} />
            <Heading color={titleColor} fontSize='32px' mt='10px' mb='10px'>
              Solicitar cadastro
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'
            >
              Informe seus dados
            </Text>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                CPF/CNPJ
              </FormLabel>
              <Input
                type='email'
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                id='email'
                placeholder='Digite seu CPF ou CNPJ'
                size='lg'
                defaultValue={cpfCnpj}
                value={cpfCnpj}
                maxLength={15}
                onChange={event => {
                  setCpfCnpj(event.target.value.replace(/[^0-9]/g, ''))
                  setError(undefined)
                }}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Nome completo
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='text'
                placeholder='Endereço de email'
                size='lg'
                defaultValue={name}
                onChange={event => {
                  setName(event.target.value)
                  setError(undefined)
                }}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Celular
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='text'
                placeholder='Endereço de email'
                size='lg'
                defaultValue={phone}
                onChange={event => {
                  setPhone(event.target.value)
                  setError(undefined)
                }}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Email
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='text'
                placeholder='Endereço de email'
                size='lg'
                defaultValue={email}
                onChange={event => {
                  setEmail(event.target.value)
                  setError(undefined)
                }}
              />

              <h4
                style={{
                  fontSize: '.9em',
                  color: 'red',
                  textAlign: 'center',
                  fontWeight: 400,
                  transition: '.2s all'
                }}
              >
                {error}
              </h4>
              <Button
                fontSize='12px'
                type='submit'
                bg='primary'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='20px'
                _hover={{
                  bg: 'secondary'
                }}
                _active={{
                  bg: 'primary'
                }}
                onClick={login}
              >
                {buttonText}
              </Button>
              <Button
                fontSize='12px'
                type='button'
                bg=''
                w='100%'
                h='45'
                mb='20px'
                color='gray.600'
                mt='0px'
                _hover={{
                  color: 'gray.800'
                }}
                _active={{
                  color: 'gray.800'
                }}
                onClick={() => {
                  history.push('/auth/signin')
                }}
              >
                Voltar
              </Button>
            </FormControl>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'
        >
          <Box
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'
          ></Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Register
