import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  useToast
} from "@chakra-ui/react";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import CompaniesApi from "../../../api/company";
import CompaniesServiceApi from "../../../api/companyServices";
import AutocompleteInput from "../../../components/Inputs/AutocompleteInput";

function ServicesForm(props) {
  const { user, serviceId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);
  const [citySelected, setCitySelected] = useState("");
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [mask, setMask] = useState("(99) 99999-9999");
  const toast = useToast();

  const [initialData, setInitialData] = useState({
    id: "",
    id_company: "",
    serv_codi: "",
    serv_nome: "",
    serv_obse: "",
    serv_nbs: "",
    serv_cnae: "",
    serv_valo: "",
    serv_valo_praz: "",
    serv_desc_serv: "",
    serv_perc_prof: "",
    sync_contabia: "",
  });

  useEffect(() => {
    if (serviceId !== false && !loading) {
      loadDataItem();
    }
  }, [serviceId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await CompaniesServiceApi.Show(serviceId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = {};
      if (serviceId) {
        response = await CompaniesServiceApi.Update(values, serviceId);
      } else {
        response = await CompaniesServiceApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";

      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        return {
          id: value.id,
          name: value.empr_nome,
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            {user.type === "A" && (
              <Flex
                h="full"
                gap={2}
                mb={8}
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <FormControl isInvalid={errors.id_company}>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="id_company"
                  >
                    Empresa
                  </FormLabel>
                  {serviceId && props.values.company ? (
                    <Input
                      size="sm"
                      readOnly
                      value={props.values.company.empr_nome}
                    />
                  ) : (
                    <AutocompleteInput
                      loading={loadingCompanies}
                      options={companies}
                      setOptions={setCompanies}
                      requests={getCompanies}
                      placeholder="Pesquisar empresa"
                      onClickFunction={(e) => {
                        props.setFieldValue("id_company", e.id);
                      }}
                    />
                  )}
                  {errors.id_company && (
                    <FormErrorMessage>{errors.id_company[0]}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            )}
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="serv_codi">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_codi}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_codi"
                    >
                      Código
                    </FormLabel>
                    <Input
                      readOnly={serviceId ? true : false}
                      size="sm"
                      {...field}
                      id="serv_codi"
                    />

                    {errors.serv_codi && (
                      <FormErrorMessage>{errors.serv_codi[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="serv_nbs">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_nbs}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_nbs"
                    >
                      NBS
                    </FormLabel>
                    <Input size="sm" {...field} id="serv_nbs" />

                    {errors.serv_nbs && (
                      <FormErrorMessage>{errors.serv_nbs[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="serv_cnae">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_cnae}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_cnae"
                    >
                      CNAE
                    </FormLabel>
                    <Input size="sm" {...field} id="serv_cnae" />

                    {errors.serv_cnae && (
                      <FormErrorMessage>{errors.serv_cnae[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="serv_nome">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_nome}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_nome"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="serv_nome" />

                    {errors.serv_nome && (
                      <FormErrorMessage>{errors.serv_nome[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="serv_valo">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_valo}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_valo"
                    >
                      Valor do Serviço
                    </FormLabel>
                    <Input
                        as={NumericFormat}
                        {...field}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale
                        allowLeadingZeros
                        thousandSeparator="."
                        decimalSeparator=","
                        id="serv_valo"
                        size="sm"
                      />
                    {errors.serv_valo && (
                      <FormErrorMessage>{errors.serv_valo[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="serv_valo_praz">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_valo_praz}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_valo_praz"
                    >
                      Valor a prazo
                    </FormLabel>
                    <Input
                        as={NumericFormat}
                        {...field}
                        prefix={"R$ "}
                        decimalScale={2}
                        fixedDecimalScale
                        allowLeadingZeros
                        thousandSeparator="."
                        decimalSeparator=","
                        id="serv_valo_praz"
                        size="sm"
                      />
                    {errors.serv_valo_praz && (
                      <FormErrorMessage>
                        {errors.serv_valo_praz[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="serv_perc_prof">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_perc_prof}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_perc_prof"
                    >
                      Percentual Profissional
                    </FormLabel>
                    <Input
                        as={NumericFormat}
                        {...field}
                        suffix={" %"}
                        decimalScale={2}
                        fixedDecimalScale
                        allowLeadingZeros
                        thousandSeparator="."
                        decimalSeparator=","
                        id="serv_perc_prof"
                        size="sm"
                      />
                    {errors.serv_perc_prof && (
                      <FormErrorMessage>
                        {errors.serv_perc_prof[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="serv_desc_serv">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_desc_serv}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_desc_serv"
                    >
                      Descrição do Serviço
                    </FormLabel>

                    <Textarea
                      size="sm"
                      {...field}
                      id="serv_desc_serv"
                      defaultValue={
                        user.type !== "A"
                          ? user.company.contabia_name_service
                          : ""
                      }
                    />
                    {errors.serv_desc_serv && (
                      <FormErrorMessage>
                        {errors.serv_desc_serv[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="serv_obse">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.serv_obse}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="serv_obse"
                    >
                      Observações
                    </FormLabel>

                    <Textarea
                      size="sm"
                      {...field}
                      id="serv_obse"
                      defaultValue={
                        user.type !== "A"
                          ? user.company.contabia_name_service
                          : ""
                      }
                    />
                    {errors.serv_obse && (
                      <FormErrorMessage>{errors.serv_obse[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"

                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"

                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ServicesForm;
