import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react';
import ptBR from "date-fns/locale/pt-BR";
import { forwardRef } from 'react';
import DatePicker, { CalendarContainer, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiFillCalendar } from 'react-icons/ai';
import "./inputs.css";
registerLocale("pt-BR", ptBR);

export default function DateRangePicker({ startDate, endDate = null, setDateRange, range = true }) {

  const MyContainer = ({ className, children }) => {
    return (
      <Box width='100%' bg='white' _dark={{bg: 'gray.800'}}>
        <CalendarContainer className={className}>
          <Flex style={{ position: "relative" }}  >{children}</Flex>
        </CalendarContainer>
      </Box>
    );
  };
  const Input = forwardRef(({ value, onClick }, ref) => (
    <Box>
      <Flex gap={2} alignItems='center' overflow='hidden' direction='row' whiteSpace='nowrap' onClick={onClick} ref={ref}>
        <AiFillCalendar color='gray'  />
        <Text fontSize='14px'>
          {value}
        </Text>
      </Flex>
    </Box>
  ));

  return (
    <Box
      borderWidth='1px'
      borderColor='gray.200'
      borderRadius='sm'
      py='1'
      px='2'
      boxShadow='6px 5px 8px rgba(0,50,30,0.02)'
      data-testid='options-list'
      position='absolute'
      backgroundColor='white'
      _dark={{
        backgroundColor: 'gray.800',
        color: 'white',
        borderColor: "gray.700"
      }}

      zIndex='999'
      width='100%'
    >
      <Flex sx={{ _dark: {
            '.react-datepicker__month-container': { color: 'white', bg: 'gray.900', borderColor: 'gray.800'},
            '.react-datepicker__month': { color: 'white', bg: 'gray.900'},
            '.react-datepicker__day': { color: 'white', bg: 'gray.800'},
            '.react-datepicker__header': { color: 'white', bg: 'gray.800', borderColor: 'gray.700'},
            '.react-datepicke': { borderColor: 'gray.700'},
            '.react-datepicker__day--today': { bg: 'gray.600'},
            '.react-datepicker__day--in-selecting-range': { bg: 'blue.600'},
            '.react-datepicker__day--in-range': { bg: 'blue.600'},
            '.react-datepicker__day--selected': { bg: 'blue.600'},
            
          }}}>
        {range ?
          <DatePicker
            locale="pt-BR"
            dateFormat="dd/MM/yyyy"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
            placeholderText="Selecione a data"
            calendarContainer={MyContainer}
            customInput={<Input />}
            popperClassName="datepicker-popup"
          />
          :
          <DatePicker
            locale="pt-BR"
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
            placeholderText="Selecione a data"
            calendarContainer={MyContainer}
            customInput={<Input />}
            popperClassName="datepicker-popup"
          />
        }
      </Flex>
    </Box>
  )
}
