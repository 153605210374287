import axios from './index'

class DashboardsApi {
  static Home = data => {
    return axios.get(`${base}/home`)
  }
}


let base = 'admin/dashboards'

export default DashboardsApi
