export const textStyles = {
  components: {
    Textarea: {
      baseStyle: {
        bg: "transparent",
        border: "1px solid",
        borderColor: "gray.300",
        borderRadius: "xl",
        _dark: {
          borderColor: "gray.700",
          background: "gray.800",
          _hover: {
            bg: "gray.700",
            borderColor: "gray.500",
          },
          _focus: {
            bg: "gray.700",
            borderColor: "gray.500",
          },
        },
        _hover: {
          bg: "gray.50",
          border: "1px solid",
          borderColor: "gray.700",
        },
        _focus: {
          bg: "gray.50",
          border: "1px solid",
          borderColor: "gray.700",
        },
        _autofill: {
          border: "1px solid transparent",
          textFillColor: "#c6c6c6",
          boxShadow: "0 0 0px 1000px #232323 inset",
          transition: "background-color 5000s ease-in-out 0s",
        },
      },
      sizes: {},
      variants: {},
      defaultProps: {
        variant: null,
      },
    },
  },
};
