import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import RecurrentProfileApi from "../../../../api/recurrentProfile";
import TicketRecurrentApi from "../../../../api/ticketRecurrent";
import AutocompleteInput from "../../../../components/Inputs/AutocompleteInput";

function RecurrentProfileForm(props) {
  const { recurrentId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const toast = useToast();

  const [initialData, setInitialData] = useState({
    name: "",
    status: "1",
    items: [],
  });

  useEffect(() => {
    if (recurrentId !== false && !loading) {
      loadRecurrent();
    }
  }, [recurrentId]);

  const loadRecurrent = async () => {
    setLoading(true);
    try {
      if (recurrentId) {
      }
      let response = await RecurrentProfileApi.Show(recurrentId);
      setItems(response.data.result.items);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }

      return setInitialData({
        ...response.data.result,
        password_confirmation: "",
        password: "",
      });
    } catch (err) {
      ////console.log(err)
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.items = items;
      let response = {};
      if (recurrentId) {
        response = await RecurrentProfileApi.Update(values, recurrentId);
      } else {
        response = await RecurrentProfileApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getRecurrents = async (word) => {
    if (word && word.length > 3) {
      setLoadingItems(true);
      let response = await TicketRecurrentApi.search(word);

      setOptions({ data: response.data });
      setLoadingItems(false);
    } else {
      setOptions([]);
    }
  };

  const selectRecurrent = async (item) => {
    let newI = items.filter((o, i) => o.id === item.id);
    if (newI.length === 0) {
      setItems((oldArray) => [...oldArray, { ...item, status: false }]);
    }
  };

  const removeRecurrent = async (index) => {
    setItems(items.filter((o, i) => index !== i));
  };

  const statusRecurrent = async (index, status) => {
    let newI = items.map((o, i) => {
      if (index === i) {
        o.status = status;
      }
      return o;
    });
    setItems(newI);
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              h="full"
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>

              <Field name="status">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.status}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="status"
                    >
                      Status
                    </FormLabel>
                    <Select size="sm" {...field} id="status">
                      <option value="1" key={1}>
                        Ativo
                      </option>
                      <option value="0" key={0}>
                        Inativo
                      </option>
                    </Select>

                    {errors.status && (
                      <FormErrorMessage>{errors.status[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              h="full"
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
            >
              <FormControl isInvalid={errors.status}>
                <FormLabel
                  fontSize="sm"
                  fontWeight="bold"
                  colorScheme="gray"
                  htmlFor="status"
                >
                  Adicionar obrigações
                </FormLabel>
                <AutocompleteInput
                  loading={loadingItems}
                  options={options}
                  setOptions={setOptions}
                  requests={getRecurrents}
                  placeholder="Pesquisar obrigações"
                  onClickFunction={selectRecurrent}
                  empty={true}
                />
              </FormControl>
            </Flex>
            <Flex
              h="full"
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
            >
              <TableContainer size="sm" w="full" fontSize="sm">
                <Table variant="striped" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th px={0}>Obrigação</Th>
                      <Th px={0}>Dia</Th>
                      <Th px={0}>Execução</Th>
                      <Th px={0} w="60px"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.map((row, i) => {
                      return (
                        <Tr key={i}>
                          <Td px={2}>{row.name}</Td>
                          <Td px={2}>{row.recurrent}</Td>
                          <Td px={2}>
                            {row.type == "week"
                              ? "Semanal"
                              : row.type == "month"
                              ? "Mensal"
                              : "Anual"}
                          </Td>
                          <Td px={2}>
                            <Switch
                              size="lg"
                              id="isChecked"
                              onChange={(event) =>
                                statusRecurrent(i, event.target.checked)
                              }
                              isChecked={row.status}
                            />
                            <Button
                              mx={2}
                              size="sm"
                              colorScheme="red"
                              cursor="pointer"
                              type="button"
                              onClick={() => removeRecurrent(i)}
                            >
                              <MdOutlineCancel />
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th px={0}>Obrigação</Th>
                      <Th px={0}>Dia</Th>
                      <Th px={0}>Execução</Th>
                      <Th px={0}></Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </Flex>
            <Flex
              align="end"
              justify="end"
              gap={2}
              mt="10px"
              flexDirection={{ sm: "column", lg: "row" }}
            >
              <Button
                leftIcon={<MdOutlineCancel />}
                size="sm"
                colorScheme="gray"
                cursor="pointer"
                type="button"
                w={{ sm: "100%", lg: "auto" }}
                onClick={() => {
                  setForm(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                leftIcon={<BiSave />}
                size="sm"
                colorScheme="blue"
                
                cursor="pointer"
                type="submit"
                
                w={{ sm: "100%", lg: "auto" }}
              >
                Salvar
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default RecurrentProfileForm;
