import axios from './index'

class ApiConfigApi {
  static List = data => {
    let search = new URLSearchParams(data);
    return axios.get(`${base}?${search}`)
  }

  static Show = id => {
    return axios.get(`${base}/${id}`, )
  }

  static Update = (data, id) => {
    return axios.put(`${base}/${id}`, data)
  }

}

let base = 'admin/config/api'

export default ApiConfigApi
