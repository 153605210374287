// Chakra imports
import {
  Avatar,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Badge
} from "@chakra-ui/react";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { useAuth } from '../../../../auth-context/auth.context';

const Departments = () => {
  const { user, setUser } = useAuth();

  return (
    <Card p='16px'>
      <CardHeader>
        <Text fontSize='lg' fontWeight='bold'>
          Departamentos
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        {(user.type === "A" && user.departments !== null) &&
          user.departments.map((row, i) => {
            return (
              <Badge size='lg' variant='outline' colorScheme='blue' px={4} py={2} m={2}>
                {row}
              </Badge>
            );
          })
        }
      </CardBody>
    </Card>
  );
};

export default Departments;
