import React, { useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Tooltip,
  Center,
  useColorModeValue
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import { AiFillEdit, AiFillDelete, AiOutlineCalendar } from 'react-icons/ai'
import { BsChatLeftTextFill } from 'react-icons/bs'
import { MdSupportAgent } from 'react-icons/md'
import { IoTimer, IoCreateOutline } from 'react-icons/io5'
import { useAuth } from "auth-context/auth.context";

function TicketsTablesTableRow (props) {
  const { user, setUser } = useAuth();
  const history = useHistory()
  const {
    id,
    cod_ticket,
    company,
    month,
    year,
    sla,
    priority,
    type,
    subtopic,
    status,
    topic,
    department,
    subject,
    content,
    date_last_message,
    user_create,
    solver,
    external_ticket,
    date_validity,
    date_previous_validity,
    date_closed,
    created_at,
    attachments,
    defeated,
    today,
    next_day,
    selectRow,
    deleteRow
  } = props
  const [bgColor, setBgColor] = useState('transparent')
  const [bgDateColor, setDateBgColor] = useState('transparent')
  const textColor = useColorModeValue('gray.700', 'white')

  useEffect(() => {
    if (today) {
      setBgColor('cyan.50')
      setDateBgColor('cyan.500')
    } else if (next_day) {
      setBgColor('orange.50')
      setDateBgColor('orange.500')
    } else if (defeated) {
      setBgColor('red.50')
      setDateBgColor('red.500')
    }
  }, [props])

  const msg = () => {
    history.push('/admin/ticket/' + id)
  }

  return (
    <>
      <Tr key={id} bgColor={bgColor} _dark={{bg: 'transparent'}} p='5px'>
        <Td px={2} verticalAlign='top' pl='10px'>
          <Text fontSize='xl' fontWeight='bold' color={textColor} _dark={{color: 'white'}}>
            {cod_ticket}
          </Text>
          {/* <Text fontSize='10' color='gray.500' my='10px'>
            Departamento:
            <Badge
              bg={department.color}
              color='white'
              fontSize='12px'
              ml='10px'
              px='10px'
              borderRadius='5px'
            >
              {department.name}
            </Badge>
          </Text>
          <Text fontSize='10' color='gray.500' my='10px'>
            Prioridade:
            <Badge
              bg={priority.color}
              color='white'
              fontSize='12px'
              ml='10px'
              px='10px'
              borderRadius='5px'
            >
              {priority.name}
            </Badge>
          </Text>
          <Text fontSize='10' color='gray.500' my='10px'>
            SLA:
            <Badge
              bg={sla.color}
              color='white'
              fontSize='12px'
              ml='10px'
              px='10px'
              borderRadius='5px'
            >
              {sla.name}
            </Badge>
          </Text> */}
          <Text fontSize='10' color='gray.500' my='10px'>
            <Badge
              bg={status.color}
              color='white'
              fontSize='12px'
              px='10px'
              borderRadius='5px'
            >
              {status.name}
            </Badge>
            <Badge
              bg={topic.color}
              color='white'
              fontSize='12px'
              m='10px'
              px='10px'
              borderRadius='5px'
            >
              {topic.name}
            </Badge>
          </Text>
          <Text color='gray.500' my='10px'>
            {subject}
          </Text>
          {/* <Text fontSize='10' color='gray.500' my='10px'>
            Subtópico:
            <Badge
              bg={subtopic.color}
              color='white'
              fontSize='12px'
              ml='10px'
              px='10px'
              borderRadius='5px'
            >
              {subtopic.name}
            </Badge>
          </Text> */}
        </Td>
        {user.type === 'A' && (
          <Td px={2} verticalAlign='top'>
            {/* <Flex
            flexDirection='row'
            alignItems='center'
            bgColor='gray.200'
            px='4'
          >
            <BsChatLeftTextFill size={20} />
            <Text
              fontSize='sm'
              colorScheme='gray' _dark={{color: 'white'}}
              fontWeight='normal'
              ml={2}
              borderRadius={5}
              px={4}
              py={2}
            >
              {subject}
            </Text>
          </Flex> */}
            <Flex
              flexDirection='column'
              alignItems='left'
              // bgColor='gray.50'
              // p='4'
            >
              <Text fontSize='sm' colorScheme='gray' _dark={{color: 'white'}} fontWeight='bold'>
                {company.empr_fant}
              </Text>
              <Text fontSize='sm' colorScheme='gray' _dark={{color: 'white'}} fontWeight='normal'>
                {company.empr_cnpj}
              </Text>
              {/* <Text fontSize='sm' colorScheme='gray' _dark={{color: 'white'}} fontWeight='normal'>
              {company.empr_emai_empr}
            </Text>
            <Text fontSize='sm' colorScheme='gray' _dark={{color: 'white'}} fontWeight='normal'>
              {company.empr_cida} {company.empr_esta}
            </Text>
            <Text fontSize='sm' colorScheme='gray' _dark={{color: 'white'}} fontWeight='normal'>
              {company.empr_fone_empr}
            </Text> */}
            </Flex>
          </Td>
        )}
        <Td px={2} verticalAlign='top'>
          <Flex
            flexDirection='row'
            alignItems='center'
            my={2}
            bgColor='yellow.50'
            _dark={{bg: 'gray.800'}}
            borderRadius='full'
            px='2'
            whiteSpace='nowrap'
          >
            <Flex
              flexDirection='row'
              alignItems='center'
              my={2}
              background='white'
              _dark={{bg: 'gray.800'}}
              borderRadius='full'
              px='2'
              mr='4'
              color='yellow.600'
            >
              <AiOutlineCalendar size={22} />
            </Flex>
            <Text
              fontSize='sm'
              colorScheme='gray'
              _dark={{color: 'gray.200'}}
              fontWeight='normal'
              borderRadius={5}
              py={2}
            >
              {created_at}
            </Text>
          </Flex>
          {user.type === 'A' && (
            <Flex
              flexDirection='row'
              alignItems='center'
              my={2}
              bgColor={bgDateColor}
              borderRadius='full'
              px='2'
              whiteSpace='nowrap'
            >
              <Flex
                flexDirection='row'
                alignItems='center'
                my={2}
                borderRadius='full'
                px='2'
                mr='4'
                color={bgDateColor != 'transparent' ? 'white' : 'red.600'}
              >
                <IoTimer size={22} />
              </Flex>
              <Text
                fontSize='sm'
                color={bgDateColor != 'transparent' ? 'white' : 'gray.600'}
                fontWeight='normal'
                borderRadius={5}
                py={2}
              >
                {date_validity}
              </Text>
            </Flex>
          )}
        </Td>
        {user.type === 'A' && (
          <Td px={2} verticalAlign='top'>
            {user_create && (
              <Flex
                flexDirection='row'
                alignItems='center'
                my={2}
                background='gray.50'
                _dark={{bg: 'gray.800'}}
                borderRadius='full'
                px='2'
              >
                <Flex
                  flexDirection='row'
                  alignItems='center'
                  my={2}
                  background='gray.200'
                  _dark={{bg: 'gray.800'}}
                  borderRadius='full'
                  p='2'
                  mr='4'
                >
                  <IoCreateOutline size={22} />
                </Flex>
                <Avatar
                  name={user_create.name}
                  src={user_create.avatar}
                  size='sm'
                  me='12px'
                />
                {user_create.name}
              </Flex>
            )}
            {solver && (
              <Flex
                flexDirection='row'
                alignItems='center'
                my={2}
                background='green.50'
                borderRadius='full'
                px='2'
              >
                <Flex
                  flexDirection='row'
                  alignItems='center'
                  my={2}
                  background='green.200'
                  borderRadius='full'
                  p='2'
                  mr='4'
                >
                  <MdSupportAgent size={22} />
                </Flex>
                <Avatar
                  name={solver.name}
                  src={solver.avatar}
                  size='sm'
                  me='12px'
                />
                {solver.name}
              </Flex>
            )}
          </Td>
        )}
        <Td px={2}>
          <Center w='100%'>
            {user.permissions.includes('tickets-message-list') && (
              <Tooltip label='Ver mensagem'>
                <Button
                  onClick={() => history.push('/admin/ticket/' + id)}
                  size='sm'
                  p='0px'
                  me='4px'
                  bg='green.400'
                  color='white'
                  cursor='pointer'
                >
                  <BsChatLeftTextFill />
                </Button>
              </Tooltip>
            )}
            {status.bloc_edit == '0' && (
              <>
                {user.permissions.includes('tickets-edit') && (
                  <Tooltip label='Editar'>
                    <Button
                      size='sm'
                      p='0px'
                      me='4px'
                      bg='blue.400'
                      color='white'
                      cursor='pointer'
                      onClick={() => selectRow(id, cod_ticket)}
                    >
                      <AiFillEdit />
                    </Button>
                  </Tooltip>
                )}
                {user.type == 'A' &&
                  user.permissions.includes('tickets-delete') && (
                    <Tooltip label='Apagar'>
                      <Button
                        size='sm'
                        p='0px'
                        bg='red.400'
                        color='white'
                        cursor='pointer'
                        onClick={() => deleteRow(id)}
                      >
                        <AiFillDelete />
                      </Button>
                    </Tooltip>
                  )}
              </>
            )}
          </Center>
        </Td>
      </Tr>
    </>
  )
}

export default TicketsTablesTableRow
