/*eslint-disable*/
import React,{useState} from "react";
import { Badge, Box, Button, Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import AllApi from "../../api/all";
import { useAuth } from "auth-context/auth.context";

export default function Footer(props) {
  const { user, setUser } = useAuth();
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  const location = useLocation();
  const [showWhats, setShowWhats] = useState(false);
  useEffect(() => {
    if(user.showWhats){
      if(location.pathname.indexOf('/admin/ticket/') !== -1){
        setShowWhats(false);
      }else{
        setShowWhats(true);
      }
    }
    
  }, [location]);

  const openWhats = async () => {
    let whats = await AllApi.whatsapp();
    let url = `https://wa.me/55${whats.data.phone}?text=${whats.data.text_message}`
    window.open(url, '_blank');
  }

  return (
    <>
      {(showWhats && user.type != 'A') && (
      <Box pos="fixed" bottom="0" right="0">
        <Button colorScheme="green" borderRadius="full" px={3} py={8} m={4} onClick={openWhats}>
          <AiOutlineWhatsApp size={40} />
        </Button>
      </Box>
      )}
      <Flex
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        alignItems={{
          base: "center",
          xl: "start",
        }}
        justifyContent="space-between"
        px="30px"
        pb="20px"
      >
        <Text
          color="gray.400"
          textAlign={{
            base: "center",
            xl: "start",
          }}
          mb={{ base: "20px", xl: "0px" }}
        ></Text>
      </Flex>
    </>
  );
}
