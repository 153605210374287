import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import Logo from "assets/img/logo.png";

import { useAuth } from "../../auth-context/auth.context";
import AuthApi from "../../api/auth";

import { useHistory } from "react-router-dom";
import AllApi from "api/all";

function SignIn() {
  const { colorMode, toggleColorMode } = useColorMode();
  const titleColor = useColorModeValue("primary", "secondary");
  const textColor = useColorModeValue("gray.400", "white");
  const history = useHistory();
  const { setUser, encryptData } = useAuth();
  const { user } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [error, setError] = useState(undefined);
  const [company, setCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Acessar");

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if (user && user.token) {
      //console.log('/dashboard')
      return history.push("/admin/home");
    }
    if (email === "") {
      return setError("Você deve inserir seu e-mail");
    }
    if (password === "") {
      return setError("Você deve digitar sua senha");
    }
    setButtonText("Acessando");
    setLoading(true);
    try {
      let response = false;
      if (cpfCnpj !== "") {
        response = await AuthApi.Login({
          email,
          password,
          cpfCnpj,
        });
      } else {
        response = await AuthApi.Login({
          email,
          password,
        });
      }
      if (response.data.status && response.data.status !== 200) {
        setButtonText("Acessar");
        setLoading(false);
        return setError(response.data.message);
      } else {
        if (response.data.company) {
          setCompany(response.data.company);
        }
        setLoading(false);
        if (response.data && response.data.success === false) {
          setButtonText("Acessar");
          return setError(response.data.message);
        }
        return setProfile(response);
      }
      
    } catch (err) {
      setLoading(false);
      setButtonText("Acessar");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("Ocorreu um erro ao acessar.");
    }
  };

  const setProfile = async (response) => {
    let user = { ...response.data.user };
    user.token = response.data.access_token;

    if (user.type === "A") {
      user.showWhats = false;
    } else {
      let whats = await AllApi.whatsapp();
      console.log(whats.data.status);
      if (whats.data.status == "A") {
        user.showWhats = true;
      } else {
        user.showWhats = false;
      }
    }

    let userJson = JSON.stringify(user);
    setUser(user);
    if (user.theme !== "light") {
      toggleColorMode();
    }
    localStorage.setItem("token", user.token);
    encryptData(user);

    return history.push("/admin/home");
  };

  return (
    <Flex position="relative" mb="40px" _dark={{ bg: "gray.800" }}>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          {user && user.token ? (
            <div>
              <img src={Logo} width={140} />
              <Heading color={titleColor} fontSize="32px" mt="10px" mb="10px">
                Bem vindo de volta
              </Heading>
              <h3 style={{ textAlign: "center" }}>Você já está logado.</h3>
              <Button
                fontSize="15px"
                type="submit"
                bg="gray"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                _hover={{
                  bg: "secondary",
                }}
                _active={{
                  bg: "primary",
                }}
                onClick={login}
              >
                {`Ir para o painel`}
              </Button>
            </div>
          ) : (
            <Flex
              direction="column"
              w="100%"
              background="transparent"
              p="48px"
              mt={{ md: "150px", lg: "80px" }}
            >
              <img src={Logo} width={140} />
              <Heading fontSize="32px" mt="10px" mb="10px">
                Painel{" "}
                <Text as={"span"} color={"primary"}>
                  Administrativo
                </Text>
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                color={textColor}
                fontWeight="bold"
                fontSize="14px"
              >
                Utilize seu email e senha para acessar!
              </Text>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  CPF/CNPJ
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type="text"
                  id="email"
                  placeholder="Digite seu CPF ou CNPJ"
                  size="lg"
                  bg="white"
                  defaultValue={cpfCnpj}
                  value={cpfCnpj}
                  maxLength={15}
                  onChange={(event) => {
                    setCpfCnpj(event.target.value.replace(/[^0-9]/g, ""));
                    setError(undefined);
                  }}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type="text"
                  id="email"
                  placeholder="Endereço de email"
                  size="lg"
                  bg="white"
                  defaultValue={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setError(undefined);
                  }}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Senha
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="36px"
                  fontSize="sm"
                  type="password"
                  id="senha"
                  placeholder="Senha"
                  size="lg"
                  bg="white"
                  defaultValue={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setError(undefined);
                  }}
                />
                {/* <FormControl display='flex' alignItems='center'>
                  <Switch id='remember-login' colorScheme='orange' me='10px' />
                  <FormLabel
                    htmlFor='remember-login'
                    mb='1'
                    ms='1'
                    fontWeight='normal'
                  >
                    Manter logado
                  </FormLabel> */}
              </FormControl>
              <h4
                style={{
                  fontSize: ".9em",
                  color: "red",
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {error}
              </h4>
              <Button
                fontSize="12px"
                type="submit"
                bg="primary"
                w="100%"
                h="45"
                mb="5px"
                color="white"
                mt="20px"
                _hover={{
                  bg: "primary",
                }}
                _active={{
                  bg: "primary",
                }}
                onClick={login}
                isLoading={loading}
              >
                {buttonText}
              </Button>
              <Button
                fontSize="12px"
                type="button"
                bg=""
                w="100%"
                h="45"
                mb="20px"
                color="gray.600"
                mt="0px"
                _hover={{
                  color: "gray.800",
                }}
                _active={{
                  color: "gray.800",
                }}
                onClick={() => {
                  history.push("/auth/forget-password");
                }}
              >
                Esqueci a senha
              </Button>
              <Button
                fontSize="12px"
                type="submit"
                bg="secondary"
                w="100%"
                h="45"
                mb="5px"
                color="white"
                mt="20px"
                _hover={{
                  bg: "secondary",
                }}
                _active={{
                  bg: "secondary",
                }}
                onClick={() => {
                  history.push("/auth/register");
                }}
              >
                Solicitar cadastro
              </Button>
            </Flex>
          )}
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
