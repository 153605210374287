import React from "react";
import ReactDOM from "react-dom";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";

import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/Admin";

import { ProtectedRoute } from "./layouts/ProtectedRoute";
import { AuthProvider } from "auth-context/auth.context";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});
const token = localStorage.getItem("token");
console.log("token", token);
ReactDOM.render(
  <AuthProvider userData={token}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <ProtectedRoute path={`/admin`} component={AdminLayout} />
          <Redirect from={`/`} to="/auth" />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  </AuthProvider>,
  document.getElementById("root")
);
