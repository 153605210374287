import React, { useState } from 'react'
import { Button, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'

import { Formik, Field, Form } from 'formik'

import { AiOutlineSearch } from 'react-icons/ai'
import { BiEraser } from 'react-icons/bi'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import moment from 'moment';

function PaymentLinkSearch(props) {
  const { user, loadData, searchParams, loading } = props
  const [initialValue, setInitialValue] = useState({
    client_name: searchParams['filter[client.name]'] ? searchParams['filter[client.name]'] : '',
    empr_nome: searchParams['filter[companies.empr_nome]']
    ? searchParams['filter[companies.empr_nome]']
    : ''
  })
  const [dateRangeEmis, setDateRangeEmis] = useState([null, null]);
  const [dateRangeVenc, setDateRangeVenc] = useState([null, null]);
  const [startDateEmis, endDateEmis] = dateRangeEmis;
  const [startDateVenc, endDateVenc] = dateRangeVenc;

  const search = params => {
    if (dateRangeEmis[0] !== null && dateRangeEmis[1] !== null) {
      params[`filter[created_at]`] = moment(dateRangeEmis[0]).format('YYYY-MM-DD') + '.' + moment(dateRangeEmis[1]).format('YYYY-MM-DD')
    }
    if (dateRangeVenc[0] !== null && dateRangeVenc[1] !== null) {
      params[`filter[expiration]`] = moment(dateRangeVenc[0]).format('YYYY-MM-DD') + '.' + moment(dateRangeVenc[1]).format('YYYY-MM-DD')
    }
    params.page = 1
    loadData(params)
  }

  const clear = () => {
    loadData({page: 1})
  }

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        let params = {}
        console.log(values);
        for (var key in values) {
          if (key == 'empr_nome') {
            params[`filter[company.empr_nome]`] = values['empr_nome']
          } else if (key == 'client_name') {
            params[`filter[client.name]`] = values['client_name']
          }else if (key != 'name') {
            params[`filter[${key}]`] = values[key]
          }
        }
        search(params)
      }}
    >
      {props => (
        <Form>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            {user.type === 'A' && (
              <Field name='empr_nome'>
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='empr_nome'
                    >
                      Empresa
                    </FormLabel>
                    <Input
                       size='sm'
                      {...field}
                      id='empr_nome'
                      placeholder='Empresa'
                    />
                  </FormControl>
                )}
              </Field>
            )}
            
            <Field name='client_name'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='client_name'
                  >
                    Cliente
                  </FormLabel>
                  <Input  size='sm' {...field} id='client_name' placeholder='Cliente' />
                </FormControl>
              )}
            </Field>
            <Field name='created_at'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='created_at'
                  >
                    Data emissão
                  </FormLabel>
                  <DateRangePicker
                    startDate={startDateEmis}
                    endDate={endDateEmis}
                    setDateRange={(update) => {
                      setDateRangeEmis(update);
                    }}
                  />
                </FormControl>
              )}
            </Field>
            <Field name='expiration'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='expiration'
                  >
                    Data expiração
                  </FormLabel>
                  <DateRangePicker
                    startDate={startDateVenc}
                    endDate={endDateVenc}
                    setDateRange={(update) => {
                      setDateRangeVenc(update);
                    }}
                  />
                </FormControl>
              )}
            </Field>
            <Flex align='end' justify='center'>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='green'
                cursor='pointer'
                
                type='submit'
              >
                <AiOutlineSearch />
              </Button>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='blue'
                cursor='pointer'
                type='button'
                
                onClick={() => {
                  clear()
                }}
              >
                <BiEraser />
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default PaymentLinkSearch
