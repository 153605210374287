import { useEffect } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Portal,
  Progress,
  SimpleGrid,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorMode,
  useColorModeValue,
  Heading,
  VStack,
  HStack,
  Tag,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import Logo from "../../assets/img/logo.png";
import LogoWhite from "../../assets/img/logo-white.png";
import PeopleImage from "../../assets/img/people-image.png";
import CardNews1 from "components/CardNews/CardNews1";
import ReactHtmlParser from "react-html-parser";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons";
import React, { useState } from "react";
// react icons
import {
  BsArrowLeft,
  BsArrowRight,
  BsCurrencyDollar,
  BsFillTicketDetailedFill,
} from "react-icons/bs";
import TicketRecurrentApi from "../../api/ticketRecurrent";
import CompanyApi from "../../api/company";
import ContentApi from "../../api/content";
import { BiUser } from "react-icons/bi";
import { FaBusinessTime } from "react-icons/fa";
import { IoBusiness } from "react-icons/io5";

export default function HomeClient() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Chakra Color Mode
  const { colorMode, toggleColorMode } = useColorMode();
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState([]);
  const [loadingRecurrents, setLoadingRecurrents] = useState(true);
  const [recurrents, setRecurrents] = useState([]);
  const [loadingContents, setLoadingContents] = useState(true);
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState(false);
  const [loadingCounters, setLoadingCounters] = useState(true);
  const [contentSelected, setContentSelected] = useState(0);
  const [counters, setCounters] = useState({
    clients: 0,
    suppliers: 0,
    nfs: 0,
    tickets: 0,
    payables: "R$ 0,00",
    total_payables: "R$ 0,00",
    receivables: "R$ 0,00",
    total_receivables: "R$ 0,00",
  });
  const [week, setWeek] = useState([
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
    "Domingo",
  ]);

  useEffect(() => {
    getContents();
    getRecurrents();
    getCounters();
  }, []);

  const selContent = async (i) => {
    console.log(i)
    setLoadingContents(true);
    if (i <= 0) {
      i = 0;
    } else if (i >= contents.length) {
      i = contents.length - 1;
    }
    console.log("qt",contents.length);
    console.log("i",i);
    setContent(contents[i]);
    setContentSelected(i);
    setLoadingContents(false);
  };

  const getContents = async () => {
    setContent(false);
    setLoadingContents(true);
    let response = await ContentApi.News(10);
    setContents(response.data.data);
    if (response.data.data.length > 0) {
      setContent(response.data.data[0]);
    } else {
      setContent(false);
    }
    setLoadingContents(false);
  };
  const getRecurrents = async (word) => {
    setLoadingRecurrents(true);
    let response = await TicketRecurrentApi.my();

    setRecurrents(response.data);
    setLoadingRecurrents(false);
  };
  const getCounters = async (word) => {
    setLoadingCounters(true);
    let response = await CompanyApi.counters();

    setCounters(response.data);
    setLoadingCounters(false);
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Contas à pagar hoje
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {loadingCounters ? (
                      <Flex
                        w="100%"
                        h="100%"
                        align="center"
                        justifyContent="center"
                        fontWeight="light"
                        fontSize="sm"
                        gap={2}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.50"
                          color="gray.300"
                          size="md"
                        />{" "}
                        Carregando
                      </Flex>
                    ) : (
                      <>{counters.payables}</>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg="primary">
                <WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Total de contas à pagar
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {loadingCounters ? (
                      <Flex
                        w="100%"
                        h="100%"
                        align="center"
                        justifyContent="center"
                        fontWeight="light"
                        fontSize="sm"
                        gap={2}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.50"
                          color="gray.300"
                          size="md"
                        />{" "}
                        Carregando
                      </Flex>
                    ) : (
                      <>{counters.total_payables}</>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg="primary">
                <BsCurrencyDollar h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat>
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Notas emitidas
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {loadingCounters ? (
                      <Flex
                        w="100%"
                        h="100%"
                        align="center"
                        justifyContent="center"
                        fontWeight="light"
                        fontSize="sm"
                        gap={2}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.50"
                          color="gray.300"
                          size="md"
                        />{" "}
                        Carregando
                      </Flex>
                    ) : (
                      <>{counters.nfs}</>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <Spacer />
              <IconBox as="box" h={"45px"} w={"45px"} bg="primary">
                <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Protocolos em aberto
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {loadingCounters ? (
                      <Flex
                        w="100%"
                        h="100%"
                        align="center"
                        justifyContent="center"
                        fontWeight="light"
                        fontSize="sm"
                        gap={2}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.50"
                          color="gray.300"
                          size="md"
                        />{" "}
                        Carregando
                      </Flex>
                    ) : (
                      <>{counters.tickets}</>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg="primary">
                <BsFillTicketDetailedFill
                  h={"24px"}
                  w={"24px"}
                  color={iconBoxInside}
                />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>

      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px" my={5}>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Contas à receber hoje
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {loadingCounters ? (
                      <Flex
                        w="100%"
                        h="100%"
                        align="center"
                        justifyContent="center"
                        fontWeight="light"
                        fontSize="sm"
                        gap={2}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.50"
                          color="gray.300"
                          size="md"
                        />{" "}
                        Carregando
                      </Flex>
                    ) : (
                      <>{counters.receivables}</>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg="primary">
                <WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Total de contas à receber
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {loadingCounters ? (
                      <Flex
                        w="100%"
                        h="100%"
                        align="center"
                        justifyContent="center"
                        fontWeight="light"
                        fontSize="sm"
                        gap={2}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.50"
                          color="gray.300"
                          size="md"
                        />{" "}
                        Carregando
                      </Flex>
                    ) : (
                      <>{counters.total_receivables}</>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg="primary">
                <BsCurrencyDollar h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat>
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Clientes
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>
                    {loadingCounters ? (
                      <Flex
                        w="100%"
                        h="100%"
                        align="center"
                        justifyContent="center"
                        fontWeight="light"
                        fontSize="sm"
                        gap={2}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.50"
                          color="gray.300"
                          size="md"
                        />{" "}
                        Carregando
                      </Flex>
                    ) : (
                      <>{counters.clients}</>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <Spacer />
              <IconBox as="box" h={"45px"} w={"45px"} bg="primary">
                <BiUser h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb=".1rem"
                >
                  Fornecedores
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {loadingCounters ? (
                      <Flex
                        w="100%"
                        h="100%"
                        align="center"
                        justifyContent="center"
                        fontWeight="light"
                        fontSize="sm"
                        gap={2}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.50"
                          color="gray.300"
                          size="md"
                        />{" "}
                        Carregando
                      </Flex>
                    ) : (
                      <>{counters.suppliers}</>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg="primary">
                <IoBusiness h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>

      <Grid
        templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Card height="400px" p="1.2rem">
          <CardBody w="100%" height="400px">
            {!content ? (
              <Flex
                w="100%"
                h="100%"
                align="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.50"
                  color="gray.300"
                  size="xl"
                  hidden={!loadingContents}
                />
                <Text hidden={!loadingContents}>Carregando conteúdos</Text>
                <Text hidden={loadingContents}>Nenhum conteúdo encontrado</Text>
              </Flex>
            ) : (
              <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%">
                <Flex
                  flexDirection="column"
                  h="100%"
                  lineHeight="1.6"
                  width={{ lg: "45%" }}
                >
                  <Text fontSize="sm" color="gray.400" fontWeight="bold">
                    {content.category}
                  </Text>
                  <Text
                    fontSize="lg"
                    color={textColor}
                    fontWeight="bold"
                    pb=".5rem"
                  >
                    {content.title}
                  </Text>
                  <Text fontSize="sm" color="gray.400" fontWeight="normal">
                    {content.short_content}
                  </Text>
                  <Spacer />
                  <Flex align="center">
                    <Button
                      p="0px"
                      bg="transparent"
                      my={{ sm: "1.5rem", lg: "0px" }}
                      onClick={() => selContent(contentSelected - 1)}
                    >
                      <Icon
                        as={BsArrowLeft}
                        w="20px"
                        h="20px"
                        fontSize="2xl"
                        transition="all .5s ease"
                        mx=".3rem"
                        cursor="pointer"
                        pt="4px"
                        _hover={{ transform: "translateX(20%)" }}
                      />
                    </Button>
                    <Button
                      p="0px"
                      bg="transparent"
                      my={{ sm: "1.5rem", lg: "0px" }}
                      onClick={() => selContent(contentSelected + 1)}
                    >
                      <Icon
                        as={BsArrowRight}
                        w="20px"
                        h="20px"
                        fontSize="2xl"
                        transition="all .5s ease"
                        mx=".3rem"
                        cursor="pointer"
                        pt="4px"
                        _hover={{ transform: "translateX(20%)" }}
                      />
                    </Button>
                    <Button
                      p="0px"
                      bg="transparent"
                      my={{ sm: "1.5rem", lg: "0px" }}
                      onClick={onOpen}
                    >
                      <Text
                        fontSize="sm"
                        color={textColor}
                        fontWeight="bold"
                        cursor="pointer"
                        transition="all .5s ease"
                        my={{ sm: "1.5rem", lg: "0px" }}
                        px={2}
                        _hover={{ me: "4px" }}
                      >
                        Leia mais
                      </Text>
                    </Button>

                    <Modal isOpen={isOpen} onClose={onClose} size="full">
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>{content.title}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          {ReactHtmlParser(content.content)}
                        </ModalBody>
                        <ModalFooter>
                          <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Fechar
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </Flex>
                </Flex>

                <Spacer />
                <Flex
                  bg="gray.100"
                  align="center"
                  justify="center"
                  borderRadius="15px"
                  width={{ lg: "40%" }}
                  minHeight={{ sm: "250px" }}
                  _dark={{
                    bg: "gray.800",
                  }}
                >
                  {content.image_url != "" && (
                    <Image
                      src={content.image_url}
                      alt={content.title}
                      minWidth={{ md: "300px", lg: "auto" }}
                    />
                  )}
                </Flex>
              </Flex>
            )}
          </CardBody>
        </Card>
        <Card height="400px" p="1rem">
          <CardHeader>
            <Heading size="md" colorScheme="gray">
              Painel de obrigações
            </Heading>
          </CardHeader>
          <CardBody p="0px" h={{ sm: "200px", lg: "100%" }} overflowY="scroll">
            <Flex flexDirection="column" width="100%" p={0} height="340px">
              {loadingRecurrents ? (
                <Flex
                  w="100%"
                  h="100%"
                  align="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.50"
                    color="gray.300"
                    size="xl"
                  />
                  <Text>Carregando suas obrigações</Text>
                </Flex>
              ) : (
                recurrents.map((row, i) => {
                  let data = "";
                  if (row.type === "month") {
                    data = `Dia ${row.day} do mês`;
                  } else if (row.type === "week") {
                    if (row.day < 6) {
                      data = "Todas às ";
                    } else {
                      data = "Todos os ";
                    }
                    data += week[row.day];
                  } else if (row.type === "year") {
                    data = `Dia ${row.day} do mês ${row.month}`;
                  }
                  return (
                    <CardNews1
                      title={row.name}
                      description={row.content}
                      date={data}
                    />
                  );
                })
              )}
            </Flex>
          </CardBody>
        </Card>
      </Grid>
    </Flex>
  );
}
