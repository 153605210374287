import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TicketDefaultApi from "../../../../api/ticketDefault";
import RecurrentApi from "../../../../api/ticketRecurrent";
import EditorToolbar, {
  formats,
  modules
} from "../../../../components/Inputs/EditorToolbar";

function RecurrentForm(props) {
  const { recurrentId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [defaults, setDefaults] = useState([]);
  const toast = useToast();
  const months = [
    { id: 1, month: "JAN" },
    { id: 2, month: "FEV" },
    { id: 3, month: "MAR" },
    { id: 4, month: "ABR" },
    { id: 5, month: "MAI" },
    { id: 6, month: "JUN" },
    { id: 7, month: "JUL" },
    { id: 8, month: "AGO" },
    { id: 9, month: "SET" },
    { id: 10, month: "OUT" },
    { id: 11, month: "NOV" },
    { id: 12, month: "DEZ" },
  ];
  const [initialData, setInitialData] = useState({
    name: "",
    fixed_date: "",
    day: "",
    month: "",
    recurrent: "1",
    type: "week",
    id_tickets_config_default: "",
    repeat: "",
    end_date: "",
    receiver: "0",
  });

  useEffect(() => {
    loadDefaults();
  }, []);

  useEffect(() => {
    if (recurrentId !== false && !loading) {
      loadRecurrent();
    }
  }, [recurrentId]);

  const loadRecurrent = async () => {
    setLoading(true);
    try {
      if (recurrentId) {
      }
      let response = await RecurrentApi.Show(recurrentId);
      setContent(response.data.result.content);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }

      return setInitialData({
        ...response.data.result,
        password_confirmation: "",
        password: "",
      });
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.content = content;
      let response = {};
      if (recurrentId) {
        response = await RecurrentApi.Update(values, recurrentId);
      } else {
        response = await RecurrentApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadDefaults = async () => {
    try {
      let defaults = await TicketDefaultApi.All();
      return setDefaults(defaults.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const onChangeContents = (contents) => {
    let _contents = null;
    if (contents.indexOf("<p><br></p>") > -1) {
      _contents = contents.replace(/<p><br><\/p>/gi, "<p>&nbsp;</p>");
    }
    setContent(_contents || contents);
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              h="full"
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_tickets_config_default">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_tickets_config_default}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_tickets_config_default"
                    >
                      Padrão de abertura
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_tickets_config_default"
                      placeholder={recurrentId ? "" : "Selecione"}
                    >
                      {defaults.map((val, i) => (
                        <option
                          key={`tickets_config_default${val.id}`}
                          value={`${val.id}`}
                        >
                          {val.name}
                        </option>
                      ))}
                    </Select>

                    {errors.id_tickets_config_default && (
                      <FormErrorMessage>
                        {errors.id_tickets_config_default[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              h="full"
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="recurrent">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.recurrent}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="recurrent"
                    >
                      Recorrente
                    </FormLabel>
                    <Select size="sm" {...field}>
                      <option key="sim" value="1">
                        SIM
                      </option>
                      <option key="nao" value="0">
                        NÃO
                      </option>
                    </Select>

                    {errors.recurrent && (
                      <FormErrorMessage>{errors.recurrent[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>

              {props.values.recurrent == "0" && (
                <Field name="fixed_date">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.fixed_date}>
                      <FormLabel
                        _dark={{
                          color: "gray.500",
                        }}
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="fixed_date"
                      >
                        Data fixa
                      </FormLabel>
                      <Input type="date" size="sm" {...field} id="fixed_date" />

                      {errors.fixed_date && (
                        <FormErrorMessage>
                          {errors.fixed_date[0]}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
              )}
              {props.values.recurrent == "1" && (
                <>
                  <Field name="type">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.type}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="type"
                        >
                          Tipo
                        </FormLabel>
                        <Select size="sm" {...field}>
                          <option key="week" value="week">
                            Semanal
                          </option>
                          <option key="month" value="month">
                            Mensal
                          </option>
                          <option key="year" value="year">
                            Anual
                          </option>
                        </Select>

                        {errors.type && (
                          <FormErrorMessage>{errors.type[0]}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>

                  <Field name="day">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.day}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="day"
                        >
                          Dia
                          {props.values.type == "week" &&
                            " da semana 1=SEG-7=DOM"}
                        </FormLabel>
                        <Input type="number" size="sm" {...field} id="day" />

                        {errors.day && (
                          <FormErrorMessage>{errors.day[0]}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  {props.values.type == "year" && (
                    <Field name="month">
                      {({ field, form }) => (
                        <FormControl isInvalid={errors.month}>
                          <FormLabel
                            _dark={{
                              color: "gray.500",
                            }}
                            fontSize="sm"
                            fontWeight="bold"
                            colorScheme="gray"
                            htmlFor="month"
                          >
                            Mês
                          </FormLabel>
                          <Select size="sm" {...field}>
                            {months.map((m, k) => (
                              <option value={m.id}>{m.month}</option>
                            ))}
                          </Select>

                          {errors.name && (
                            <FormErrorMessage>
                              {errors.month[0]}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  )}
                </>
              )}

              <Field name="repeat">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.repeat}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="repeat"
                    >
                      Repetição
                    </FormLabel>
                    <Input
                      type="number"
                      size="sm"
                      {...field}
                      id="repeat"
                      placeholder="Repetição"
                    />

                    {errors.repeat && (
                      <FormErrorMessage>{errors.repeat[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="end_date">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.end_date}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="end_date"
                    >
                      Data final
                    </FormLabel>
                    <Input type="date" size="sm" {...field} />

                    {errors.end_date && (
                      <FormErrorMessage>{errors.end_date[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="receiver">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.receiver}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="receiver"
                    >
                      Destinatário
                    </FormLabel>
                    <Select size="sm" {...field} id="receiver">
                      <option value="1" key={1}>
                        Cliente
                      </option>
                      <option value="0" key={0}>
                        Equipe interna
                      </option>
                    </Select>

                    {errors.receiver && (
                      <FormErrorMessage>{errors.receiver[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Flex my="20px">
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="content"
                  >
                    Mensagem
                  </FormLabel>
                  <Box bg="white" color='black'>
                  <EditorToolbar />
                    <ReactQuill
                      id="snow"
                      theme="snow"
                      value={content}
                      onChange={onChangeContents}
                      placeholder={"Insira um conteúdo para o protocolo..."}
                      modules={modules}
                      formats={formats}
                    />
                  </Box>
                </FormControl>
              </Flex>
            </Flex>

            <Flex
              align="end"
              justify="end"
              gap={2}
              mt="10px"
              flexDirection={{ sm: "column", lg: "row" }}
            >
              <Button
                leftIcon={<MdOutlineCancel />}
                size="sm"
                colorScheme="gray"
                cursor="pointer"
                type="button"
                w={{ sm: "100%", lg: "auto" }}
                onClick={() => {
                  setForm(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                leftIcon={<BiSave />}
                size="sm"
                colorScheme="blue"
                
                cursor="pointer"
                type="submit"
                
                w={{ sm: "100%", lg: "auto" }}
              >
                Salvar
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default RecurrentForm;
