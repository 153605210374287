import React, { useState } from 'react'
import { Route, useParams } from 'react-router-dom'
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
// Assets
import signInImage from 'assets/img/signInImage.png'
import Logo from 'assets/img/logo.png'
import AuthApi from '../../api/auth'

import { useHistory } from 'react-router-dom'

function ResetPassword (props) {
  const { token, email } = useParams();
  const history = useHistory();
  // Chakra color mode
  const titleColor = useColorModeValue('primary', 'secondary')
  const textColor = useColorModeValue('gray.400', 'white')

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const [buttonText, setButtonText] = useState('Alterar senha')

  const login = async event => {
    if (event) {
      event.preventDefault()
    }
    if (password === '') {
      return setError('Você deve inserir uma senha.')
    }
    if (password !== passwordConfirmation) {
      return setError('As senhas digitadas não coincidem.')
    }
    setButtonText('Alterando a senha')
    try {
      let response = await AuthApi.ResetPassword({
        email,
        token,
        password: password,
        password_confirmation: passwordConfirmation
      })
      if (response.data && response.data.error === true) {
        setButtonText('Alterar senha')
        setSuccess(undefined)
        return setError(response.data.msg)
      }
      setError(undefined)
      setButtonText('Alterar senha')
      return setSuccess(response.data.msg)
    } catch (err) {
      setSuccess(undefined)
      setButtonText('Alterar senha')
      if (err.response) {
        return setError(err.response.data.msg)
      }
      return setError('Ocorreu um erro ao resetar a senha.')
    }
  }
  
  const home = () => {
    return history.push("/auth/signin");
  }

  return (
    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: '100px', md: '0px' }}
      >
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: '150px', lg: '80px' }}
          >
            <img src={Logo} width={140} />
            <Heading color={titleColor} fontSize='32px' mt='10px' mb='10px'>
              Alterar senha
            </Heading>
            {success && (
              <>
              <Text
                  mb='36px'
                  ms='4px'
                  color={textColor}
                  fontWeight='bold'
                  fontSize='14px'
                >
                  {success}
                </Text>
                <Button
                    fontSize='12px'
                    type='button'
                    bg='primary'
                    w='100%'
                    h='45'
                    mb='20px'
                    color='white'
                    mt='20px'
                    _hover={{
                      bg: 'secondary'
                    }}
                    _active={{
                      bg: 'primary'
                    }}
                    onClick={home}
                  >
                    Ir para o login
                  </Button>
              </>
            )}
            {!success && (
              <>
                <Text
                  mb='36px'
                  ms='4px'
                  color={textColor}
                  fontWeight='bold'
                  fontSize='14px'
                >
                  Informe sua nova senha de acesso!
                </Text>
                <FormControl>
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    Senha
                  </FormLabel>
                  <Input
                    borderRadius='15px'
                    mb='24px'
                    fontSize='sm'
                    type='password'
                    placeholder='Senha'
                    size='lg'
                    defaultValue={password}
                    onChange={event => {
                      setPassword(event.target.value)
                      setError(undefined)
                    }}
                  />

                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    Confirmar Senha
                  </FormLabel>
                  <Input
                    borderRadius='15px'
                    mb='24px'
                    fontSize='sm'
                    type='password'
                    placeholder='Confirmar Senha'
                    size='lg'
                    defaultValue={passwordConfirmation}
                    onChange={event => {
                      setPasswordConfirmation(event.target.value)
                      setError(undefined)
                    }}
                  />

                  <h4
                    style={{
                      fontSize: '.9em',
                      color: 'red',
                      textAlign: 'center',
                      fontWeight: 400,
                      transition: '.2s all'
                    }}
                  >
                    {error}
                  </h4>
                  <h4
                    style={{
                      fontSize: '.9em',
                      color: 'green',
                      textAlign: 'center',
                      fontWeight: 400,
                      transition: '.2s all'
                    }}
                  >
                    {success}
                  </h4>
                  <Button
                    fontSize='12px'
                    type='submit'
                    bg='primary'
                    w='100%'
                    h='45'
                    mb='20px'
                    color='white'
                    mt='20px'
                    _hover={{
                      bg: 'secondary'
                    }}
                    _active={{
                      bg: 'primary'
                    }}
                    onClick={login}
                  >
                    {buttonText}
                  </Button>
                </FormControl>
              </>
            )}
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'
        >
          <Box
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'
          ></Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default ResetPassword
