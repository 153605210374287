import React from "react";
import {
  Avatar,
  Text,
  Box,
  HStack,
  Flex,
  Center,
  Badge,
  Spacer,
  Spinner,
} from "@chakra-ui/react";
import { AiOutlineClockCircle } from "react-icons/ai";

function HistTicket(props) {
  const { historyData, loading } = props;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Box w="100%">
          {historyData.map((hist, i) => (
            <HStack spacing={8} key={i} shadow="md" borderWidth="1px" my={2}>
              <Flex direction="row" p={2} w="100%">
                <Box direction="column" p={2}>
                  <Flex direction="row" gap={2}>
                    <Avatar
                      size="md"
                      name={hist.user_name}
                      src={hist.user_avatar_url}
                    />

                    <Flex direction="column">
                      <Text fontSize={{ base: "sm", sm: "md", md: "lg" }}>
                        {hist.user_name}
                      </Text>
                      <Center gap={2}>
                        <AiOutlineClockCircle size={16} />
                        <Text fontSize="sm" direction="row">
                          {hist.updated_at}
                        </Text>
                      </Center>
                    </Flex>
                  </Flex>
                </Box>
                <Box p="4">
                  <Flex direction="column" gap={2}>
                    <Text fontSize="sm">
                      Status:
                      <Badge background={hist.status_color} color="#ffffff">
                        {hist.status_name}
                      </Badge>
                    </Text>
                    <Text fontSize="sm">
                      SLA:
                      <Badge background={hist.sla_color} color="#ffffff">
                        {hist.sla_name}
                      </Badge>
                    </Text>
                    <Text fontSize="sm">
                      Prioridade:
                      <Badge background={hist.priority_color} color="#ffffff">
                        {hist.priority_name}
                      </Badge>
                    </Text>
                    <Text fontSize="sm">
                      Tipo:
                      <Badge background={hist.type_color} color="#ffffff">
                        {hist.type_name}
                      </Badge>
                    </Text>
                    <Text fontSize="sm">
                      Tópico:
                      <Badge background={hist.topic_color} color="#ffffff">
                        {hist.topic_name}
                      </Badge>
                    </Text>
                    <Text fontSize="sm">
                      Departamento:
                      <Badge background={hist.department_color} color="#ffffff">
                        {hist.department_name}
                      </Badge>
                    </Text>
                  </Flex>
                </Box>
                <Spacer />
                <Box p="4">
                  {hist.user_company !== "" && (
                    <Badge colorScheme="gray">{hist.user_company}</Badge>
                  )}
                  <Badge colorScheme="green">{hist.user_type}</Badge>
                </Box>
              </Flex>
            </HStack>
          ))}
        </Box>
      )}
    </>
  );
}

export default HistTicket;
