import {
  Button,
  Flex,
  FormControl, FormErrorMessage, FormLabel, Input, Link, Select, Switch, Textarea, useToast, Text
} from "@chakra-ui/react";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import AllApi from "../../../api/all";
import CompaniesApi from "../../../api/company";
import CompaniesBankApi from "../../../api/companyAccountsPayable";
import CompaniesSuppliersApi from "../../../api/companySuppliers";
import AutocompleteInput from "../../../components/Inputs/AutocompleteInput";
import DateRangePicker from "../../../components/Inputs/DateRangePicker";
import PopupFormSupplier  from "../Suppliers/PopupFormSupplier";

function AccountsPayableForm(props) {
  const { user, accountsPayableId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [accountsPlans, setAccountsPlans] = useState([]);
  const [accountsPayable, setAccountsPayable] = useState([]);
  const [companySelected, setCompanySelected] = useState("");
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [paymentsForm, setPaymentsForm] = useState([]);
  const [banks, setBanks] = useState([]);
  const [clients, setClients] = useState(false);
  const toast = useToast();

  const [initialData, setInitialData] = useState({
    id: "",
    id_company: "",
    id_supplier: "",
    id_account_plan: "",
    id_contabia: "",
    titu_fili: "",
    titu_forn: "",
    titu_titu: "",
    titu_seri: "",
    titu_parc: "",
    titu_emis: null,
    titu_venc: null,
    titu_valo: "",
    titu_cont: "",
    titu_obse: "",
    titu_form_rece: "",
    payment: false,
    bapa_dpag: null,
    bapa_valo: "",
    bapa_vmul: "",
    bapa_vjur: "",
    bapa_vdes: "",
    file: "",
    bapa_banc: "",
    file_url: "",
    id_account_receivables: "",
  });

  useEffect(() => {
    getPaymentsForm();
    if(user.type != 'A'){
      loadBanks(user.id_company);
      loadAccountsPlans(user.id_company);
    }
  }, []);
  useEffect(() => {
    if (accountsPayableId !== false && !loading) {
      loadDataItem();
    }
  }, [accountsPayableId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await CompaniesBankApi.Show(accountsPayableId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      await loadAccountsPayable(response.data.result.id_company);
      await loadAccountsPlans(response.data.result.id_company);
      await loadBanks(response.data.result.id_company);
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      //console.log(err);
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let formData = new FormData();
    for (let value in values) {
      if (values[value] instanceof Date) {
        formData.append(value, values[value].toISOString());
      } else if (value != "file") {
        formData.append(value, values[value]);
      }
    }
    if (values.file) {
      formData.append("file", values.file);
    }
    try {
      let response = {};
      if (accountsPayableId) {
        formData.append("_method", "PUT");
        response = await CompaniesBankApi.Update(formData, accountsPayableId);
      } else {
        response = await CompaniesBankApi.Save(formData);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";

      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getPaymentsForm = async () => {
    let response = await AllApi.paymentsForm();
    //console.log(response);
    setPaymentsForm(response.data);
  };

  const loadBanks = async (idCompany) => {
    try {
      let banks = await CompaniesApi.Banks(idCompany);

      return setBanks(banks.data);
    } catch (err) {
      return setBanks([]);
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        return {
          id: value.id,
          name: value.empr_nome,
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  const loadAccountsPayable = async (idCompany) => {
    try {
      let accountsPayable = await AllApi.accountsPayable(idCompany);

      return setAccountsPayable(accountsPayable.data);
    } catch (err) {
      return setAccountsPayable([]);
    }
  };

  const loadAccountsPlans = async (idCompany) => {
    try {
      let accounts = await CompaniesApi.AccountPlans(idCompany);

      return setAccountsPlans(accounts.data);
    } catch (err) {
      return setAccountsPlans([]);
    }
  };

  const getClients = async (word) => {
    if (word && word.length > 3) {
      setLoadingClients(true);
      let response = await CompaniesSuppliersApi.search(word, companySelected);

      setClients({ data: response.data });
      setLoadingClients(false);
    } else {
      setClients([]);
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            {user.type === "A" && (
              <Flex
                h="full"
                gap={2}
                mb={8}
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <FormControl isInvalid={errors.id_company}>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="id_company"
                  >
                    Empresa
                  </FormLabel>
                  {accountsPayableId && props.values.company ? (
                    <Input
                      size="sm"
                      readOnly
                      value={props.values.company.empr_nome}
                    />
                  ) : (
                    <AutocompleteInput
                      loading={loadingCompanies}
                      options={companies}
                      setOptions={setCompanies}
                      requests={getCompanies}
                      placeholder="Pesquisar empresa"
                      onClickFunction={(e) => {
                        props.setFieldValue("id_company", e.id);
                        setCompanySelected(e.id);
                        loadAccountsPayable(e.id);
                        loadAccountsPlans(e.id);
                        loadBanks(e.id);
                      }}
                    />
                  )}
                  {errors.id_company && (
                    <FormErrorMessage>{errors.id_company[0]}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            )}
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "column" }}
              w="100%"
            >
              <FormLabel
                fontSize="sm"
                fontWeight="bold"
                colorScheme="gray"
                htmlFor="id_supplier"
              >
                <Flex flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                  align="center"
                  justify="space-between">
                  <Text>Fornecedor</Text>
                  <PopupFormSupplier idCompany={companySelected} />
                </Flex>
              </FormLabel>
              {accountsPayableId && props.values.supplier ? (
                <Input
                  size="sm"
                  readOnly
                  value={props.values.supplier.name}
                />
              ) : (

                <AutocompleteInput
                  loading={loadingClients}
                  options={clients}
                  setOptions={setClients}
                  requests={getClients}
                  placeholder="Pesquisar fornecedor"
                  onClickFunction={async (e) => {
                    props.setFieldValue("id_supplier", e.id);
                  }}
                />
              )}
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="titu_fili">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_fili}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_fili"
                    >
                      Filial
                    </FormLabel>
                    <Input size="sm" {...field} id="titu_fili" />

                    {errors.titu_fili && (
                      <FormErrorMessage>{errors.titu_fili[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="titu_titu">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_titu}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_titu"
                    >
                      Número do documento
                    </FormLabel>
                    <Input size="sm" {...field} id="titu_titu" />

                    {errors.titu_titu && (
                      <FormErrorMessage>{errors.titu_titu[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="titu_seri">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_seri}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_seri"
                    >
                      Série
                    </FormLabel>
                    <Input size="sm" {...field} id="titu_seri" />

                    {errors.titu_seri && (
                      <FormErrorMessage>{errors.titu_seri[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="titu_parc">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_parc}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_parc"
                    >
                      Parcela
                    </FormLabel>
                    <Input size="sm" {...field} id="titu_parc" />

                    {errors.titu_parc && (
                      <FormErrorMessage>{errors.titu_parc[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>

            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="titu_emis">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_emis}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_emis"
                    >
                      Data emissão
                    </FormLabel>
                    <DateRangePicker
                      startDate={
                        accountsPayableId
                          ? new Date(props.values.titu_emis)
                          : props.values.titu_emis
                      }
                      range={false}
                      setDateRange={(update) => {
                        props.setFieldValue("titu_emis", update);
                      }}
                    />
                    {errors.titu_emis && (
                      <FormErrorMessage>{errors.titu_emis[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="titu_venc">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_venc}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_venc"
                    >
                      Data vencimento
                    </FormLabel>
                    <DateRangePicker
                      startDate={
                        accountsPayableId
                          ? new Date(props.values.titu_venc)
                          : props.values.titu_venc
                      }
                      range={false}
                      setDateRange={(update) => {
                        props.setFieldValue("titu_venc", update);
                      }}
                    />
                    {errors.titu_venc && (
                      <FormErrorMessage>{errors.titu_venc[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="titu_valo">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_valo}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_valo"
                    >
                      Valor
                    </FormLabel>
                    <Input
                      as={NumericFormat}
                      {...field}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale
                      allowLeadingZeros
                      thousandSeparator="."
                      decimalSeparator=","
                      id="titu_valo"
                      size="sm"
                    />
                    {errors.titu_valo && (
                      <FormErrorMessage>{errors.titu_valo[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>

            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="id_account_plan">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_account_plan}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_account_plan"
                    >
                      Plano de contas
                    </FormLabel>
                    <Select size="sm" {...field} id="id_account_plan">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {accountsPlans.map((val, i) => (
                        <option key={`accountsPlans${val.id}`} value={val.id}>
                          {val.plan_nome}
                        </option>
                      ))}
                    </Select>

                    {errors.id_account_plan && (
                      <FormErrorMessage>
                        {errors.id_account_plan[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="titu_form_rece">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_form_rece}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_form_rece"
                    >
                      Forma de Pagamento
                    </FormLabel>
                    <Select size="sm" {...field} id="titu_form_rece">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {paymentsForm.map((val, i) => (
                        <option key={`paymentsForm${val.id}`} value={val.cod}>
                          {val.name}
                        </option>
                      ))}
                    </Select>

                    {errors.titu_form_rece && (
                      <FormErrorMessage>
                        {errors.titu_form_rece[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="titu_obse">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.titu_obse}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="titu_obse"
                    >
                      Observações
                    </FormLabel>
                    <Textarea size="sm" {...field} id="titu_obse" />
                    {errors.titu_obse && (
                      <FormErrorMessage>{errors.titu_obse[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="payment">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.payment}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="payment"
                    >
                      Pago
                    </FormLabel>
                    <Switch {...field} isChecked={props.values.payment} />
                    {errors.payment && (
                      <FormErrorMessage>{errors.payment[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            {props.values.payment && (
              <>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                >
                  <Field name="bapa_dpag">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.bapa_dpag}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="bapa_dpag"
                        >
                          Data pagamento {props.values.id_account_receivables}
                        </FormLabel>
                        <DateRangePicker
                          startDate={
                            props.values.id_account_receivables !== ""
                              ? new Date(props.values.bapa_dpag)
                              : props.values.bapa_dpag
                          }
                          range={false}
                          setDateRange={(update) => {
                            props.setFieldValue("bapa_dpag", update);
                          }}
                        />
                        {errors.bapa_dpag && (
                          <FormErrorMessage>
                            {errors.bapa_dpag[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="bapa_banc">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.bapa_banc}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="bapa_banc"
                        >
                          Banco
                        </FormLabel>
                        <Select size="sm" {...field} id="bapa_banc">
                          <option value="" disabled>
                            Selecione
                          </option>
                          {banks.map((val, i) => (
                            <option key={`banks${val.id}`} value={val.id}>
                              {val.banc_nome}
                            </option>
                          ))}
                        </Select>

                        {errors.bapa_banc && (
                          <FormErrorMessage>
                            {errors.bapa_banc[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                >
                  <Field name="bapa_valo">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.bapa_valo}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="bapa_valo"
                        >
                          Valor
                        </FormLabel>
                        <Input
                          as={NumericFormat}
                          {...field}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator="."
                          decimalSeparator=","
                          id="bapa_valo"
                          size="sm"
                        />
                        {errors.bapa_valo && (
                          <FormErrorMessage>
                            {errors.bapa_valo[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="bapa_vmul">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.bapa_vmul}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="bapa_vmul"
                        >
                          Valor da multa
                        </FormLabel>
                        <Input
                          as={NumericFormat}
                          {...field}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator="."
                          decimalSeparator=","
                          id="bapa_vmul"
                          size="sm"
                        />
                        {errors.bapa_vmul && (
                          <FormErrorMessage>
                            {errors.bapa_vmul[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="bapa_vjur">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.bapa_vjur}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="bapa_vjur"
                        >
                          Valor juros
                        </FormLabel>
                        <Input
                          as={NumericFormat}
                          {...field}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator="."
                          decimalSeparator=","
                          id="bapa_vjur"
                          size="sm"
                        />
                        {errors.bapa_vjur && (
                          <FormErrorMessage>
                            {errors.bapa_vjur[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="bapa_vdes">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.bapa_vdes}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="bapa_vdes"
                        >
                          Valor desconto
                        </FormLabel>
                        <Input
                          as={NumericFormat}
                          {...field}
                          prefix={"R$ "}
                          decimalScale={2}
                          fixedDecimalScale
                          allowLeadingZeros
                          thousandSeparator="."
                          decimalSeparator=","
                          id="bapa_vdes"
                          size="sm"
                        />
                        {errors.bapa_vdes && (
                          <FormErrorMessage>
                            {errors.bapa_vdes[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                >
                  <Field name="file">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={errors.file || errors.certificado}
                      >
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="file"
                        >
                          Arquivo
                        </FormLabel>
                        {props.values.file_url !== "" && (
                          <Flex
                            flexDirection={{ sm: "column", lg: "row" }}
                            w="100%"
                            my={4}
                          >
                            <Link
                              mx={2}
                              size="sm"
                              cursor="pointer"
                              type="button"
                              target="_blank"
                              href={props.values.file_url}
                              isExternal
                            >
                              <Flex
                                flexDirection={{ sm: "column", lg: "row" }}
                                w="100%"
                              >
                                <FaFileDownload size={50} />
                              </Flex>
                            </Link>
                          </Flex>
                        )}
                        <Input
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => {
                            props.setFieldValue(
                              "file",
                              event.currentTarget.files[0]
                            );
                          }}
                          className="form-control"
                        />
                        {errors.file && (
                          <FormErrorMessage>{errors.file[0]}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Flex>
              </>
            )}
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"

                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"

                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AccountsPayableForm;
