import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react'
import { useState } from 'react'

import { Field, Form, Formik } from 'formik'

import { AiOutlineSearch } from 'react-icons/ai'
import { BiEraser } from 'react-icons/bi'

function ContentsSearch(props) {
  const { loadData, searchParams, loading } = props
  const [initialValue, setInitialValue] = useState({
    title: (searchParams["filter[title]"]) ? searchParams["filter[title]"] : '',
    category: (searchParams["filter[category]"]) ? searchParams["filter[category]"] : '',
    type: (searchParams["filter[type]"]) ? searchParams["filter[type]"] : '',
    status: (searchParams["filter[status]"]) ? searchParams["filter[status]"] : '',
  })

  const search = params => {
    params.page = 1
    loadData(params)
  }
  const clear = () => {
    loadData({ page: 1 })
  }

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        let params = {}
        for (var key in values) {
          if (values[key] != '') {
            params[`filter[${key}]`] = values[key]
          }
        }
        search(params)
      }}
    >
      {props => (
        <Form>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            <Field name='name'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                     fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='name'
                  >
                    Nome
                  </FormLabel>
                  <Input size='sm' {...field} id='name' placeholder='Nome' />
                </FormControl>
              )}
            </Field>
            <Field name='category'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                     fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='category'
                  >
                    Categoria
                  </FormLabel>
                  <Input size='sm' {...field} id='category' placeholder='Categoria' />
                </FormControl>
              )}
            </Field>
            <Field name='type'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                     fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='type'
                  >
                    Tipo
                  </FormLabel>
                  <Select
                    size='sm'
                    {...field}
                    id='type'
                    placeholder='Permissão'
                  >
                    <option value='1'>ADMIN</option>
                    <option value='0'>FUNCIONÁRIO</option>
                  </Select>
                </FormControl>
              )}
            </Field>
            <Field name='status'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                     fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='status'
                  >
                    Status
                  </FormLabel>
                  <Select size='sm' {...field} id='status' placeholder='Status'>
                    <option value='1'>Ativo</option>
                    <option value='0'>Inativo</option>
                  </Select>
                </FormControl>
              )}
            </Field>
            <Flex align='end' justify='center'>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='green'
                cursor='pointer'
                
                type='submit'
              >
                <AiOutlineSearch />
              </Button>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='blue'
                cursor='pointer'
                type='button'
                
                onClick={() => {
                  props.setFieldValue('name', '')
                  props.setFieldValue('category', '')
                  props.setFieldValue('type', '')
                  props.setFieldValue('status', '')
                  clear()
                }}
              >
                <BiEraser />
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default ContentsSearch
