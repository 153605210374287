import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdArrowBack, MdOutlineCancel } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import AllApi from "../../../api/all";
import CitiesApi from "../../../api/cities";
import CompaniesApi from "../../../api/company";
import CompaniesClientApi from "../../../api/companyClients";
import CompaniesNfApi from "../../../api/companyNf";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import CustomInputMask from "../../../components/Inputs/CustomInputMask";
import AutocompleteInput from "../../../components/Inputs/AutocompleteInput";
import PopupFormClient from "../Clients/PopupFormClient";

function NfForm(props) {
  const { user, nfId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);
  const [citySelected, setCitySelected] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [citiesNf, setCitiesNf] = useState([]);
  const [companies, setCompanies] = useState(false);
  const [clients, setClients] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);
  const [mask, setMask] = useState("(99) 99999-9999");
  const [cityNf, setCityNf] = useState("Selecionar cidade");
  const [services, setServices] = useState([]);
  const toast = useToast();
  const typeAddress = [
    "Alameda",
    "Avenida",
    "Chácara",
    "Colônia",
    "Condomínio",
    "Estância",
    "Estrada",
    "Fazenda",
    "Praça",
    "Prolongamento",
    "Rodovia",
    "Rua",
    "Sítio",
    "Travessa",
    "Vicinal",
    "Eqnp",
  ];

  const [initialData, setInitialData] = useState({
    id_client: "",
    id_company: user.type != "A" ? user.company.id : "",
    description: "",
    city_ibge: user.type != "A" ? user.company_city.id : "",
    info: "",
    total: "",
    cnae: "",
    type: "J",
    cpf_cnpj: "",
    name: "",
    phone: "",
    email: "",
    cep: "",
    address: "",
    number: "",
    id_city: "",
    district: "",
    complement: "",
    state: "",
    insc_mun: "",
    type_address: "",
    contabia_name_service: "",
    id_service: "",
  });
  useEffect(() => {
    loadUf();
    if (nfId !== false && !loading) {
      loadDataItem();
    }
    if (user.type !== "A") {
      setInitialData({
        ...initialData,
        description: user.company.contabia_name_service,
        cnae: user.company.nf_cnae,
      });
      setCompanySelected(user.id_company);
      loadServices(user.id_company);
      if (user.company_city) {
        setCitiesNf([
          {
            id: user.company_city.id,
            name: `${user.company_city.state.abbr} - ${user.company_city.name}`,
          },
        ]);
        setCityNf(
          `${user.company_city.state.abbr} - ${user.company_city.name}`
        );
      } else {
        setCityNf(`Pesquisar cidade`);
      }
    }
  }, []);

  useEffect(() => {
    if (nfId !== false && !loading) {
      loadDataItem();
    }
  }, [nfId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await CompaniesNfApi.Show(nfId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      if (response.data.result.uf !== "" && nfId == false) {
        loadCities(response.data.result.state_id);
        setCitySelected(response.data.result.id_city);
      }
      setCompanySelected(response.data.result.id_company);
      loadServices(response.data.result.id_company);
      if(nfId != false && response.data.result.state_nf !== null && response.data.result.city_nf !== null){
        setCityNf(
          `${response.data.result.state_nf.abbr} - ${response.data.result.city_nf.name}`
        );
      }
      
      console.log('5');
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = {};
      response = await CompaniesNfApi.Save(values);

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";

      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadServices = async (idCompany) => {
    try {
      let servs = await AllApi.services(idCompany);
      return setServices(servs.data);
    } catch (err) {
      return setServices([]);
    }
  };

  const loadUf = async () => {
    try {
      let uf = await CitiesApi.Ufs();
      return setUfs(uf.data);
    } catch (err) {
      return setUfs([]);
    }
  };

  const loadClient = async (cpfCnpj, id_company) => {
    try {
      let client = await CompaniesClientApi.cnpjCpf(cpfCnpj, id_company);

      if (typeof client.data === "object") {
        return client.data;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  const loadCities = async (uf) => {
    try {
      let cities = await CitiesApi.All(uf);
      setCities(cities.data);
      if (initialData.city) {
        if (parseInt(uf) !== initialData.city.state_id) {
          setCitySelected("");
        } else {
          setCitySelected(initialData.city.id);
        }
      } else {
        setCitySelected("");
      }

      return true;
    } catch (err) {
      setCities([]);
      setCitySelected("");
      return false;
    }
  };

  const getClients = async (word) => {
    if (word && word.length > 3) {
      setLoadingItems(true);
      let response = await CompaniesClientApi.search(word, companySelected);

      setClients({ data: response.data });
      setLoadingItems(false);
    } else {
      setClients([]);
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        let plugnotas = true;
        if (value.status_plugnotas !== "1") {
          plugnotas = false;
        }
        return {
          id: value.id,
          name: value.empr_nome,
          city: value.city,
          cnae: (value.nf_cnae != 'null' && value.nf_cnae != '') ? value.nf_cnae:value.empr_cnae,
          services: value.services,
          contabia_name_service: value.contabia_name_service,
          badge: !plugnotas
            ? {
                colorScheme: "red",
                text: "NÃO CONECTADO AO PLUGNOTAS",
              }
            : {
                colorScheme: "green",
                text: "CONECTADO",
              },
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  const getCities = async (word) => {
    if (word && word.length > 3) {
      setLoadingCities(true);
      try {
        let cities = await CitiesApi.Search(word);
        setCitiesNf(cities);
        setLoadingCities(false);
        return true;
      } catch (err) {
        setCitiesNf([]);
        setLoadingCities(false);
        return false;
      }
    } else {
      setCitiesNf([]);
    }
  };

  const getCep = async (cep) => {
    try {
      let search = cep.replace(/[^0-9]/g, "");

      if (search.length == 8) {
        let result = await AllApi.cep(search);
        if (result.data.error) {
          toast({
            title: "Erro",
            description: result.data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          return false;
        }
        return result.data.cep;
      }
    } catch (err) {
      ////console.log(err)
      toast({
        title: "Erro",
        description: err.response.data.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return false;
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      {user.type !== "A" && user.company.status_plugnotas !== "1" ? (
        <Flex gap={2} mb={8} flexDirection="column" w="100%">
          <Alert status="error" my={4}>
            <AlertIcon />
            <AlertTitle>Atenção!</AlertTitle>
            <AlertDescription>
            Faça contato com a ContaBia para ativar a emissão de nota fiscal.
            </AlertDescription>
          </Alert>
          <Flex
            align="center"
            justify="center"
            gap={2}
            mt="10px"
            flexDirection={{ sm: "column", lg: "row" }}
          >
            <Button
              leftIcon={<MdArrowBack />}
              size="sm"
              colorScheme="gray"
              cursor="pointer"
              type="button"
              w={{ sm: "100%", lg: "auto" }}
              onClick={() => {
                setForm(false);
              }}
            >
              Voltar
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Formik
          initialValues={initialData}
          enableReinitialize
          onSubmit={async (values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <Form>
              {user.type === "A" && (
                <Flex
                  h="full"
                  gap={2}
                  mb={8}
                  flexDirection={{ sm: "column", lg: "row" }}
                >
                  <FormControl isInvalid={errors.status}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="status"
                    >
                      Empresa
                    </FormLabel>
                    {nfId && props.values.company ? (
                      <Input
                        _dark={{
                          backgroundColor: "gray.800",
                        }}
                        size="sm"
                        readOnly
                        value={props.values.company.empr_nome}
                      />
                    ) : (
                      <AutocompleteInput
                        loading={loadingCompanies}
                        options={companies}
                        setOptions={setCompanies}
                        requests={getCompanies}
                        placeholder={(nfId && props.values.company) ? props.values.company.empr_nome : "Pesquisar empresa"}
                        onClickFunction={(e) => {
                          setCompanySelected(e.id);
                          props.setFieldValue("id_company", e.id);
                          props.setFieldValue("cnae", e.cnae);
                          props.setFieldValue("id_service", "");
                          props.setFieldValue(
                            "description",
                            e.contabia_name_service
                          );
                          if (e.city !== null) {
                            setCitiesNf([
                              {
                                id: e.city.id,
                                name: `${e.city.state.abbr} - ${e.city.name}`,
                              },
                            ]);
                            props.setFieldValue("city_ibge", e.id);
                            setCityNf(`${e.city.state.abbr} - ${e.city.name}`);
                          } else {
                            setCityNf(`Pesquisar cidade`);
                          }
                          if (e.services != null) {
                            setServices(e.services);
                          } else {
                            setServices([]);
                          }
                        }}
                      />
                    )}
                  </FormControl>
                </Flex>
              )}
              <Card
                my={4}
                borderWidth={1}
                borderColor="gray.100"
                _dark={{ borderColor: "gray.800" }}
                width="100%"
              >
                <CardHeader title="" description="" py={2}>
                  <Box>Dados do cliente</Box>
                  <Spacer />
                  <PopupFormClient idCompany={companySelected}/>
                </CardHeader>
                <CardBody>
                  <Flex flexDirection="column" w="100%">
                    <Flex
                      gap={2}
                      mb={8}
                      flexDirection={{ sm: "column", lg: "column" }}
                      w="100%"
                    >
                      <AutocompleteInput
                        loading={loadingClients}
                        options={clients}
                        setOptions={setClients}
                        requests={getClients}
                        placeholder="Pesquisar cliente"
                        onClickFunction={async (e) => {
                          if (e) {
                            props.setFieldValue("cpf_cnpj", e.cpf_cnpj);
                            props.setFieldValue("phone", e.phone);
                            props.setFieldValue("name", e.name);
                            props.setFieldValue("cep", e.cep);
                            props.setFieldValue("email", e.email);
                            props.setFieldValue("type_address", e.type_address);
                            props.setFieldValue("address", e.address);
                            props.setFieldValue("district", e.district);
                            props.setFieldValue("complement", e.complement);
                            props.setFieldValue("number", e.number);
                            props.setFieldValue("state", e.state);
                            props.setFieldValue("id_city", e.id_city);
                            props.setFieldValue("city", e.city.name);
                            props.setFieldValue("id_client", e.id);
                            props.setFieldValue("type", e.type);
                          } else {
                            props.setFieldValue("cpf_cnpj", "");
                            props.setFieldValue("phone", "");
                            props.setFieldValue("name", "");
                            props.setFieldValue("cep", "");
                            props.setFieldValue("email", "");
                            props.setFieldValue("type_address", "");
                            props.setFieldValue("address", "");
                            props.setFieldValue("district", "");
                            props.setFieldValue("complement", "");
                            props.setFieldValue("number", "");
                            props.setFieldValue("state_id", 41);
                            props.setFieldValue("id_city", "");
                            props.setFieldValue("city", "");
                            props.setFieldValue("id_client", "");
                            props.setFieldValue("type", "");
                          }
                        }}
                      />
                    </Flex>
                    <Flex
                      gap={2}
                      mb={8}
                      flexDirection={{ sm: "column", lg: "row" }}
                      w="100%"
                    >
                      <Field name="type">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.type}>
                            <FormLabel
                              _dark={{
                                color: "gray.500",
                              }}
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="type"
                            >
                              Tipo
                            </FormLabel>
                            <Select size="sm" {...field} id="type">
                              <option value="" disabled>
                                Selecione
                              </option>
                              <option value="F" key={1}>
                                PF
                              </option>
                              <option value="J" key={0}>
                                PJ
                              </option>
                            </Select>

                            {errors.type && (
                              <FormErrorMessage>
                                {errors.type[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                      <Field name="cpf_cnpj">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.cpf_cnpj}>
                            <FormLabel
                              _dark={{
                                color: "gray.500",
                              }}
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="cpf_cnpj"
                            >
                              CPF/CNPJ
                            </FormLabel>
                            {/* <Input size='sm' {...field} id='cpf_cnpj' /> */}
                            <CustomInputMask
                              mask={
                                props.values.type == "F"
                                  ? "999.999.999-99"
                                  : "99.999.999/9999-99"
                              }
                              name="cpf_cnpj"
                              value={props.values.cpf_cnpj}
                              onChange={(e) => {
                                const value = e.target.value || "";
                                props.setFieldValue("cpf_cnpj", value);
                                if (value === "") {
                                  props.setFieldValue("cpf_cnpj", "");
                                  props.setFieldValue("phone", "");
                                  props.setFieldValue("name", "");
                                  props.setFieldValue("cep", "");
                                  props.setFieldValue("email", "");
                                  props.setFieldValue("type_address", "");
                                  props.setFieldValue("address", "");
                                  props.setFieldValue("district", "");
                                  props.setFieldValue("complement", "");
                                  props.setFieldValue("number", "");
                                  props.setFieldValue("state_id", 41);
                                  props.setFieldValue("id_city", "");
                                  props.setFieldValue("city", "");
                                  props.setFieldValue("id_client", "");
                                  props.setFieldValue("type", "");
                                }
                              }}
                            />
                            {errors.cpf_cnpj && (
                              <FormErrorMessage>
                                {errors.cpf_cnpj[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                      <Field name="name">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.name}>
                            <FormLabel
                              _dark={{
                                color: "gray.500",
                              }}
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="name"
                            >
                              Nome
                            </FormLabel>
                            <Input size="sm" {...field} id="name" />

                            {errors.name && (
                              <FormErrorMessage>
                                {errors.name[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Flex>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton
                            bg="gray.100"
                            _hover={{ bg: "gray.100" }}
                            _dark={{ bg: "gray.800" }}
                            _focus={{
                              boxShadow: "none",
                            }}
                          >
                            <Box as="span" flex="1" textAlign="left">
                              Dados do cliente
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel
                          pb={4}
                          bg="gray.50"
                          _dark={{
                            border: "1px",
                            borderColor: "gray.800",
                            bg: "transparent",
                          }}
                        >
                          <Flex
                            gap={2}
                            mb={8}
                            flexDirection={{ sm: "column", lg: "row" }}
                            w="100%"
                          >
                            <Field name="phone">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.phone}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="phone"
                                  >
                                    Telefone
                                  </FormLabel>
                                  {/* <Input size='sm' {...field} id='phone' /> */}
                                  <CustomInputMask
                                    mask={mask}
                                    name="phone"
                                    value={props.values.phone}
                                    onBlur={(e) => {
                                      if (
                                        e.target.value.replace("_", "")
                                          .length === 14
                                      ) {
                                        setMask("(99) 9999-9999");
                                      }
                                    }}
                                    onFocus={(e) => {
                                      if (
                                        e.target.value.replace("_", "")
                                          .length === 14
                                      ) {
                                        setMask("(99) 99999-9999");
                                      }
                                    }}
                                    onChange={(e) => {
                                      const value = e.target.value || "";
                                      props.setFieldValue("phone", value);
                                    }}
                                  />
                                  {errors.phone && (
                                    <FormErrorMessage>
                                      {errors.phone[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="email">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.email}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="email"
                                  >
                                    E-mail
                                  </FormLabel>
                                  <Input size="sm" {...field} id="email" />

                                  {errors.email && (
                                    <FormErrorMessage>
                                      {errors.email[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </Flex>
                          <Flex
                            gap={2}
                            mb={8}
                            flexDirection={{ sm: "column", lg: "row" }}
                            w="100%"
                          >
                            <Field name="cep">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.cep}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="cep"
                                  >
                                    CEP
                                  </FormLabel>
                                  {/* <Input size='sm' {...field} id='cep' /> */}
                                  <CustomInputMask
                                    mask="99999-999"
                                    name="cep"
                                    value={props.values.cep}
                                    onChange={async (e) => {
                                      let value = e.target.value || "";
                                      props.setFieldValue("cep", value);
                                      let cep = await getCep(value);
                                      if (cep) {
                                        props.setFieldValue(
                                          "type_address",
                                          cep.address_type
                                        );
                                        props.setFieldValue(
                                          "address",
                                          cep.logradouro
                                        );
                                        props.setFieldValue(
                                          "complement",
                                          cep.complemento
                                        );
                                        props.setFieldValue(
                                          "district",
                                          cep.bairro
                                        );
                                        props.setFieldValue(
                                          "city",
                                          cep.municipio
                                        );
                                        props.setFieldValue(
                                          "id_city",
                                          cep.ibge
                                        );
                                        props.setFieldValue("state", cep.uf);
                                      }
                                    }}
                                  />
                                  {errors.cep && (
                                    <FormErrorMessage>
                                      {errors.cep[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="type_address">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.type_address}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="type_address"
                                  >
                                    Tipo de endereço
                                  </FormLabel>
                                  <Select
                                    size="sm"
                                    {...field}
                                    id="type_address"
                                    value={props.values.type_address}
                                  >
                                    <option value="" disabled>
                                      Selecione
                                    </option>
                                    {typeAddress.map((val, i) => (
                                      <option
                                        key={`typeAddress${val}`}
                                        value={val}
                                      >
                                        {val}
                                      </option>
                                    ))}
                                  </Select>

                                  {errors.type_address && (
                                    <FormErrorMessage>
                                      {errors.type_address[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="address">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.address}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="address"
                                  >
                                    Endereço
                                  </FormLabel>
                                  <Input size="sm" {...field} id="address" />

                                  {errors.address && (
                                    <FormErrorMessage>
                                      {errors.address[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </Flex>
                          <Flex
                            gap={2}
                            mb={8}
                            flexDirection={{ sm: "column", lg: "row" }}
                            w="100%"
                          >
                            <Field name="number">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.number}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="number"
                                  >
                                    Número
                                  </FormLabel>
                                  <Input size="sm" {...field} id="number" />

                                  {errors.number && (
                                    <FormErrorMessage>
                                      {errors.number[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="complement">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.complement}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="complement"
                                  >
                                    Complemento
                                  </FormLabel>
                                  <Input size="sm" {...field} id="complement" />

                                  {errors.address && (
                                    <FormErrorMessage>
                                      {errors.address[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="district">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.district}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="district"
                                  >
                                    Bairro
                                  </FormLabel>
                                  <Input size="sm" {...field} id="district" />

                                  {errors.district && (
                                    <FormErrorMessage>
                                      {errors.district[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </Flex>
                          <Flex
                            gap={2}
                            mb={8}
                            flexDirection={{ sm: "column", lg: "row" }}
                            w="100%"
                          >
                            <Field name="city">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.city}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="city"
                                    value={citySelected}
                                  >
                                    Cidade
                                  </FormLabel>
                                  <Input
                                    size="sm"
                                    {...field}
                                    id="city"
                                    readOnly
                                  />

                                  {errors.city && (
                                    <FormErrorMessage>
                                      {errors.city[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="state">
                              {({ field, form }) => (
                                <FormControl isInvalid={errors.state}>
                                  <FormLabel
                                    _dark={{
                                      color: "gray.500",
                                    }}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    colorScheme="gray"
                                    htmlFor="state"
                                  >
                                    UF
                                  </FormLabel>
                                  <Input
                                    size="sm"
                                    {...field}
                                    id="state"
                                    readOnly
                                  />

                                  {errors.state && (
                                    <FormErrorMessage>
                                      {errors.state[0]}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </Flex>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Flex>
                </CardBody>
              </Card>
              <Card
                my={4}
                borderWidth={1}
                borderColor="gray.100"
                _dark={{ borderColor: "gray.800" }}
              >
                <CardHeader title="Dados fiscais" description=""></CardHeader>
                <CardBody>
                  <Flex flexDirection="column" w="100%">
                    <Flex
                      gap={2}
                      mb={8}
                      flexDirection={{ sm: "column", lg: "row" }}
                      w="100%"
                    >
                      <Field name="id_service">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.id_service}>
                            <FormLabel
                              _dark={{
                                color: "gray.500",
                              }}
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="id_service"
                            >
                              Serviço
                            </FormLabel>
                            
                            <Select
                              size="sm"
                              {...field}
                              id="id_service"
                              onChange={(e) => {
                                let service = services.find(
                                  (o) => o.id === parseInt(e.target.value)
                                );
                                //console.log(e.target.value)
                                //console.log(service)
                                //console.log(services)
                                if (service) {
                                  props.setFieldValue(
                                    "description",
                                    service.serv_desc_serv
                                  );
                                  props.setFieldValue(
                                    "cnae",
                                    service.serv_cnae
                                  );
                                }
                                props.setFieldValue(
                                  "id_service",
                                  e.target.value
                                );
                              }}
                            >
                              <option key={`service0`} value="" disabled>
                                Selecione
                              </option>
                              {services.map((val, i) => (
                                <option key={`service${val.id}`} value={val.id}>
                                  {val.serv_nome} - {val.serv_cnae}
                                </option>
                              ))}
                            </Select>

                            {errors.id_service && (
                              <FormErrorMessage>
                                {errors.id_service[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Flex>
                    <Flex
                      gap={2}
                      mb={8}
                      flexDirection={{ sm: "column", lg: "row" }}
                      w="100%"
                    >
                      <Field name="description">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.description}>
                            <FormLabel
                              _dark={{
                                color: "gray.500",
                              }}
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="description"
                            >
                              Descrição
                            </FormLabel>

                            <Textarea
                              size="sm"
                              {...field}
                              id="description"
                              defaultValue={
                                user.type !== "A"
                                  ? user.company.contabia_name_service
                                  : ""
                              }
                            />
                            {errors.description && (
                              <FormErrorMessage>
                                {errors.description[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Flex>
                    <Flex
                      gap={2}
                      mb={8}
                      flexDirection={{ sm: "column", lg: "row" }}
                      w="100%"
                    >
                      <Field name="total">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.total}>
                            <FormLabel
                              _dark={{
                                color: "gray.500",
                              }}
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="total"
                            >
                              Valor
                            </FormLabel>
                            <Input
                              as={NumericFormat}
                              {...field}
                              prefix={"R$ "}
                              decimalScale={2}
                              fixedDecimalScale
                              allowLeadingZeros
                              thousandSeparator="."
                              decimalSeparator=","
                              id="total"
                              size="sm"
                            />
                            {errors.total && (
                              <FormErrorMessage>
                                {errors.total[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Flex>
                    <Flex
                      gap={2}
                      mb={8}
                      flexDirection={{ sm: "column", lg: "row" }}
                      w="100%"
                    >
                      <FormControl isInvalid={errors.status}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="status"
                        >
                          Cidade prestação
                        </FormLabel>
                        <AutocompleteInput
                          loading={loadingCities}
                          options={citiesNf}
                          setOptions={setCities}
                          requests={getCities}
                          placeholder="Selecione a cidade"
                          value={cityNf}
                          onClickFunction={(e) =>
                            props.setFieldValue("city_ibge", e.id)
                          }
                        />
                      </FormControl>

                      <Field name="cnae">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.cnae}>
                            <FormLabel
                              _dark={{
                                color: "gray.500",
                              }}
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="cnae"
                            >
                              CNAE do serviço prestado
                            </FormLabel>
                            <Input
                              size="sm"
                              {...field}
                              id="cnae"
                              defaultValue={
                                user.type !== "A" ? user.company.nf_cnae : ""
                              }
                            />

                            {errors.cnae && (
                              <FormErrorMessage>
                                {errors.cnae[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Flex>
                    <Flex
                      gap={2}
                      mb={8}
                      flexDirection={{ sm: "column", lg: "row" }}
                      w="100%"
                    >
                      <Field name="info">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.info}>
                            <FormLabel
                              _dark={{
                                color: "gray.500",
                              }}
                              fontSize="sm"
                              fontWeight="bold"
                              colorScheme="gray"
                              htmlFor="info"
                            >
                              Informações obrigatório para o tomador
                            </FormLabel>
                            <Textarea size="sm" {...field} id="info" />

                            {errors.info && (
                              <FormErrorMessage>
                                {errors.info[0]}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
              <Flex
                gap={2}
                mb={8}
                flexDirection={{ sm: "column", lg: "row" }}
                w="100%"
              >
                <Flex
                  align="end"
                  justify="end"
                  gap={2}
                  mt="10px"
                  flexDirection={{ sm: "column", lg: "row" }}
                >
                  <Button
                    leftIcon={<MdOutlineCancel />}
                    size="sm"
                    colorScheme="gray"
                    cursor="pointer"
                    type="button"
                    w={{ sm: "100%", lg: "auto" }}
                    onClick={() => {
                      setForm(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    leftIcon={<BiSave />}
                    size="sm"
                    colorScheme="blue"
                    cursor="pointer"
                    type="submit"
                    w={{ sm: "100%", lg: "auto" }}
                  >
                    Salvar
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default NfForm;
