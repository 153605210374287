import React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

export default function NotFound (props) {
  return (
    <Box textAlign="center" py={10} px={6}>
      <InfoIcon boxSize={'50px'} color={'gray.300'} />
      <Heading as="h2" size="md" color={'gray.300'} mt={6} mb={2}>
        Nenhum registro encontrado
      </Heading>
    </Box>
  )
}
