import React, { useState } from 'react'
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
// Assets
import signInImage from 'assets/img/signInImage.png'

import { useAuth } from '../../auth-context/auth.context'
import AuthApi from '../../api/auth'
import Logo from 'assets/img/logo.png'
import { useHistory } from 'react-router-dom'

function ForgetPassword () {
  // Chakra color mode
  const titleColor = useColorModeValue('primary', 'secondary')
  const textColor = useColorModeValue('gray.400', 'white')

  const [email, setEmail] = useState('')
  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(undefined)
  const [buttonText, setButtonText] = useState('Resetar senha')

  const login = async event => {
    if (event) {
      event.preventDefault()
    }
    if (email === '') {
      return setError('Você deve inserir seu e-mail.')
    }
    setButtonText('Resetando')
    try {
      let response = await AuthApi.ForgetPassword({
        email
      })
      if (response.data && response.data.error === true) {
        setSuccess(undefined)
        return setError(response.data.msg)
      }
      setButtonText('Resetar senha')
      setError(undefined)
      return setSuccess(response.data.msg)
    } catch (err) {
      setSuccess(undefined)
      setButtonText('Resetar senha')
      if (err.response) {
        return setError(err.response.data.msg)
      }
      return setError('Ocorreu um erro ao resetar a senha.')
    }
  }


  return (
    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: '100px', md: '0px' }}
      >
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          
          <Flex
              direction='column'
              w='100%'
              background='transparent'
              p='48px'
              mt={{ md: '150px', lg: '80px' }}
            >
              <img src={Logo} width={140} />
              <Heading color={titleColor} fontSize='32px' mt='10px' mb='10px'>
                Resetar senha
              </Heading>
              <Text
                mb='36px'
                ms='4px'
                color={textColor}
                fontWeight='bold'
                fontSize='14px'
              >
                Informe seu e-mail de acesso!
              </Text>
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Email
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='24px'
                  fontSize='sm'
                  type='text'
                  placeholder='Endereço de email'
                  size='lg'
                  defaultValue={email}
                  onChange={event => {
                    setEmail(event.target.value)
                    setError(undefined)
                  }}
                />

                <h4
                  style={{
                    fontSize: '.9em',
                    color: 'red',
                    textAlign: 'center',
                    fontWeight: 400,
                    transition: '.2s all'
                  }}
                >
                  {error}
                </h4>
                <h4
                  style={{
                    fontSize: '.9em',
                    color: 'green',
                    textAlign: 'center',
                    fontWeight: 400,
                    transition: '.2s all'
                  }}
                >
                  {success}
                </h4>
                <Button
                  fontSize='12px'
                  type='submit'
                  bg='primary'
                  w='100%'
                  h='45'
                  mb='20px'
                  color='white'
                  mt='20px'
                  _hover={{
                    bg: 'secondary'
                  }}
                  _active={{
                    bg: 'primary'
                  }}
                  onClick={login}
                >
                  {buttonText}
                </Button>
              </FormControl>
            </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'
        >
          <Box
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'
          ></Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default ForgetPassword
