import { useEffect, useState } from 'react'
// Chakra imports
import {
  Container,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast
} from '@chakra-ui/react'
// Custom components
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import queryString from 'query-string'
import { RiSortAsc, RiSortDesc } from 'react-icons/ri'
import { useHistory } from 'react-router'
import EmailApi from '../../../../api/mailserver'
import NotFound from '../../../../components/Progress/NotFound'
import MailForm from './MailForm'
import TablesTableRow from './MailTablesTableRow'
import LoadingFullpage from 'components/Progress/LoadingFullpage'

function MailList (props) {
  const history = useHistory()
  const queryParams = queryString.parse(window.location.search)
  const textColor = useColorModeValue('gray.700', 'white')
  const [email, setEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(false)
  const [emailId, setHolidayId] = useState(false)
  const [busca, setBusca] = useState({})
  const [sort, setSort] = useState('name')
  const toast = useToast()
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState({})

  useEffect(() => {
    loadData(queryParams)
  }, [])

  useEffect(() => {
    let p = queryParams.page ? queryParams.sort : 1
    if (queryParams.sort !== sort || page !== p) {
      loadData(queryParams)
    }
  }, [sort, page])

  const loadData = async searchParams => {
    setLoading(true)
    try {
      let response = await EmailApi.List({
        sort: sort, page: page, ...searchParams
      })
      setLoading(false)
      if (response.data && response.data.success === false) {
        return setError(response.data.message)
      }
      setPages(response.data.meta)
      let search = new URLSearchParams({ ...searchParams, sort: sort })
      history.push(`/admin/config/email?${search}`)
      return setEmail(response.data.data)
    } catch (err) {
      setLoading(false)
      let erro = ''

      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  const selectRow = id => {
    setHolidayId(id)
    setForm(true)
  }

  const listSort = column => {
    if (sort.includes(column)) {
      if (sort.includes('-')) {
        setSort(column)
      } else {
        setSort('-' + column)
      }
    } else {
      setSort(column)
    }
  }

  const deleteRow = async id => {
    try {
      let response = await EmailApi.Delete(id)

      if (response.data.error === true) {
        toast({
          title: 'Erro',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
      toast({
        title: 'Sucesso',
        description: response.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      return loadData()
    } catch (err) {
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  return (
    <Flex direction="column" pt={{ sm: "40px", base: "120px", md: "75px" }}>
      {loading && 
        <LoadingFullpage />
      }
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} _dark={{bg:'gray.900'}}>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex>
            {!form && (
              <Text fontSize='xl' color={textColor} fontWeight='bold'>
                Servidor de email
              </Text>
            )}
            {form && emailId && (
              <Text fontSize='xl' color={textColor} fontWeight='bold'>
                Editar servidor de email
              </Text>
            )}
          </Flex>
        </CardHeader>
        <CardBody>
          {form ? (
            <Container maxW={'12xl'} p="0">
              <MailForm
                emailId={emailId}
                setForm={() => setForm()}
                loadData={() => loadData()}
              />
            </Container>
          ) : (
            <Container maxW={'12xl'} p="0">
              <Table variant='striped' colorScheme='gray' color={textColor}>
                <Thead>
                  <Tr colorScheme='gray'>
                    <Th px={0}
                      
                      colorScheme='gray'
                      cursor='pointer'
                      onClick={() => listSort('name')}
                    >
                      <Flex align='center' justify='start' gap={1}>
                        {sort == '-name' && <RiSortDesc />}
                        {sort == 'name' && <RiSortAsc />}
                        <Text>Nome</Text>
                      </Flex>
                    </Th>
                    <Th px={0}
                      
                      colorScheme='gray'
                      cursor='pointer'
                      onClick={() => listSort('email')}
                    >
                      <Flex align='center' justify='start' gap={1}>
                        {sort == '-email' && <RiSortDesc />}
                        {sort == 'email' && <RiSortAsc />}
                        <Text>Email</Text>
                      </Flex>
                    </Th>
                    <Th px={0}
                      
                      colorScheme='gray'
                      cursor='pointer'
                      onClick={() => listSort('server')}
                    >
                      <Flex align='center' justify='start' gap={1}>
                        {sort == '-server' && <RiSortDesc />}
                        {sort == 'server' && <RiSortAsc />}
                        <Text>Servidor</Text>
                      </Flex>
                    </Th>
                    <Th px={0}
                      colorScheme='gray'
                      cursor='pointer'
                      onClick={() => listSort('port')}
                    >
                      <Flex align='center' justify='start' gap={1}>
                        {sort == '-port' && <RiSortDesc />}
                        {sort == 'port' && <RiSortAsc />}
                        Porta
                      </Flex>
                    </Th>

                    <Th px={0} colorScheme='gray' w='140px' textAlign='center'>
                      Ações
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {email.length == 0 && (
                    <Tr>
                      <Td px={2} colSpan={4}>
                        <NotFound />
                      </Td>
                    </Tr>
                  )}
                  {email &&
                    email.map(row => {
                      return (
                        <TablesTableRow
                          key={row.id}
                          id={row.id}
                          name={row.name}
                          email={row.email}
                          server={row.server}
                          port={row.port}
                          selectRow={id => selectRow(id)}
                          deleteRow={id => deleteRow(id)}
                        />
                      )
                    })}
                </Tbody>
              </Table>
            </Container>
          )}
        </CardBody>
      </Card>
    </Flex>
  )
}

export default MailList
