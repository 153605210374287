import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  AiFillCalendar,
  AiFillFileExcel,
  AiFillFileImage,
  AiFillFilePdf,
  AiFillFileWord,
  AiOutlineClockCircle,
  AiOutlineFileZip,
  AiOutlineWhatsApp,
  AiTwotoneFileAdd,
} from "react-icons/ai";
import { BiNote } from "react-icons/bi";
import { BsFillTrashFill } from "react-icons/bs";
import { FaReplyAll, FaReplyd } from "react-icons/fa";
import { IoCreateOutline, IoTimer } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import TicketsApi from "../../../../api/ticket";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import Indeterminate from "../../../../components/Progress/Indeterminate";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import ChangeDepartment from "./ChangeDepartment";
import ChangeSolver from "./ChangeSolver";
import ChangeStatus from "./ChangeStatus";
import ReOpenTicket from "./ReOpenTicket";
import ReplyTicket from "./ReplyTicket";
import TakeTicket from "./TakeTicket";
import AllApi from "api/all";
import HistTicket from "./HistTicket";
import ViewsTicket from "./ViewsTicket";
import TablePagination from "components/Tables/TablePagination";
import { useAuth } from "auth-context/auth.context";

function TicketsMsg(props) {
  const { user, setUser } = useAuth();

  const [idTicket, setIdTicket] = useState(props.match.params.id);
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(true);
  const [saveData, setSaveData] = useState(false);
  const [historyData, setHistoryData] = useState({});
  const [tabLoadingHist, setTabLoadingHist] = useState(false);
  const [viewsData, setViewsData] = useState({});
  const [tabLoadingViews, setTabLoadingViews] = useState(false);
  const [pagesViews, setPagesViews] = useState({});
  const [pagesHist, setPagesHist] = useState({});
  const toast = useToast();
  const history = useHistory();
  const [reply, setReply] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [bgColor, setBgColor] = useState("transparent");
  const [bgDateColor, setDateBgColor] = useState("transparent");
  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    loadTicket();
  }, []);

  useEffect(() => {
    if (ticket.today) {
      setBgColor("cyan.50");
      setDateBgColor("cyan.500");
    } else if (ticket.next_day) {
      setBgColor("orange.50");
      setDateBgColor("orange.500");
    } else if (ticket.defeated) {
      setBgColor("red.50");
      setDateBgColor("red.500");
    }
  }, [ticket]);

  const loadTicket = async () => {
    setLoading(true);
    try {
      let response = await TicketsApi.Show(idTicket);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setTicket({});
      }

      return setTicket(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setTicket({});
      return history.push("/admin/tickets");
    }
  };

  const deleteMsg = async (messageId) => {
    setSaveData(true);
    try {
      let response = {};
      response = await TicketsApi.msgDelete(messageId);

      setTicket(response.data.ticket);
      setReply("");
      setAttachments([]);
      setSaveData(false);
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      setSaveData(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const openWhats = async () => {
    let whats = await AllApi.whatsapp();
    let url = `https://wa.me/55${
      whats.data.phone
    }?text=${whats.data.text_ticket.replace("{protocolo}", ticket.cod_ticket)}`;
    window.open(url, "_blank");
  };

  const loadHist = async (page = 1) => {
    console.log("loadHist");
    setTabLoadingHist(true);
    try {
      let response = {};
      response = await TicketsApi.Hist(idTicket, page);
      setHistoryData(response.data);
      setPagesHist(response.data.meta);
      setTabLoadingHist(false);
    } catch (err) {
      console.log(err);
      setTabLoadingHist(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }
    }
  };

  const loadViews = async (page = 1) => {
    console.log("loadViews");
    setTabLoadingViews(true);
    try {
      let response = {};
      response = await TicketsApi.Views(idTicket, page);
      setViewsData(response.data);
      console.log(response.data.meta);
      setPagesViews(response.data.meta);
      setTabLoadingViews(false);
    } catch (err) {
      console.log(err);
      setTabLoadingViews(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }
    }
  };

  return (
    <>
      {user.showWhats && (
        <Box pos="fixed" bottom="0" right="0" zIndex={5}>
          <Button
            colorScheme="green"
            borderRadius="full"
            px={3}
            py={8}
            m={4}
            onClick={openWhats}
          >
            <AiOutlineWhatsApp size={40} />
          </Button>
        </Box>
      )}
      <Flex mt={20} flexDirection="column">
        <Modal closeOnOverlayClick={false} isOpen={saveData} isCentered>
          <ModalOverlay />
          <ModalContent background="transparent" shadow="none">
            <ModalBody textAlign="center" alignItems="center">
              <Spinner thickness="4px" speed="0.65s" color="white" size="xl" />
              <Text color="white">Carregando dados</Text>
            </ModalBody>
          </ModalContent>
        </Modal>
        {loading && <LoadingFullpage />}
        {ticket.cod_ticket && (
          <>
            <Card p="1rem" my={{ sm: "24px", xl: "0px" }}>
              <CardHeader p="12px 5px" mb="12px">
                <Flex w="100%">
                  <Box py="4">
                    <Tag
                      fontSize={20}
                      px={4}
                      py={2}
                      variant="outline"
                      colorScheme="blue"
                    >
                      {ticket.cod_ticket}
                    </Tag>
                  </Box>
                  <Spacer />
                  <Box p="4">
                    {ticket.external_ticket != null && (
                      <Flex alignItems="center">
                        <Text
                          fontSize="sm"
                          _dark={{ color: "white" }}
                          fontWeight="normal"
                        >
                          Ticket externo:
                        </Text>
                        <Tag
                          fontSize={20}
                          px={4}
                          py={2}
                          variant="outline"
                          _dark={{ color: "white" }}
                        >
                          {ticket.external_ticket}
                        </Tag>
                      </Flex>
                    )}
                  </Box>
                </Flex>
              </CardHeader>
              <CardBody p="0px 5px">
                <Flex flexDirection="column" w="100%">
                  <Flex flexDirection="row" gap={4} my={5}>
                    <Text
                      fontWeight="bold"
                      color="gray.500"
                      _dark={{ color: "white" }}
                      fontSize="12px"
                    >
                      Departamento:
                      <br />
                      <Badge
                        bg={ticket.department.color}
                        color="white"
                        fontSize="16px"
                        px="10px"
                        borderRadius="5px"
                        w="100%"
                      >
                        {ticket.department.name}
                      </Badge>
                    </Text>
                    <Text
                      fontWeight="bold"
                      color="gray.500"
                      _dark={{ color: "white" }}
                      fontSize="12px"
                    >
                      Prioridade:
                      <br />
                      <Badge
                        bg={ticket.priority.color}
                        color="white"
                        fontSize="16px"
                        px="10px"
                        borderRadius="5px"
                        w="100%"
                      >
                        {ticket.priority.name}
                      </Badge>
                    </Text>
                    <Text
                      fontWeight="bold"
                      color="gray.500"
                      _dark={{ color: "white" }}
                      fontSize="12px"
                    >
                      SLA:
                      <br />
                      <Badge
                        bg={ticket.sla.color}
                        color="white"
                        fontSize="16px"
                        px="10px"
                        borderRadius="5px"
                        w="100%"
                      >
                        {ticket.sla.name}
                      </Badge>
                    </Text>
                    <Text
                      fontWeight="bold"
                      color="gray.500"
                      _dark={{ color: "white" }}
                      fontSize="12px"
                    >
                      Status:
                      <br />
                      <Badge
                        bg={ticket.status.color}
                        color="white"
                        fontSize="16px"
                        px="10px"
                        borderRadius="5px"
                        w="100%"
                      >
                        {ticket.status.name}
                      </Badge>
                    </Text>
                    <Text
                      fontWeight="bold"
                      color="gray.500"
                      _dark={{ color: "white" }}
                      fontSize="12px"
                    >
                      Tópico:
                      <br />
                      <Badge
                        bg={ticket.topic.color}
                        color="white"
                        fontSize="16px"
                        px="10px"
                        borderRadius="5px"
                        w="100%"
                      >
                        {ticket.topic.name}
                      </Badge>
                    </Text>
                    <Text
                      fontWeight="bold"
                      color="gray.500"
                      _dark={{ color: "white" }}
                      fontSize="12px"
                    >
                      Subtópico:
                      <br />
                      <Badge
                        bg={ticket.subtopic.color}
                        color="white"
                        fontSize="16px"
                        px="10px"
                        borderRadius="5px"
                        w="100%"
                      >
                        {ticket.subtopic.name}
                      </Badge>
                    </Text>
                  </Flex>
                  <Flex flexDirection="column">
                    <Box textAlign="left">
                      <Box>
                        <Flex gap={2} mb={8} flexDirection="row" w="100%">
                          <Flex
                            gap={2}
                            mb={8}
                            flexDirection="column"
                            py={4}
                            color="gray.700"
                            borderRadius="xl"
                            shadow="sm"
                          >
                            <Text
                              fontSize="sm"
                              _dark={{ color: "white" }}
                              fontWeight="normal"
                            >
                              {ticket.company.empr_fant}
                            </Text>
                            <Text
                              fontSize="sm"
                              _dark={{ color: "white" }}
                              fontWeight="normal"
                            >
                              {ticket.company.empr_cnpj}
                            </Text>
                            <Text
                              fontSize="sm"
                              _dark={{ color: "white" }}
                              fontWeight="normal"
                            >
                              {ticket.company.empr_emai_empr}
                            </Text>
                            <Text
                              fontSize="sm"
                              _dark={{ color: "white" }}
                              fontWeight="normal"
                            >
                              {ticket.company.empr_cida}{" "}
                              {ticket.company.empr_esta}
                            </Text>
                            <Text
                              fontSize="sm"
                              _dark={{ color: "white" }}
                              fontWeight="normal"
                            >
                              {ticket.company.empr_fone_empr}
                            </Text>
                          </Flex>
                          <Spacer />
                          <Flex gap={2} mb={8} flexDirection="column">
                            <Flex flexDirection="row" alignItems="center">
                              <Flex
                                bg="green.400"
                                color="white"
                                borderRadius="full"
                                gap={2}
                                flexDirection="row"
                                px={4}
                                py={2}
                                alignItems="center"
                                w="100%"
                              >
                                <AiFillCalendar />
                                <Text fontSize="sm" fontWeight="bold">
                                  Data abertura:
                                </Text>
                                <Text fontSize="sm" fontWeight="normal">
                                  {ticket.created_at}
                                </Text>
                              </Flex>
                            </Flex>
                            {ticket.date_last_message !== null && (
                              <Flex flexDirection="row" alignItems="center">
                                <Flex
                                  bg="teal.400"
                                  color="white"
                                  borderRadius="full"
                                  gap={2}
                                  flexDirection="row"
                                  px={4}
                                  py={2}
                                  alignItems="center"
                                  w="100%"
                                >
                                  <AiFillCalendar />
                                  <Text fontSize="sm" fontWeight="bold">
                                    Última mensagem:
                                  </Text>
                                  <Text fontSize="sm" fontWeight="normal">
                                    {ticket.date_last_message}
                                  </Text>
                                </Flex>
                              </Flex>
                            )}
                            {ticket.date_closed !== null && (
                              <Flex flexDirection="row" alignItems="center">
                                <Flex
                                  bg="teal.400"
                                  color="white"
                                  borderRadius="full"
                                  gap={2}
                                  flexDirection="row"
                                  px={4}
                                  py={2}
                                  alignItems="center"
                                  w="100%"
                                >
                                  <AiFillCalendar />
                                  <Text fontSize="sm" fontWeight="bold">
                                    Resolvido em:
                                  </Text>
                                  <Text fontSize="sm" fontWeight="normal">
                                    {ticket.date_closed}
                                  </Text>
                                </Flex>
                              </Flex>
                            )}
                            {ticket.competence !== "-" && (
                              <Flex flexDirection="row" alignItems="center">
                                <Flex
                                  bg="teal.400"
                                  color="white"
                                  borderRadius="full"
                                  gap={2}
                                  flexDirection="row"
                                  px={4}
                                  py={2}
                                  alignItems="center"
                                  w="100%"
                                >
                                  <AiFillCalendar />
                                  <Text fontSize="sm" fontWeight="bold">
                                    Competência:
                                  </Text>
                                  <Text fontSize="sm" fontWeight="normal">
                                    {ticket.competence}
                                  </Text>
                                </Flex>
                              </Flex>
                            )}
                            <Flex flexDirection="column">
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                bgColor={bgDateColor}
                                borderRadius="full"
                                px="2"
                                w="100%"
                              >
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  my={2}
                                  borderRadius="full"
                                  px="2"
                                  color={
                                    bgDateColor != "transparent"
                                      ? "white"
                                      : "red.600"
                                  }
                                >
                                  <IoTimer size={22} />
                                </Flex>
                                <Text
                                  fontSize="sm"
                                  color={
                                    bgDateColor != "transparent"
                                      ? "white"
                                      : "gray.600"
                                  }
                                  fontWeight="normal"
                                  borderRadius={5}
                                  py={2}
                                >
                                  Vencimento: {ticket.date_validity}
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Box>
                    </Box>
                    <Box>
                      <Flex>
                        {ticket.user_create && (
                          <Flex
                            flexDirection="row"
                            alignItems="center"
                            my={2}
                            background="gray.50"
                            _dark={{ background: "gray.600" }}
                            borderRadius="full"
                            px="2"
                          >
                            <Flex
                              flexDirection="row"
                              alignItems="center"
                              my={2}
                              background="gray.200"
                              _dark={{ background: "gray.600" }}
                              borderRadius="full"
                              p="2"
                              mr="4"
                            >
                              <IoCreateOutline size={22} />
                            </Flex>
                            <Avatar
                              name={ticket.user_create.name}
                              src={ticket.user_create.avatar}
                              size="sm"
                              me="12px"
                            />
                            {ticket.user_create.name}
                          </Flex>
                        )}
                        {ticket.solver && (
                          <Flex
                            flexDirection="row"
                            alignItems="center"
                            my={2}
                            background="green.50"
                            borderRadius="full"
                            px="2"
                          >
                            <Flex
                              flexDirection="row"
                              alignItems="center"
                              my={2}
                              background="green.200"
                              borderRadius="full"
                              p="2"
                              mr="4"
                            >
                              <MdSupportAgent size={22} />
                            </Flex>
                            <Avatar
                              name={ticket.solver.name}
                              src={ticket.solver.avatar}
                              size="sm"
                              me="12px"
                            />
                            {ticket.solver.name}
                          </Flex>
                        )}
                      </Flex>
                    </Box>
                    <VStack spacing={4} align="stretch">
                      {ticket.topic.comments !== null && (
                        <Box
                          background="gray.50"
                          borderRadius="xl"
                          p="5"
                          borderColor="gray.100"
                          boxShadow="md"
                        >
                          {ReactHtmlParser(ticket.topic.comments)}
                        </Box>
                      )}
                      {ticket.subtopic.comments !== null && (
                        <Box
                          background="gray.50"
                          borderRadius="xl"
                          p="5"
                          borderColor="gray.100"
                          boxShadow="md"
                        >
                          {ReactHtmlParser(ticket.subtopic.comments)}
                        </Box>
                      )}
                      {ticket.type.comments !== null && (
                        <Box
                          background="gray.50"
                          borderRadius="xl"
                          p="5"
                          borderColor="gray.100"
                          boxShadow="md"
                        >
                          {ReactHtmlParser(ticket.type.comments)}
                        </Box>
                      )}
                    </VStack>
                    <Box textAlign="left">
                      <Flex gap={2} my={8} flexDirection="column" w="100%">
                        <Text
                          fontSize="sm"
                          _dark={{ color: "white" }}
                          fontWeight="bold"
                        >
                          Assunto:
                        </Text>
                        {ticket.subject}
                      </Flex>
                      <Divider />
                      <Flex gap={2} mb={8} flexDirection="column" w="100%">
                        <Text
                          fontSize="sm"
                          _dark={{ color: "white" }}
                          fontWeight="bold"
                        >
                          Mensagem:
                        </Text>
                        <Box
                          bg="white"
                          _dark={{ bg: "white", borderColor: "gray.900" }}
                          w="100%"
                          p={4}
                          color="gray.700"
                          borderRadius="xl"
                        >
                          {ReactHtmlParser(ticket.content)}
                        </Box>
                      </Flex>
                      {ticket.attachments.lenght > 0 && (
                        <>
                          <Box
                            fontSize="sm"
                            fontWeight="bold"
                            _dark={{ color: "white" }}
                          >
                            Anexos
                          </Box>
                          <SimpleGrid columns={4} spacing={2} mb={10}>
                            {ticket.attachments.map((val, i) => (
                              <Link isExternal href={val.url} gap={2} mt="10px">
                                <Box
                                  boxShadow="xs"
                                  px="2"
                                  py="1"
                                  rounded="md"
                                  bg="white"
                                >
                                  <Flex
                                    gap={2}
                                    flexDirection="row"
                                    w="100%"
                                    alignItems="center"
                                  >
                                    {val.type.search("pdf") >= 0 && (
                                      <AiFillFilePdf color="#F56565" />
                                    )}
                                    {val.type.search("xls") >= 0 && (
                                      <AiFillFileExcel color="#48BB78" />
                                    )}
                                    {val.type.search("image") >= 0 && (
                                      <AiFillFileImage color="#9F7AEA" />
                                    )}
                                    {val.type.search("doc") >= 0 && (
                                      <AiFillFileWord color="#4299E1" />
                                    )}
                                    {(val.type.search("zip") >= 0 ||
                                      val.type.search("rar") >= 0) && (
                                      <AiOutlineFileZip color="#ECC94B" />
                                    )}
                                    {val.type.search("txt") >= 0 && (
                                      <AiTwotoneFileAdd color="#FEEBC8" />
                                    )}
                                    {val.name}
                                  </Flex>
                                </Box>
                              </Link>
                            ))}
                          </SimpleGrid>
                        </>
                      )}
                    </Box>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
            <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%" gap={4}>
              {ticket.status.block_edit === "1" ? (
                <Flex mt={4} gap={4}>
                  {user.permissions.includes("tickets-reopen") && (
                    <ReOpenTicket
                      idTicket={ticket.id}
                      setSaveData={setSaveData}
                      setTicket={setTicket}
                      setAttachments={setAttachments}
                    />
                  )}
                </Flex>
              ) : (
                <Flex mt={4} gap={4}>
                  {user.permissions.includes("tickets-take") && (
                    <TakeTicket
                      idTicket={ticket.id}
                      setSaveData={setSaveData}
                      setTicket={setTicket}
                      setAttachments={setAttachments}
                    />
                  )}
                  {user.permissions.includes("tickets-change_department") && (
                    <ChangeDepartment
                      idTicket={ticket.id}
                      setSaveData={setSaveData}
                      setTicket={setTicket}
                      setAttachments={setAttachments}
                      idDepartment={ticket.id_department}
                    />
                  )}
                  {user.permissions.includes("tickets-change-status") && (
                    <ChangeStatus
                      idTicket={ticket.id}
                      setSaveData={setSaveData}
                      setTicket={setTicket}
                      setAttachments={setAttachments}
                      idStatusTicket={ticket.id_status}
                    />
                  )}
                  {user.permissions.includes("tickets-change_solver") && (
                    <ChangeSolver
                      idTicket={ticket.id}
                      setSaveData={setSaveData}
                      setTicket={setTicket}
                      setAttachments={setAttachments}
                      idSolver={ticket.id_solver}
                    />
                  )}

                  {user.permissions.includes("tickets-message-create") && (
                    <ReplyTicket
                      type="N"
                      idDepartment={ticket.id_department}
                      idStatusTicket={ticket.id_status}
                      idTicket={ticket.id}
                      setSaveData={setSaveData}
                      setTicket={setTicket}
                      setAttachments={setAttachments}
                      permissionStatus={
                        user.permissions.includes("tickets-change-status")
                          ? true
                          : false
                      }
                      permissionDepartment={
                        user.permissions.includes("tickets-change-department")
                          ? true
                          : false
                      }
                    />
                  )}
                  {user.permissions.includes("tickets-message-create") && (
                    <ReplyTicket
                      messageId={false}
                      type="R"
                      idDepartment={ticket.id_department}
                      idStatusTicket={ticket.id_status}
                      idTicket={ticket.id}
                      setSaveData={setSaveData}
                      setTicket={setTicket}
                      setAttachments={setAttachments}
                      permissionStatus={
                        user.permissions.includes("tickets-change-status")
                          ? true
                          : false
                      }
                      permissionDepartment={
                        user.permissions.includes("tickets-change-department")
                          ? true
                          : false
                      }
                    />
                  )}
                </Flex>
              )}
              <Flex mt={4}>
                {user.permissions.includes("tickets-reopen") && (
                  <ReOpenTicket
                    idTicket={ticket.id}
                    setSaveData={setSaveData}
                    setTicket={setTicket}
                    setAttachments={setAttachments}
                  />
                )}
              </Flex>
            </Flex>
            <Flex mt={4} w="100%">
              <Tabs
                onChange={(tab) => {
                  if (tab == 1) {
                    loadHist();
                  } else if (tab == 2) {
                    loadViews();
                  }
                }}
                w="100%"
              >
                <TabList>
                  <Tab>Mensagens</Tab>
                  {user.type == "A" && (
                    <>
                      <Tab>Histórico</Tab>
                      <Tab>Visualizações</Tab>
                    </>
                  )}
                </TabList>
                <TabPanels px={0} border={1} background="white">
                  <TabPanel>
                    {ticket.messages.map((msg, i) => (
                      <Card
                        key={i}
                        p="1rem"
                        my={5}
                        shadow="md"
                        bg={msg.background}
                        _dark={{ bg: "gray.900" }}
                        rounded="none"
                      >
                        <CardHeader p="0 2px" rounded="none" mb="12px">
                          <Flex w="100%">
                            <Flex
                              px={4}
                              fontSize="lg"
                              fontWeight="bold"
                              bg="transparent"
                              _dark={{ color: "white" }}
                              alignItems="center"
                            >
                              {msg.type == "R" && msg.user.type == "A" && (
                                <FaReplyAll />
                              )}
                              {msg.type == "R" && msg.user.type != "A" && (
                                <FaReplyd />
                              )}
                              {msg.type == "N" && <BiNote />}
                            </Flex>
                            <Spacer />
                            <Flex flexDirection="column">
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                my={2}
                                background="transparent"
                                _dark={{ bg: "gray.700", color: "white" }}
                                borderRadius="full"
                                px="2"
                              >
                                <Avatar
                                  name={msg.user.name}
                                  src={msg.user.avatar_url}
                                  size="sm"
                                  me="12px"
                                />
                                {msg.user.name}
                              </Flex>
                              {user.type == "A" && msg.read_date && (
                                <Flex flexDirection="column">
                                  <Text fontSize="xs">
                                    Visualizado pelo cliente em:
                                  </Text>
                                  <Center gap={2}>
                                    <AiOutlineClockCircle size={16} />
                                    <Text fontSize="sm" direction="row">
                                      {msg.read_date}
                                    </Text>
                                  </Center>
                                </Flex>
                              )}
                              {user.type == "A" && !msg.read_date && (
                                <Flex flexDirection="column">
                                  <Text fontSize="xs">
                                    Não visualizado pelo cliente
                                  </Text>
                                </Flex>
                              )}
                            </Flex>
                          </Flex>
                        </CardHeader>
                        <CardBody p="0px 5px">
                          <Flex direction="column">
                            <Flex direction="column">
                              <Flex direction="column">{msg.data}</Flex>
                              {msg.external_ticket && (
                                <Flex direction="column">
                                  <Stack direction="row" py={4}>
                                    <Badge
                                      variant="outline"
                                      colorScheme="green"
                                      fontSize="md"
                                    >
                                      Protocolo externo: {msg.external_ticket}
                                    </Badge>
                                  </Stack>
                                </Flex>
                              )}
                              <Flex direction="column">
                                <Text
                                  fontSize="md"
                                  color="gray.500"
                                  fontWeight="400"
                                  mb="30px"
                                >
                                  {ReactHtmlParser(msg.contents)}
                                </Text>
                              </Flex>
                              {msg.attachments[0] && (
                                <>
                                  <Box
                                    fontSize="sm"
                                    fontWeight="bold"
                                    _dark={{ color: "white" }}
                                  >
                                    Anexos
                                  </Box>
                                  <SimpleGrid columns={4} spacing={2} mb={10}>
                                    {msg.attachments.map((val, i) => (
                                      <Link
                                        isExternal
                                        href={val.url}
                                        gap={2}
                                        mt="10px"
                                      >
                                        <Box
                                          boxShadow="xs"
                                          px="2"
                                          py="1"
                                          rounded="md"
                                          bg="white"
                                          _dark={{
                                            bg: "gray.700",
                                            color: "white",
                                          }}
                                        >
                                          <Flex
                                            gap={2}
                                            flexDirection="row"
                                            w="100%"
                                            alignItems="center"
                                          >
                                            {val.type.search("pdf") >= 0 && (
                                              <AiFillFilePdf color="#F56565" />
                                            )}
                                            {val.type.search("xls") >= 0 && (
                                              <AiFillFileExcel color="#48BB78" />
                                            )}
                                            {val.type.search("image") >= 0 && (
                                              <AiFillFileImage color="#9F7AEA" />
                                            )}
                                            {val.type.search("doc") >= 0 && (
                                              <AiFillFileWord color="#4299E1" />
                                            )}
                                            {(val.type.search("zip") >= 0 ||
                                              val.type.search("rar") >= 0) && (
                                              <AiOutlineFileZip color="#ECC94B" />
                                            )}
                                            {val.type.search("txt") >= 0 && (
                                              <AiTwotoneFileAdd color="#FEEBC8" />
                                            )}
                                            {val.name}
                                          </Flex>
                                        </Box>
                                      </Link>
                                    ))}
                                  </SimpleGrid>
                                </>
                              )}
                            </Flex>
                            {ticket.status.block_edit === "0" &&
                              user.permissions.includes(
                                "tickets-message-delete"
                              ) && (
                                <Flex>
                                  <Button
                                    onClick={() => deleteMsg(msg.id)}
                                    colorScheme="red"
                                    size="sm"
                                  >
                                    <BsFillTrashFill /> Apagar mensagem
                                  </Button>
                                </Flex>
                              )}
                          </Flex>
                        </CardBody>
                      </Card>
                    ))}
                  </TabPanel>
                  <TabPanel>
                    {historyData.data && (
                      <>
                        <HistTicket
                          historyData={historyData.data}
                          loading={tabLoadingHist}
                        />
                        <TablePagination
                          pages={pagesHist}
                          changePage={(e) => loadHist(e)}
                        />
                      </>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {viewsData.data && (
                      <>
                        <ViewsTicket
                          viewsData={viewsData.data}
                          loading={tabLoadingViews}
                        />
                        <TablePagination
                          pages={pagesViews}
                          changePage={(e) => loadViews(e)}
                        />
                      </>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
}

export default TicketsMsg;
