
import HomeClient from "./HomeClient";
import HomeAdmin from "./HomeAdmin";
import { useAuth } from "auth-context/auth.context";

export default function Home() {
  const { user, setUser } = useAuth();
  return (
    <>
    {user.type === "E" ? (<HomeClient />) : (<HomeAdmin />) }
    </>


  );
}
