import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import LoadingFullpage from 'components/Progress/LoadingFullpage'
import { Field, Form, Formik } from 'formik'
import { BiSave } from 'react-icons/bi'
import { MdOutlineCancel } from 'react-icons/md'
import CategoriesApi from '../../../../api/categories'

function CategoriesForm (props) {
  const { categoryId, setForm, loadData } = props
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [initialData, setInitialData] = useState({
    title: '',
    name: '',
    description: ''
  })

  useEffect(() => {
    if (categoryId !== false && !loading) {
      loadCategory()
    }
  }, [categoryId])

  const loadCategory = async () => {
    setLoading(true)
    try {
      if (categoryId) {
      }
      let response = await CategoriesApi.Show(categoryId)
      setLoading(false)
      if (response.data.error === true) {
        toast({
          title: 'Erro',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        return setForm(false)
      }

      return setInitialData({
        ...response.data.result,
        password_confirmation: '',
        password: ''
      })
    } catch (err) {
      setLoading(false)
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      return setForm(false)
    }
  }

  const handleSubmit = async values => {
    setLoading(true)
    try {
      let response = {}
      if (categoryId) {
        response = await CategoriesApi.Update(values, categoryId)
      } else {
        response = await CategoriesApi.Save(values)
      }

      setLoading(false)
      toast({
        title: (response.data.error)?'Erro':'Sucesso',
        description: response.data.message,
        status: (response.data.error)?'error':'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      loadData()
      return setForm(false)
    } catch (err) {
      setLoading(false)
      let erro = ''
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      return toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }


  return (
    <>
    {loading && <LoadingFullpage />}
    <Formik
      initialValues={initialData}
      enableReinitialize
      onSubmit={async values => {
        handleSubmit(values)
      }}
    >
      {props => (
        <Form>
          <Flex
            h="full"
            gap={2}
            mb={8}
            flexDirection={{ sm: 'column', lg: 'column' }}
            w='100%'
          >
            <Field name='name'>
              {({ field, form }) => (
                <FormControl isInvalid={errors.name}>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='name'
                  >
                    Nome
                  </FormLabel>
                  <Input size='sm' {...field} id='name' placeholder='Nome' />

                  {errors.name && (
                    <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>
            <Field name='title'>
              {({ field, form }) => (
                <FormControl isInvalid={errors.title}>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='title'
                  >
                    Título
                  </FormLabel>
                  <Input size='sm' {...field} id='title' placeholder='Título' />

                  {errors.title && (
                    <FormErrorMessage>{errors.title[0]}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>
            <Field name='description' >
              {({ field, form }) => (
                <FormControl isInvalid={errors.description}>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='description'
                  >
                    Descrição
                  </FormLabel>
                  <Textarea type='description' size='sm' {...field} id='description' />

                  {errors.description && (
                    <FormErrorMessage>{errors.description[0]}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>
            
            <Flex
              align='end'
              justify='end'
              gap={2}
              mt='10px'
              flexDirection={{ sm: 'column', lg: 'row' }}
            >
              <Button
                leftIcon={<MdOutlineCancel />}
                size='sm'
                colorScheme='gray' 
                cursor='pointer'
                type='button'
                
                w={{ sm: '100%', lg: 'auto' }}
                onClick={() => {
                  setForm(false)
                }}
              >
                Cancelar
              </Button>
              <Button
                leftIcon={<BiSave />}
                size='sm'
                                colorScheme='blue'
                cursor='pointer'
                type='submit'
                
                w={{ sm: '100%', lg: 'auto' }}
              >
                Salvar
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
    </>
  )
}

export default CategoriesForm
