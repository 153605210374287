import {
  Pagination,
  PaginationContainer,
  PaginationPage,
  PaginationPageGroup,
} from "@ajna/pagination";
import { Box, ChakraProvider, Flex, Show } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
function TablePagination(props) {
  const history = useHistory();
  const { pages, changePage } = props;
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (pages.current_page) {
      setCurrentPage(pages.current_page);
      setPagesCount(pages.last_page);
    }
  }, [pages]);

  return (
    <>
      {pages.last_page > 1 && (
        <>
        <Show above="lg">
          <Flex
            my={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ChakraProvider>
              <Pagination
                pagesCount={pagesCount}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              >
                <PaginationContainer>
                  <PaginationPageGroup>
                    {pages.links &&
                      pages.links.map((page, k) => (
                        <PaginationPage
                          onClick={() => {
                            if (page.label == "Anterior") {
                              let back = currentPage - 1;
                              if (back <= 1) {
                                changePage(1);
                              } else {
                                changePage(back);
                              }
                            } else if (page.label == "Próximo") {
                              let next = currentPage + 1;
                              if (next >= page.last_page) {
                                changePage(page.last_page);
                              } else {
                                changePage(next);
                              }
                            } else {
                              changePage(page.label);
                            }
                          }}
                          size="sm"
                          px={4}
                          py={2}
                          key={`pagination_page_${k}`}
                          page={page.label}
                          bg={page.active ? "gray.400" : "gray.50"}
                          _dark={{
                            bg: page.active ? "primary" : "gray.800",
                            color: page.active ? "gray.800" : "gray.500",
                          }}
                          color={page.active ? "white" : "gray.800"}
                        />
                      ))}
                  </PaginationPageGroup>
                </PaginationContainer>
              </Pagination>
            </ChakraProvider>
            <Box color="gray.400" fontWeight="bold" fontSize="sm">
              Total: {pages.total}
            </Box>
          </Flex>
          </Show>
          <Show below="lg">
          <Flex
            my={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <ChakraProvider>
              <Pagination
                pagesCount={pagesCount}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              >
                <PaginationContainer>
                  <PaginationPageGroup mx='auto' width='100%' justifyContent='space-between'>
                    {pages.links &&
                      pages.links.map((page, k) => (
                        <PaginationPage
                          onClick={() => {
                            if (page.label == "Anterior") {
                              let back = currentPage - 1;
                              if (back <= 1) {
                                changePage(1);
                              } else {
                                changePage(back);
                              }
                            } else if (page.label == "Próximo") {
                              let next = currentPage + 1;
                              if (next >= page.last_page) {
                                changePage(page.last_page);
                              } else {
                                changePage(next);
                              }
                            } else {
                              changePage(page.label);
                            }
                          }}
                          size="md"
                          py={2}
                          key={`pagination_page_${k}`}
                          page={page.label}
                          display={(page.label === 'Anterior' || page.label === 'Próximo') ? 'block' : 'none'}
                          bg='gray.100'
                          _dark={{
                            bg: page.active ? "primary" : "gray.800",
                            color: page.active ? "gray.800" : "gray.500",
                          }}
                          color={page.active ? "white" : "gray.800"}
                        />
                      ))}
                  </PaginationPageGroup>
                </PaginationContainer>
              </Pagination>
            </ChakraProvider>
            <Box color="gray.400" fontWeight="bold" fontSize="sm" textAlign='center' mt='-30px'>
              Total: {pages.total}
            </Box>
          </Flex>
          </Show>
        </>
      )}
    </>
  );
}

export default TablePagination;
