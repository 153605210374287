// Chakra Icons
import { FaLightbulb } from "react-icons/fa";
// Chakra Imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Text,
  useColorMode,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor
} from "@chakra-ui/react";
// Assets
import { ChevronDownIcon } from "@chakra-ui/icons";
import LogoWhite from "assets/img/logo-white.png";
import Logo from "assets/img/logo.png";
import { useAuth } from "auth-context/auth.context";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import routes from "routes";
import UsersApi from "../../api/user";
import UserCard from './UserCard';
import { BsMoonStarsFill, BsSun } from 'react-icons/bs'

export default function HeaderLinks(props) {
  const { user, setUser } = useAuth();
  //console.log(user);
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // Chakra Color Mode
  let mainTeal = useColorModeValue("primary", "primary");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");


  const changeColorMode = async () => {
    try {
      setLoading(true);
      let color = "";
      if (colorMode == "dark") {
        color = "light";
      } else {
        color = "dark";
      }
      await UsersApi.Color({
        id: user.id,
        color: color,
      });
      toggleColorMode();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Flex
      pe={{ sm: "0px", lg: "16px" }}
      w={{ sm: "100%", lg: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      {/* <InputGroup
        cursor='pointer'
        bg={inputBg}
        borderRadius='15px'
        w={{
          sm: '128px',
          md: '200px'
        }}
        me={{ sm: 'auto', md: '20px' }}
        _focus={{
          borderColor: { mainTeal }
        }}
        _active={{
          borderColor: { mainTeal }
        }}
      >
        <InputLeftElement
          children={
            <IconButton
              bg='inherit'
              borderRadius='inherit'
              _hover='none'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: 'none'
              }}
              icon={<SearchIcon color={searchIcon} w='15px' h='15px' />}
            ></IconButton>
          }
        />
        <Input
          fontSize='xs'
          py='11px'
          color={mainText}
          placeholder='Pesquisar...'
          borderRadius='inherit'
        />
      </InputGroup> */}
      <SidebarResponsive
        key="side1"
        // logoText={props.logoText}
        // secondary={props.secondary}
        routes={routes}
        logo={colorMode === "dark" ? LogoWhite : Logo}
        user={user}
        {...rest}
      />
      <Show below="lg">
        <Flex mx={4}>
          {colorMode == "dark" ? (
            <Image src={LogoWhite} alt="CONT LOGO" h={12} />
          ) : (
            <Image src={Logo} alt="CONT LOGO" h={12} />
          )}
        </Flex>
      </Show>
      <Flex mx={4}>
        <Button
          aria-label="Alterar tema"
          onClick={changeColorMode}
          isLoading={loading}
          _focus={{ boxShadow: 'none' }}
          w="fit-content"
          {...props}>
          {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
        </Button>
      </Flex>

      {user && (
        <Flex
          flex="1"
          alignItems="center"
          flexWrap="wrap"
          justifyContent={{ base: "flex-start", sm: "flex-end" }}
        >
          <Box d={{ base: "block", sm: "none" }}>
            <Heading size="sm">{user.name}</Heading>
            <Text>
              {user.type !== "A" &&
                user.company !== null &&
                user.company.empr_nome}
              {user.type == "A" && "Administrador"}
            </Text>
          </Box>
          <Popover placement='bottom-end' border='none'>
            <PopoverTrigger>
              <Flex flexDirection={"row"} gap={2}>
                <Show above="lg">
                  <Flex flexDirection={"column"} textAlign={"left"} py={1}>
                    <Heading size="sm">{user.name}</Heading>
                    <Text>
                      {user.type !== "A" &&
                        user.company !== null &&
                        user.company.empr_nome}
                      {user.type == "A" && "Administrador"}
                    </Text>
                  </Flex>
                </Show>
                <Show above="lg">
                  <Avatar name={user.name} src={user.avatar_url} />
                </Show>
                <Show below="lg">
                  <Avatar name={user.name} src={user.avatar_url} size="sm" />
                </Show>
              </Flex>
            </PopoverTrigger>
            <PopoverContent bg='transparent' border='none' _focus={{ border: 'none', outline: 0 }} _active={{ border: 'none', outline: 0 }}>
              <PopoverBody>
                <UserCard />
              </PopoverBody>
            </PopoverContent>
          </Popover>

        </Flex>
      )}
      {/* <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w='18px' h='18px' />
        </MenuButton>
        <MenuList p='16px 8px'>
          <Flex flexDirection='column'>
            <MenuItem borderRadius='8px' mb='10px'>
              <ItemContent
                time='13 minutes ago'
                info='from Alicia'
                boldInfo='New Message'
                aName='Alicia'
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem borderRadius='8px' mb='10px'>
              <ItemContent
                time='2 days ago'
                info='by Josh Henry'
                boldInfo='New Album'
                aName='Josh Henry'
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem borderRadius='8px'>
              <ItemContent
                time='3 days ago'
                info='Payment succesfully completed!'
                boldInfo=''
                aName='Kara'
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
