import axios from './index'

class CitiesApi {

  static All = (uf) => {
    return axios.get(`admin/list-cities/${uf}`)
  }

  static Search = (search) => {
    return axios.get(`admin/list-cities?search=${search}`)
  }

  static Ufs = () => {
    return axios.get(`admin/list-ufs`)
  }

  static List = data => {
    let search = new URLSearchParams(data);
    return axios.get(`${base}?${search}`)
  }

  static Show = id => {
    return axios.get(`${base}/${id}`, )
  }

  static Update = (data, id) => {
    return axios.put(`${base}/${id}`, data)
  }

  
}
let base = 'admin/cities'
export default CitiesApi
