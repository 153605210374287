import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import AllApi from "../../../../api/all";
import TicketDefault from "../../../../api/ticketDefault";

function DefaultForm(props) {
  const { defaultId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [initialData, setInitialData] = useState({
    name: "",
    id_sla: "",
    id_priority: "",
    id_type: "",
    id_subtopic: "",
    id_status: "",
    id_topic: "",
    id_department: "",
    subject: "",
  });
  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    if (defaultId !== false && !loading) {
      loadDefault();
    }
  }, [defaultId]);

  const loadDefault = async () => {
    setLoading(true);
    try {
      let response = await TicketDefault.Show(defaultId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }

      return setInitialData({
        ...response.data.result,
        password_confirmation: "",
        password: "",
      });
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };
  const loadOptions = async () => {
    try {
      let response = await AllApi.allOptions();
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setOptions(false);
      }

      return setOptions(response.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setOptions({});
    }
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = {};
      if (defaultId) {
        response = await TicketDefault.Update(values, defaultId);
      } else {
        response = await TicketDefault.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "column" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="subject">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.subject}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="subject"
                    >
                      Assunto
                    </FormLabel>
                    <Input type="text" size="sm" {...field} id="subject" />

                    {errors.subject && (
                      <FormErrorMessage>{errors.subject[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_department">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_department}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_department"
                    >
                      Departamento
                    </FormLabel>
                    <Select size="sm" {...field} id="id_department">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {options.departments &&
                        options.departments.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_department && (
                      <FormErrorMessage>
                        {errors.id_department[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_sla">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_sla}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_sla"
                    >
                      Sla
                    </FormLabel>
                    <Select size="sm" {...field} id="id_sla">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {options.slas &&
                        options.slas.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_sla && (
                      <FormErrorMessage>{errors.id_sla[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_priority">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_priority}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_priority"
                    >
                      Prioridade
                    </FormLabel>
                    <Select size="sm" {...field} id="id_priority">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {options.priorities &&
                        options.priorities.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_priority && (
                      <FormErrorMessage>
                        {errors.id_priority[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_type">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_type}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_type"
                    >
                      Tipo
                    </FormLabel>
                    <Select size="sm" {...field} id="id_type">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {options.types &&
                        options.types.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_type && (
                      <FormErrorMessage>{errors.id_type[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_topic">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_topic}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_topic"
                    >
                      Tópico
                    </FormLabel>
                    <Select size="sm" {...field} id="id_topic">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {options.topics &&
                        options.topics.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_topic && (
                      <FormErrorMessage>{errors.id_topic[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_subtopic">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_subtopic}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_subtopic"
                    >
                      Subtópico
                    </FormLabel>
                    <Select size="sm" {...field} id="id_subtopic">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {options.subtopics &&
                        options.subtopics.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_subtopic && (
                      <FormErrorMessage>
                        {errors.id_subtopic[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_status">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_status}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_status"
                    >
                      Status
                    </FormLabel>
                    <Select size="sm" {...field} id="id_status">
                      <option value="" disabled>
                        Selecione
                      </option>
                      {options.status &&
                        options.status.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_status && (
                      <FormErrorMessage>{errors.id_status[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"
                  
                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"
                  
                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default DefaultForm;
