import {
  Badge,
  Button,
  Center,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'

import { AiFillEdit } from 'react-icons/ai'

function ExpedientTablesTableRow (props) {
  const {
    id,
    day,
    status,
    start,
    end,
    selectRow, deleteRow 
  } = props

  const textColor = useColorModeValue('gray.700', 'white')

  return (
    <Tr key={id}>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {day}
        </Text>
      </Td>
      <Td px={2} p={0}>
        <Badge
          bg={status ? 'green.400' : 'red.400'}
          color='white'
          fontSize='11px'
          p='3px 10px'
          borderRadius='8px'
        >
          {status && 'ATIVO'}
          {!status && 'INATIVO'}
        </Badge>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {start}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {end}
        </Text>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
                cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  )
}

export default ExpedientTablesTableRow
