import axios from './index'

class ImagesApi {

  static Base = (data) => {
    return axios.post(`${base}/base`, data)
  }

}

let base = 'admin/images'

export default ImagesApi
