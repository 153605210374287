import React, { useState } from "react";
import { Button, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";

import { Formik, Field, Form } from "formik";

import { AiOutlineSearch } from "react-icons/ai";
import { BiEraser } from "react-icons/bi";

function ClientsSearch(props) {
  const { user, loadData, searchParams, loading } = props;
  const [initialValue, setInitialValue] = useState({
    name: searchParams["filter[name]"] ? searchParams["filter[name]"] : "",
    cpf_cnpj: searchParams["filter[cpf_cnpj]"]
      ? searchParams["filter[cpf_cnpj]"]
      : "",
    empr_nome: searchParams["filter[companies.empr_nome]"]
      ? searchParams["filter[companies.empr_nome]"]
      : "",
  });

  const search = (params) => {
    params.page = 1;
    loadData(params);
  };
  const clear = () => {
    loadData({ page: 1 });
  };

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        let params = {};
        for (var key in values) {
          if (key == "empr_nome") {
            params[`filter[company.empr_nome]`] = values["empr_nome"];
          } else {
            params[`filter[${key}]`] = values[key];
          }
        }
        search(params);
      }}
    >
      {(props) => (
        <Form>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: "column", lg: "row" }}
            w="100%"
          >
            {user.type === "A" && (
              <Field name="empr_nome">
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="empr_nome"
                    >
                      Empresa
                    </FormLabel>
                    <Input
                      
                      size="sm"
                      {...field}
                      id="empr_nome"
                      placeholder="Empresa"
                    />
                  </FormControl>
                )}
              </Field>
            )}

            <Field name="name">
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="name"
                  >
                    Nome
                  </FormLabel>
                  <Input
                    
                    size="sm"
                    {...field}
                    id="name"
                    placeholder="Nome"
                  />
                </FormControl>
              )}
            </Field>
            <Field name="cpf_cnpj">
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="cpf_cnpj"
                  >
                    CPF/CNPJ
                  </FormLabel>
                  <Input
                    size="sm"
                    {...field}
                    id="cpf_cnpj"
                    placeholder="CPF/CNPJ"
                  />
                </FormControl>
              )}
            </Field>
            <Flex align="end" justify="center">
              <Button
                w={{ sm: "50%", lg: "auto" }}
                size="sm"
                p="0px"
                me="4px"
                colorScheme="green"
                cursor="pointer"
                
                type="submit"
              >
                <AiOutlineSearch />
              </Button>
              <Button
                w={{ sm: "50%", lg: "auto" }}
                size="sm"
                p="0px"
                me="4px"
                colorScheme="blue"
                cursor="pointer"
                type="button"
                
                onClick={() => {
                  props.setFieldValue("name", "");
                  clear();
                }}
              >
                <BiEraser />
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default ClientsSearch;
