import Axios from 'axios'
import { API_SERVER } from '../config/constant'
const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  // headers: {
  //   'Content-Type': 'application/json',
  //   // "Access-Control-Allow-Origin": "*"
  //   // Authorization: `Bearer ${token}`
  // }
})
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token");
    if(token !== null){
      config.headers.authorization = `Bearer ${token}`;
    }
    return Promise.resolve(config)
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return Promise.resolve(response)
  },
  error => {
    if(error.response.status === 403 ){
      window.location.href = '/admin/403'
    }else if(error.response.status === 401 && error.response.data.refreshed_token){
      let user = localStorage.getItem("user");
      user.token = error.response.data.refreshed_token
      let userJson = JSON.stringify(user)
      localStorage.setItem('user', userJson)
      window.location.href = '/admin/home'
    }else if(error.response.status === 401){
      localStorage.removeItem('user')
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

export default axios
