// import
import Dashboard from 'views/Dashboard/Dashboard'
import Home from 'views/Dashboard/Home'
import Page403 from 'views/Dashboard/Page403'
import Page404 from 'views/Dashboard/Page404'
import Users from 'views/Dashboard/Users/UsersList'
import HolidaysList from 'views/Dashboard/Configs/Holidays/HolidaysList'
import ApiConfigList from 'views/Dashboard/Configs/ApiConfig/ApiConfigList'
import WhatsappList from 'views/Dashboard/Configs/Whatsapp/WhatsappList'
import ExpedientList from 'views/Dashboard/Configs/Expedient/ExpedientList'
import MailList from 'views/Dashboard/Configs/Email/MailList'
import EmailsList from 'views/Dashboard/Tickets/Emails/EmailsList'
import DefaultList from 'views/Dashboard/Configs/Default/DefaultList'
import RecurrentProfileList from 'views/Dashboard/Configs/RecurrentProfile/RecurrentProfileList'
import RecurrentList from 'views/Dashboard/Configs/Recurrent/RecurrentList'
import TicketsList from 'views/Dashboard/Tickets/Tickets/TicketsList'
import TicketsMsg from 'views/Dashboard/Tickets/Tickets/TicketsMsg'
import TypesList from 'views/Dashboard/Tickets/Type/TypesList'
import StatusList from 'views/Dashboard/Tickets/Status/StatusList'
import TopicList from 'views/Dashboard/Tickets/Topic/TopicList'
import SubTopicList from 'views/Dashboard/Tickets/SubTopic/SubTopicList'
import DepartmentList from 'views/Dashboard/Tickets/Department/DepartmentList'
import SlasList from 'views/Dashboard/Tickets/Sla/SlasList'
import PriorityList from 'views/Dashboard/Tickets/Priority/PriorityList'
import RolesList from 'views/Dashboard/Roles/RolesList'
import SignIn from 'views/Pages/SignIn'
import ForgetPassword from 'views/Pages/ForgetPassword'
import ResetPassword from 'views/Pages/ResetPassword'
import SignOut from 'views/Pages/SignOut'
import Register from 'views/Pages/Register'
import Profile from 'views/Dashboard/Profile'
import CategoriesList from 'views/Dashboard/Configs/Categories/CategoriesList'
import CategoriesOptionsList from 'views/Dashboard/Configs/CategoriesOptions/CategoriesOptionsList'
import CompaniesList from 'views/Dashboard/Companies/CompaniesList'
import ClientsList from 'views/Dashboard/Clients/ClientsList'
import ContentsList from 'views/Dashboard/Contents/ContentsList'
import SuppliersList from 'views/Dashboard/Suppliers/SuppliersList'
import NfList from 'views/Dashboard/Nf/NfList'
import CompaniesServicesList from 'views/Dashboard/Services/ServicesList'
import CompaniesBanksList from 'views/Dashboard/Banks/BanksList'
import AccountsPayable from 'views/Dashboard/AccountsPayable/AccountsPayableList'
import AccountsReceivables from 'views/Dashboard/AccountsReceivables/AccountsReceivablesList'
import PaymentLink from 'views/Dashboard/PaymentLink/PaymentLinkList'


import { HomeIcon, DocumentIcon, RocketIcon } from 'components/Icons/Icons'

import { BiBookContent, BiCategoryAlt, BiRepeat } from 'react-icons/bi'
import { BsFillBoxSeamFill, BsFillCalendarCheckFill, BsFillStickiesFill } from 'react-icons/bs'
import { MdAddBusiness, MdBusinessCenter, MdOutlineAttachMoney, MdOutlineOpenInNew } from 'react-icons/md'
import { FiArchive } from 'react-icons/fi'
import { AiOutlineIdcard, AiOutlineProfile, AiFillApi,AiOutlineFieldTime, AiOutlineStar, AiOutlineMail, AiOutlineClockCircle, AiFillFolderOpen, AiFillBank, AiOutlineWhatsApp } from 'react-icons/ai'
import { MdBusiness, MdPriorityHigh, MdAttachEmail, MdPayment } from 'react-icons/md'
import { ImMap, ImUsers } from 'react-icons/im'
import { FaFileInvoice, FaMoneyBillWaveAlt } from 'react-icons/fa'
import { VscGroupByRefType, VscTypeHierarchy, VscTypeHierarchySub } from 'react-icons/vsc'
import { GrMoney } from 'react-icons/gr'
import { IoBusiness } from 'react-icons/io5'
import { SiContentful } from 'react-icons/si'
import CitiesList from 'views/Dashboard/Configs/Cities/CitiesList'

let dashRoutes = [
  {
    path: '/profile',
    name: 'Perfil',
    icon: <DocumentIcon color='inherit' />,
    component: Profile,
    layout: '/admin',
    hide: true
  },
  {
    path: '/home',
    name: 'Home',
    icon: <HomeIcon color='inherit' />,
    component: Home,
    layout: '/admin'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/tickets',
    name: 'Protocolos',
    icon: <BsFillStickiesFill color='inherit' />,
    component: TicketsList,
    layout: '/admin'
  },
  {
    path: '/ticket/:id',
    name: 'Protocolo',
    icon: <HomeIcon color='inherit' />,
    component: TicketsMsg,
    layout: '/admin',
    hide: true
  },
  {
    path: '/403',
    name: '403',
    icon: <HomeIcon color='inherit' />,
    component: Page403,
    layout: '/admin',
    hide: true
  },
  {
    name: 'NOTAS FISCAIS',
    category: 'account',
    state: 'pageCollapse',
    permission: ['companies-client-list','companies-nf-list','companies-services-list'],
    views: [
      {
        path: '/nf/clients',
        name: 'Clientes',
        icon: <MdBusinessCenter color='inherit' />,
        secondaryNavbar: true,
        component: ClientsList,
        layout: '/admin',
        permission: 'companies-client-list'
      },
      
      {
        path: '/nf/list',
        name: 'Notas fiscais',
        icon: <FaFileInvoice color='inherit' />,
        secondaryNavbar: true,
        component: NfList,
        layout: '/admin',
        permission: 'companies-client-list'
      },
      {
        path: '/nf/services',
        name: 'Serviços',
        icon: <BsFillBoxSeamFill color='inherit' />,
        secondaryNavbar: true,
        component: CompaniesServicesList,
        layout: '/admin',
        permission: 'companies-services-list'
      }
    ]
  },
  {
    name: 'CONTAS',
    category: 'account',
    state: 'pageCollapse',
    permission: ['companies-banks-list','companies-suppliers-list','companies-accounts-payables-list','companies-accounts-receivables-list'],
    views: [
      {
        path: '/payment/link',
        name: 'Link de pagamento',
        icon: <MdPayment color='inherit' />,
        secondaryNavbar: true,
        component: PaymentLink,
        layout: '/admin',
        permission: 'companies-payment-links-list'
      },
      {
        path: '/accounts/receivables',
        name: 'Contas à receber',
        icon: <MdOutlineAttachMoney color='inherit' />,
        secondaryNavbar: true,
        component: AccountsReceivables,
        layout: '/admin',
        permission: 'companies-accounts-receivables-list'
      },
      {
        path: '/accounts/payable',
        name: 'Contas à pagar',
        icon: <FaMoneyBillWaveAlt color='inherit' />,
        secondaryNavbar: true,
        component: AccountsPayable,
        layout: '/admin',
        permission: 'companies-accounts-payables-list'
      },
      {
        path: '/accounts/suppliers',
        name: 'Fornecedores',
        icon: <MdAddBusiness color='inherit' />,
        secondaryNavbar: true,
        component: SuppliersList,
        layout: '/admin',
        permission: 'companies-suppliers-list'
      },
      {
        path: '/accounts/banks',
        name: 'Bancos',
        icon: <AiFillBank color='inherit' />,
        secondaryNavbar: true,
        component: CompaniesBanksList,
        layout: '/admin',
        permission: 'companies-banks-list'
      }
    ]
  },
  {
    name: 'CONFIG. CLIENTE',
    category: 'account',
    state: 'pageCollapse',
    permission: ['form-categories-list','form-list','companies-list','config-recurrent-profile-list','config-ticket-recurrent-list'],
    views: [
      {
        path: '/config/companies',
        name: 'Empresas',
        icon: <IoBusiness color='inherit' />,
        secondaryNavbar: true,
        component: CompaniesList,
        layout: '/admin',
        permission: 'companies-list'
      },
      {
        path: '/config/forms/categories',
        name: 'Categoria protocolos',
        icon: <BiCategoryAlt color='inherit' />,
        secondaryNavbar: true,
        component: CategoriesList,
        layout: '/admin',
        permission: 'form-categories-list'
      },
      {
        path: '/config/forms/options',
        name: 'Opções protocolos',
        icon: <AiFillFolderOpen color='inherit' />,
        secondaryNavbar: true,
        component: CategoriesOptionsList,
        layout: '/admin',
        permission: 'form-list'
      },
      {
        path: '/config/forms/profile-recurrent',
        name: 'Perfil de obrigações',
        icon: <AiOutlineProfile color='inherit' />,
        secondaryNavbar: true,
        component: RecurrentProfileList,
        layout: '/admin',
        permission: 'config-recurrent-profile-list'
      },
      {
        path: '/config/forms/tickets-recurrent',
        name: 'Obrigações',
        icon: <BiRepeat color='inherit' />,
        secondaryNavbar: true,
        component: RecurrentList,
        layout: '/admin',
        permission: 'config-ticket-recurrent-list'
      },
      {
        path: '/contents',
        name: 'Conteúdos',
        icon: <BiBookContent color='inherit' />,
        secondaryNavbar: true,
        component: ContentsList,
        layout: '/admin',
        permission: 'contents-list'
      },
    ]
  },
  {
    name: 'PROTOCOLOS CONFIG.',
    category: 'account',
    state: 'pageCollapse',
    permission: ['type-list','teste-list'],
    views: [
      {
        path: '/tickets-defaults',
        name: 'Padrões de abertura',
        icon: <MdOutlineOpenInNew color='inherit' />,
        secondaryNavbar: true,
        component: DefaultList,
        layout: '/admin',
        permission: 'type-list'
      },
      {
        path: '/departments',
        name: 'Departamentos',
        icon: <FiArchive color='inherit' />,
        secondaryNavbar: true,
        component: DepartmentList,
        layout: '/admin',
        permission: 'config-ticket-default-list'
      },
      {
        path: '/types',
        name: 'Tipos de protocolo',
        icon: <VscGroupByRefType color='inherit' />,
        secondaryNavbar: true,
        component: TypesList,
        layout: '/admin',
        permission: 'type-list'
      },
      {
        path: '/priorities',
        name: 'Prioridades',
        icon: <MdPriorityHigh color='inherit' />,
        secondaryNavbar: true,
        component: PriorityList,
        layout: '/admin',
        permission: 'type-list'
      },
      {
        path: '/slas',
        name: 'Slas',
        icon: <AiOutlineFieldTime color='inherit' />,
        secondaryNavbar: true,
        component: SlasList,
        layout: '/admin',
        permission: 'type-list'
      },
      {
        path: '/status',
        name: 'Status',
        icon: <AiOutlineStar color='inherit' />,
        secondaryNavbar: true,
        component: StatusList,
        layout: '/admin',
        permission: 'status-list'
      },
      {
        path: '/topics',
        name: 'Tópicos',
        icon: <VscTypeHierarchySub color='inherit' />,
        secondaryNavbar: true,
        component: TopicList,
        layout: '/admin',
        permission: 'topic-list'
      },
      {
        path: '/subtopics',
        name: 'Sub tópicos',
        icon: <VscTypeHierarchy color='inherit' />,
        secondaryNavbar: true,
        component: SubTopicList,
        layout: '/admin',
        permission: 'subtopic-list'
      },
      {
        path: '/emails',
        name: 'Emails',
        icon: <AiOutlineMail color='inherit' />,
        secondaryNavbar: true,
        component: EmailsList,
        layout: '/admin',
        permission: 'email-list'
      },
    ]
  },
  {
    name: 'CONFIGURAÇÕES',
    category: 'account',
    state: 'pageCollapse',
    permission: ['config-expedient-list', 'config-email-server-list','role-list','user-list', 'config-holiday-list', 'config-api-list'],
    views: [
      {
        path: '/config/cities',
        name: 'Cidades',
        icon: <ImMap color='inherit' />,
        secondaryNavbar: true,
        component: CitiesList,
        layout: '/admin',
        permission: 'cities-list'
      },
      {
        path: '/config/email',
        name: 'Servidor de email',
        icon: <MdAttachEmail color='inherit' />,
        secondaryNavbar: true,
        component: MailList,
        layout: '/admin',
        permission: 'config-email-server-list'
      },
      {
        path: '/config/api',
        name: 'Apis',
        icon: <AiFillApi color='inherit' />,
        secondaryNavbar: true,
        component: ApiConfigList,
        layout: '/admin',
        permission: 'config-api-list'
      },
      {
        path: '/config/whatsapp',
        name: 'Whatsapp',
        icon: <AiOutlineWhatsApp color='inherit' />,
        secondaryNavbar: true,
        component: WhatsappList,
        layout: '/admin',
        permission: 'config-whatsapp-list'
      },
      {
        path: '/holidays',
        name: 'Feriados',
        icon: <BsFillCalendarCheckFill color='inherit' />,
        secondaryNavbar: true,
        component: HolidaysList,
        layout: '/admin',
        permission: 'config-holiday-list'
      },
      {
        path: '/expedient',
        name: 'Expediente',
        icon: <AiOutlineClockCircle color='inherit' />,
        secondaryNavbar: true,
        component: ExpedientList,
        layout: '/admin',
        permission: 'config-expedient-list'
      },
      {
        path: '/users',
        name: 'Usuários',
        icon: <ImUsers color='inherit' />,
        secondaryNavbar: true,
        component: Users,
        layout: '/admin',
        permission: 'user-list'
      },
      {
        path: '/roles',
        name: 'Grupos de acesso',
        icon: <ImUsers color='inherit' />,
        secondaryNavbar: true,
        component: RolesList,
        layout: '/admin',
        permission: 'role-list'
      }
    ]
  },
  {
    path: '/signin',
    name: 'Sign In',
    icon: <DocumentIcon color='inherit' />,
    component: SignIn,
    layout: '/auth',
    hide: true
  },
  {
    path: '/forget-password',
    name: 'Forget password',
    icon: <DocumentIcon color='inherit' />,
    component: ForgetPassword,
    layout: '/auth',
    hide: true
  },
  {
    path: '/reset-password/:token/:email',
    name: 'Reset password',
    icon: <DocumentIcon color='inherit' />,
    component: ResetPassword,
    layout: '/auth',
    hide: true
  },
  {
    path: '/register',
    name: 'Solicitar cadastro',
    icon: <DocumentIcon color='inherit' />,
    component: Register,
    layout: '/auth',
    hide: true
  },
  {
    path: '/404',
    name: '404',
    exact: true,
    icon: <HomeIcon color='inherit' />,
    component: Page404,
    layout: '/admin',
    hide: true
  },
  
]
export default dashRoutes
