import axios from './index'

class UsersApi {
  static List = data => {
    let search = new URLSearchParams(data);
    return axios.get(`${base}?${search}`)
  }

  static Show = id => {
    return axios.get(`${base}/${id}`, )
  }

  static Save = (data) => {
    return axios.post(`${base}`, data)
  }

  static Update = (data, id) => {
    return axios.post(`${base}/${id}`, data)
  }

  static Delete = id => {
    return axios.delete(`${base}/${id}`)
  }

  static Solver = () => {
    return axios.get(`admin/list-solver`)
  }

  static Color = (data) => {
    return axios.post(`${base}/change-color-mode`, data)
  }

  static UpdateProfile = (data) => {
    return axios.post(`admin/update-profile`, data)
  }

}

let base = 'admin/users'

export default UsersApi
