import {
  Button,
  Flex, FormControl, FormErrorMessage, FormLabel, Input,
  Select,
  Text,
  useToast,
  useColorMode
} from "@chakra-ui/react";
import { useState } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave, BiTrash } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import UsersApi from "../../../../api/user";
import { useAuth } from '../../../../auth-context/auth.context';

function UsersForm(props) {
  const { colorMode, toggleColorMode } = useColorMode()
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useAuth()
  const toast = useToast();
  const [initialData, setInitialData] = useState({
    name: user.name,
    email: user.email,
    password: "",
    password_confirmation: "",
    theme: user.theme,
    avatar: null,
  });

  const handleSubmit = async (values) => {
    setLoading(true)
    let formData = new FormData();
    for (let value in values) {
      if (value != "avatar") {
        formData.append(value, values[value]);
      }
    }

    formData.append("avatar", values.avatar);
    try {
      let response = {};
      response = await UsersApi.UpdateProfile(formData);

      setLoading(false);
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });


      let userJson = JSON.stringify({ ...response.data.user, token: user.token })
      setUser({ ...response.data.user, token: user.token })
      if (colorMode !== response.data.user.theme) {
        toggleColorMode()
      }

      localStorage.setItem('user', userJson)
      setErrors({})
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };


  return (
    <Card p='16px'>
      <CardHeader>
        <Text fontSize='lg' fontWeight='bold'>
          Alterar dados
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column' w='100%'>
          {loading && <LoadingFullpage />}
          <Formik
            initialValues={initialData}
            enableReinitialize
            onSubmit={async (values) => {
              handleSubmit(values);
            }}

          >
            {(props) => (
              <Form>
                <Flex
                  gap={2}
                  mb={8}
                  flexDirection='column'
                >
                  <Field name="name">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.name}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="name"
                        >
                          Nome
                        </FormLabel>
                        <Input size="sm" {...field} id="name" placeholder="Nome" />

                        {errors.names && (
                          <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.email}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="email"
                        >
                          E-mail
                        </FormLabel>
                        <Input
                          size="sm"
                          {...field}
                          id="email"
                          placeholder="E-mail"
                        />

                        {errors.email && (
                          <FormErrorMessage>{errors.email[0]}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Flex
                    align="center"
                    justify="start"
                    gap={2}
                    flexDirection={{ sm: "column", lg: "row" }}
                  >
                    <Field name="password">
                      {({ field, form }) => (
                        <FormControl isInvalid={errors.password}>
                          <FormLabel
                            _dark={{
                              color: "gray.500",
                            }}
                            fontSize="sm"
                            fontWeight="bold"
                            colorScheme="gray"
                            htmlFor="password"
                          >
                            Senha
                          </FormLabel>
                          <Input

                            type="password"
                            size="sm"
                            {...field}
                            id="password"
                            placeholder="Senha"
                          />

                          {errors.password && (
                            <FormErrorMessage>
                              {errors.password[0]}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </Field>
                    <Field name="password_confirmation">
                      {({ field, form }) => (
                        <FormControl isInvalid={errors.password_confirmation}>
                          <FormLabel
                            _dark={{
                              color: "gray.500",
                            }}
                            fontSize="sm"
                            fontWeight="bold"
                            colorScheme="gray"
                            htmlFor="password_confirmation"
                          >
                            Confirmar senha
                          </FormLabel>
                          <Input

                            type="password"
                            size="sm"
                            {...field}
                            id="password_confirmation"
                            placeholder="Confirmar senha"
                          />

                          {errors.password_confirmation && (
                            <FormErrorMessage>
                              {errors.password_confirmation[0]}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Flex
                    align="center"
                    justify="start"
                    gap={2}
                    flexDirection={{ sm: "column", lg: "row" }}
                  >
                    <Field name="theme">
                      {({ field, form }) => (
                        <FormControl isInvalid={errors.theme}>
                          <FormLabel
                            _dark={{
                              color: "gray.500",
                            }}
                            fontSize="sm"
                            fontWeight="bold"
                            colorScheme="gray"
                            htmlFor="theme"
                          >
                            Tema da administração
                          </FormLabel>
                          <Select
                            size="sm"
                            {...field}
                            id="theme"
                            placeholder="Tema"
                          >
                            <option value="light">Claro</option>
                            <option value="dark">Escuro</option>
                          </Select>
                          {errors.theme && (
                            <FormErrorMessage>{errors.theme[0]}</FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Field name="avatar">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.avatar}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="avatar"
                        >
                          Imagem
                        </FormLabel>
                        <Button as="label" htmlFor="avatar" mt={2}>
                          Selecionar imagem
                        </Button>
                        <Text mt={2}>
                          {props.values.avatar && (
                            <>
                              {props.values.avatar.name}
                              <Button variant="link" color="red.500" onClick={() =>
                                props.setFieldValue(
                                  "avatar",
                                  null
                                )}>
                                <BiTrash />
                              </Button>
                            </>
                          )}
                        </Text>
                        <Input
                          id="avatar"
                          name="avatar"
                          type="file"
                          display="none"
                          onChange={(event) => {
                            //console.log(event.target.files[0])
                            props.setFieldValue(
                              "avatar",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        {errors.avatar && (
                          <FormErrorMessage>{errors.avatar[0]}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>

                  <Flex
                    align="end"
                    justify="end"
                    gap={2}
                    mt="10px"
                    flexDirection={{ sm: "column", lg: "row" }}
                  >
                    <Button
                      leftIcon={<BiSave />}
                      size="sm"
                      colorScheme="blue"
                      cursor="pointer"
                      type="submit"

                      w={{ sm: "100%", lg: "auto" }}
                    >
                      Salvar
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </CardBody>
    </Card>
  );
}

export default UsersForm;
