import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BiNote } from 'react-icons/bi'
import { BsFillTrashFill } from 'react-icons/bs'
import { FaReplyAll } from 'react-icons/fa'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import DepartmentsApi from '../../../../api/department'
import StatusApi from '../../../../api/status'
import TicketsApi from '../../../../api/ticket'
import EditorToolbar, {
  formats,
  modules
} from '../../../../components/Inputs/EditorToolbar'
function replyTicket(props) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    idTicket,
    setSaveData,
    setTicket,
    idDepartment,
    type,
    idStatusTicket,
    messageId,
    permissionStatus,
    permissionDepartment
  } = props
  const [departments, setDepartments] = useState([{}])
  const [department, setDepartment] = useState(idDepartment)
  const [status, setStatus] = useState([{}])
  const [idStatus, setIdStatus] = useState(idStatusTicket)
  const [attachments, setAttachments] = useState([])
  const [reply, setReply] = useState('')
  const [externalTicket, setExternalTicket] = useState('')
  const [errors, setErrors] = useState({});

  useEffect(() => {
    loadDepartments()
    loadStatus()
  }, [])

  const save = async () => {
    setSaveData(true)
    try {
      let formData = new FormData()
      formData.append('type', type)
      formData.append('contents', reply)
      formData.append('id_ticket', idTicket)
      formData.append('id_department', department)
      formData.append('id_status', idStatus)
      formData.append('external_ticket', externalTicket)
      for (const element of attachments) {
        formData.append('attachment[]', element)
      }
      let response = {}
      if (messageId) {
        formData.append('_method', 'PUT')
        response = await TicketsApi.msgUpdate(formData, messageId)
      } else {
        response = await TicketsApi.msgSave(formData)
      }

      setTicket(response.data.ticket)
      setReply('')
      setExternalTicket('')
      setExternalTicket('')
      setIdStatus('')
      setDepartment('')
      setAttachments([])
      setSaveData(false)
      onClose()
      toast({
        title: 'Sucesso',
        description: response.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    } catch (err) {
      console.log(err.response.data.errors)
      setSaveData(false)
      let erro = ''
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      return toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  const loadStatus = async () => {
    try {
      let result = await StatusApi.All()
      return setStatus(result.data)
    } catch (err) {
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  const loadDepartments = async () => {
    try {
      let result = await DepartmentsApi.All()
      return setDepartments(result.data)
    } catch (err) {
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  const onChangeReply = reply => {
    let _reply = null
    if (reply.indexOf('<p><br></p>') > -1) {
      _reply = reply.replace(/<p><br><\/p>/gi, '<p>&nbsp;</p>')
    }
    setReply(_reply || reply)
  }

  return (
    <Box>
      {type == 'R' ? (
        <Button onClick={onOpen} colorScheme='green' size='sm'>
          <FaReplyAll /> Responder
        </Button>
      ) : (
        <Button onClick={onOpen} colorScheme='blue' size='sm'>
          <BiNote /> Adicionar nota
        </Button>
      )}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size='xl'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {type == 'R' ? 'Responder' : 'Adicionar nota'}
          </ModalHeader>
          <ModalBody pb={6}>
            <Flex flexDirection='column' mb={8}>
              {errors.contents && (
                <Alert status='error'>
                  <AlertIcon />
                  {errors.contents[0]}
                </Alert>
              )}
              <Flex
                gap={2}
                mb={8}
                flexDirection={{ sm: 'column', lg: 'row' }}
                w='100%'
              >

                <Flex my='20px'>
                  <FormControl background='white'>
                    <Box bg="white" color='black'>
                      <EditorToolbar />
                      <ReactQuill
                        id='snow'
                        theme='snow'
                        value={reply}
                        onChange={onChangeReply}
                        placeholder={'Responder o protocolo...'}
                        modules={modules}
                        formats={formats}
                      />
                    </Box>

                  </FormControl>
                </Flex>

              </Flex>
              <Flex>
                <FormLabel
                  fontSize="sm"
                  fontWeight="bold"
                  htmlFor="attachment"
                  display="block"
                  cursor="pointer"
                  py={1}
                  borderRadius="full"
                  textAlign="left"
                  w={200}
                >
                  <Flex 
                      style={{ /*display: "none" zIndex: 9999*/ }}
                      background="gray.200"
                      borderRadius={10}
                      padding={2}
                      textAlign={"center"}
                      >Incluir anexos</Flex>
                </FormLabel>
                <input
                  style={{ display: 'none' }}
                  id='attachment'
                  name='attachment'
                  type='file'
                  multiple
                  onChange={event => {
                    setAttachments(event.currentTarget.files)
                    ////console.log()
                  }}
                  className='form-control'
                />
              </Flex>
              <Flex flexDirection='column'>
                {Object.keys(attachments).map((key, index) => (
                  <Flex flexDirection='row' alignItems='center' my='1'>
                    <BsFillTrashFill
                      cursor='pointer'
                      onClick={() => {
                        let fileListArr = Array.from(attachments)
                        fileListArr.splice(key, 1)
                        setAttachments(fileListArr)
                      }}
                    />
                    {attachments[key].name}
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Divider />
            <Flex flexDirection='column' gap={4} my={8}>
              <Flex>
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='external_ticket'
                  >
                    Protocolo externo
                  </FormLabel>
                  <Input
                    fontSize='sm'
                    fontWeight='bold'
                    value={externalTicket}
                    onChange={e => {
                      setExternalTicket(e.target.value)
                    }}
                    rounded='md'
                    id='external_ticket'
                  />
                </FormControl>
              </Flex>
              {permissionStatus && (
                <Flex>
                  <FormControl>
                    <FormLabel
                      fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='status'
                    >
                      Status
                    </FormLabel>
                    <Select

                      id='status'
                      placeholder='Selecione o status'
                      onChange={e => {
                        setIdStatus(e.target.value)
                      }}
                    >
                      {status &&
                        status.map((val, i) => {
                          if (idStatusTicket != val.id && val.status) {
                            return (
                              <option key={`dpto${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            )
                          }
                        })}
                    </Select>
                  </FormControl>
                </Flex>
              )}
              {permissionDepartment && (
                <Flex>
                  <FormControl>
                    <FormLabel
                      fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='departamento'
                    >
                      Departamento
                    </FormLabel>
                    <Select

                      id='departamento'
                      placeholder='Selecione o departamento'
                      onChange={e => {
                        setDepartment(e.target.value)
                      }}
                    >
                      {departments &&
                        departments.map((val, i) => {
                          if (idDepartment != val.id && val.status) {
                            return (
                              <option key={`dpto${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            )
                          }
                        })}
                    </Select>
                  </FormControl>
                </Flex>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button size='sm' colorScheme='green' mr={3} onClick={save}>
              Salvar
            </Button>
            <Button size='sm' colorScheme='gray' onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default replyTicket
