import {
  Badge,
  Button,
  Center,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'

import { AiFillEdit, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { GrConnect } from 'react-icons/gr'

function CitiesTablesTableRow (props) {
  const {
    id,
    name,
    uf,
    status_plugnotas,
    envia_codigo_tributacao,
    selectRow, deleteRow 
  } = props

  const textColor = useColorModeValue('gray.700', 'white')

  return (
    <Tr key={id}>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {name}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {uf}
        </Text>
      </Td>
      <Td px={2}>
        {status_plugnotas ? (
            <Tooltip label="Cidade homologada com o Plugnotas">
              <Button
                size="sm"
                p="0px"
                me="4px"
                bg="green.400"
                color="white"
                cursor="pointer"
                _hover={{ bg: 'green.400' }}
              >
                <AiOutlineCheck color="white" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Cidade não homologada com o Plugnotas">
              <Button
                size="sm"
                p="0px"
                me="4px"
                bg="red.400"
                color="white"
                cursor="pointer"
                _hover={{ bg: 'red.400' }}
              >
                <GrConnect color="white" />
              </Button>
            </Tooltip>
          )}
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {envia_codigo_tributacao ? (
            <Tooltip label="Envia código de tributação">
              <Button
                size="sm"
                p="0px"
                me="4px"
                bg="transparent"
                color="white"
                cursor="pointer"
                _hover={{ bg: 'transparent' }}
              >
                <AiOutlineCheck color="green" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Não envia código de tributação">
              <Button
                size="sm"
                p="0px"
                me="4px"
                bg="transparent"
                color="white"
                cursor="pointer"
                _hover={{ bg: 'transparent' }}
              >
                <AiOutlineClose color="red" />
              </Button>
            </Tooltip>
          )}
        </Text>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
                cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  )
}

export default CitiesTablesTableRow
