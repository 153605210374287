import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cryptoJs from "crypto-js";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ token, children }) => {
  const [user, setUser] = React.useState([]);

  useEffect(() => {
    if (user.length === 0) {
      const userData = decryptData();
      if (userData === null) {
        if (window.location.href.includes("admin")) {
          window.location.href = "/";
        }
      } else {
        setUser(userData);
      }
    }
  }, [user]);

  const encryptData = (data) => {
    const userCrypt = cryptoJs.AES.encrypt(
      JSON.stringify(data),
      "secret_key"
    ).toString();
    console.log("userCrypt", userCrypt);
    localStorage.setItem("user", userCrypt);
  };

  const decryptData = () => {
    const userCrypt = localStorage.getItem("user");
    if (userCrypt) {
      const decryptedBytes = cryptoJs.AES.decrypt(userCrypt, "secret_key");
      const decryptedData = decryptedBytes.toString(cryptoJs.enc.Utf8);
      return JSON.parse(decryptedData);
    }
    return null;
  };

  return (
    <AuthContext.Provider value={{ user, setUser, encryptData }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  userData: PropTypes.any,
  children: PropTypes.any,
};

export const useAuth = () => React.useContext(AuthContext);
