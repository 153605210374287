import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Tooltip,
  Center,
  useColorModeValue,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import {
  AiFillEdit,
  AiOutlineCheck,
  AiFillSafetyCertificate,
} from "react-icons/ai";
import CompaniesApi from "../../../api/company";
import { GrConnect } from "react-icons/gr";
import { BsFillHddNetworkFill } from "react-icons/bs";
import { MdAccountBalance, MdProductionQuantityLimits } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";

function CompaniesTablesTableRow(props) {
  const {
    id,
    empr_nome,
    empr_cnpj,
    certificate_url,
    status_plugnotas,
    certificate_required,
    webhook,
    selectRow,
    deleteRow,
  } = props;

  const textColor = useColorModeValue("gray.700", "white");
  const [loadingService, setLoadingService] = useState(false);
  const [loadingBanks, setLoadingBanks] = useState(false);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const toast = useToast();

  const syncServices = async (id) => {
    setLoadingService(true);
    try {
      let response = await CompaniesApi.SyncServices(id);
      setLoadingService(false);
      if (response.data && response.data.success === false) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return true;
    } catch (err) {
      setLoadingService(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const SyncAccount = async (id) => {
    setLoadingAccount(true);
    try {
      let response = await CompaniesApi.SyncAccountPlans(id);
      setLoadingAccount(false);
      if (response.data && response.data.success === false) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return true;
    } catch (err) {
      setLoadingAccount(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const SyncBanks = async (id) => {
    setLoadingBanks(true);
    try {
      let response = await CompaniesApi.SyncBanks(id);
      setLoadingBanks(false);
      if (response.data && response.data.success === false) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return true;
    } catch (err) {
      setLoadingBanks(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Tr key={id}>
      <Td px={2} minWidth={{ sm: "250px" }} >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={textColor} minWidth="100%">
            {empr_nome}
          </Text>
        </Flex>
      </Td>
      <Td px={2} minWidth={{ sm: "250px" }} >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={textColor} minWidth="100%">
            {empr_cnpj}
          </Text>
        </Flex>
      </Td>
      <Td px={2}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {status_plugnotas === "1" ? (
            <Tooltip label="Empresa comunicada com o Plugnotas">
              <Button
                size="sm"
                p="0px"
                me="4px"
                bg="green.400"
                color="white"
                cursor="pointer"
              >
                <AiOutlineCheck color="white" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Empresa não comunicada com o Plugnotas">
              <Button
                size="sm"
                p="0px"
                me="4px"
                bg="red.400"
                color="white"
                cursor="pointer"
              >
                <GrConnect color="white" />
              </Button>
            </Tooltip>
          )}
          {certificate_url === "" && certificate_required === "1" && (
            <Tooltip label="Certificado é obrigatório para este munícipio">
              <Button
                size="sm"
                p="0px"
                me="4px"
                bg="red.400"
                color="white"
                cursor="pointer"
              >
                <AiFillSafetyCertificate color="white" />
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Td>
      <Td px={2}>
        <Center w="100%">
          <Tooltip label="Editar">
            <Button
              size="sm"
              p="0px"
              me="4px"
              colorScheme="blue"
              cursor="pointer"
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>

          <Tooltip label="Sincronizar Serviços">
            <Button
              size="sm"
              p="0px"
              me="4px"
              colorScheme="blue"
              cursor="pointer"
              disabled={loadingService}
              onClick={() => syncServices(id)}
            >
              {loadingService ? <Spinner size="xs" /> : <MdProductionQuantityLimits />}
            </Button>
          </Tooltip>

          <Tooltip label="Sincronizar Bancos">
            <Button
              size="sm"
              p="0px"
              me="4px"
              colorScheme="cyan"
              cursor="pointer"
              disabled={loadingBanks}
              onClick={() => SyncBanks(id)}
            >
              {loadingBanks ? <Spinner size="xs" /> : <MdAccountBalance />}
            </Button>
          </Tooltip>

          <Tooltip label="Sincronizar plano de contas">
            <Button
              size="sm"
              p="0px"
              me="4px"
              colorScheme="teal"
              cursor="pointer"
              disabled={loadingAccount}
              onClick={() => SyncAccount(id)}
            >
              {loadingAccount ? <Spinner size="xs" /> : <RiMoneyDollarCircleLine />}
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  );
}

export default CompaniesTablesTableRow;
