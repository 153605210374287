import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ColorPicker from "../../../../components/Inputs/ColorPicker";
import EditorToolbar, {
  formats,
  modules
} from "../../../../components/Inputs/EditorToolbar";

import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import SubTopicsApi from "../../../../api/subtopics";
import TopicsApi from "../../../../api/topics";
import TypesApi from "../../../../api/types";

function SubTopicForm(props) {
  const { subtopicId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([{}]);
  const [types, setTypes] = useState([{}]);
  const toast = useToast();
  const [content, setContent] = useState("");
  const [initialData, setInitialData] = useState({
    name: "",
    id_type: "",
    id_topics: [],
    color: "",
    comments: "",
    status: "1",
  });
  useEffect(() => {
    loadTopics();
    loadTypes();
    if (subtopicId !== false && !loading) {
      loadDataItem();
    }
  }, [subtopicId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await SubTopicsApi.Show(subtopicId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }

      setContent(response.data.result.comments);
      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.comments = content;
      let response = {};
      if (subtopicId) {
        response = await SubTopicsApi.Update(values, subtopicId);
      } else {
        response = await SubTopicsApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadTopics = async () => {
    try {
      let result = await TopicsApi.All();
      ////console.log(result)
      return setTopics(result.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadTypes = async () => {
    try {
      let result = await TypesApi.All();
      return setTypes(result.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const onChangeContents = (contents) => {
    let _contents = null;
    if (contents.indexOf("<p><br></p>") > -1) {
      _contents = contents.replace(/<p><br><\/p>/gi, "<p>&nbsp;</p>");
    }
    setContent(_contents || contents);
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_type">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_type}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_type"
                    >
                      Tipo
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_type"
                      placeholder="Selecione"
                    >
                      {types.map((val, i) => (
                        <option key={`type${val.id}`} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                    </Select>

                    {errors.id_type && (
                      <FormErrorMessage>{errors.id_type[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="status">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.status}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="status"
                    >
                      Status
                    </FormLabel>
                    <Select size="sm" {...field} id="status">
                      <option value="1" key={1}>
                        Ativo
                      </option>
                      <option value="0" key={0}>
                        Inativo
                      </option>
                    </Select>

                    {errors.status && (
                      <FormErrorMessage>{errors.status[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex my="20px">
              <FormControl>
                <FormLabel
                  fontSize="sm"
                  fontWeight="bold"
                  colorScheme="gray"
                  htmlFor="comments"
                >
                  Observações
                </FormLabel>
                <Box bg="white" color='black'>
                  <EditorToolbar />
                  <ReactQuill
                    id="snow"
                    theme="snow"
                    value={content}
                    onChange={onChangeContents}
                    placeholder={
                      "Insira um conteúdo para mostrar como observações deste subtópico..."
                    }
                    modules={modules}
                    formats={formats}
                  />
                </Box>
              </FormControl>
            </Flex>
            <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%">
              <ColorPicker
                label=""
                onChange={(color) => {
                  props.setFieldValue("color", color);
                }}
              />
            </Flex>
            <Flex flexDirection={{ sm: "column", lg: "row" }} mt="10px">
              <Badge
                backgroundColor={props.values.color}
                color={props.values.color != "" ? "#ffffff" : "#333333"}
                fontSize="md"
                fontWeight="extrabold"
                borderRadius={6}
                py={1}
                px={4}
              >
                {props.values.name}
              </Badge>
            </Flex>

            <Flex flexDirection={{ sm: "column" }} mt="10px">
              <Box fontSize="sm" fontWeight="bold" colorScheme="gray">
                Tópicos
              </Box>
              <SimpleGrid columns={4} spacing={10}>
                {topics.map((val, i) => {
                  return (
                    <Box p="2">
                      <Field
                        name="id_topics"
                        type="checkbox"
                        value={`${val.id}`}
                        key={val.id}
                      >
                        {({ field, form }) => (
                          <Checkbox
                            {...field}
                            colorScheme="gray"
                            isChecked={field.checked}
                          >
                            {val.name}
                          </Checkbox>
                        )}
                      </Field>
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Flex>
            <Flex
              align="end"
              justify="end"
              gap={2}
              mt="10px"
              flexDirection={{ sm: "column", lg: "row" }}
            >
              <Button
                leftIcon={<MdOutlineCancel />}
                size="sm"
                colorScheme="gray"
                cursor="pointer"
                type="button"
                w={{ sm: "100%", lg: "auto" }}
                onClick={() => {
                  setForm(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                leftIcon={<BiSave />}
                size="sm"
                colorScheme="blue"
                
                cursor="pointer"
                type="submit"
                
                w={{ sm: "100%", lg: "auto" }}
              >
                Salvar
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SubTopicForm;
