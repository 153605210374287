import {
  Avatar, Box, Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input,
  Select, SimpleGrid, Table, TableContainer, Tbody, Td,
  Text, Tfoot, Th, Thead, Tr, useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import AutocompleteInput from "components/Inputs/AutocompleteInput";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave, BiTrash } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import CompaniesApi from "../../../api/company";
import DepartmentsApi from "../../../api/department";
import RolesApi from "../../../api/roles";
import UsersApi from "../../../api/user";
import Actions from "./Actions";
import { useAuth } from "auth-context/auth.context";

function UsersForm(props) {
  const { userId, setForm, loadData } = props;
  const { user, setUser } = useAuth();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([{}]);
  const [companies, setCompanies] = useState([{}]);
  const [companiesAll, setCompaniesAll] = useState([{}]);
  const [companiesTable, setCompaniesTable] = useState([]);
  const [departments, setDepartments] = useState([{}]);
  const toast = useToast();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [initialData, setInitialData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    id_permissions: "",
    id_company: "",
    status: "1",
    type: "A",
    theme: "light",
    avatar: null,
    id_companies: [],
    id_departments: [],
  });
  useEffect(() => {
    loadRoles();
    loadDepartments();
    console.log(user);
    if(user.type == 'A'){
      getCompaniesAll();
    }
  }, []);

  useEffect(() => {
    if (userId !== false && !loading) {
      loadUser();
    }
  }, [userId]);

  const loadUser = async () => {
    setLoading(true);
    try {
      if (userId) {
      }
      let response = await UsersApi.Show(userId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      setCompaniesTable(response.data.result.companies);
      return setInitialData({
        ...response.data.result,
        password_confirmation: "",
        password: "",
      });
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true)
    let formData = new FormData();
    for (let value in values) {
      if (value != "avatar") {
        if (value == "id_permissions" || value == "id_departments") {
          for (var i = 0; i < values[value].length; i++) {
            formData.append(value + "[]", values[value][i]);
          }
        } else if (value == "id_companies") {
          for (var i = 0; i < companiesTable.length; i++) {
            formData.append(value + "[]", companiesTable[i].id);
          }
        } else {
          formData.append(value, values[value]);
        }
      }
    }

    formData.append("avatar", values.avatar);
    try {
      let response = {};
      if (userId) {
        formData.append("_method", "PUT");
        response = await UsersApi.Update(formData, userId);
      } else {
        response = await UsersApi.Save(formData);
      }

      setLoading(false);
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadRoles = async () => {
    try {
      let perms = await RolesApi.Roles();
      return setRoles(perms.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadDepartments = async () => {
    try {
      let perms = await DepartmentsApi.All();
      return setDepartments(perms.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        return {
          id: value.id,
          name: value.empr_nome,
          empr_cnpj: value.empr_cnpj,
          empr_nome: value.empr_nome,
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  const getCompaniesAll = async () => {
    let response = await CompaniesApi.All();
    let companies = response.data.map((value, index) => {
      return {
          id: value.id,
          name: value.empr_nome,
          empr_cnpj: value.empr_cnpj,
          empr_nome: value.empr_nome,
      };
    });
    setCompaniesAll(companies);
  };

  const removeCompany = async (index) => {
    setCompaniesTable(companiesTable.filter((o, i) => index !== i));
  };

  const selectCompany = async (item) => {
    ////console.log(item);
    let newI = companiesTable.filter((o, i) => o.id === item.id);
    if (newI.length === 0) {
      setCompaniesTable((oldArray) => [
        ...oldArray,
        { ...item, status: false },
      ]);
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}

      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "column" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.email}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="email"
                    >
                      E-mail
                    </FormLabel>
                    <Input
                      size="sm"
                      {...field}
                      id="email"
                      placeholder="E-mail"
                    />

                    {errors.email && (
                      <FormErrorMessage>{errors.email[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Flex
                align="center"
                justify="start"
                gap={2}
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.password}>
                      <FormLabel
                        _dark={{
                          color: "gray.500",
                        }}
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="password"
                      >
                        Senha
                      </FormLabel>
                      <Input

                        type="password"
                        size="sm"
                        {...field}
                        id="password"
                        placeholder="Senha"
                      />

                      {errors.password && (
                        <FormErrorMessage>
                          {errors.password[0]}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="password_confirmation">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.password_confirmation}>
                      <FormLabel
                        _dark={{
                          color: "gray.500",
                        }}
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="password_confirmation"
                      >
                        Confirmar senha
                      </FormLabel>
                      <Input

                        type="password"
                        size="sm"
                        {...field}
                        id="password_confirmation"
                        placeholder="Confirmar senha"
                      />

                      {errors.password_confirmation && (
                        <FormErrorMessage>
                          {errors.password_confirmation[0]}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Flex
                align="center"
                justify="start"
                gap={2}
                flexDirection={{ sm: "column", lg: "row" }}
              >
                {props.values.type != "A" && (
                  <Field name="id_company">
                    {({ field, form }) => (
                      <FormControl isInvalid={errors.id_company}>
                        <FormLabel
                          _dark={{
                            color: "gray.500",
                          }}
                          fontSize="sm"
                          fontWeight="bold"
                          colorScheme="gray"
                          htmlFor="id_company"
                        >
                          Empresa
                        </FormLabel>
                        <Select
                          _dark={{
                            backgroundColor: "gray.800",
                          }}
                          size="sm"
                          {...field}
                          id="id_company"
                          placeholder="Empresa"
                        >
                          {companiesAll.map((val, i) => (
                            <option key={`company${val.id}`} value={val.id}>
                              {val.empr_cnpj} - {val.empr_nome}
                            </option>
                          ))}
                        </Select>

                        {errors.id_company && (
                          <FormErrorMessage>
                            {errors.id_company[0]}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                )}
                <Field name="status">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.status}>
                      <FormLabel
                        _dark={{
                          color: "gray.500",
                        }}
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="status"
                      >
                        Status
                      </FormLabel>
                      <Select

                        size="sm"
                        {...field}
                        id="status"
                        placeholder="Status"
                      >
                        <option value="1" key={1}>
                          Ativo
                        </option>
                        <option value="0" key={0}>
                          Inativo
                        </option>
                      </Select>

                      {errors.status && (
                        <FormErrorMessage>{errors.status[0]}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="type">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.type}>
                      <FormLabel
                        _dark={{
                          color: "gray.500",
                        }}
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="type"
                      >
                        Tipo
                      </FormLabel>
                      <Select size="sm" {...field} id="type" placeholder="Tipo">
                        <option value="A" key={0}>
                          Admin
                        </option>
                        <option value="E" key={1}>
                          Cliente
                        </option>
                        <option value="F" key={2}>
                          Funcionário cliente
                        </option>
                      </Select>

                      {errors.type && (
                        <FormErrorMessage>{errors.type[0]}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="theme">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.theme}>
                      <FormLabel
                        _dark={{
                          color: "gray.500",
                        }}
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="theme"
                      >
                        Tema da administração
                      </FormLabel>
                      <Select
                        size="sm"
                        {...field}
                        id="theme"
                        placeholder="Tema"
                      >
                        <option value="light">Claro</option>
                        <option value="dark">Escuro</option>
                      </Select>
                      {errors.theme && (
                        <FormErrorMessage>{errors.theme[0]}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Field name="avatar">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.avatar}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="avatar"
                    >
                      Imagem
                    </FormLabel>
                    <Avatar
                      name={props.values.name}
                      src={props.values.avatar_url}
                      size="2xl"
                      borderRadius="12px"
                      me="18px"
                    />

                    <Button as="label" htmlFor="avatar" mt={2}>
                      Selecionar arquivo
                    </Button>
                    <Text mt={2}>
                      {props.values.avatar && (
                        <>
                          {props.values.avatar.name}
                          <Button variant="link" color="red.500" onClick={() =>                      
                          props.setFieldValue(
                            "avatar",
                            null
                          )}>
                            <BiTrash />
                          </Button>
                        </>
                      )}
                    </Text>
                    <Input
                      id="avatar"
                      name="avatar"
                      type="file"
                      display="none"
                      onChange={(event) => {
                        //console.log(event.target.files[0])
                        props.setFieldValue(
                          "avatar",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    {errors.avatar && (
                      <FormErrorMessage>{errors.avatar[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              {props.values.type == "A" && (
                <>
                  <Flex
                    align="left"
                    justify="start"
                    flexDirection={{ sm: "column" }}
                  >
                    <Box
                      borderWidth="1px"
                      borderRadius="md"
                      bg="gray.50"
                      px={4}
                      py={6}
                      my={2}
                      _dark={{
                        bg: "gray.800",
                        borderColor: "gray.700",
                      }}
                    >
                      <Text fontWeight="bold" colorScheme="gray">
                        Permissões
                      </Text>
                      <SimpleGrid columns={4} spacing={2}>
                        {roles.map((val, i) => {
                          return (
                            <Box p="2">
                              <Field
                                name="id_permissions"
                                type="checkbox"
                                value={`${val.id}`}
                                key={val.id}
                              >
                                {({ field, form }) => (
                                  <Checkbox
                                    {...field}
                                    colorScheme="gray"
                                    isChecked={field.checked}
                                  >
                                    {val.name}
                                  </Checkbox>
                                )}
                              </Field>
                            </Box>
                          );
                        })}
                      </SimpleGrid>
                    </Box>
                    <Box
                      borderWidth="1px"
                      borderRadius="md"
                      bg="gray.50"
                      px={4}
                      py={6}
                      my={2}
                      _dark={{
                        bg: "gray.800",
                        borderColor: "gray.700",
                      }}
                    >
                      <Text fontWeight="bold" colorScheme="gray">
                        Departamentos
                      </Text>
                      <SimpleGrid columns={4} spacing={2}>
                        {departments.map((val, i) => {
                          return (
                            <Box p="2">
                              <Field
                                name="id_departments"
                                type="checkbox"
                                value={`${val.id}`}
                                key={val.id}
                              >
                                {({ field, form }) => (
                                  <Checkbox
                                    {...field}
                                    colorScheme="gray"
                                    isChecked={field.checked}
                                  >
                                    {val.name}
                                  </Checkbox>
                                )}
                              </Field>
                            </Box>
                          );
                        })}
                      </SimpleGrid>
                    </Box>
                  </Flex>

                  <Flex h="full" gap={2} mb={8} flexDirection="column">
                    <Box
                      borderWidth="1px"
                      borderRadius="md"
                      bg="gray.50"
                      px={4}
                      py={6}
                      my={2}
                      _dark={{
                        bg: "gray.800",
                        borderColor: "gray.700",
                      }}
                    >
                      <Flex
                        h="full"
                        gap={2}
                        mb={8}
                        flexDirection={{ sm: "column", lg: "row" }}
                      >
                        <FormControl isInvalid={errors.status}>
                          <FormLabel
                            fontSize="sm"
                            fontWeight="bold"
                            colorScheme="gray"
                            htmlFor="status"
                          >
                            Empresas
                          </FormLabel>
                          <AutocompleteInput
                            loading={loadingCompanies}
                            options={companies}
                            setOptions={setCompanies}
                            requests={getCompanies}
                            placeholder="Pesquisar empresas"
                            onClickFunction={selectCompany}
                            empty={true}
                          />
                        </FormControl>
                      </Flex>
                      <TableContainer size="sm" w="full" fontSize="sm">
                        <Table variant="striped" colorScheme="gray">
                          <Thead>
                            <Tr>
                              <Th px={0}>Nome</Th>
                              <Th px={0}>CNPJ</Th>
                              <Th px={0} w="60px"></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {companiesTable.map((row, i) => {
                              return (
                                <Tr key={i}>
                                  <Td px={2}>{row.empr_nome}</Td>
                                  <Td px={2}>{row.empr_cnpj}</Td>
                                  <Td px={2}>
                                    <Button
                                      mx={2}
                                      size="sm"
                                      colorScheme="red"
                                      cursor="pointer"
                                      type="button"
                                      onClick={() => removeCompany(i)}
                                    >
                                      <MdOutlineCancel />
                                    </Button>
                                  </Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                          <Tfoot>
                            <Tr>
                              <Th px={0}>Nome</Th>
                              <Th px={0}>CNPJ</Th>
                              <Th px={0}></Th>
                            </Tr>
                          </Tfoot>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Flex>
                </>
              )}
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"
                  
                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"
                  
                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UsersForm;
