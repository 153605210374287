import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave, BiTrash } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import ReactQuill from "react-quill";
import ContentsApi from "../../../api/content";
import EditorToolbar, {
  formats,
  modules,
} from "../../../components/Inputs/EditorToolbar";

function ContentsForm(props) {
  const { contentId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([{}]);
  const [companies, setCompanies] = useState([{}]);
  const [companiesTable, setCompaniesTable] = useState([]);
  const [departments, setDepartments] = useState([{}]);
  const toast = useToast();
  const [content, setContent] = useState("");
  const [initialData, setInitialData] = useState({
    status: "1",
    name: "",
    title: "",
    category: "",
    short_content: "",
    content: "",
    type: "E",
  });

  useEffect(() => {
    if (contentId !== false && !loading) {
      loadContent();
    }
  }, [contentId]);

  const loadContent = async () => {
    setLoading(true);
    try {
      if (contentId) {
      }
      let response = await ContentsApi.Show(contentId);
      setContent(response.data.result.content);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      setCompaniesTable(response.data.result.companies);
      return setInitialData({
        ...response.data.result,
        password_confirmation: "",
        password: "",
      });
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let formData = new FormData();
    for (let value in values) {
      if (value == "content") {
        formData.append(value, content);
      } else if (value !== "image") {
        formData.append(value, values[value]);
      }
    }

    formData.append("image", values.image);
    try {
      let response = {};
      if (contentId) {
        formData.append("_method", "PUT");
        response = await ContentsApi.Update(formData, contentId);
      } else {
        response = await ContentsApi.Save(formData);
      }

      setLoading(false);
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const onChangeContents = (contents) => {
    let _contents = null;
    if (contents.indexOf("<p><br></p>") > -1) {
      _contents = contents.replace(/<p><br><\/p>/gi, "<p>&nbsp;</p>");
    }
    setContent(_contents || contents);
  };

  return (
    <>
      {loading && <LoadingFullpage />}

      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "column" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="title">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.title}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="title"
                    >
                      Título
                    </FormLabel>
                    <Input
                      size="sm"
                      {...field}
                      id="title"
                      placeholder="Título"
                    />

                    {errors.title && (
                      <FormErrorMessage>{errors.title[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="category">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.category}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="category"
                    >
                      Categoria
                    </FormLabel>
                    <Input
                      size="sm"
                      {...field}
                      id="category"
                      placeholder="Categoria"
                    />

                    {errors.category && (
                      <FormErrorMessage>{errors.category[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="short_content">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.short_content}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="short_content"
                    >
                      Breve descrição
                    </FormLabel>
                    <Textarea size="sm" {...field} id="short_content" />

                    {errors.short_content && (
                      <FormErrorMessage>
                        {errors.short_content[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Flex
                gap={2}
                mb={8}
                flexDirection={{ sm: "column", lg: "row" }}
                w="100%"
              >
                <Field name="status">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.status}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="status"
                      >
                        Status
                      </FormLabel>
                      <Select size="sm" {...field} id="status">
                        <option value="1" key={1}>
                          Ativo
                        </option>
                        <option value="0" key={0}>
                          Inativo
                        </option>
                      </Select>

                      {errors.status && (
                        <FormErrorMessage>{errors.status[0]}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="type">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.type}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="bold"
                        colorScheme="gray"
                        htmlFor="type"
                      >
                        Tipo
                      </FormLabel>
                      <Select size="sm" {...field} id="type">
                        <option value="A" key={1}>
                          Todos
                        </option>
                        <option value="E" key={1}>
                          Empresa
                        </option>
                        <option value="F" key={0}>
                          Funcionário
                        </option>
                      </Select>

                      {errors.type && (
                        <FormErrorMessage>{errors.type[0]}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Flex
                gap={2}
                mb={8}
                flexDirection={{ sm: "column", lg: "row" }}
                w="100%"
              >
                <Flex my="20px">
                  <FormControl>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="content"
                    >
                      Mensagem
                    </FormLabel>
                    <Box bg="white" color="black">
                      <EditorToolbar />
                      <ReactQuill
                        id="snow"
                        theme="snow"
                        value={content}
                        onChange={onChangeContents}
                        placeholder={"Insira um conteúdo para o protocolo..."}
                        modules={modules}
                        formats={formats}
                      />
                    </Box>
                  </FormControl>
                </Flex>
              </Flex>
              <Field name="image">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.image}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="image"
                    >
                      Imagem
                    </FormLabel>
                    <Avatar
                      name={props.values.name}
                      src={props.values.image_url}
                      size="2xl"
                      borderRadius="12px"
                      me="18px"
                    />

                    <Button as="label" htmlFor="image" mt={2}>
                      Selecionar arquivo
                    </Button>
                    <Text mt={2}>
                      {props.values.image && (
                        <>
                          {props.values.image.name}
                          <Button
                            variant="link"
                            color="red.500"
                            onClick={() => props.setFieldValue("image", null)}
                          >
                            <BiTrash />
                          </Button>
                        </>
                      )}
                    </Text>
                    <Input
                      id="image"
                      name="image"
                      type="file"
                      display="none"
                      onChange={(event) => {
                        //console.log(event.target.files[0])
                        props.setFieldValue(
                          "image",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    {errors.image && (
                      <FormErrorMessage>{errors.image[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"
                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"
                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ContentsForm;
