import {
  Avatar,
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Text
} from '@chakra-ui/react';
import { AiOutlineMail } from 'react-icons/ai';
import { MdBusinessCenter } from 'react-icons/md';
import { useAuth } from '../../../../auth-context/auth.context';

export default function ProfileInformation() {
  const { user, setUser } = useAuth();

  return (
    <Center>
      <Box
        maxW={'320px'}
        w={'full'}
        p={6}
        textAlign={'center'}>
        <Avatar
          size={'xl'}
          name={user.name}
          alt={user.name}
          src={user.avatar_url}
          mb={4}
          pos={'relative'}
        />
        <Heading fontSize={'2xl'} fontFamily={'body'}>
          {user.name}
        </Heading>
        <Text fontWeight={600} color={'gray.500'} mb={4}>
          {user.type !== "A" &&
            user.company !== null &&
            user.company.empr_nome}
          {user.type == "A" && "Administrador"}
        </Text>
        <Flex
          alignItems="center"
          mt={4}
          color="gray.700"
          _dark={{
            color: "gray.200",
          }}
        >
          <Icon as={AiOutlineMail} h={6} w={6} mr={2} />

          <Text px={2} fontSize="sm">
            {user.email}
          </Text>
        </Flex>
        {user.type === "A" && 
          user.companies.map((row, i) => {
            return (
              <Flex
                alignItems="left"
                mt={4}
                color="gray.700"
                _dark={{
                  color: "gray.200",
                }}
              >
                <Icon as={MdBusinessCenter} h={6} w={6} mr={2} />

                <Text px={2} fontSize="sm">
                  {row.empr_nome}
                </Text>
              </Flex>

            );
          })
        }

      </Box>
    </Center>
  );
}