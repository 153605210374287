import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Td,
  Thead,
  Tr,
  useColorModeValue,
  Container,
  Button,
  Spacer,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Box,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import TablesTableRow from "./CompaniesTablesTableRow";
import TableSearch from "./CompaniesSearch";
import CompaniesApi from "../../../api/company";
import Indeterminate from "../../../components/Progress/Indeterminate";
import NotFound from "../../../components/Progress/NotFound";
import CompaniesForm from "./CompaniesForm";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import queryString from "query-string";
import { useHistory } from "react-router";
import TablePagination from "../../../components/Tables/TablePagination";
import LoadingFullpage from "components/Progress/LoadingFullpage";

function CompaniesList(props) {
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search);
  const textColor = useColorModeValue("gray.700", "white");
  const [companies, setCompanies] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState({});
  const [loading, setLoading] = useState(false);
  const [saveCompanyLoading, setSaveCompanyLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [companyId, setRoleId] = useState(false);
  const [busca, setBusca] = useState({});
  const [sort, setSort] = useState("empr_nome");
  const toast = useToast();

  useEffect(() => {
    loadData(queryParams);
  }, []);

  useEffect(() => {
    let p = queryParams.page ? queryParams.sort : 1;
    if (queryParams.sort !== sort || page !== p) {
      loadData(queryParams);
    }
  }, [sort, page]);

  const loadData = async (searchParams) => {
    setLoading(true);
    try {
      let response = await CompaniesApi.List({
        sort: sort,
        page: page,
        ...searchParams,
      });
      setLoading(false);
      if (response.data && response.data.success === false) {
        return setError(response.data.message);
      }
      setPages(response.data.meta);
      let search = new URLSearchParams({ ...searchParams, sort: sort });
      history.push(`/admin/config/companies?${search}`);
      return setCompanies(response.data.data);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const selectRow = (id) => {
    setRoleId(id);
    setForm(true);
  };

  const listSort = (column) => {
    if (sort.includes(column)) {
      if (sort.includes("-")) {
        setSort(column);
      } else {
        setSort("-" + column);
      }
    } else {
      setSort(column);
    }
  };

  const deleteRow = async (id) => {
    try {
      let response = await CompaniesApi.Delete(id);

      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return loadData();
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const addCompany = async () => {
    setSaveCompanyLoading(true);
    try {
      if (cpfCnpj === "") {
        return toast({
          title: "Erro",
          description: "Preencha o CNPJ",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
      let response = {};
      response = await CompaniesApi.AddManual({ cpfCnpj: cpfCnpj });

      setSaveCompanyLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      if (!response.data.error) {
        setCpfCnpj("");
      }

      return setForm(false);
    } catch (err) {
      setSaveCompanyLoading(false);
      let erro = "";

      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Flex direction="column" pt={{ sm: "40px", base: "120px", md: "75px" }}>
      {loading && <LoadingFullpage />}
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        _dark={{ bg: "gray.900" }}
      >
        <CardHeader p="6px 0px 22px 0px">
          <Flex w="100%" align="center" justifyContent="space-between">
            <Box>
              {!form && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Empresas
                </Text>
              )}
              {form && companyId && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Editar Empresa
                </Text>
              )}
            </Box>
            <Spacer />
            <Box>
              <Button
                leftIcon={<AiOutlinePlusCircle />}
                size="sm"
                color="white"
                bg="primary"
                cursor="pointer"
                type="button"
                onClick={() => setOpenNew(true)}
              >
                Nova empresa
              </Button>
              <Modal
                size="xl"
                isCentered
                scrollBehavior="inside"
                isOpen={openNew}
                onClose={() => setOpenNew(false)}
              >
                <ModalOverlay>
                  <ModalContent>
                    <ModalHeader fontSize="lg" fontWeight="bold">
                      Adicionar empresa
                    </ModalHeader>

                    <ModalBody>
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          CPF/CNPJ
                        </FormLabel>
                        <Input
                          borderRadius="15px"
                          mb="24px"
                          fontSize="sm"
                          type="text"
                          id="cpfCnpj"
                          placeholder="Digite seu CPF ou CNPJ"
                          size="lg"
                          defaultValue={cpfCnpj}
                          value={cpfCnpj}
                          maxLength={15}
                          readOnly={saveCompanyLoading}
                          onChange={(event) => {
                            setCpfCnpj(
                              event.target.value.replace(/[^0-9]/g, "")
                            );
                          }}
                        />
                      </FormControl>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="red"
                        onClick={() => setOpenNew(false)}
                        ml={3}
                        isLoading={saveCompanyLoading}
                        disabled={saveCompanyLoading}
                      >
                        Fechar
                      </Button>
                      <Button
                        colorScheme="green"
                        onClick={() => addCompany()}
                        ml={3}
                        isLoading={saveCompanyLoading}
                        disabled={saveCompanyLoading}
                      >
                        Adicionar
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </ModalOverlay>
              </Modal>
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          {form ? (
            <Container maxW={"12xl"} p="0">
              <CompaniesForm
                companyId={companyId}
                setForm={() => setForm()}
                loadData={() => loadData()}
              />
            </Container>
          ) : (
            <Container maxW={"12xl"} p="0">
              <TableSearch
                loadData={loadData}
                searchParams={queryParams}
                loading={loading}
              />
              <Box overflowX="auto">
                <Table variant="striped" colorScheme="gray" color={textColor}>
                  <Thead>
                    <Tr colorScheme="gray">
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("empr_nome")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-empr_nome" && <RiSortDesc />}
                          {sort == "empr_nome" && <RiSortAsc />}
                          <Text>Empresa</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("empr_cnpj")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-empr_cnpj" && <RiSortDesc />}
                          {sort == "empr_cnpj" && <RiSortAsc />}
                          <Text>CNPJ</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("empr_cnpj")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-empr_cnpj" && <RiSortDesc />}
                          {sort == "empr_cnpj" && <RiSortAsc />}
                          <Text>PlugNotas</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        w="140px"
                        textAlign="center"
                      >
                        Ações
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {companies.length == 0 && (
                      <Tr>
                        <Td px={2} colSpan={4}>
                          <NotFound />
                        </Td>
                      </Tr>
                    )}
                    {companies &&
                      companies.map((row) => {
                        return (
                          <TablesTableRow
                            key={row.id}
                            {...row}
                            selectRow={(id) => selectRow(id)}
                            deleteRow={(id) => deleteRow(id)}
                          />
                        );
                      })}
                  </Tbody>
                </Table>
              </Box>
              <TablePagination pages={pages} changePage={(e) => setPage(e)} />
            </Container>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default CompaniesList;
