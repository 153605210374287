import React, { useState } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Box
} from '@chakra-ui/react'
import DateRangePicker from '../../../components/Inputs/DateRangePicker'
import { Formik, Field, Form } from 'formik'
import { AiOutlineSearch } from 'react-icons/ai'
import { BiEraser, BiRefresh } from 'react-icons/bi'
import moment from 'moment';

function NfSearch(props) {
  const { user, loadData, searchParams, loading } = props
  const [initialValue, setInitialValue] = useState({
    rps_number: searchParams['filter[rps_number]']
      ? searchParams['filter[rps_number]']
      : '',
    nf_number: searchParams['filter[nf_number]']
      ? searchParams['filter[nf_number]']
      : '',
    created_between: searchParams['filter[created_between]']
      ? searchParams['filter[created_between]']
      : '',
    client: searchParams['filter[client]']
      ? searchParams['filter[client]']
      : '',
    company: searchParams['filter[company]']
      ? searchParams['filter[company]']
      : '',
    status: searchParams['filter[status]'] ? searchParams['filter[status]'] : ''
  })
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const search = params => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      params[`filter[created_between]`] = moment(dateRange[0]).format('YYYY-MM-DD') + '.' + moment(dateRange[1]).format('YYYY-MM-DD')
    }
    params.page = 1
    loadData(params)
  }
  const clear = () => {
    loadData({page: 1})
  }

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        let params = {}
        for (var key in values) {
          params[`filter[${key}]`] = values[key]
        }
        search(params)
      }}
    >
      {props => (
        <Form>
          <Flex
            gap={2}
            mb={2}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            <Field name='rps_number'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='rps_number'
                  >
                    Nº RPS
                  </FormLabel>
                  <Input
                     size='sm'
                    {...field}
                    id='rps_number'
                    placeholder='Nº RPS'
                  />
                </FormControl>
              )}
            </Field>
            <Field name='nf_number'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='nf_number'
                  >
                    Nº NF
                  </FormLabel>
                  <Input
                    
                    size='sm'
                    {...field}
                    id='nf_number'
                    placeholder='Nome'

                  />
                </FormControl>
              )}
            </Field>
            <Field name='created_between'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='created_between'
                  >
                    Data emissão
                  </FormLabel>
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setDateRange={(update) => {
                      setDateRange(update);
                    }}
                  />
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            {user.type === 'A' && (
              <Field name='company'>
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel
                      fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='company'
                    >
                      Empresa
                    </FormLabel>
                    <Input
                       size='sm'
                      {...field}
                      id='company'
                      placeholder='Empresa'
                    />
                  </FormControl>
                )}
              </Field>
            )}
            <Field name='client'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='client'
                  >
                    Tomador
                  </FormLabel>
                  <Input
                     
                    size='sm'
                    {...field}
                    id='client'
                    placeholder='Tomador'
                  />
                </FormControl>
              )}
            </Field>
            <Field name='status'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='status'
                  >
                    Situação
                  </FormLabel>
                  <Select
                     
                    size='sm'
                    {...field}
                    id='status'
                    placeholder='Selecione'
                  >
                    <option key='ERRO_COMUNICACAO' value='ERRO_COMUNICACAO'>
                      ERRO NA COMUNICAÇÃO
                    </option>
                    <option key='CONCLUIDO' value='CONCLUIDO'>
                      CONCLUÍDO
                    </option>
                    <option key='REJEITADO' value='REJEITADO'>
                      REJEITADO
                    </option>
                  </Select>
                </FormControl>
              )}
            </Field>
            <Flex align='end' justify='center'>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='green'
                cursor='pointer'
                
                type='submit'
              >
                <AiOutlineSearch />
              </Button>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='blue'
                cursor='pointer'
                type='button'
                
                onClick={() => {
                  props.setFieldValue('rps_number', '')
                  props.setFieldValue('nf_number', '')
                  props.setFieldValue('created_between', '')
                  props.setFieldValue('company', '')
                  props.setFieldValue('client', '')
                  props.setFieldValue('status', '')
                  clear()
                }}
              >
                <BiEraser />
              </Button>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='orange'
                cursor='pointer'
                
                type='submit'
              >
                <BiRefresh />
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default NfSearch
