import {
  Button,
  Center,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'

import { AiFillDelete, AiFillEdit } from 'react-icons/ai'

function DefaultTablesTableRow (props) {
  const { id, name, subject, system, selectRow, deleteRow } = props

  const textColor = useColorModeValue('gray.700', 'white')

  return (
    <Tr key={id}>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {name}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {subject}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {system == '1' && 'SIM'}
          {system == '0' && 'NÃO'}
        </Text>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
                cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
          {system == '0' && (
            <Tooltip label='Apagar'>
              <Button
                size='sm'
                p='0px'
                bg='red.400'
                color='white'
                cursor='pointer'
                onClick={() => deleteRow(id)}
              >
                <AiFillDelete />
              </Button>
            </Tooltip>
          )}
        </Center>
      </Td>
    </Tr>
  )
}

export default DefaultTablesTableRow
