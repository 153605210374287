import { useState } from "react";

import {
  Avatar,
  Badge,
  Button,
  Center,
  Flex,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import AuthApi from 'api/auth';

import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { RiLockPasswordFill } from "react-icons/ri";

function UsersTablesTableRow(props) {
  const {
    id,
    name,
    email,
    status,
    avatar,
    permission,
    company,
    type,
    selectRow,
    deleteRow
  } = props
  const [resetLoading, setResetLoading] = useState(false);
  const textColor = useColorModeValue('gray.700', 'white')
  const toast = useToast()

  const resetPassword = async event => {
    setResetLoading(true)
    try {
      let response = await AuthApi.ForgetPassword({
        email
      })
      setResetLoading(false)
      toast({
        title: "Sucesso",
        description: response.data.msg,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      setResetLoading(false)
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.msg;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Tr key={id}>
      <Td px={2} minWidth={{ sm: '250px' }} >
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Avatar
            name={name}
            src={avatar}
            w='50px'
            borderRadius='12px'
            me='18px'
          />
          <Flex direction='column'>
            <Text
              fontSize='md'
              color={textColor}
              minWidth='100%'
            >
              {name}
            </Text>
            <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
              {email}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {company !== null && company.empr_nome}
        </Text>
      </Td>
      <Td px={2}>
        <Text fontSize='sm' colorScheme='gray' fontWeight='normal'>
          {type == 'A' ? 'ADMINISTRADOR' : 'CLIENTE'}
        </Text>
      </Td>
      <Td px={2}>
        <Badge
          bg={status ? 'green.400' : 'red.400'}
          color='white'
          fontSize='11px'
          p='3px 10px'
          borderRadius='8px'
        >
          {status && 'ATIVO'}
          {!status && 'INATIVO'}
        </Badge>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Enviar link para alterar senha'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='orange'
              cursor='pointer'
              onClick={() => resetPassword()}
              isLoading={resetLoading}
            >
              <RiLockPasswordFill />
            </Button>
          </Tooltip>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
              cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
          <Tooltip label='Apagar'>
            <Button
              size='sm'
              p='0px'
              colorScheme='red'
              cursor='pointer'
              onClick={() => deleteRow(id)}
            >
              <AiFillDelete />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  )
}

export default UsersTablesTableRow
