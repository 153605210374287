import React, { useState } from 'react'
import { Button, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'

import { Formik, Field, Form } from 'formik'

import { AiOutlineSearch } from 'react-icons/ai'
import { BiEraser } from 'react-icons/bi'

function BanksSearch(props) {
  const { user, loadData, searchParams, loading } = props
  const [initialValue, setInitialValue] = useState({
    banc_nome: searchParams['filter[banc_nome]'] ? searchParams['filter[banc_nome]'] : '',
    banc_comp: searchParams['filter[banc_comp]']
      ? searchParams['filter[banc_comp]']
      : '',
    empr_nome: searchParams['filter[companies.empr_nome]']
      ? searchParams['filter[companies.empr_nome]']
      : ''
  })

  const search = params => {
    params.page = 1
    loadData(params)
  }
  const clear = () => {
    loadData({page: 1})
  }

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={(values, actions) => {
        let params = {}
        for (var key in values) {
          if (key == 'empr_nome') {
            params[`filter[company.empr_nome]`] = values['empr_nome']
          } else {
            params[`filter[${key}]`] = values[key]
          }
        }
        search(params)
      }}
    >
      {props => (
        <Form>
          <Flex
            gap={2}
            mb={8}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            {user.type === 'A' && (
              <Field name='empr_nome'>
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='empr_nome'
                    >
                      Empresa
                    </FormLabel>
                    <Input
                       size='sm'
                      {...field}
                      id='empr_nome'
                      placeholder='Empresa'
                    />
                  </FormControl>
                )}
              </Field>
            )}
            
            <Field name='banc_nome'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                    
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='banc_nome'
                  >
                    Nome
                  </FormLabel>
                  <Input  size='sm' {...field} id='banc_nome' placeholder='Nome' />
                </FormControl>
              )}
            </Field>
            <Field name='banc_comp'>
              {({ field, form }) => (
                <FormControl>
                  <FormLabel
                     fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='banc_comp'
                  >
                    Código
                  </FormLabel>
                  <Input
                     size='sm'
                    {...field}
                    id='banc_comp'
                    placeholder='Código'
                  />
                </FormControl>
              )}
            </Field>
            <Flex align='end' justify='center'>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='green'
                cursor='pointer'
                
                type='submit'
              >
                <AiOutlineSearch />
              </Button>
              <Button
                w={{ sm: '50%', lg: 'auto' }}
                size='sm'
                p='0px'
                me='4px'
                colorScheme='blue'
                cursor='pointer'
                type='button'
                
                onClick={() => {
                  props.setFieldValue('name', '')
                  clear()
                }}
              >
                <BiEraser />
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default BanksSearch
