import React from "react";
import {
  Avatar,
  Text,
  Box,
  HStack,
  Flex,
  Center,
  Badge,
  Spacer,
  Spinner,
} from "@chakra-ui/react";
import { AiOutlineClockCircle } from "react-icons/ai";

function ViewsTicket(props) {
  const { viewsData, loading } = props;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Box w="100%">
          {viewsData.map((msg, i) => (
            <HStack spacing={8} key={i} shadow="md" borderWidth="1px" my={2}>
              <Box p={4}>
                <Avatar size="md" name={msg.name} src={msg.avatar_url} />
              </Box>
              <Flex direction="row" p={2} w="100%">
                <Box direction="column" p={2}>
                  <Text fontSize={{ base: "sm", sm: "md", md: "lg" }}>
                    {msg.name}
                  </Text>
                  <Flex direction="row">
                    <Center gap={2}>
                      <AiOutlineClockCircle size={16} />
                      <Text fontSize="sm" direction="row" py={2}>
                        {msg.created_at}
                      </Text>
                    </Center>
                  </Flex>
                </Box>
                <Spacer />
                <Box p="4">
                  {msg.company !== "" && (
                    <Badge colorScheme="gray">{msg.company}</Badge>
                  )}
                  <Badge colorScheme="green">{msg.type}</Badge>
                </Box>
              </Flex>
            </HStack>
          ))}
        </Box>
      )}
    </>
  );
}

export default ViewsTicket;
