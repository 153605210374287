import React from 'react'
import {
  Button,
  Box,
  useToast
} from '@chakra-ui/react'
import { AiFillFolderOpen } from 'react-icons/ai'
import TicketsApi from '../../../../api/ticket'

function ReOpenTicket (props) {
  const toast = useToast()
  const { idTicket, setSaveData, setTicket, setAttachments } = props

  const saveChange = async () => {
    setSaveData(true)
    try {
      let response = {}
      response = await TicketsApi.ReOpen(idTicket)
      
      setTicket(response.data.ticket)
      setAttachments([])
      setSaveData(false)
      toast({
        title: (response.data.error)?'Erro':'Sucesso',
        description: response.data.message,
        status: (response.data.error)?'error':'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    } catch (err) {
      ////console.log(err)
      setSaveData(false)
      let erro = ''
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      return toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  return (
    <Box>
      <Button onClick={() => saveChange()} colorScheme='green' size='sm'>
        <AiFillFolderOpen /> Reabrir atendimento
      </Button>
    </Box>
  )
}

export default ReOpenTicket
