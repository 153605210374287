import { useEffect, useState } from "react";
// Chakra imports
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Container,
  Flex,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import queryString from "query-string";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import { useHistory } from "react-router";
import CompaniesNfApi from "../../../api/companyNf";
import Indeterminate from "../../../components/Progress/Indeterminate";
import NotFound from "../../../components/Progress/NotFound";
import TablePagination from "../../../components/Tables/TablePagination";
import NfForm from "./NfForm";
import TableSearch from "./NfSearch";
import TablesTableRow from "./NfTablesTableRow";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { useAuth } from "auth-context/auth.context";

function CompaniesNfList(props) {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search);
  const textColor = useColorModeValue("gray.700", "white");
  const [nfs, setNfs] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState({});
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [nfId, setNfId] = useState(false);
  const [busca, setBusca] = useState({});
  const [sort, setSort] = useState("-companies_nf.created_at");
  const toast = useToast();

  useEffect(() => {
    loadData(queryParams);
  }, []);

  useEffect(() => {
    let p = queryParams.page ? queryParams.sort : 1;
    if (queryParams.sort !== sort || page !== p) {
      loadData(queryParams);
    }
  }, [sort, page]);

  const loadData = async (searchParams) => {
    setLoading(true);

    try {
      let response = await CompaniesNfApi.List({
        sort: sort,
        page: page,
        ...searchParams,
      });
      setLoading(false);
      if (response.data && response.data.success === false) {
        return setError(response.data.message);
      }
      setPages(response.data.meta);
      let search = new URLSearchParams({ ...searchParams, sort: sort });
      history.push(`/admin/nf/list?${search}`);
      return setNfs(response.data.data);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const cancelNf = async (id) => {
    try {
      let response = await CompaniesNfApi.Delete(id);

      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return loadData();
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const refreshNf = async (id) => {
    try {
      let response = await CompaniesNfApi.Refresh(id);

      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return loadData();
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const sendEmailNf = async (idNf, email) => {
    try {
      let response = await CompaniesNfApi.Email(idNf, email);
      ////console.log(response)
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return true;
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return false;
    }
  };

  const listSort = (column) => {
    if (sort.includes(column)) {
      if (sort.includes("-")) {
        setSort(column);
      } else {
        setSort("-" + column);
      }
    } else {
      setSort(column);
    }
  };

  const selectRow = (id) => {
    setNfId(id);
    setForm(true);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {loading && <LoadingFullpage />}
      <Card _dark={{ bg: "gray.900" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex w="100%">
            <Box>
              {!form && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Notas fiscais
                </Text>
              )}
              {form && nfId && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Editar nota fiscal
                </Text>
              )}

              {form && !nfId && (
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Nova nota fiscal
                </Text>
              )}
            </Box>
            <Spacer />
            <Box>
              {!form && (
                <Button
                  leftIcon={<AiOutlinePlusCircle />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="button"
                  onClick={() => {
                    setForm(true);
                    setNfId(false);
                  }}
                >
                  Nova nota fiscal
                </Button>
              )}
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          {form ? (
            <Container maxW={"12xl"}>
              <NfForm
                user={user}
                nfId={nfId}
                setForm={() => setForm()}
                loadData={() => loadData()}
              />
            </Container>
          ) : (
            <Container maxW={"12xl"} p="0">
              {user.type !== "A" && user.company.status_plugnotas !== "1" && (
                <Alert status="error" my={4}>
                  <AlertIcon />
                  <AlertTitle>Atenção! </AlertTitle>
                  <AlertDescription>
                  Faça contato com a ContaBia para ativar a emissão de nota fiscal.
                  </AlertDescription>
                </Alert>
              )}
              <TableSearch
                loadData={loadData}
                searchParams={queryParams}
                loading={loading}
                user={user}
              />
              <Box overflowX="auto">
                <Table variant="striped" colorScheme="gray" color={textColor}>
                  <Thead>
                    <Tr my=".8rem" px={2} colorScheme="gray">
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("rps_number")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-rps_number" && <RiSortDesc />}
                          {sort == "rps_number" && <RiSortAsc />}
                          <Text>Nº RPS</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("nf_number")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-nf_number" && <RiSortDesc />}
                          {sort == "nf_number" && <RiSortAsc />}
                          <Text>Nº NF</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("companies_nf.created_at")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-companies_nf.created_at" && <RiSortDesc />}
                          {sort == "companies_nf.created_at" && <RiSortAsc />}
                          <Text>Data emissão</Text>
                        </Flex>
                      </Th>
                      {user.type === "A" && (
                        <Th
                          px={0}
                          colorScheme="gray"
                          cursor="pointer"
                          onClick={() => listSort("company.empr_nome")}
                        >
                          <Flex align="center" justify="start" gap={1}>
                            {sort == "-company.empr_nome" && <RiSortDesc />}
                            {sort == "company.empr_nome" && <RiSortAsc />}
                            <Text>Empresa</Text>
                          </Flex>
                        </Th>
                      )}
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("client.name")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-client.name" && <RiSortDesc />}
                          {sort == "client.name" && <RiSortAsc />}
                          <Text>Tomador</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("total")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-total" && <RiSortDesc />}
                          {sort == "total" && <RiSortAsc />}
                          <Text>Total</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        cursor="pointer"
                        onClick={() => listSort("plugnotas_status")}
                      >
                        <Flex align="center" justify="start" gap={1}>
                          {sort == "-plugnotas_status" && <RiSortDesc />}
                          {sort == "plugnotas_status" && <RiSortAsc />}
                          <Text>Situação</Text>
                        </Flex>
                      </Th>
                      <Th
                        px={0}
                        colorScheme="gray"
                        w="140px"
                        textAlign="center"
                      >
                        Ações
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {nfs.length == 0 && (
                      <Tr>
                        <Td px={2} colSpan={8}>
                          <NotFound />
                        </Td>
                      </Tr>
                    )}
                    {nfs &&
                      nfs.map((row) => {
                        return (
                          <TablesTableRow
                            key={row.id}
                            user={user}
                            sendEmailNf={sendEmailNf}
                            {...row}
                            cancelNf={(id) => cancelNf(id)}
                            refreshNf={(id) => refreshNf(id)}
                            selectRow={(id) => selectRow(id)}

                          />
                        );
                      })}
                  </Tbody>
                </Table>
              </Box>
              <TablePagination pages={pages} changePage={(e) => setPage(e)} />
            </Container>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default CompaniesNfList;
