import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const LoadingFullpage = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="rgba(0, 0, 0, 0.6)"
      zIndex="9999"
    >
      <Spinner color='primary' size="xl" />
    </Box>
  );
};

export default LoadingFullpage;