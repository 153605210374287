import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
  SimpleGrid,
  Tag, useToast, VStack
} from "@chakra-ui/react";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  AiFillFileExcel,
  AiFillFileImage,
  AiFillFilePdf,
  AiFillFileWord,
  AiOutlineFileZip,
  AiTwotoneFileAdd
} from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { BsFillTrashFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AllApi from "../../../../api/all";
import CompaniesApi from "../../../../api/company";
import TicketsApi from "../../../../api/ticket";
import EditorToolbar, {
  formats,
  modules
} from "../../../../components/Inputs/EditorToolbar";
import AutocompleteInput from "../../../../components/Inputs/AutocompleteInput";

function TicketForm(props) {
  const { ticketId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState({});
  const [companies, setCompanies] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [topicComment, setTopicComment] = useState("");
  const [subTopicComment, setSubTopicComment] = useState("");
  const [typeComment, setTypeComment] = useState("");
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companySelected, setCompanySelected] = useState("");
  const toast = useToast();
  const [initialData, setInitialData] = useState({
    cod_ticket: "",
    id_company: "",
    month: "",
    year: "",
    id_sla: "",
    id_priority: "",
    id_type: "",
    id_subtopic: "",
    id_status: "",
    id_topic: "",
    id_department: "",
    subject: "",
    content: "",
    id_user_create: "",
    id_solver: "",
    external_ticket: "",
    competence: "",
    attachment: [],
  });
  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    if (ticketId !== false && !loading) {
      loadTicket();
    }
  }, [ticketId]);

  const loadTicket = async () => {
    setLoading(true);
    try {
      let response = await TicketsApi.Show(ticketId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }
      setContent(response.data.result.content);
      setSubtopics(response.data.result.subtopics);
      setAttachments(response.data.result.attachments);
      return setInitialData({
        ...response.data.result,
        password_confirmation: "",
        password: "",
      });
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };
  const loadOptions = async () => {
    try {
      let response = await AllApi.allOptions();
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        return setOptions(false);
      }

      return setOptions(response.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setOptions({});
    }
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let formData = new FormData();
      for (let value in values) {
        if (value == "content") {
          formData.append(value, content);
        } else if (value !== "attachment") {
          formData.append(value, values[value]);
        } else {
          for (const element of values[value]) {
            formData.append("attachment[]", element);
          }
        }
      }

      let response = {};
      if (ticketId) {
        formData.append("_method", "PUT");
        response = await TicketsApi.Update(formData, ticketId);
      } else {
        response = await TicketsApi.Save(formData);
      }

      setLoading(false);
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getCompanies = async (word) => {
    if (word && word.length > 3) {
      setLoadingCompanies(true);
      let response = await CompaniesApi.Search(word);
      let companies = response.data.map((value, index) => {
        return {
          id: value.id,
          name: value.empr_nome,
        };
      });

      setCompanies({ data: companies });
      setLoadingCompanies(false);
    } else {
      setCompanies([]);
      setLoadingCompanies(false);
    }
  };

  const onChangeContents = (contents) => {
    let _contents = null;
    if (contents.indexOf("<p><br></p>") > -1) {
      _contents = contents.replace(/<p><br><\/p>/gi, "<p>&nbsp;</p>");
    }
    setContent(_contents || contents);
  };

  

  return (
    <>
      {loading && <LoadingFullpage />}
      
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            {ticketId && (
              <Flex mb={8}>
                <Tag
                  fontSize={20}
                  px={4}
                  py={2}
                  variant="outline"
                  colorScheme="blue"
                >
                  {props.values.cod_ticket}
                </Tag>
              </Flex>
            )}
            <Flex
              h="full"
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
            >
              <FormControl isInvalid={errors.id_company}>
                <FormLabel
                  fontSize="sm"
                  fontWeight="bold"
                  colorScheme="gray"
                  htmlFor="id_company"
                >
                  Empresa
                </FormLabel>

                <AutocompleteInput
                  loading={loadingCompanies}
                  options={companies}
                  setOptions={setCompanies}
                  requests={getCompanies}
                  placeholder="Pesquisar empresa"
                  onClickFunction={(e) => {
                    props.setFieldValue("id_company", e.id);
                    setCompanySelected(e.id);
                  }}
                />
                {errors.id_company && (
                  <FormErrorMessage>{errors.id_company[0]}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="external_ticket">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.external_ticket}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="external_ticket"
                    >
                      Protocolo externo
                    </FormLabel>
                    <Input
                      type="text"
                      size="sm"
                      {...field}
                      id="external_ticket"
                    />

                    {errors.external_ticket && (
                      <FormErrorMessage>
                        {errors.external_ticket[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="competence">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.competence}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="competence"
                    >
                      Competência
                    </FormLabel>
                    <Input type="month" size="sm" {...field} id="competence" />

                    {errors.competence && (
                      <FormErrorMessage>
                        {errors.competence[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="id_department">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_department}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_department"
                    >
                      Departamento
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_department"
                      placeholder={ticketId ? "" : "Selecione"}
                    >
                      {options.departments &&
                        options.departments.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`dpto${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            );
                          }
                        })}
                    </Select>

                    {errors.id_department && (
                      <FormErrorMessage>
                        {errors.id_department[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_sla">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_sla}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_sla"
                    >
                      Sla
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_sla"
                      placeholder={ticketId ? "" : "Selecione"}
                    >
                      {options.slas &&
                        options.slas.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`sla${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            );
                          }
                        })}
                    </Select>

                    {errors.id_sla && (
                      <FormErrorMessage>{errors.id_sla[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_priority">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_priority}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_priority"
                    >
                      Prioridade
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_priority"
                      placeholder={ticketId ? "" : "Selecione"}
                    >
                      {options.priorities &&
                        options.priorities.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`priority${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            );
                          }
                        })}
                    </Select>

                    {errors.id_priority && (
                      <FormErrorMessage>
                        {errors.id_priority[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_status" placeholder={ticketId ? "" : "Status"}>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_status}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_status"
                    >
                      Status
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_status"
                      placeholder={ticketId ? "" : "Selecione"}
                    >
                      {options.status &&
                        options.status.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`status${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            );
                          }
                        })}
                    </Select>

                    {errors.id_status && (
                      <FormErrorMessage>{errors.id_status[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="id_topic">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_topic}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_topic"
                    >
                      Tópico
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_topic"
                      placeholder={ticketId ? "" : "Selecione"}
                      onChange={(e) => {
                        let comment = options.topics.map((val, i) => {
                          if (val.id == e.target.value) {
                            return val.comments;
                          }
                        });
                        setTopicComment(comment);
                        props.setFieldValue("id_topic", e.target.value);
                      }}
                    >
                      {options.topics &&
                        options.topics.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`topic${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            );
                          }
                        })}
                    </Select>

                    {errors.id_topic && (
                      <FormErrorMessage>{errors.id_topic[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>

              <Field name="id_subtopic">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_subtopic}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_subtopic"
                    >
                      Subtópico
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_subtopic"
                      placeholder={ticketId ? "" : "Selecione"}
                      onChange={(e) => {
                        let comment = options.subtopics.map((val, i) => {
                          if (val.id == e.target.value) {
                            return val.comments;
                          }
                        });
                        setSubTopicComment(comment);
                        props.setFieldValue("id_subtopic", e.target.value);
                      }}
                    >
                      {options.subtopics &&
                        options.subtopics.map((val, i) => {
                          if (
                            val.topics[props.values.id_topic] !== undefined &&
                            val.status
                          ) {
                            return (
                              <option key={`subtopic${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            );
                          }
                        })}
                    </Select>

                    {errors.id_subtopic && (
                      <FormErrorMessage>
                        {errors.id_subtopic[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="id_type">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_type}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="id_type"
                    >
                      Tipo
                    </FormLabel>
                    <Select
                      size="sm"
                      {...field}
                      id="id_type"
                      placeholder={ticketId ? "" : "Selecione"}
                      onChange={(e) => {
                        let comment = options.types.map((val, i) => {
                          if (val.id == e.target.value) {
                            return val.comments;
                          }
                        });
                        setTypeComment(comment);
                        props.setFieldValue("id_type", e.target.value);
                      }}
                    >
                      {options.types &&
                        options.types.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_type && (
                      <FormErrorMessage>{errors.id_type[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <VStack spacing={4} align="stretch">
              {topicComment !== null && topicComment !== "" && (
                <Box
                  background="gray.50"
                  borderRadius="xl"
                  p="5"
                  borderColor="gray.100"
                  boxShadow="md"
                >
                  {ReactHtmlParser(topicComment)}
                </Box>
              )}
              {subTopicComment !== null && subTopicComment !== "" && (
                <Box
                  background="gray.50"
                  borderRadius="xl"
                  p="5"
                  borderColor="gray.100"
                  boxShadow="md"
                >
                  {ReactHtmlParser(subTopicComment)}
                </Box>
              )}
              {typeComment !== null && typeComment !== "" && (
                <Box
                  background="gray.50"
                  borderRadius="xl"
                  p="5"
                  borderColor="gray.100"
                  boxShadow="md"
                >
                  {ReactHtmlParser(typeComment)}
                </Box>
              )}
            </VStack>
            <Flex
              gap={2}
              my={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="subject">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.subject}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="subject"
                    >
                      Assunto
                    </FormLabel>
                    <Input type="text" size="sm" {...field} id="subject" />

                    {errors.subject && (
                      <FormErrorMessage>{errors.subject[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Flex my="20px">
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    colorScheme="gray"
                    htmlFor="content"
                  >
                    Mensagem
                  </FormLabel>
                  <Box bg="white" color='black'>
                    <EditorToolbar />
                    <ReactQuill
                      id="snow"
                      theme="snow"
                      value={content}
                      onChange={onChangeContents}
                      placeholder={"Insira um conteúdo para o protocolo..."}
                      modules={modules}
                      formats={formats}
                    />
                  </Box>
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="attachment">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.avatar}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      htmlFor="attachment"
                      display="block"
                      cursor="pointer"
                      py={1}
                      borderRadius="full"
                      textAlign="left"
                      w={200}
                    >
                      <Flex 
                      style={{ /*display: "none" zIndex: 9999*/ }}
                      background="gray.200"
                      borderRadius={10}
                      padding={2}
                      textAlign={"center"}
                      >Incluir anexos</Flex>
                    </FormLabel>

                    <Input
                      style={{ display: "none" }}
                      id="attachment"
                      name="attachment"
                      type="file"
                      multiple
                      onChange={(event) => {
                        props.setFieldValue(
                          "attachment",
                          event.currentTarget.files
                        );
                        ////console.log(props.values.attachment)
                      }}
                      className="form-control w-full block mt-2"
                    />
                    <Flex flexDirection="column">
                      {Object.keys(props.values.attachment).map(
                        (key, index) => (
                          <Flex flexDirection="row" alignItems="center" my="1">
                            <BsFillTrashFill
                              cursor="pointer"
                              onClick={() => {
                                ////console.log(props.values.attachment)
                                let fileListArr = Array.from(
                                  props.values.attachment
                                );
                                fileListArr.splice(key, 1);
                                props.setFieldValue("attachment", fileListArr);
                              }}
                            />
                            {props.values.attachment[key].name}
                          </Flex>
                        )
                      )}
                    </Flex>
                    {errors.attachment && (
                      <FormErrorMessage>
                        {errors.attachment[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            {ticketId && (
              <>
                <Box fontSize="sm" fontWeight="bold" colorScheme="gray">
                  Anexos
                </Box>
                <SimpleGrid columns={4} spacing={2} mb={10}>
                  {attachments &&
                    attachments.map((val, i) => (
                      <Link isExternal href={val.url} gap={2} mt="10px">
                        <Box
                          boxShadow="xs"
                          px="2"
                          py="1"
                          rounded="md"
                          bg="white"
                        >
                          <Flex
                            gap={2}
                            flexDirection="row"
                            w="100%"
                            alignItems="center"
                          >
                            {val.type.search("pdf") >= 0 && (
                              <AiFillFilePdf color="#F56565" />
                            )}
                            {val.type.search("xls") >= 0 && (
                              <AiFillFileExcel color="#48BB78" />
                            )}
                            {val.type.search("image") >= 0 && (
                              <AiFillFileImage color="#9F7AEA" />
                            )}
                            {val.type.search("doc") >= 0 && (
                              <AiFillFileWord color="#4299E1" />
                            )}
                            {(val.type.search("zip") >= 0 ||
                              val.type.search("rar") >= 0) && (
                                <AiOutlineFileZip color="#ECC94B" />
                              )}
                            {val.type.search("txt") >= 0 && (
                              <AiTwotoneFileAdd color="#FEEBC8" />
                            )}
                            {val.name}
                          </Flex>
                        </Box>
                      </Link>
                    ))}
                </SimpleGrid>
              </>
            )}
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"

                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"

                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default TicketForm;
