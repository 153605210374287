import axios from './index'

class AuthApi {
  static Login = data => {
    return axios.post(`${base}/login`, data)
  }

  static ForgetPassword = data => {
    return axios.post(`${base}/forget-password`, data)
  }

  static ResetPassword = data => {
    return axios.post(`${base}/reset-password`, data)
  }

  static Register = data => {
    return axios.post(`${base}/create`, data)
  }

  static Logout = data => {
    return axios.post(`admin/logout`, data)
  }

  static Me = data => {
    return axios.post(`admin/me`, data)
  }
}

let base = 'auth'

export default AuthApi
