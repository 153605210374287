import React, { useState } from "react";
import {
  Alert,
  AlertIcon,
  Badge,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  Link,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import { AiFillDelete, AiOutlineLink } from "react-icons/ai";

function PaymentLinkTablesTableRow(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openContabia, setOpenContabia] = useState(false);
  const [openContabiaP, setOpenContabiaP] = useState(false);
  const {
    id,
    amount,
    id_contabia,
    created_at,
    deleted_at,
    description,
    emailNotification,
    expiration,
    id_link,
    phoneNotification,
    status,
    updated_at,
    url_link,
    company,
    selectRow,
    deleteRow,
    user,
    client,
  } = props;

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Tr key={id}>
      {user.type === "A" && (
        <Td px={2} minWidth={{ sm: "250px" }}>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="md" color={textColor} minWidth="100%">
              {company.empr_nome}
            </Text>
          </Flex>
        </Td>
      )}
      <Td px={2} minWidth={{ sm: "250px" }}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={textColor} minWidth="100%">
            {client.name} {client.cpf_cnpj}
          </Text>
        </Flex>
      </Td>
      <Td px={2} minWidth={{ sm: "250px" }}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={textColor} minWidth="100%">
            {description}
          </Text>
        </Flex>
      </Td>
      <Td px={2} minWidth={{ sm: "250px" }}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={textColor} minWidth="100%">
            R$ {amount}
          </Text>
        </Flex>
      </Td>
      <Td px={2}>
        <Center w="100%">
          <Tooltip label="Link de pagamento">
            <Button
              onClick={onOpen}
              size="sm"
              p="0px"
              mx="2"
              colorScheme="blue"
              cursor="pointer"
            >
              <AiOutlineLink />
            </Button>
          </Tooltip>
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Link de pagamento</ModalHeader>
              <ModalBody>
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Tbody>
                      <Tr>
                        <Td>ID</Td>
                        <Td>{id_link}</Td>
                      </Tr>
                      <Tr>
                        <Td>Cliente</Td>
                        <Td>{client.name}</Td>
                      </Tr>
                      <Tr>
                        <Td>CPF/CNPJ</Td>
                        <Td>{client.cpf_cnpj}</Td>
                      </Tr>
                      <Tr>
                        <Td>Celular</Td>
                        <Td>{phoneNotification}</Td>
                      </Tr>
                      <Tr>
                        <Td>E-mail</Td>
                        <Td>{emailNotification}</Td>
                      </Tr>
                      <Tr>
                        <Td>Descrição</Td>
                        <Td>{description}</Td>
                      </Tr>
                      <Tr>
                        <Td>Valor</Td>
                        <Td>{amount}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </ModalBody>

              <ModalFooter>
                <Link href={url_link} isExternal>
                  <Button colorScheme="blue" mr={3}>
                    Abrir link <ExternalLinkIcon mx="2px" />
                  </Button>
                </Link>
                <Button colorScheme="gray" mr={3} onClick={onClose}>
                  Fechar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Tooltip label="Apagar">
            <Button
              size="sm"
              p="0px"
              colorScheme="red"
              cursor="pointer"
              onClick={() => deleteRow(id)}
            >
              <AiFillDelete />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  );
}

export default PaymentLinkTablesTableRow;
