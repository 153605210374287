import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { BiSave } from 'react-icons/bi'
import { BsFillTrashFill } from 'react-icons/bs'
import { MdOutlineCancel } from 'react-icons/md'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AllApi from '../../../../api/all'
import TicketsApi from '../../../../api/ticket'
import EditorToolbar, {
  formats,
  modules
} from '../../../../components/Inputs/EditorToolbar'

function TicketsFormHome(props) {
  const { resetForm } = props
  const [errors, setErrors] = useState({})
  const [options, setOptions] = useState({})
  const [attachments, setAttachments] = useState([])
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState('')
  const [topicComment, setTopicComment] = useState('')
  const [subTopicComment, setSubTopicComment] = useState('')
  const [typeComment, setTypeComment] = useState('')
  const toast = useToast()
  const [initialData, setInitialData] = useState({
    cod_ticket: '',
    month: '',
    year: '',
    id_sla: props.idSla,
    id_priority: props.idPriority,
    id_type: props.idType,
    id_subtopic: props.idSubtopic,
    id_status: props.idStatus,
    id_topic: props.idTopic,
    id_department: props.idDepartment,
    subject: props.subject,
    content: '',
    competence: '',
    attachment: []
  })
  useEffect(() => {
    loadOptions()
  }, [])

  const loadOptions = async () => {
    try {
      let response = await AllApi.allOptions()
      if (response.data.error === true) {
        toast({
          title: 'Erro',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })

        return setOptions(false)
      }

      return setOptions(response.data)
    } catch (err) {
      let erro = ''
      if (err.response) {
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      return setOptions({})
    }
  }
  const handleSubmit = async values => {
    setLoading(true)
    try {
      let formData = new FormData()
      ////console.log(initialData)
      for (let init in initialData) {
        if (!values[init]) {
          values[init] = initialData[init]
        }
      }
      for (let value in values) {
        if (value == 'content') {
          formData.append(value, content)
        } else if (value !== 'attachment') {
          formData.append(value, values[value])
        } else {
          for (const element of values[value]) {
            formData.append('attachment[]', element)
          }
        }
      }
      ////console.log(values)
      let response = {}
      response = await TicketsApi.Save(formData)

      setLoading(false)
      toast({
        title: 'Sucesso',
        description: response.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
      return resetForm()
    } catch (err) {
      setLoading(false)
      let erro = ''
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
        erro = err.response.data.message
      } else {
        erro = 'Ocorreu um erro inesperado.'
      }

      return toast({
        title: 'Erro',
        description: erro,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  const onChangeContents = contents => {
    let _contents = null
    if (contents.indexOf('<p><br></p>') > -1) {
      _contents = contents.replace(/<p><br><\/p>/gi, '<p>&nbsp;</p>')
    }
    setContent(_contents || contents)
  }

  return (
    <Formik
      initialValues={initialData}
      enableReinitialize
      onSubmit={async values => {
        handleSubmit(values)
      }}
    >
      {props => (
        <Form>
          <Flex maxWidth='200px'>
            <Field name='competence'>
              {({ field, form }) => (
                <FormControl isInvalid={errors.competence}>
                  <FormLabel
                    fontSize='sm'
                    fontWeight='bold'
                    colorScheme='gray'
                    htmlFor='competence'

                  >
                    Competência
                  </FormLabel>
                  <Input type='month' size='sm' {...field} id='competence' />

                  {errors.competence && (
                    <FormErrorMessage>{errors.competence[0]}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex
            gap={2}
            mb={2}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            {!initialData.id_department && (
              <Field name='id_department'>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_department}>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='id_department'
                    >
                      Departamento
                    </FormLabel>
                    <Select

                      size='sm'
                      {...field}
                      id='id_department'
                      placeholder='Selecione'
                    >
                      {options.departments &&
                        options.departments.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`dpto${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            )
                          }
                        })}
                    </Select>

                    {errors.id_department && (
                      <FormErrorMessage>
                        {errors.id_department[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            )}
            {!initialData.id_sla && (
              <Field name='id_sla'>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_sla}>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='id_sla'
                    >
                      Sla
                    </FormLabel>
                    <Select

                      size='sm'
                      {...field}
                      id='id_sla'
                      placeholder='Selecione'
                    >
                      {options.slas &&
                        options.slas.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`sla${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            )
                          }
                        })}
                    </Select>

                    {errors.id_sla && (
                      <FormErrorMessage>{errors.id_sla[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            )}
            {!initialData.id_priority && (
              <Field name='id_priority'>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_priority}>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='id_priority'
                    >
                      Prioridade
                    </FormLabel>
                    <Select

                      size='sm'
                      {...field}
                      id='id_priority'
                      placeholder='Selecione'
                    >
                      {options.priorities &&
                        options.priorities.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`priority${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            )
                          }
                        })}
                    </Select>

                    {errors.id_priority && (
                      <FormErrorMessage>
                        {errors.id_priority[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            )}
            {!initialData.id_status && (
              <Field name='id_status'>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_status}>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='id_status'
                    >
                      Status
                    </FormLabel>
                    <Select

                      size='sm'
                      {...field}
                      id='id_status'
                      placeholder='Selecione'
                    >
                      {options.status &&
                        options.status.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`status${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            )
                          }
                        })}
                    </Select>

                    {errors.id_status && (
                      <FormErrorMessage>{errors.id_status[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            )}
          </Flex>
          <Flex
            gap={2}
            mb={2}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            {!initialData.id_topic && (
              <Field name='id_topic'>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_topic}>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='id_topic'
                    >
                      Tópico
                    </FormLabel>
                    <Select

                      size='sm'
                      {...field}
                      id='id_topic'
                      placeholder='Selecione'
                      onChange={e => {
                        let comment = options.topics.map((val, i) => {
                          if (val.id == e.target.value) {
                            return val.comments
                          }
                        })
                        setTopicComment(comment)
                        props.setFieldValue('id_topic', e.target.value)
                      }}
                    >
                      {options.topics &&
                        options.topics.map((val, i) => {
                          if (val.status) {
                            return (
                              <option key={`topic${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            )
                          }
                        })}
                    </Select>

                    {errors.id_topic && (
                      <FormErrorMessage>{errors.id_topic[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            )}
            {!initialData.id_subtopic && (
              <Field name='id_subtopic'>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_subtopic}>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='id_subtopic'
                    >
                      Subtópico
                    </FormLabel>
                    <Select

                      size='sm'
                      {...field}
                      id='id_subtopic'
                      placeholder='Selecione'
                      onChange={e => {
                        let comment = options.subtopics.map((val, i) => {
                          if (val.id == e.target.value) {
                            return val.comments
                          }
                        })
                        setSubTopicComment(comment)
                        props.setFieldValue('id_subtopic', e.target.value)
                      }}
                    >
                      {options.subtopics &&
                        options.subtopics.map((val, i) => {
                          if (
                            val.topics[props.values.id_topic] !== undefined &&
                            val.status
                          ) {
                            return (
                              <option key={`subtopic${val.id}`} value={val.id}>
                                {val.name}
                              </option>
                            )
                          }
                        })}
                    </Select>

                    {errors.id_subtopic && (
                      <FormErrorMessage>
                        {errors.id_subtopic[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            )}
            {!initialData.id_type && (
              <Field name='id_type'>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.id_type}>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='id_type'
                    >
                      Tipo
                    </FormLabel>
                    <Select

                      size='sm'
                      {...field}
                      id='id_type'
                      placeholder='Selecione'
                      onChange={e => {
                        let comment = options.types.map((val, i) => {
                          if (val.id == e.target.value) {
                            return val.comments
                          }
                        })
                        setTypeComment(comment)
                        props.setFieldValue('id_type', e.target.value)
                      }}
                    >
                      {options.types &&
                        options.types.map((val, i) => (
                          <option key={`dpto${val.id}`} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                    </Select>

                    {errors.id_type && (
                      <FormErrorMessage>{errors.id_type[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            )}
          </Flex>
          {!initialData.subject && (
            <Flex
              gap={2}
              my={8}
              flexDirection={{ sm: 'column', lg: 'row' }}
              w='100%'
            >
              <Field name='subject'>
                {({ field, form }) => (
                  <FormControl isInvalid={errors.subject}>
                    <FormLabel
                      _dark={{
                        color: 'gray.500'
                      }} fontSize='sm'
                      fontWeight='bold'
                      colorScheme='gray'
                      htmlFor='subject'
                    >
                      Assunto
                    </FormLabel>
                    <Input type='text' size='sm' {...field} id='subject' />

                    {errors.subject && (
                      <FormErrorMessage>{errors.subject[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
          )}
          <Flex
            gap={2}
            mb={2}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            <Flex my='20px'>
              <FormControl>
                <FormLabel
                  fontSize='sm'
                  fontWeight='bold'
                  colorScheme='gray'
                  htmlFor='content'
                >
                  Mensagem
                </FormLabel>
                <Box bg="white" color='black'>
                  <EditorToolbar />
                  <ReactQuill
                    id='snow'
                    theme='snow'
                    value={content}
                    onChange={onChangeContents}
                    placeholder={'Insira um conteúdo para o protocolo...'}
                    modules={modules}
                    formats={formats}
                  />
                </Box>
              </FormControl>
            </Flex>
          </Flex>
          <Flex
            gap={2}
            mb={2}
            flexDirection={{ sm: 'column', lg: 'row' }}
            maxWidth='200px'
          >
            <Field name='attachment'>
              {({ field, form }) => (
                <FormControl isInvalid={errors.avatar}>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    htmlFor="attachment"
                    display="block"
                    cursor="pointer"
                    py={1}
                    borderRadius="full"
                    textAlign="left"
                    w={200}
                  >
                    <Flex 
                      style={{ /*display: "none" zIndex: 9999*/ }}
                      background="gray.200"
                      borderRadius={10}
                      padding={2}
                      textAlign={"center"}
                      >Incluir anexos</Flex>
                  </FormLabel>

                  <Input
                    style={{ display: 'none' }}
                    id='attachment'
                    name='attachment'
                    type='file'
                    multiple
                    onChange={event => {
                      props.setFieldValue(
                        'attachment',
                        event.currentTarget.files
                      )
                      ////console.log(props.values.attachment)
                    }}
                    className='form-control'
                  />

                  {errors.attachment && (
                    <FormErrorMessage>{errors.attachment[0]}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex flexDirection='column'>
            {Object.keys(props.values.attachment).map((key, index) => (
              <Flex flexDirection='row' alignItems='center' my='1'>
                <BsFillTrashFill
                  cursor='pointer'
                  onClick={() => {
                    ////console.log(props.values.attachment)
                    let fileListArr = Array.from(props.values.attachment)
                    fileListArr.splice(key, 1)
                    props.setFieldValue('attachment', fileListArr)
                  }}
                />
                {props.values.attachment[key].name}
              </Flex>
            ))}
          </Flex>
          <Flex
            gap={2}
            mb={2}
            flexDirection={{ sm: 'column', lg: 'row' }}
            w='100%'
          >
            <Flex
              align='end'
              justify='end'
              gap={2}
              mt='10px'
              flexDirection={{ sm: 'column', lg: 'row' }}
            >
              <Button
                leftIcon={<MdOutlineCancel />}
                size='sm'
                colorScheme='gray'
                cursor='pointer'
                type='button'

                w={{ sm: '100%', lg: 'auto' }}
                onClick={() => {
                  resetForm()
                }}
              >
                Cancelar
              </Button>
              <Button
                leftIcon={<BiSave />}
                size='sm'
                colorScheme='blue'
                cursor='pointer'
                type='submit'

                w={{ sm: '100%', lg: 'auto' }}
              >
                Salvar
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default TicketsFormHome
