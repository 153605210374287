import axios from './index'

class DepartmentsApi {
  static List = data => {
    let search = new URLSearchParams(data);
    return axios.get(`${base}?${search}`)
  }

  static Show = id => {
    return axios.get(`${base}/${id}`, )
  }

  static Save = (data) => {
    return axios.post(`${base}`, data)
  }

  static Update = (data, id) => {
    return axios.put(`${base}/${id}`, data)
  }

  static Delete = id => {
    return axios.delete(`${base}/${id}`)
  }

  static All = () => {
    return axios.get(`admin/list-departments`)
  }

}

let base = 'admin/department'

export default DepartmentsApi
