import React from 'react'
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Tooltip,
  Center,
  useColorModeValue
} from '@chakra-ui/react'

import { AiFillEdit, AiFillDelete } from 'react-icons/ai'

function RolesTablesTableRow (props) {
  const { id, name, selectRow, deleteRow } = props

  const textColor = useColorModeValue('gray.700', 'white')

  return (
    <Tr key={id}>
      <Td px={2} minWidth={{ sm: '250px' }} >
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Text
            fontSize='md'
            color={textColor}
            minWidth='100%'
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
                cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
          <Tooltip label='Apagar'>
            <Button
              size='sm'
              p='0px'
              colorScheme='red'
                cursor='pointer'
              onClick={() => deleteRow(id)}
            >
              <AiFillDelete />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  )
}

export default RolesTablesTableRow
