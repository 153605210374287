import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ColorPicker from "../../../../components/Inputs/ColorPicker";

import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import StatusApi from "../../../../api/status";

function StatusForm(props) {
  const { StatusId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([{}]);
  const toast = useToast();
  const [initialData, setInitialData] = useState({
    name: "",
    type: "",
    color: "",
    default: "0",
    block_edit: "0",
    show_user: "0",
    indicator: "0",
    wait_approve: "0",
    status: "1",
  });
  useEffect(() => {
    if (StatusId !== false && !loading) {
      loadDataItem();
    }
  }, [StatusId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await StatusApi.Show(StatusId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }

      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = {};
      if (StatusId) {
        response = await StatusApi.Update(values, StatusId);
      } else {
        response = await StatusApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>

              <Field name="status">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.status}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="status"
                    >
                      Status
                    </FormLabel>
                    <Select size="sm" {...field} id="status">
                      <option value="1" key={1}>
                        Ativo
                      </option>
                      <option value="0" key={0}>
                        Inativo
                      </option>
                    </Select>

                    {errors.status && (
                      <FormErrorMessage>{errors.status[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "row" }}
              w="100%"
            >
              <Field name="type">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.type}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="type"
                    >
                      Considerar
                    </FormLabel>
                    <Select size="sm" {...field} id="type">
                      <option value="C" key="C">
                        Fechado
                      </option>
                      <option value="O" key="D">
                        Aberto
                      </option>
                    </Select>

                    {errors.type && (
                      <FormErrorMessage>{errors.type[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="block_edit">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.block_edit}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="block_edit"
                    >
                      Bloqueia edição
                    </FormLabel>
                    <Select size="sm" {...field} id="block_edit">
                      <option value="1" key="1">
                        Sim
                      </option>
                      <option value="0" key="0">
                        Não
                      </option>
                    </Select>

                    {errors.block_edit && (
                      <FormErrorMessage>
                        {errors.block_edit[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="show_user">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.show_user}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="show_user"
                    >
                      Mostrar para o cliente
                    </FormLabel>
                    <Select size="sm" {...field} id="show_user">
                      <option value="1" key="1">
                        Sim
                      </option>
                      <option value="0" key="0">
                        Não
                      </option>
                    </Select>

                    {errors.show_user && (
                      <FormErrorMessage>{errors.show_user[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="indicator">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.indicator}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="indicator"
                    >
                      Indicador
                    </FormLabel>
                    <Select size="sm" {...field} id="indicator">
                      <option value="1" key="1">
                        Sim
                      </option>
                      <option value="0" key="0">
                        Não
                      </option>
                    </Select>

                    {errors.indicator && (
                      <FormErrorMessage>{errors.indicator[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="wait_approve">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.wait_approve}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="wait_approve"
                    >
                      Aguardar aprovação
                    </FormLabel>
                    <Select size="sm" {...field} id="wait_approve">
                      <option value="1" key="1">
                        Sim
                      </option>
                      <option value="0" key="0">
                        Não
                      </option>
                    </Select>

                    {errors.wait_approve && (
                      <FormErrorMessage>
                        {errors.wait_approve[0]}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="default">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.default}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="default"
                    >
                      Padrão
                    </FormLabel>
                    <Select size="sm" {...field} id="default">
                      <option value="1" key={1}>
                        Sim
                      </option>
                      <option value="0" key={0}>
                        Não
                      </option>
                    </Select>

                    {errors.default && (
                      <FormErrorMessage>{errors.default[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%">
              <ColorPicker
                label=""
                onChange={(color) => {
                  props.setFieldValue("color", color);
                }}
              />
            </Flex>
            <Flex flexDirection={{ sm: "column", lg: "row" }} mt="10px">
              <Badge
                backgroundColor={props.values.color}
                color={props.values.color != "" ? "#ffffff" : "#333333"}
                fontSize="md"
                fontWeight="extrabold"
                borderRadius={6}
                py={1}
                px={4}
              >
                {props.values.name}
              </Badge>
            </Flex>
            <Flex
              align="end"
              justify="end"
              gap={2}
              mt="10px"
              flexDirection={{ sm: "column", lg: "row" }}
            >
              <Button
                leftIcon={<MdOutlineCancel />}
                size="sm"
                colorScheme="gray"
                cursor="pointer"
                type="button"
                w={{ sm: "100%", lg: "auto" }}
                onClick={() => {
                  setForm(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                leftIcon={<BiSave />}
                size="sm"
                colorScheme="blue"
                
                cursor="pointer"
                type="submit"
                w={{ sm: "100%", lg: "auto" }}
              >
                Salvar
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default StatusForm;
