// Chakra imports
import {
  Flex,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorMode,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Badge,
} from "@chakra-ui/react";

import StatsWithIcons from "./HomeAdminBoxes/StatsWithIcons";
import DashboardsApi from "../../api/dashboards";
import { useEffect, useState } from "react";
import CardSimple from "components/Card/CardSimple";
import { FaAddressBook } from "react-icons/fa";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import { VscLayersActive } from "react-icons/vsc";
import { MdInsertComment, MdToday } from "react-icons/md";
import { BiTimer } from "react-icons/bi";
import { BsCalendarRangeFill, BsFillCalendarMinusFill } from "react-icons/bs";

export default function HomeAdmin() {
  const [loadingContents, setLoadingContents] = useState(true);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    getContents();
  }, []);

  const getContents = async () => {
    setLoadingContents(true);
    let response = await DashboardsApi.Home();
    setContents(response.data);
    setLoadingContents(false);
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px" mb="20px">
        <CardSimple
          textColor="primary"
          iconColor="primary"
          icon={<VscLayersActive color="white" />}
          title="Protocolos ativos"
          value={contents?.active}
          loading={loadingContents}
          descritption="Protocolos ativos no sistema"
        />
        <CardSimple
          textColor="primary"
          iconColor="primary"
          icon={<MdToday color="white" />}
          title="Vencendo hoje"
          value={contents?.expiration}
          loading={loadingContents}
          descritption="Protocolos vencendo hoje"
        />
        <CardSimple
          textColor="primary"
          iconColor="primary"
          icon={<BsFillCalendarMinusFill color="white" />}
          title="Vencem amanhã"
          value={contents?.tomorrow}
          loading={loadingContents}
          descritption="Vencem amanhã"
        />
        <CardSimple
          textColor="primary"
          iconColor="primary"
          icon={<BiTimer color="white" />}
          title="Em atraso"
          value={contents?.expired}
          loading={loadingContents}
          descritption="Protocolos em atraso"
        />
        <CardSimple
          textColor="primary"
          iconColor="primary"
          icon={<BsCalendarRangeFill color="white" />}
          title="Recentes"
          value={contents?.recents}
          loading={loadingContents}
          descritption="Abertos nos últimos 7 dias"
        />
        <CardSimple
          textColor="primary"
          iconColor="primary"
          icon={<MdInsertComment color="white" />}
          title="Hoje"
          value={contents?.today}
          loading={loadingContents}
          descritption="Abertos hoje"
        />
      </SimpleGrid>

      <SimpleGrid spacing={4} columns={4}>
        {!loadingContents &&
          Object.keys(contents.companies).map((key) => {
            return (
              <Card key={key}>
                <CardHeader>
                  <Heading size="md" color="gray.400">{contents.companies[key].title}</Heading>
                </CardHeader>
                <CardBody px="0">
                  <Flex flexDirection="column" w="full">
                    {Object.keys(contents.companies[key].departments).map(
                      (keyD) => {
                        return (
                          <Card key={keyD} bg="gray.50" _dark={{bg: 'gray.800'}} w="full" my="2" px="2">
                            <CardHeader>
                              <Heading size="md" fontSize="sm" color="gray.400">
                                {
                                  contents.companies[key].departments[keyD]
                                    .title
                                }
                              </Heading>
                            </CardHeader>
                            <CardBody>
                              <TableContainer w="full" >
                                <Table size="sm" variant='simple'>
                                  <Tbody>
                                    {contents.companies[key].departments[
                                      keyD
                                    ].status.map((status) => {
                                      return (
                                        <Tr>
                                          <Td mx="0" px="0">
                                            <Badge
                                              bg={status.color}
                                              color="white"
                                              fontSize="10px"
                                              mx="2"
                                              p="10px"
                                              borderRadius="10px"
                                            ></Badge>
                                            {status.title}
                                          </Td>
                                          <Td isNumeric>{status.total}</Td>
                                        </Tr>
                                      );
                                    })}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </CardBody>
                          </Card>
                        );
                      }
                    )}
                  </Flex>
                </CardBody>
                {/* <CardFooter>
            <Button>View here</Button>
          </CardFooter> */}
              </Card>
            );
          })}
      </SimpleGrid>
    </Flex>
  );
}
