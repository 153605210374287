import React from 'react'
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Tooltip,
  Center,
  useColorModeValue
} from '@chakra-ui/react'

import { AiFillEdit, AiFillDelete } from 'react-icons/ai'

function StatusTablesTableRow (props) {
  const { id, name, type, color, defaultStatus, status, selectRow, deleteRow } = props

  const textColor = useColorModeValue('gray.700', 'white')

  return (
    <Tr key={id}>
      <Td px={2} minWidth={{ sm: '250px' }} >
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Text
            fontSize='md'
            color={textColor}
            minWidth='100%'
          >
            <Badge
                bg={color}
                color='white'
                fontSize='10px'
                mx='2'
                p='10px'
                borderRadius='10px'
              >
              </Badge>
            {name}
            {defaultStatus == 1 && (
              <Badge
                bg='gray.400'
                color='white'
                fontSize='10px'
                mx='2'
                p='0px 4px'
                borderRadius='8px'
              >
                PADRÃO
              </Badge>
            )}
          </Text>
        </Flex>
      </Td>
      <Td px={2} p={0}>
        <Badge
          color={type == 'O' ? 'green.400' : 'red.400'}
          fontSize='11px'
          p='3px 10px'
          borderRadius='8px'
        >
          {type == 'O' ? 'ABERTO' : 'FECHADO'}
        </Badge>
      </Td>
      <Td px={2} p={0}>
        <Badge
          bg={status ? 'green.400' : 'red.400'}
          color='white'
          fontSize='11px'
          p='3px 10px'
          borderRadius='8px'
        >
          {status && 'ATIVO'}
          {!status && 'INATIVO'}
        </Badge>
      </Td>
      <Td px={2}>
        <Center w='100%'>
          <Tooltip label='Editar'>
            <Button
              size='sm'
              p='0px'
              me='4px'
              colorScheme='blue'
                cursor='pointer'
              onClick={() => selectRow(id)}
            >
              <AiFillEdit />
            </Button>
          </Tooltip>
          <Tooltip label='Apagar'>
            <Button
              size='sm'
              p='0px'
              colorScheme='red'
                cursor='pointer'
              onClick={() => deleteRow(id)}
            >
              <AiFillDelete />
            </Button>
          </Tooltip>
        </Center>
      </Td>
    </Tr>
  )
}

export default StatusTablesTableRow
