import { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Container,
  Flex,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingFullpage from "components/Progress/LoadingFullpage";
import queryString from "query-string";
import { AiOutlinePlusCircle, AiOutlineWhatsApp } from "react-icons/ai";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import { useHistory } from "react-router";
import TicketsApi from "../../../../api/ticket";
import NotFound from "../../../../components/Progress/NotFound";
import TablePagination from "../../../../components/Tables/TablePagination";
import TicketsForm from "./TicketsForm";
import TableSearch from "./TicketsSearch";
import TablesTableRow from "./TicketsTablesTableRow";
import AllApi from "api/all";
import { useQuery, useQueryClient } from "react-query";
import { useAuth } from "auth-context/auth.context";

function Tickets(props) {
  const history = useHistory();
  const { user, setUser } = useAuth();
  // const queryParams = queryString.parse(window.location.search);
  const textColor = useColorModeValue("gray.700", "white");
  // const [tickets, setTickets] = useState(false);
  const [searchParams, setSearchParams] = useState(
    queryString.parse(window.location.search)
  );
  const [pages, setPages] = useState({});
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [ticketId, setticketId] = useState(false);
  const [ticketCod, setticketCod] = useState(false);
  const [sort, setSort] = useState("-created_at");
  const [page, setPage] = useState(1);
  const toast = useToast();

  const queryClient = useQueryClient();
  const {
    status,
    data: tickets,
    isError,
    isLoading,
    refetch,
    error,
  } = useQuery(
    [
      "tickets",
      {
        sort: sort,
        page: page,
        ...searchParams,
      },
    ],
    () =>
      TicketsApi.List({
        sort: sort,
        page: page,
        ...searchParams,
      }).then((res) => res.data.data),
    { keepPreviousData: true, staleTime: 5000 }
  );

  useEffect(() => {
    refetch();
  }, [sort, searchParams, page]);

  // useEffect(() => {
  //   toast({
  //     title: "Erro",
  //     description: "Ocorreu um erro inesperado.",
  //     status: "error",
  //     duration: 3000,
  //     isClosable: true,
  //     position: "top-right",
  //   });
  // }, [isError]);

  const loadData = async (searchParams) => {
    setSearchParams(searchParams);
    refetch();
  };

  const selectRow = (id, cod_ticket) => {
    setticketId(id);
    setticketCod(cod_ticket);
    setForm(true);
  };

  const listSort = (column) => {
    if (sort.includes(column)) {
      if (sort.includes("-")) {
        setSort(column);
      } else {
        setSort("-" + column);
      }
    } else {
      setSort(column);
    }
  };

  const deleteRow = async (id) => {
    try {
      let response = await TicketsApi.Delete(id);

      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      toast({
        title: "Sucesso",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return refetch();
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Flex direction="column" pt={{ sm: "40px", base: "120px", md: "75px" }}>
        {isLoading && <LoadingFullpage />}
        
        <Card
          overflowX={{ sm: "scroll", xl: "hidden" }}
          _dark={{ bg: "gray.900" }}
        >
          <CardHeader p="6px 0px 22px 0px">
            <Flex w="100%" align="center" justifyContent="space-between">
              <Box>
                {!form && (
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Protocolos
                  </Text>
                )}
                {form && ticketId && (
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Editar protocolo
                  </Text>
                )}
                {form && !ticketId && (
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Novo protocolo
                  </Text>
                )}
              </Box>
              <Spacer />
              <Box>
                {!form && (
                  <Button
                    leftIcon={<AiOutlinePlusCircle />}
                    size="sm"
                    colorScheme="blue"
                    cursor="pointer"
                    type="button"
                    onClick={() => {
                      setForm(true);
                      setticketId(false);
                    }}
                  >
                    Novo protocolo
                  </Button>
                )}
              </Box>
            </Flex>
          </CardHeader>
          <CardBody>
            {form ? (
              <Container maxW={"12xl"} p="0">
                <TicketsForm
                  ticketId={ticketId}
                  setForm={() => setForm()}
                  loadData={() => loadData()}
                />
              </Container>
            ) : (
              <Container maxW={"12xl"} p="0">
                <TableSearch
                  loadData={loadData}
                  searchParams={(searchParams)?searchParams:{}}
                  loading={loading}
                  user={user}
                />
                <Box overflowX="auto">
                  <Table variant="striped" colorScheme="gray" color={textColor}>
                    <Thead>
                      <Tr colorScheme="gray">
                        <Th
                          px={0}
                          colorScheme="gray"
                          cursor="pointer"
                          onClick={() => listSort("cod_ticket")}
                        >
                          <Flex align="center" justify="start" gap={1}>
                            {sort == "-cod_ticket" && <RiSortDesc />}
                            {sort == "cod_ticket" && <RiSortAsc />}
                            <Text>Protocolo</Text>
                          </Flex>
                        </Th>
                        {user.type === "A" && (
                          <Th
                            px={0}
                            colorScheme="gray"
                            cursor="pointer"
                            onClick={() => listSort("company.empr_nome")}
                          >
                            <Flex align="center" justify="start" gap={1}>
                              {sort == "-company.empr_nome" && <RiSortDesc />}
                              {sort == "company.empr_nome" && <RiSortAsc />}
                              Empresa
                            </Flex>
                          </Th>
                        )}
                        <Th
                          px={0}
                          colorScheme="gray"
                          cursor="pointer"
                          onClick={() => listSort("created_at")}
                        >
                          <Flex align="center" justify="start" gap={1}>
                            {sort == "-created_at" && <RiSortDesc />}
                            {sort == "created_at" && <RiSortAsc />}
                            Data
                          </Flex>
                        </Th>
                        {user.type === "A" && (
                          <Th px={0} colorScheme="gray" cursor="pointer">
                            <Flex align="center" justify="start" gap={1}>
                              Usuário
                            </Flex>
                          </Th>
                        )}
                        <Th
                          px={0}
                          colorScheme="gray"
                          w="140px"
                          textAlign="center"
                        >
                          Ações
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {tickets && tickets.length == 0 && (
                        <Tr>
                          <Td px={2} colSpan={4}>
                            <NotFound />
                          </Td>
                        </Tr>
                      )}
                      {tickets &&
                        tickets.map((row) => {
                          return (
                            <TablesTableRow
                              user={user}
                              key={row.id}
                              id={row.id}
                              cod_ticket={row.cod_ticket}
                              company={row.company}
                              month={row.month}
                              year={row.year}
                              sla={row.sla}
                              priority={row.priority}
                              type={row.type}
                              subtopic={row.subtopic}
                              status={row.status}
                              topic={row.topic}
                              department={row.department}
                              subject={row.subject}
                              content={row.content}
                              date_last_message={row.date_last_message}
                              user_create={row.user_create}
                              solver={row.solver}
                              external_ticket={row.external_ticket}
                              date_validity={row.date_validity}
                              date_previous_validity={
                                row.date_previous_validity
                              }
                              date_closed={row.date_closed}
                              created_at={row.created_at}
                              attachments={row.attachments}
                              defeated={row.defeated}
                              today={row.today}
                              next_day={row.next_day}
                              selectRow={(id) => selectRow(id)}
                              deleteRow={(id) => deleteRow(id)}
                            />
                          );
                        })}
                    </Tbody>
                  </Table>
                </Box>
                <TablePagination pages={pages} changePage={(e) => setPage(e)} />
              </Container>
            )}
          </CardBody>
        </Card>
      </Flex>
    </>
  );
}

export default Tickets;
