import React, {useState} from 'react'
import { CirclePicker  } from 'react-color'

export default function ColorPicker ({ label, onChange }) {
  const [color, setColor] = useState('#333')
  const handleChange = color => {
    setColor(color.hex)
  }
  return (
    <CirclePicker 
        color={color}
        onChange={handleChange}
        onChangeComplete={color => onChange(color.hex)}
      />
  )
}
