import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import LoadingFullpage from "components/Progress/LoadingFullpage";
import { Field, Form, Formik } from "formik";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import RolesApi from "../../../api/roles";

function RolesForm(props) {
  const { roleId, setForm, loadData } = props;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([{}]);
  const toast = useToast();
  const [initialData, setInitialData] = useState({
    name: "",
    permissions: [],
  });
  useEffect(() => {
    loadPermissions();
  }, []);
  useEffect(() => {
    if (roleId !== false && !loading) {
      loadDataItem();
    }
  }, [roleId]);

  const loadDataItem = async () => {
    setLoading(true);
    try {
      let response = await RolesApi.Show(roleId);
      setLoading(false);
      if (response.data.error === true) {
        toast({
          title: "Erro",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return setForm(false);
      }

      return setInitialData(response.data.result);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return setForm(false);
    }
  };

  const loadPermissions = async () => {
    try {
      let perms = await RolesApi.Permissions();
      //console.log(perms);
      return setPermissions(perms.data);
    } catch (err) {
      let erro = "";
      if (err.response) {
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let response = {};
      if (roleId) {
        response = await RolesApi.Update(values, roleId);
      } else {
        response = await RolesApi.Save(values);
      }

      setLoading(false);
      toast({
        title: response.data.error ? "Erro" : "Sucesso",
        description: response.data.message,
        status: response.data.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      loadData();
      return setForm(false);
    } catch (err) {
      setLoading(false);
      let erro = "";
      if (err.response) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }
        erro = err.response.data.message;
      } else {
        erro = "Ocorreu um erro inesperado.";
      }

      return toast({
        title: "Erro",
        description: erro,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      {loading && <LoadingFullpage />}
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Flex
              gap={2}
              mb={8}
              flexDirection={{ sm: "column", lg: "column" }}
              w="100%"
            >
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      _dark={{
                        color: "gray.500",
                      }}
                      fontSize="sm"
                      fontWeight="bold"
                      colorScheme="gray"
                      htmlFor="name"
                    >
                      Nome
                    </FormLabel>
                    <Input size="sm" {...field} id="name" placeholder="Nome" />

                    {errors.name && (
                      <FormErrorMessage>{errors.name[0]}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
              {permissions.map((group, i) => {
                return (
                  <Box
                    borderWidth="1px"
                    key={`perms${i}`}
                    borderRadius="md"
                    bg="gray.50"
                    px={4}
                    py={6}
                    my={2}
                    _dark={{
                      bg: "gray.800",
                      borderColor: "gray.700",
                    }}
                  >
                    <Flex direction="column">
                      <Text fontWeight="bold" colorScheme="gray">
                        {group.name}
                      </Text>
                      <Flex direction="column">
                        <SimpleGrid columns={4} spacing={2}>
                          {group.perms &&
                            group.perms.map((val, i) => {
                              return (
                                <Box p="2" key={val.id}>
                                  <Field
                                    name="permissions"
                                    type="checkbox"
                                    value={val.name}
                                    key={val.id}
                                  >
                                    {({ field, form }) => (
                                      <Checkbox
                                        {...field}
                                        colorScheme="gray"
                                        isChecked={field.checked}
                                      >
                                        <Text>{val.title}</Text>
                                      </Checkbox>
                                    )}
                                  </Field>
                                </Box>
                              );
                            })}
                        </SimpleGrid>
                      </Flex>
                    </Flex>
                  </Box>
                );
              })}
              <Flex
                align="end"
                justify="end"
                gap={2}
                mt="10px"
                flexDirection={{ sm: "column", lg: "row" }}
              >
                <Button
                  leftIcon={<MdOutlineCancel />}
                  size="sm"
                  colorScheme="gray"
                  cursor="pointer"
                  type="button"
                  w={{ sm: "100%", lg: "auto" }}
                  onClick={() => {
                    setForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  leftIcon={<BiSave />}
                  size="sm"
                  colorScheme="blue"
                  cursor="pointer"
                  type="submit"
                  w={{ sm: "100%", lg: "auto" }}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default RolesForm;
