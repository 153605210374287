// Chakra imports
import { SimpleGrid, useColorModeValue, Grid } from "@chakra-ui/react";
import ChangeUserData from "./components/ChangeUserData";
import Departments from "./components/Departments";
import ProfileInformation from "./components/ProfileInformation";
import { useAuth } from "auth-context/auth.context";

function Profile() {
  const { user, setUser } = useAuth();
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  return (
    <SimpleGrid columns={(user.type === "A") ? 3 : 2} my={20} gap={4} >
      <ProfileInformation />
      <ChangeUserData />
      {
        user.type === "A" &&
        <Departments />
      }
    </SimpleGrid >
  );
}

export default Profile;
