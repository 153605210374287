import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    primary: '#4f4991',
    secondary: '#49bac5',
    gray: {
      700: "#1f2733",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.50", "gray.800")(props),
        fontFamily: 'Helvetica, sans-serif'
      },
      html: {
        fontFamily: 'Helvetica, sans-serif'
      },
      '::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      
      '::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(0,0,0,0.1)' 
      },
      
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#4f4991',
      },
      
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
      '*': {
        scrollbarWidth: 'thin',
        scrollbarColor: '#4f4991 #f1f1f1',
      },
      
      '*::-moz-scrollbar': {
        width: '8px',
        height: '8px',
      },
      
      '*::-moz-scrollbar-track': {
        backgroundColor: 'rgba(0,0,0,0.1)' 
      },
      
      '*::-moz-scrollbar-thumb': {
        backgroundColor: '#4f4991',
      },
      
      '*::-moz-scrollbar-thumb:hover': {
        backgroundColor: '#4f4991',
      },
    }),
  }
  
};
